import React from 'react';
import useCubeWithQueryErrorHandling from '../../hooks/useCubeWithQueryErrorHandling';
import StatisticsCard from '../../StatisticsCard';
import { dotFormattedNumber } from '../../../../core/helper/numberFormatter';

function CompanyCards({ companyFilterValue, setCompanyFilterValue }) {
    const query = {
        measures: ['CompanyStats.clicked', 'CompanyStats.seen', 'CompanyStats.follows'],
        dimensions: ['CompanyStats.companyId'],
    };
    const { resultSet, isLoading } = useCubeWithQueryErrorHandling(query);

    if (!resultSet) {
        return null;
    }
    let data = {};
    if (resultSet) {
        [data = {}] = resultSet.tablePivot();
    }

    return (
        <>
            <StatisticsCard
                title="Follower"
                tooltipTitle="Wie häufig wurde Ihr Unternehmen in der App als Favorit gespeichert."
                data={dotFormattedNumber(data['CompanyStats.follows']) || '0'}
                active={companyFilterValue === 'Follows'}
                isLoading={isLoading}
                key={data['CompanyStats.companyId']}
                onClick={() => setCompanyFilterValue('Follows')}
            />
            <StatisticsCard
                title="Klicks"
                tooltipTitle="Wie häufig wurde Ihr Unternehmen in der App aktiv aufgerufen."
                data={dotFormattedNumber(data['CompanyStats.clicked']) || '0'}
                active={companyFilterValue === 'Clicked'}
                isLoading={isLoading}
                key={data['CompanyStats.companyId']}
                onClick={() => setCompanyFilterValue('Clicked')}
            />
            <StatisticsCard
                title="Gesehen"
                tooltipTitle="Wie häufig wurde Ihr Unternehmen in der App auf der Startseite oder in Suchergebnislisten angezeigt."
                data={dotFormattedNumber(data['CompanyStats.seen']) || '0'}
                active={companyFilterValue === 'Seen'}
                isLoading={isLoading}
                key={data['CompanyStats.companyId']}
                onClick={() => setCompanyFilterValue('Seen')}
            />
        </>
    );
}

export default CompanyCards;
