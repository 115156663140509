import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input, Icon, Form, Select, Button } from 'antd';
import { addToArray, removeFromArray, changeArrayElement } from '../../core/redux/actions';
import { generateID } from '../../core/helper/redux';
import { ProfileLayout } from '../../components';
import { defaultSocialMedium } from '../../core/helper/defaultObjects';
import buttonWithPopconfirm from '../../decorators/buttonWithPopconfirm';
import buttonWithTooltip from '../../decorators/buttonWithTooltip/index';
import urlPattern from '../../core/helper/urlPattern';

const { Option } = Select;

class SocialMedia extends Component {
    constructor(props) {
        super(props);
        this.socialMediaPlattformOpts = this.socialMediaPlattforms.map((plattform, index) => (
            <Option key={plattform.text} value={plattform.text}>
                {plattform.text}
            </Option>
        ));
    }

    socialMediaPlattforms = [
        { text: 'Blog', iconSet: 'MaterialCommunityIcons', icon: 'blogger' },
        { text: 'Facebook', iconSet: 'MaterialCommunityIcons', icon: 'facebook' },
        { text: 'GitHub', iconSet: 'MaterialCommunityIcons', icon: 'github-circle' },
        { text: 'Google+', iconSet: 'MaterialCommunityIcons', icon: 'google-plus' },
        { text: 'Instagram', iconSet: 'MaterialCommunityIcons', icon: 'instagram' },
        { text: 'Kununu', iconSet: 'MaterialCommunityIcons', icon: 'kununu' },
        { text: 'LinkedIn', iconSet: 'MaterialCommunityIcons', icon: 'linkedin' },
        { text: 'Pinterest', iconSet: 'MaterialCommunityIcons', icon: 'pinterest' },
        { text: 'SnapChat', iconSet: 'MaterialCommunityIcons', icon: 'snapchat' },
        { text: 'X', iconSet: '', icon: 'x' },
        { text: 'Vimeo', iconSet: 'MaterialCommunityIcons', icon: 'vimeo' },
        { text: 'Website', iconSet: 'MaterialCommunityIcons', icon: 'web' },
        { text: 'WhatsApp', iconSet: 'MaterialCommunityIcons', icon: 'whatsapp' },
        { text: 'Xing', iconSet: 'MaterialCommunityIcons', icon: 'xing' },
        { text: 'YouTube', iconSet: 'MaterialCommunityIcons', icon: 'youtube-play' },
        { text: 'Twitch', iconSet: 'MaterialCommunityIcons', icon: 'twitch' },
        { text: 'TikTok', iconSet: 'MaterialCommunityIcons', icon: 'tiktok' },
        { text: 'Glassdoor', iconSet: 'MaterialCommunityIcons', icon: 'glassdoor' },
        { text: 'watchado', iconSet: 'MaterialCommunityIcons', icon: 'watchado' },
        { text: 'Spotify', iconSet: 'MaterialCommunityIcons', icon: 'spotify' },
        { text: 'Andere', iconSet: 'MaterialCommunityIcons', icon: 'globe' },
    ].sort((a, b) => a.text.toUpperCase().localeCompare(b.text.toUpperCase()));

    handleAddSocialMedium = () => {
        this.props.addToArray('profile.socialMedia', {
            ...defaultSocialMedium,
            tempID: generateID(),
        });
    };

    handleRemoveSocialMedium = (indexOfChannel) => {
        this.props.removeFromArray('profile.socialMedia', indexOfChannel);
    };

    handleSocialMediumChange = (attributeName, value, indexOfChannel) => {
        let socialMedium = this.props.socialMedia[indexOfChannel];
        if (attributeName === 'plattform') {
            socialMedium = {
                ...socialMedium,
                ...this.socialMediaPlattforms.find((plattform) => plattform.text === value),
            };
        } else if (attributeName === 'uri') {
            socialMedium.uri = value;
        }

        this.props.changeArrayElement('profile.socialMedia', indexOfChannel, {
            ...socialMedium,
        });
    };

    renderSocialMediaSelect = (plattform, indexOfChannel) => {
        const selectProps = {
            onChange: (value) => this.handleSocialMediumChange('plattform', value, indexOfChannel),
            style: { width: 150 },
            value: plattform,
        };

        return <Select {...selectProps}>{this.socialMediaPlattformOpts}</Select>;
    };

    onBlurCompletion = (fieldName) => {
        const { getFieldValue, setFields, validateFields } = this.props.form;
        const value = getFieldValue(fieldName);
        if (
            value.length > 0 &&
            value.startsWith &&
            !value.startsWith('https://') &&
            !value.startsWith('http://')
        ) {
            const newValue = {};
            newValue[fieldName] = { value: `https://${value}`, errors: null };
            setFields(newValue);
        }
        validateFields([fieldName]);
    };

    renderSocialMediaForm = (socialMedium, indexOfChannel) => {
        const { getFieldDecorator } = this.props.form;
        const { tempID, _id, uri, text } = socialMedium;
        const key = tempID || _id;
        return (
            <Form.Item key={key}>
                {getFieldDecorator(`medium[${key}]`, {
                    rules: [
                        {
                            required: true,
                            pattern: urlPattern,
                            message:
                                'Bitte geben Sie eine gültige URL an (z.B. https://example.com )',
                        },
                    ],
                    initialValue: uri === text ? '' : uri,
                    validateTrigger: null,
                })(
                    <Input
                        placeholder={text}
                        onChange={(e) =>
                            this.handleSocialMediumChange('uri', e.target.value, indexOfChannel)
                        }
                        onBlur={() => this.onBlurCompletion(`medium[${key}]`)}
                        addonBefore={this.renderSocialMediaSelect(text, indexOfChannel)}
                        addonAfter={buttonWithPopconfirm({
                            placement: 'top',
                            title: 'Wollen Sie dieses Medium wirklich löschen?',
                            onConfirm: () => this.handleRemoveSocialMedium(indexOfChannel),
                            okText: 'Löschen',
                        })(buttonWithTooltip('Medium löschen')(<Icon type="delete" />))}
                    />,
                )}
            </Form.Item>
        );
    };

    handleSubmit = () =>
        new Promise((resolve, reject) =>
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    resolve(true);
                }
                resolve(false);
            }),
        );

    render() {
        const socialMediaForms = this.props.socialMedia.map((medium, index) =>
            this.renderSocialMediaForm(medium, index),
        );

        return (
            <ProfileLayout
                subHeaderSelectedKey="Soziale Medien"
                onSubmitMin={this.handleSubmit}
                infoText="Geben Sie die Social-Media-Kanäle Ihres Unternehmens an, um sich mit Ihren potentiellen Mitarbeitern zu vernetzen.">
                <Form>
                    {socialMediaForms}
                    <Button type="primary" icon="plus" onClick={this.handleAddSocialMedium}>
                        Medium hinzufügen
                    </Button>
                </Form>
            </ProfileLayout>
        );
    }
}

const mapStateToProps = (state) => ({
    socialMedia: state.company.profile.socialMedia,
});

const mapDispatchToProps = (dispatch) => ({
    addToArray: (attributeName, element) => dispatch(addToArray(attributeName, element)),
    removeFromArray: (attributeName, index) => dispatch(removeFromArray(attributeName, index)),
    changeArrayElement: (attributeName, index, updatedElement) =>
        dispatch(changeArrayElement(attributeName, index, updatedElement)),
});

const WrappedSocialMedia = Form.create()(SocialMedia);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedSocialMedia);
