import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import { Row, Col, Icon } from 'antd';

import UNIcon from '../../UNIcon';

class Benefits extends Component {
    renderBenefit = (el) => (
        <Row className="rowContainer" key={el.key} type="flex" align="middle">
            <Col span={4}>
                <UNIcon icon={el.icon} className="benefitsIcon" />
            </Col>
            <Col span={18}>{el.text}</Col>
        </Row>
    );

    render() {
        return (
            <div
                style={{
                    height: '100%',
                    overflowY: 'scroll',
                }}>
                <div
                    className="navbar centerText"
                    style={{
                        borderBottom: '1px solid #edeff4',
                        backgroundColor: '#ffffff',
                    }}>
                    <Link to="/profil">
                        <Icon
                            type="left"
                            style={{
                                fontSize: 12,
                                margin: 6,
                                float: 'left',
                            }}
                            className="color-primary"
                        />
                    </Link>
                </div>

                <div className="navbarSpacer" />

                {this.props.benefits.length > 0 &&
                    this.props.benefits
                        .map(this.renderBenefit)
                        .reduce((prev, curr) => [
                            prev,
                            <div key={`${curr.key}_divider`} className="divider" />,
                            curr,
                        ])}
            </div>
        );
    }
}

const mapStateToProps = ({ company }) => ({
    benefits: company.profile.benefits,
});

export default connect(mapStateToProps)(Benefits);
