/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { RequestContext } from '../../components/Session/RequestProvider';

function PlanSelect({ onChange, value }) {
    const [options, setOptions] = useState([]);
    const { request } = useContext(RequestContext);
    const [loading, setLoading] = useState(true);
    const hasPlanOverrides = useSelector(
        (state) => state.company.planOverrides && !isEmpty(state.company.planOverrides),
    );
    const load = async () => {
        setLoading(true);
        try {
            const { body } = await request('recruiting/config/plans');
            setOptions(body?.sort((a, b) => a.order - b.order) ?? []);
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };
    useEffect(() => {
        load().catch();
    }, []);
    return (
        <Select
            value={value._id}
            style={{ width: '100%' }}
            onSelect={(id) => onChange(options.find(({ _id }) => _id === id))}
            options={options}
            loading={loading}>
            {options.map((plan) => (
                <Select.Option key={plan._id} value={plan._id}>
                    {plan.title}
                    {hasPlanOverrides && <span> (angepasst)</span>}
                </Select.Option>
            ))}
        </Select>
    );
}

PlanSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PlanSelect;
