import React, { useContext, useEffect, useState } from 'react';
import { Form, Input, Spin } from 'antd';
import { RequestContext } from '../../components/Session/RequestProvider';

const FormItem = Form.Item;
const { TextArea } = Input;
function Updates({ jobID }) {
    const [data, setData] = useState('');
    const [loading, setLoading] = useState(true);
    const { request } = useContext(RequestContext);
    const loadUpdates = async () => {
        if (!jobID) {
            return;
        }
        const { body } = await request('recruiting/job/getNotes', { jobID });

        if (body) {
            setData(body);
        }
        setLoading(false);
    };
    useEffect(() => {
        loadUpdates();
    }, [jobID]);
    return (
        <FormItem label="Schnittstelleninformationen:">
            {loading ? (
                <Spin />
            ) : (
                <TextArea
                    placeholder="Updates von Schnittstellen"
                    autoSize={{ minRows: 4 }}
                    value={data}
                    readOnly
                />
            )}
        </FormItem>
    );
}

export default Updates;
