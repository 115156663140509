import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Table, Icon, Badge, Input, notification, Tooltip } from 'antd';
import moment from 'moment';
import '../style.css';
import AdvertStatsModal from './AdvertStatsModal';
import AdCards from './AdCards';
import useCubeWithQueryErrorHandling from '../../hooks/useCubeWithQueryErrorHandling';
import { dotFormattedNumber, formatAsPercentage } from '../../../../core/helper/numberFormatter';

const TITLE_THRESHOLD = 70;
const NO_DATE = moment('1970', 'YYYY');

const ALL_STATUSES = [
    { text: 'aktiv', value: 'ACTIVE' },
    { text: 'geplant', value: 'PLANNED' },
    { text: 'inaktiv', value: 'INACTIVE' },
    { text: 'gelöscht', value: 'REMOVED' },
    { text: 'archiviert', value: 'ARCHIVED' },
];
const DEFAULT_STATUS = ['PLANNED', 'INACTIVE', 'ACTIVE', 'REMOVED', 'ARCHIVED'];
const DEFAULT_CUBE_QUERY = {
    measures: ['AdvertStats.clicked', 'AdvertStats.seen', 'AdvertStats.closed'],
    dimensions: [
        'AdvertStats.advertId',
        'AdvertStats.title',
        'AdvertStats.status',
        'AdvertStats.startDate',
        'AdvertStats.endDate',
        'AdvertStats.createdAt',
        'AdvertStats.usedAdCoins',
        'AdvertStats.shortId',
    ],
};

const getCubeFiltersQuery = (values) => ({
    filters: [
        {
            member: 'AdvertStats.status',
            operator: 'equals',
            values,
        },
    ],
});
const filterByStatus = (value, record) => {
    return record['AdvertStats.status'] === value;
};

const Ad = () => {
    let data = [];
    const [filter, setFilter] = useState(DEFAULT_STATUS);
    let searchInput = useRef(null);
    const query = (adFilter) =>
        adFilter.length
            ? { ...getCubeFiltersQuery(adFilter), ...DEFAULT_CUBE_QUERY }
            : DEFAULT_CUBE_QUERY;
    const { resultSet, isLoading } = useCubeWithQueryErrorHandling(query(filter));

    if (!resultSet) {
        return null;
    }

    if (resultSet) {
        data = resultSet.tablePivot();
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'AdvertStats.title',
            key: 'AdvertStats.title',
            filterIcon: (filtered) => (
                <Icon
                    type="filter"
                    style={{ color: filtered ? '#f21850' : '#aaa', marginBottom: 4 }}
                />
            ),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
                return (
                    <div id="TableFilter">
                        <Input.Search
                            ref={(ele) => {
                                searchInput = ele;
                            }}
                            placeholder="Suchen"
                            value={selectedKeys[0]}
                            onChange={(e) =>
                                setSelectedKeys(e.target.value ? [e.target.value] : [])
                            }
                            onSearch={() => confirm()}
                            onPressEnter={() => confirm()}
                            enterButton
                        />
                    </div>
                );
            },
            onFilter: (value, record) =>
                record['AdvertStats.title'].toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => {
                        searchInput.focus();
                    });
                }
            },
            render: (title, record) => {
                return (
                    <>
                        <Link
                            to={`?advertId=${record['AdvertStats.advertId']}`}
                            style={{ color: '#f21850' }}
                            replace>
                            {title.length > TITLE_THRESHOLD
                                ? `${title.substring(0, TITLE_THRESHOLD)}...`
                                : title}
                        </Link>
                        <div style={{ color: 'rgb(130, 130, 140)' }}>
                            {record['AdvertStats.shortId']}
                        </div>
                    </>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'AdvertStats.status',
            key: 'AdvertStats.status',
            align: 'center',
            defaultFilteredValue: filter,
            filters: ALL_STATUSES,
            onFilter: filterByStatus,
            render: (visible, record) => {
                if (record['AdvertStats.status'] === 'REMOVED') {
                    return (
                        <span>
                            <Badge status="error" /> gelöscht
                        </span>
                    );
                }

                if (record['AdvertStats.status'] === 'ARCHIVED') {
                    return (
                        <span>
                            <Badge status="error" /> archiviert
                        </span>
                    );
                }
                if (record['AdvertStats.status'] === 'INACTIVE') {
                    return (
                        <span>
                            <Badge status="error" /> inaktiv
                        </span>
                    );
                }
                if (
                    (record['AdvertStats.startDate'] &&
                        moment(record['AdvertStats.startDate']).isAfter(moment())) ||
                    record['AdvertStats.status'] === 'PLANNED'
                ) {
                    return (
                        <span>
                            <Badge status="warning" /> geplant
                        </span>
                    );
                }
                return (
                    <span>
                        <Badge status="success" /> aktiv
                    </span>
                );
            },
        },
        {
            title: 'Erstellungsdatum',
            dataIndex: 'AdvertStats.createdAt',
            key: 'AdvertStats.createdAt',
            sorter: (a, b) =>
                (a['AdvertStats.startDate'] ? moment(a['AdvertStats.startDate']) : NO_DATE).diff(
                    b['AdvertStats.startDate'] ? moment(b['AdvertStats.startDate']) : NO_DATE,
                ),
            render: (createdAt) => (
                <div>
                    {moment(createdAt).format('DD.MM.YYYY')}
                    <br />
                    <br />
                </div>
            ),
        },
        {
            title: 'Startdatum',
            dataIndex: 'AdvertStats.startDate',
            key: 'AdvertStats.startDate',
            sorter: (a, b) =>
                (a['AdvertStats.startDate'] ? moment(a['AdvertStats.startDate']) : NO_DATE).diff(
                    b['AdvertStats.startDate'] ? moment(b['AdvertStats.startDate']) : NO_DATE,
                ),
            render: (startDate) => (
                <div>
                    {startDate && moment(startDate).format('DD.MM.YYYY')}
                    <br />
                    <br />
                </div>
            ),
        },
        {
            title: 'Enddatum',
            dataIndex: 'AdvertStats.endDate',
            key: 'AdvertStats.endDate',
            sorter: (a, b) =>
                (a['AdvertStats.endDate'] ? moment(a['AdvertStats.endDate']) : NO_DATE).diff(
                    b['AdvertStats.endDate'] ? moment(b['AdvertStats.endDate']) : NO_DATE,
                ),
            render: (endDate) => (
                <div>
                    {endDate ? moment(endDate).format('DD.MM.YYYY') : <br />}
                    <br />
                    <br />
                </div>
            ),
        },
        {
            title: (
                <Tooltip title="Wie häufig wurden Ihre Werbeanzeigen in der App angezeigt.">
                    Gesehen
                </Tooltip>
            ),
            dataIndex: 'AdvertStats.seen',
            key: 'AdvertStats.seen',
            render: (seen) => (
                <div>
                    {dotFormattedNumber(seen)}
                    <br />
                    <br />
                </div>
            ),
            sorter: (a, b) => a['AdvertStats.seen'] - b['AdvertStats.seen'],
        },
        {
            title: (
                <Tooltip title="Wie häufig wurden Ihre Werbeanzeigen in der App aktiv aufgerufen.">
                    Klicks
                </Tooltip>
            ),
            dataIndex: 'AdvertStats.clicked',
            key: 'AdvertStats.clicked',
            render: (clicked, record) => (
                <div>
                    <div>{dotFormattedNumber(clicked)}</div>
                    <div style={{ color: '#82828c' }}>
                        <Tooltip title="Klick-Rate">
                            {formatAsPercentage(clicked, record['AdvertStats.seen'])}
                        </Tooltip>
                    </div>
                </div>
            ),
            sorter: (a, b) => a['AdvertStats.clicked'] - b['AdvertStats.clicked'],
        },
        {
            title: (
                <Tooltip title="Wie häufig wurden Ihre Werbeanzeigen in der App aktiv geschlossen.">
                    Geschlossen
                </Tooltip>
            ),
            dataIndex: 'AdvertStats.closed',
            key: 'AdvertStats.closed',
            render: (closed) => (
                <div>
                    {dotFormattedNumber(closed)}
                    <br />
                    <br />
                </div>
            ),
            sorter: (a, b) => a['AdvertStats.closed'] - b['AdvertStats.closed'],
        },
        {
            title: (
                <Tooltip title="Adcoins, die seit Sichtbarschalten der Anzeige verbraucht wurden">
                    Verbrauchte AdCoins
                </Tooltip>
            ),
            dataIndex: 'AdvertStats.usedAdCoins',
            key: 'AdvertStats.usedAdCoins',
            render: (adCoinsUsed) => <div>{dotFormattedNumber(adCoinsUsed) || 0}</div>,
            sorter: (a, b) => a['AdvertStats.usedAdCoins'] - b['AdvertStats.usedAdCoins'],
        },
    ];
    return (
        <div>
            <Row>
                <AdCards filters={filter} />
                <Col span={24}>
                    <Table
                        columns={columns}
                        dataSource={data}
                        onChange={(pagination, filters, sorter, extra) => {
                            setFilter(filters['AdvertStats.status']);
                        }}
                        loading={isLoading}
                        locale={{ emptyText: 'Es wurden keine Daten gefunden' }}
                        rowClassName={(record) => (record.total ? 'table-highlight-row' : '')}
                        pagination={{ defaultPageSize: 10, position: 'bottom' }}
                        rowKey={(record) => record['AdvertStats.advertId']}
                    />
                    <div
                        style={{
                            fontSize: '0.8rem',
                            opacity: 0.5,
                            color: '#000000',
                        }}>
                        Letzte Aktualisierung am{' '}
                        {moment(data['AdvertStats.lastUpdate']).format('DD.MM.YYYY')}
                    </div>
                </Col>
            </Row>
            {data && !isLoading && <AdvertStatsModal loading={isLoading} data={data} />}
        </div>
    );
};

export default Ad;
