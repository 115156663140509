import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import { SessionContext } from './index';
import { RequestContext } from './RequestProvider';
import { CompanyContext } from './CompanyProvider';

function LegacyContextAdapter({ children }) {
    const sessionContext = useContext(SessionContext);
    const requestContext = useContext(RequestContext);
    const companyContext = useContext(CompanyContext);
    return (
        <InnerLegacyContextAdapter
            sessionContext={sessionContext}
            requestContext={requestContext}
            companyContext={companyContext}>
            {children}
        </InnerLegacyContextAdapter>
    );
}
class InnerLegacyContextAdapter extends Component {
    getChildContext = () => {
        return {
            ...this.props.sessionContext,
            ...this.props.requestContext,
            ...this.props.companyContext,
        };
    };

    render = () => {
        return this.props.children;
    };
}

InnerLegacyContextAdapter.childContextTypes = {
    login: PropTypes.func.isRequired,
    loginWithToken: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired,
    sendPasswordEmail: PropTypes.func.isRequired,
    loadSessionData: PropTypes.func.isRequired,
    createUser: PropTypes.func.isRequired,
    updateUserRole: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
    username: PropTypes.string,
    accountId: PropTypes.string,
    role: PropTypes.string,
    setMessage: PropTypes.func.isRequired,
    subscribeToStore: PropTypes.func,
    // requestContext
    authToken: PropTypes.string,
    request: PropTypes.func,
    upload: PropTypes.func,
    uploadAsset: PropTypes.func,
    // companyContext
    companyId: PropTypes.string,
    updateCompanyData: PropTypes.func.isRequired,
    switchCompany: PropTypes.func.isRequired,
    createCompany: PropTypes.func.isRequired,
    setCompanyVisibility: PropTypes.func,
    createJob: PropTypes.func.isRequired,
    updateJob: PropTypes.func.isRequired,
};

export default LegacyContextAdapter;
