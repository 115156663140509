import React from 'react';
import { Button } from 'antd';
import { useLocation } from 'react-router-dom';

function InAppPreview() {
    const { search, pathname } = useLocation();
    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                padding: 10,
                textAlign: 'center',
            }}>
            <p>Öffnen Sie diese Seite auf Ihrem Mobilgerät und tippen Sie hier:</p>
            <a href={`uninow://${pathname.replace('/app_preview/', '')}${search}`}>
                <Button type="primary">Hier Tippen</Button>
            </a>
        </div>
    );
}

export default InAppPreview;
