import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Input, Row, Col } from 'antd';
import { ImageManager, ProfileLayout, UniNowList } from '../../components';
import {
    addToArray,
    removeFromArray,
    changeArrayElement,
    setAttributeValue,
} from '../../core/redux/actions';
import buttonWithPopconfirm from '../../decorators/buttonWithPopconfirm';
import buttonWithTooltip from '../../decorators/buttonWithTooltip';
import { defaultAward } from '../../core/helper/defaultObjects';
import { generateID } from '../../core/helper/redux';
import imageConfig from '../../core/constants/imageConfig';
import ListSorter from '../../components/ListSorter';
import MarkdownLink from '../MarkdownHelp/MarkdownLink';
import PlanFilter, { PLAN_PERMISSION } from '../../components/PlanFilter';

const FormItem = Form.Item;

class Awards extends Component {
    add = () => {
        const newAward = { ...defaultAward, tempID: generateID() };
        this.props.addToArray('profile.awards', newAward, true);
    };

    remove = (targetKey) => {
        this.props.removeFromArray('profile.awards', targetKey);
    };

    updateAward = (award, index) => (attributeName, value) => {
        const updatedAward = {
            ...award,
            [attributeName]: value,
        };
        this.props.changeArrayElement('profile.awards', index, updatedAward);
    };

    renderAwardForm = (award, onInputChange) => {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        const { tempID, _id, title, subtitle } = award;
        const key = tempID || _id;

        return (
            <div>
                <FormItem label="Name:">
                    {getFieldDecorator(`name[${key}]`, {
                        rules: [
                            {
                                required: true,
                                message: 'Bitte geben Sie den Namen der Auszeichnung an.',
                            },
                        ],
                        onChange: (e) => onInputChange('title', e.target.value),
                        initialValue: title,
                    })(<Input placeholder="Name der Auszeichnung..." />)}
                </FormItem>

                <FormItem label="Beschreibung:">
                    {getFieldDecorator(`subtitle[${key}]`, {
                        onChange: (e) => onInputChange('subtitle', e.target.value),
                        initialValue: subtitle,
                    })(<Input placeholder="Beschreiben Sie die Auszeichnung..." />)}
                </FormItem>
            </div>
        );
    };

    handleSubmit = () =>
        new Promise((resolve, reject) =>
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    resolve(true);
                }
                resolve(false);
            }),
        );

    render() {
        return (
            <ProfileLayout
                subHeaderSelectedKey="Auszeichnungen"
                onSubmitMin={this.handleSubmit}
                infoText="Verwalten Sie hier die Auszeichnungen Ihres Unternehmens. Sie	gehören	zu	den beliebsten Arbeitgebern	Deutschlands?	Sie	haben
      einen	Design	Award	gewonnen	oder
      wurden	mit	anderen	Preisen
      ausgezeichnet? Lassen Sie es die Studierenden wissen.">
                <PlanFilter permission={PLAN_PERMISSION.AWARDS}>
                    <Button type="primary" size="large" icon="plus" onClick={() => this.add()}>
                        Auszeichung hinzufügen
                    </Button>
                    <Form>
                        <UniNowList
                            noItemsText="Es wurden noch keine Auszeichnungen hinzugefügt"
                            itemLayout="vertical"
                            size="large"
                            dataSource={this.props.awards}
                            renderItem={(award, index) => (
                                <Row gutter={25} style={{ paddingBottom: 25 }}>
                                    <ListSorter
                                        index={index}
                                        maxLength={this.props.awards.length}
                                        attributeName="profile.awards"
                                    />
                                    <Col span={18} offset={1}>
                                        {this.renderAwardForm(
                                            { ...award },
                                            this.updateAward(award, index),
                                        )}
                                        <br />
                                        {buttonWithPopconfirm({
                                            placement: 'top',
                                            title: 'Wollen Sie diese Auszeichnung wirklich löschen?',
                                            onConfirm: () => this.remove(index),
                                            okText: 'Löschen',
                                        })(
                                            buttonWithTooltip('Auszeichnung löschen')(
                                                <Button shape="circle" icon="delete" />,
                                            ),
                                        )}
                                    </Col>
                                    <Col span={4}>
                                        <ImageManager
                                            image={award.image}
                                            imageRatio={{ x: 1, y: 1 }}
                                            width={200}
                                            optimalWidth={imageConfig.awardsOptimalSize.width}
                                            optimalHeight={imageConfig.awardsOptimalSize.height}
                                            enableZoomOut
                                            onChange={(value) => {
                                                this.updateAward(award, index)('image', value);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            )}
                        />
                    </Form>
                    <MarkdownLink />
                </PlanFilter>
            </ProfileLayout>
        );
    }
}

const mapStateToProps = (state) => ({
    awards: state.company.profile.awards,
});

const mapDispatchToProps = (dispatch) => ({
    addToArray: (attributeName, element, prepend) =>
        dispatch(addToArray(attributeName, element, 'company', prepend)),
    removeFromArray: (attributeName, index) => dispatch(removeFromArray(attributeName, index)),
    changeArrayElement: (attributeName, index, updatedElement) =>
        dispatch(changeArrayElement(attributeName, index, updatedElement)),
    setAttributeValue: (attributeName, value) => dispatch(setAttributeValue(attributeName, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Awards));
