import * as loadImage from 'blueimp-load-image';

export function loadFromFile(file) {
    return new Promise((resolve) => {
        if (/(?:jpg|jpeg)/gi.test(file.name)) {
            loadImage(
                file.preview,
                (editedImage) => {
                    resolve({
                        data: file,
                        name: file.name,
                        type: 'image',
                        preview: editedImage.toDataURL(),
                    });
                },
                { orientation: true, maxWidth: 600, canvas: true },
            );
        } else {
            resolve({
                data: file,
                name: file.name,
                type: 'image',
                preview: file.preview,
            });
        }
    });
}
