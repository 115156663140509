import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Phone, AdvertCard } from '..';

import './style.css';
import MailInbox from './components/MailInbox';
import MailDetail from './components/MailDetail';
import { Button } from 'antd';

function AdvertPreview({ data, type, hideSecondPage }) {
    const [currentPage, setPage] = useState(0);

    const page = !hideSecondPage && currentPage;
    return (
        <>
            <Phone
                backgroundColor="#EDEFF4"
                topContent={
                    type === '3' &&
                    !hideSecondPage && (
                        <Button.Group size="small" style={{ margin: 10 }}>
                            <Button
                                type={page === 0 ? 'primary' : 'secondary'}
                                onClick={() => setPage(0)}>
                                Übersicht
                            </Button>
                            <Button
                                type={page === 1 ? 'primary' : 'secondary'}
                                onClick={() => setPage(1)}>
                                Detail
                            </Button>
                        </Button.Group>
                    )
                }>
                {type !== '3' && <AdvertCard data={data} />}
                {type === '3' && (
                    <>
                        {page ? (
                            <MailDetail data={data} onBackClicked={() => setPage(0)} />
                        ) : (
                            <MailInbox data={data} onDetailClick={() => setPage(1)} />
                        )}
                    </>
                )}
            </Phone>
        </>
    );
}

AdvertPreview.propTypes = {
    data: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
};

export default AdvertPreview;
