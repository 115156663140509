import React, { Component } from 'react';
import moment from 'moment';
import { Form, Input, Row, Col, DatePicker, TimePicker, Modal, Switch } from 'antd';
import { Geosearch, Map, AdminArea } from '../../components';

class EventModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            event: props.event,
        };
    }

    handleInputChange = (key, value) => {
        const updatedEvent = { ...this.state.event };

        const start = updatedEvent.start ? moment.utc(updatedEvent.start) : null;
        const end = updatedEvent.end ? moment.utc(updatedEvent.end) : null;

        switch (key) {
            case 'location':
                const addition = updatedEvent.location ? updatedEvent.location.addition : undefined;

                updatedEvent.location = value
                    ? {
                          ...(updatedEvent.location || {}),
                          ...value,
                      }
                    : { addition };
                break;

            case 'name':
            case 'addition':
                updatedEvent.location = {
                    ...(updatedEvent.location || {}),
                    [key]: value,
                };
                break;

            case 'startDate':
                updatedEvent.start =
                    start && value ? value.hour(start.hour()).minute(start.minute()) : value;
                break;

            case 'startTime':
                updatedEvent.start =
                    start && value
                        ? value.year(start.year()).month(start.month()).date(start.date())
                        : value;
                break;

            case 'endDate':
                updatedEvent.end =
                    end && value ? value.hour(end.hour()).minute(end.minute()) : value;
                break;

            case 'endTime':
                updatedEvent.end =
                    end && value
                        ? value.year(end.year()).month(end.month()).date(end.date())
                        : value;
                break;

            default:
                updatedEvent[key] = value;
                break;
        }

        this.setState({
            event: updatedEvent,
        });
    };

    addEnd = () => {
        const { start } = this.state.event;

        const date = start ? moment.utc(start).add(1, 'hour') : moment.utc().add(1, 'hour');
        this.handleInputChange('endDate', date);
    };

    componentWillReceiveProps({ visible, event }) {
        if (visible && visible !== this.props.visible) {
            this.setState({ event });
        }
    }

    onSave = () => {
        const { event } = this.state;
        const { validateFields } = this.props.form;

        validateFields((error) => {
            if (!error) {
                this.props.onOk(event);
            }
        });
    };

    onBlurCompletion = (fieldName) => {
        const { getFieldValue, setFields, validateFields } = this.props.form;
        const value = getFieldValue(fieldName);
        if (
            value.length > 0 &&
            value.startsWith &&
            !value.startsWith('https://') &&
            !value.startsWith('http://')
        ) {
            const newValue = {};
            newValue[fieldName] = { value: `https://${value}`, errors: null };
            setFields(newValue);
        }
        validateFields([fieldName]);
    };

    render() {
        const { event } = this.state;
        const { form, role, visible, onCancel } = this.props;
        const { getFieldDecorator } = form;
        const {
            _id,
            tempID,
            title,
            date,
            notes,
            location = {},
            start,
            end,
            onlineEvent,
            link,
        } = event || {};
        const { addition, name, label, geoPoint = {} } = location;
        const { lat, lng } = geoPoint;
        const key = _id || tempID;

        return (
            <Modal
                title="Event bearbeiten"
                maskClosable={false}
                cancelText="Abbrechen"
                onCancel={onCancel}
                onOk={this.onSave}
                destroyOnClose
                visible={visible}>
                {key && (
                    <div>
                        <AdminArea role={role}>
                            <Row type="flex" align="middle">
                                <Form.Item style={{ width: '100%' }} label="ID:">
                                    {getFieldDecorator(`uuid[${key}]`, {
                                        onChange: (e) =>
                                            this.handleInputChange('uuid', e.target.value),
                                        initialValue: event.uuid,
                                    })(<Input placeholder="Optionale ID" />)}
                                </Form.Item>
                            </Row>
                        </AdminArea>

                        <Row type="flex" align="top" justify="space-between">
                            <Form.Item style={{ width: '100%' }}>
                                {getFieldDecorator(`title[${key}]`, {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Bitte geben Sie einen Titel ein',
                                        },
                                    ],
                                    onChange: (e) =>
                                        this.handleInputChange('title', e.target.value),
                                    initialValue: title,
                                })(<Input placeholder="Titel der Veranstaltung" />)}
                            </Form.Item>
                        </Row>

                        <Row type="flex" align="middle">
                            <Form.Item style={{ width: '100%' }}>
                                {getFieldDecorator(`notes[${key}]`, {
                                    onChange: (e) =>
                                        this.handleInputChange('notes', e.target.value),
                                    initialValue: notes || date,
                                })(
                                    <Input.TextArea
                                        style={{ resize: 'none' }}
                                        autoSize={{ minRows: 3, maxRows: 4 }}
                                        placeholder="Beschreibung"
                                    />,
                                )}
                            </Form.Item>
                        </Row>

                        <Row style={{ textAlign: 'center', margin: '10px 0px' }}>
                            <h3 style={{ borderBottom: '1px solid #e8e8e8', lineHeight: '0.1em' }}>
                                <span style={{ backgroundColor: '#ffffff', color: '#8f8f8f' }}>
                                    Standort
                                </span>
                            </h3>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Item
                                    style={{ width: '100%', marginBottom: 0 }}
                                    label={<span style={{ color: '#8f8f8f' }}>Online Event</span>}
                                    labelAlign="left"
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 16 }}>
                                    {getFieldDecorator(`onlineEvent[${key}]`, {
                                        onChange: (checked) =>
                                            this.handleInputChange('onlineEvent', checked),
                                        initialValue: onlineEvent,
                                    })(<Switch defaultChecked={onlineEvent} />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        {!onlineEvent && (
                            <Row type="flex" align="top" gutter={20}>
                                <Col span={14}>
                                    <Form.Item>
                                        <Geosearch
                                            disabled={onlineEvent}
                                            onChange={(value) =>
                                                !value
                                                    ? this.handleInputChange('location', null)
                                                    : null
                                            }
                                            onSelect={(location) =>
                                                this.handleInputChange('location', location)
                                            }
                                            initialValue={label}
                                        />
                                    </Form.Item>
                                    <Form.Item style={{ width: '100%', marginBottom: 0 }}>
                                        {getFieldDecorator(`name[${key}]`, {
                                            onChange: (e) =>
                                                this.handleInputChange('name', e.target.value),
                                            initialValue: name,
                                        })(
                                            <Input
                                                disabled={onlineEvent}
                                                placeholder="Bezeichnung* (z.B. Hauptsitz)"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item style={{ width: '100%', marginBottom: 0 }}>
                                        {getFieldDecorator(`addition[${key}]`, {
                                            onChange: (e) =>
                                                this.handleInputChange('addition', e.target.value),
                                            initialValue: addition,
                                        })(
                                            <Input
                                                disabled={onlineEvent}
                                                placeholder="Adresszusatz*"
                                            />,
                                        )}
                                    </Form.Item>
                                    <div style={{ color: 'grey', paddingTop: 5 }}>* optional</div>
                                </Col>
                                <Col span={10}>
                                    <Map
                                        showMarker
                                        lat={lat}
                                        lng={lng}
                                        zoom={15}
                                        containerElement={<div style={{ height: '150px' }} />}
                                        mapElement={<div style={{ height: '100%' }} />}
                                    />
                                </Col>
                            </Row>
                        )}
                        {onlineEvent && (
                            <Row type="flex" align="top" gutter={20}>
                                <Col span={24}>
                                    <Form.Item style={{ width: '100%', marginBottom: 0 }}>
                                        {getFieldDecorator(`link[${key}]`, {
                                            onChange: (e) =>
                                                this.handleInputChange('link', e.target.value),
                                            initialValue: link,
                                        })(
                                            <Input
                                                placeholder="Link"
                                                onBlur={() => this.onBlurCompletion(`link[${key}]`)}
                                            />,
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                        <br />

                        <Row style={{ textAlign: 'center', margin: '10px 0px' }}>
                            <h3 style={{ borderBottom: '1px solid #e8e8e8', lineHeight: '0.1em' }}>
                                <span style={{ backgroundColor: '#ffffff', color: '#8f8f8f' }}>
                                    Zeit
                                </span>
                            </h3>
                        </Row>

                        <Row gutter={20}>
                            <Col span={14}>
                                <Form.Item>
                                    {getFieldDecorator(`startDate[${key}]`, {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Bitte geben Sie ein Startdatum ein',
                                            },
                                        ],
                                        onChange: (value) =>
                                            this.handleInputChange('startDate', value),
                                        initialValue: start ? moment.utc(start) : null,
                                    })(
                                        <DatePicker
                                            allowClear={false}
                                            placeholder="Startdatum"
                                            format="DD.MM.YYYY"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item>
                                    {getFieldDecorator(`startTime[${key}]`, {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Bitte geben Sie eine Startzeit ein',
                                            },
                                        ],
                                        onChange: (value) =>
                                            this.handleInputChange('startTime', value),
                                        initialValue: start ? moment.utc(start) : null,
                                    })(
                                        <TimePicker
                                            format="HH:mm"
                                            minuteStep={15}
                                            placeholder="Startzeit"
                                            allowEmpty={false}
                                            allowClear={false}
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>

                        {end ? (
                            <Row gutter={20}>
                                <Col span={14}>
                                    <Form.Item>
                                        {getFieldDecorator(`endDate[${key}]`, {
                                            onChange: (value) =>
                                                this.handleInputChange('endDate', value),
                                            initialValue: end ? moment.utc(end) : null,
                                        })(
                                            <DatePicker
                                                placeholder="Enddatum"
                                                format="DD.MM.YYYY"
                                            />,
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item>
                                        {getFieldDecorator(`endTime[${key}]`, {
                                            onChange: (value) =>
                                                this.handleInputChange('endTime', value),
                                            initialValue: end ? moment.utc(end) : null,
                                        })(
                                            <TimePicker
                                                format="HH:mm"
                                                minuteStep={15}
                                                placeholder="Endzeit"
                                                allowEmpty={false}
                                            />,
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        ) : (
                            <Row gutter={20}>
                                <Col span={14} />
                                <Col span={10}>
                                    <a onClick={this.addEnd}>+ Ende hinzufügen</a>
                                </Col>
                            </Row>
                        )}
                    </div>
                )}
            </Modal>
        );
    }
}

export default EventModal;
