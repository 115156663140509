import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Icon, Menu, Modal } from 'antd';

const { confirm } = Modal;

function EditMultipleDropdown({ selectedJobs = [], archiveJob, toggleJobVisible, onFinish }) {
    const onArchive = async () => {
        await Promise.all(selectedJobs.map((job) => archiveJob(job)));
        onFinish();
    };
    const onLive = async () => {
        await Promise.all(
            selectedJobs
                .filter(
                    (job) =>
                        job.title &&
                        job.type &&
                        (job.location || job.addresses.length > 0) &&
                        job.summary &&
                        job.studies &&
                        (job.link || job.mailing),
                )
                .map((job) => toggleJobVisible(job, true, true)),
        );
        onFinish();
    };
    const onOffline = async () => {
        await Promise.all(selectedJobs.map((job) => toggleJobVisible(job, false, true)));
        onFinish();
    };
    return (
        <Dropdown
            overlay={
                <Menu>
                    <Menu.Item
                        onClick={() => {
                            confirm({
                                title: `Wollen sie diese ${selectedJobs.length} jobs archivieren?`,
                                onOk: onArchive,
                            });
                        }}>
                        <Icon type="folder-add" />
                        Archivieren
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => {
                            confirm({
                                title: `Wollen sie diese ${selectedJobs.length} jobs live schalten?`,
                                onOk: onLive,
                            });
                        }}>
                        <Icon type="eye" theme="filled" />
                        Live schalten
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => {
                            confirm({
                                title: `Wollen sie diese ${selectedJobs.length} jobs offline schalten?`,
                                onOk: onOffline,
                            });
                        }}>
                        <Icon type="eye" theme="outlined" />
                        Offline schalten
                    </Menu.Item>
                </Menu>
            }>
            <Button shape="circle" icon="menu" />
        </Dropdown>
    );
}

EditMultipleDropdown.propTypes = {};

export default EditMultipleDropdown;
