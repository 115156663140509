import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Checkbox } from 'antd';
import { cloneDeep } from 'lodash';

const OTHER_OPTION = { label: 'Andere', value: '__OTHER__' };

class CheckboxGrid extends Component {
    constructor(props) {
        super(props);

        const { initialValues, options, otherOption } = props;

        const checkboxValues = (otherOption ? [...options, OTHER_OPTION] : options).map(
            ({ value }) => value,
        );

        this.state = {
            allValues:
                initialValues.length === 0 ||
                (initialValues.every((value) => checkboxValues.includes(value)) &&
                    initialValues.length === options.length),
            values: cloneDeep(initialValues),
        };
    }

    onValueCheck = ({ target }) => {
        const { value, checked } = target;
        const { options } = this.props;
        const { values, allValues } = this.state;

        if (checked) {
            const checkedValues = [...values, value].filter((v) => v !== `!${value}`);
            const otherChecked = values.some((v) => v.startsWith('!'));

            const allChecked =
                otherChecked && options.every((option) => checkedValues.includes(option.value));

            this.setState(
                {
                    values: allChecked ? [] : checkedValues,
                    allValues: allChecked,
                },
                this.handleChange,
            );
        } else {
            const currentValues = allValues ? options.map((option) => option.value) : values;
            const otherChecked = allValues || currentValues.some((v) => v.startsWith('!'));

            const nextValues = otherChecked
                ? options.map((option) =>
                      option.value === value || !currentValues.includes(option.value)
                          ? `!${option.value}`
                          : option.value,
                  )
                : currentValues.filter((v) => v !== value);

            this.setState(
                {
                    values: nextValues,
                    allValues: false,
                },
                this.handleChange,
            );
        }
    };

    onOtherCheck = ({ target }) => {
        const { checked } = target;
        const { options } = this.props;
        const { values, allValues } = this.state;

        if (checked) {
            const nextValues = options.map(({ value }) =>
                values.includes(value) ? value : `!${value}`,
            );

            const allChecked = options.every(({ value }) => nextValues.includes(value));

            this.setState(
                {
                    values: allChecked ? [] : nextValues,
                    allValues: allChecked,
                },
                this.handleChange,
            );
        } else {
            const currentValues = allValues ? options.map(({ value }) => value) : values;

            this.setState(
                {
                    values: currentValues.filter((v) => !v.startsWith('!')),
                    allValues: false,
                },
                this.handleChange,
            );
        }
    };

    checkAllValues = () => {
        this.setState(
            {
                values: [],
                allValues: true,
            },
            this.handleChange,
        );
    };

    uncheckAllValues = () => {
        this.setState(
            {
                values: [],
                allValues: false,
            },
            this.handleChange,
        );
    };

    handleChange = () => {
        const { values } = this.state;

        this.props.onChange(values);
    };

    render() {
        const { disabled, description, options, otherOption } = this.props;
        const { allValues, values } = this.state;

        return (
            <div>
                {description && (
                    <div>
                        {description}
                        <br /> <br />
                    </div>
                )}
                <Row>
                    <span className="primary-clickable" onClick={this.checkAllValues}>
                        Alle auswählen
                    </span>{' '}
                    |{' '}
                    <span className="primary-clickable" onClick={this.uncheckAllValues}>
                        Keine auswählen
                    </span>
                </Row>
                <br />
                <Row>
                    {options.map(({ label, value }, index) => (
                        <Col span={index % 2 === 0 ? 14 : 10} key={value}>
                            <Checkbox
                                disabled={disabled}
                                value={value}
                                checked={allValues || values.findIndex((a) => a === value) !== -1}
                                onChange={this.onValueCheck}
                                style={{ padding: '2px 0px' }}>
                                {label}
                            </Checkbox>
                        </Col>
                    ))}
                    {otherOption && (
                        <Col span={options.length % 2 === 0 ? 14 : 10} key={OTHER_OPTION.value}>
                            <Checkbox
                                disabled={disabled}
                                value={OTHER_OPTION.value}
                                checked={allValues || values.some((value) => value.startsWith('!'))}
                                onChange={this.onOtherCheck}
                                style={{ padding: '2px 0px' }}>
                                {OTHER_OPTION.label}
                            </Checkbox>
                        </Col>
                    )}
                </Row>
            </div>
        );
    }
}

CheckboxGrid.defaultProps = {
    initialValues: [],
    description: '',
    disabled: false,
    otherOption: false,
};

CheckboxGrid.propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
    }).isRequired,
    initialValues: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
    disabled: PropTypes.bool,
    otherOption: PropTypes.bool,
};

export default CheckboxGrid;
