export const TRANSLATIONS = {
    de: {
        LEARN_MORE: 'Erfahre mehr',
        PLACE: 'Gebäude/Raum: ',
        POLL_I: '0 Stimmen',
        POLL_II: 'Diese Umfrage endet ',
        POLL_III: 'in 24 h',
        PHONE_TIME: 'vor ein paar Sekunden',
        NEW_POST: 'Neuen Post verfassen ...',
    },
    en: {
        LEARN_MORE: 'Learn more',
        PLACE: 'Building/Room: ',
        POLL_I: '0 votes',
        POLL_II: 'This poll ends ',
        POLL_III: 'in 24 h',
        PHONE_TIME: 'a few seconds ago',
        NEW_POST: 'Create new post ...',
    },
};
