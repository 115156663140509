import React from 'react';
import { InstantSearch } from 'react-instantsearch';
import useMeiliSearch from '../../../../core/api/MeileSearchClient';
import StudentsContactedLayout from '../layout/StudentsContactedLayout';

const StudentsContactedProfiles = () => {
    const { client } = useMeiliSearch();

    return (
        <InstantSearch
            indexName="talent_profiles_contact_requests:updatedAt:desc"
            searchClient={client.searchClient}
            routing={true}>
            <StudentsContactedLayout title="Kontaktierte Profile" />
        </InstantSearch>
    );
};

export default StudentsContactedProfiles;
