import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Col, InputNumber, Row } from 'antd';

class AdvertLimitsForm extends PureComponent {
    constructor(props) {
        super(props);
        const { userLimit, deltaTimeInMin } = this.props;
        this.state = {
            userLimit,
            deltaTimeInMin,
        };
    }

    render() {
        const { onChangeUserLimit, onChangeDeltaTime, disabled } = this.props;
        const { userLimit, deltaTimeInMin } = this.state;
        return (
            <div>
                Wählen Sie die maximalen Ansichten pro Zeitraum.
                <br />
                <br />
                <Row>
                    <Col md={11}>
                        Maximale Ansichten:{' '}
                        <InputNumber
                            disabled={disabled}
                            value={userLimit}
                            min={0}
                            onChange={(value) => {
                                this.setState({ userLimit: value });
                                onChangeUserLimit(value);
                            }}
                        />
                    </Col>
                    <Col md={{ span: 11, offset: 2 }}>
                        Zeitraum in Minuten:{' '}
                        <InputNumber
                            disabled={disabled}
                            value={deltaTimeInMin}
                            min={1}
                            onChange={(value) => {
                                this.setState({ deltaTimeInMin: value });
                                onChangeDeltaTime(value);
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

AdvertLimitsForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

export default AdvertLimitsForm;
