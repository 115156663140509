import React, { Component } from 'react';
import { Input, Row, Col, Button, Form } from 'antd';
import { AsyncGeosuggest, ImageManager, Map } from '..';
import { defaultLocation } from '../../core/helper/defaultObjects';
import { GoogleMapContainer } from './styles';
import { ErrorText } from '../styles';

import imageConfig from '../../core/constants/imageConfig.json';

function buildStreet({ label, postalCode, city, country }) {
    let street = label;

    if (postalCode) {
        const postalCodeRegex = new RegExp(`[, ]*${postalCode}[, ]*`);
        street = street.replace(postalCodeRegex, '');
    }

    if (city) {
        const cityRegex = new RegExp(`[, ]*${city}[, ]*`);
        street = street.replace(cityRegex, '');
    }

    if (country) {
        const countyRegex = new RegExp(`[, ]*${country}[, ]*`);
        street = street.replace(countyRegex, '');
    }

    return street;
}

class LocationManager extends Component {
    constructor(props) {
        super(props);
        const { location = {} } = this.props;

        this.initialLabel =
            location.label ||
            ['street', 'postalCode', 'city', 'country']
                .map((key) => location[key])
                .filter((element) => element)
                .join(', ');

        this.state = {
            errorMessage: null,
            name: location.name,
            addition: location.addition,
            currentAddress: location,
        };
    }

    handleAdditionChange = ({ target }) => {
        const { currentAddress, name } = this.state;

        const value = target.value || null;

        this.setState({
            addition: value,
        });

        if (currentAddress) {
            this.props.onSuggestSelect({
                ...currentAddress,
                addition: value,
                name,
            });
        }
    };

    handleNameChange = ({ target }) => {
        const { currentAddress, addition } = this.state;

        const value = target.value || null;

        this.setState({
            name: value,
        });

        if (currentAddress) {
            this.props.onSuggestSelect({
                ...currentAddress,
                name: value,
                addition,
            });
        }
    };

    handleAddressSearch = () => {
        this.geoSuggest.selectSuggest();
    };

    handleLogoChange = (logo) => {
        const { currentAddress } = this.state;

        if (currentAddress) {
            this.props.onSuggestSelect({
                ...currentAddress,
                logo,
            });
        }
    };

    handleAddressChange = (suggest) => {
        const { errorMessage, addition, name } = this.state;

        if (suggest && suggest.label !== '') {
            const { gmaps, location } = suggest;

            if (!location) {
                this.setState({
                    errorMessage: 'Ungültige Addresse',
                });
                return;
            }

            if (errorMessage) {
                this.setState({
                    errorMessage: null,
                });
            }

            const { lat, lng } = location;
            const { address_components: addressComponents } = gmaps;

            const addressComponentsTypes = {
                street_number: 'streetNumber',
                route: 'streetName',
                postal_code: 'postalCode',
                locality: 'locality',
                administrative_area_level_1: 'administrativeArea',
                country: 'country',
            };

            const addressComponentsValues = Object.keys(addressComponentsTypes).reduce(
                (values, key) => {
                    const element = addressComponents.find((item) => item.types.includes(key));

                    if (element) {
                        values[addressComponentsTypes[key]] = element.long_name;
                    }

                    return values;
                },
                {},
            );

            const { streetNumber, streetName, postalCode, locality, administrativeArea, country } =
                addressComponentsValues;

            const newAddress = {
                geoPoint: {
                    lat,
                    lng,
                },
                street: streetNumber ? `${streetName} ${streetNumber}` : streetName || '',
                postalCode: postalCode || '',
                city: locality || administrativeArea || '',
                country: country || '',
                addition: addition || null,
                name: name || null,
                administrativeArea,
                label: suggest.label,
            };

            if (!newAddress.city) {
                this.setState({
                    errorMessage: 'Ungültige Addresse',
                });
                return;
            }

            if (!newAddress.street) {
                newAddress.street = buildStreet(newAddress);
            }

            this.setState({
                currentAddress: newAddress,
            });

            this.props.onSuggestSelect(newAddress);
        }
    };

    handleManualChange = ({ target }, key) => {
        const { currentAddress } = this.state;

        const value = target.value || null;

        const newAddress = { ...this.state.currentAddress, [key]: value, manualChange: new Date() };
        this.setState({
            currentAddress: newAddress,
        });

        if (currentAddress) {
            this.props.onSuggestSelect(newAddress);
        }
    };

    render() {
        const { errorMessage, addition, name } = this.state;
        const { error } = this.props;
        const location = this.props.location || { ...defaultLocation };
        const { geoPoint = {}, isDefault: isDefaultLocation } = location;
        const { lat, lng } = geoPoint;

        const geoSuggestProps = {
            style: { marginBottom: '5px' },
            onSuggestSelect: this.handleAddressChange,
            placeholder: 'Geben Sie einen Standort ein...',
            initialValue: this.initialLabel,
            noButton: true,
        };
        return (
            <GoogleMapContainer>
                <AsyncGeosuggest {...geoSuggestProps} />
                <Row
                    type="flex"
                    align="middle"
                    style={{ padding: '10px 0px 15px 0px' }}
                    gutter={15}>
                    <Col span={14}>
                        <Input
                            value={name}
                            placeholder="Bezeichnung* (z.B. Hauptsitz)"
                            onChange={this.handleNameChange}
                        />
                    </Col>
                    <Col span={10}>
                        <Input
                            value={addition}
                            placeholder="Addresszusatz*"
                            onChange={this.handleAdditionChange}
                        />
                    </Col>
                </Row>
                {(errorMessage || error) && <ErrorText>{errorMessage || error}</ErrorText>}
                <Map
                    lat={lat}
                    lng={lng}
                    showMarker
                    zoom={isDefaultLocation ? 10 : 15}
                    containerElement={<div style={{ height: '20em' }} />}
                    mapElement={<div style={{ height: '100%' }} />}
                />
                {(this.state.currentAddress?.logo || this.props.editLogo) && (
                    <>
                        <div
                            style={{
                                paddingTop: 20,
                                paddingBottom: 10,
                            }}>
                            Logo*
                        </div>
                        {this.props.editLogo && (
                            <ImageManager
                                image={this.state.currentAddress?.logo}
                                onChange={this.handleLogoChange}
                                width={300}
                                optimalWidth={imageConfig.logoOptimalSize.width}
                                optimalHeight={imageConfig.logoOptimalSize.height}
                                enableZoomOut
                                imageRatio={{ x: 3, y: 2 }}
                            />
                        )}
                        {!this.props.editLogo && (
                            <>
                                <img
                                    alt="logo"
                                    src={
                                        this.state.currentAddress?.logo?.edited ??
                                        this.state.currentAddress?.logo?.large
                                    }
                                    style={{ width: 300 }}
                                />
                                <div style={{ color: 'grey', paddingTop: 10 }}>
                                    Kann über die Unternehmensstandorte bearbeitet werden
                                </div>
                            </>
                        )}
                    </>
                )}
                <div style={{ color: 'grey', paddingTop: 20 }}>* optional</div>
                {this.props.allowManualEdit && !this.state.showManualEdit && (
                    <Row type="flex">
                        <Col>
                            <Button
                                type="link"
                                size="small"
                                onClick={() => this.setState({ showManualEdit: true })}>
                                Felder manuell bearbeiten
                            </Button>
                        </Col>
                    </Row>
                )}
                {this.state.currentAddress?.manualChange &&
                    `Manuell geändert am ${new Date(
                        this.state.currentAddress?.manualChange,
                    ).toLocaleDateString()}`}
                {this.props.allowManualEdit && this.state.showManualEdit && (
                    <Row>
                        <Form.Item label="Straße">
                            <Input
                                value={this.state.currentAddress?.street}
                                onChange={(e) => this.handleManualChange(e, 'street')}
                            />
                        </Form.Item>
                        <Form.Item label="PLZ">
                            <Input
                                value={this.state.currentAddress?.postalCode}
                                onChange={(e) => this.handleManualChange(e, 'postalCode')}
                            />
                        </Form.Item>
                        <Form.Item label="Stadt">
                            <Input
                                value={this.state.currentAddress?.city}
                                onChange={(e) => this.handleManualChange(e, 'city')}
                            />
                        </Form.Item>
                        <Form.Item label="Land">
                            <Input
                                value={this.state.currentAddress?.country}
                                onChange={(e) => this.handleManualChange(e, 'country')}
                            />
                        </Form.Item>
                    </Row>
                )}
            </GoogleMapContainer>
        );
    }
}

export default LocationManager;
