import React, { Component } from 'react';
import { Tag, Input, Tooltip, AutoComplete } from 'antd';

export default class TagList extends Component {
    state = {
        tags: [],
        inputVisible: false,
        inputValue: '',
    };

    componentDidMount = () => {
        if (this.props.tags) {
            this.setState({ tags: [...this.props.tags] });
        }
    };

    handleClose = (removedTag) => {
        const tags = this.state.tags.filter((tag) => tag !== removedTag);
        this.setState({ tags });
    };

    handleInputChange = (e) => {
        this.setState({ inputValue: e });
    };

    handleInputConfirm = () => {
        const { state } = this;
        let { tags } = state;
        const { inputValue } = this.state;
        if (inputValue && tags.indexOf(inputValue) === -1) {
            tags = [...tags, inputValue];
        }

        this.setState(
            {
                tags,
                inputVisible: false,
                inputValue: '',
            },
            () => this.props.onInputConfirm(tags),
        );
    };

    saveInputRef = (input) => (this.input = input);

    render() {
        const { tags, inputValue } = this.state;

        return (
            <div>
                {tags.map((tag, index) => {
                    const isLongTag = tag.length > 20;
                    const tagElem = (
                        <Tag key={tag} closable afterClose={() => this.handleClose(tag)}>
                            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                        </Tag>
                    );
                    return isLongTag ? (
                        <Tooltip title={tag} key={tag}>
                            {tagElem}
                        </Tooltip>
                    ) : (
                        tagElem
                    );
                })}

                <AutoComplete
                    value={inputValue}
                    dataSource={this.props.autoCompleteSource}
                    placeholder="Studiengang"
                    filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onChange={(value) => this.handleInputChange(value)}>
                    <Input
                        onBlur={this.handleInputConfirm}
                        onPressEnter={this.handleInputConfirm}
                    />
                </AutoComplete>
            </div>
        );
    }
}
