import React, { Component } from 'react';
import { Card, Button, Form, Input, Switch, Select, Modal, Col } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { defaultJob, defaultSection } from '../../core/helper/defaultObjects';
import TagList from '../TagList';
import GenericList from '../GenericList';
import { generateID } from '../../core/helper/redux';

const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;
const { confirm } = Modal;

class JobForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            job: { ...defaultJob, ...props.job },
            showDialogue: false,
            isEdit: !!props.job,
            isDirty: false,
        };
    }

    onDataChange = (attributeName, value) => {
        this.setState({ job: { ...this.state.job, [attributeName]: value }, isDirty: true });
    };

    handleSectionChange = (attributeName, indexOfSection, value) => {
        const sectionsClone = [...this.state.job.sections];
        const sectionToUpdate = sectionsClone[indexOfSection];
        sectionToUpdate[attributeName] = value;
        this.onDataChange('sections', sectionsClone);
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ isDirty: false }, () => {
                    if (!this.state.isEdit) {
                        this.context.createJob(this.state.job);
                    } else {
                        this.context.updateJob(this.state.job);
                    }
                    this.props.history.push('/stellenanzeigen');
                });
            }
        });
    };

    typeOpts = ['Praktika', 'Abschlussarbeiten', 'Nebenjob', 'Festanstellung', 'Trainee'].sort();

    renderDialogue = (content, okText, onOk, cancelText) =>
        confirm({
            title: 'Achtung',
            content,
            okText,
            okType: 'danger',
            cancelText,
            onOk,
        });

    render() {
        const { companyIsVisible, form } = this.props;
        const { getFieldDecorator } = form;
        const { job, isDirty } = this.state;
        const sections = job.sections.map((section, index) => (
            <Card
                style={{ marginTop: '10px' }}
                title={
                    <Input
                        placeholder="Title"
                        value={section.title}
                        onChange={(e) => {
                            this.handleSectionChange('title', index, e.target.value);
                        }}
                    />
                }
                extra={
                    <Button
                        shape="circle"
                        icon="delete"
                        onClick={() => {
                            const sectionsClone = [...job.sections].filter(
                                (sectionX, indexOfSection) => indexOfSection !== index,
                            );
                            this.onDataChange('sections', sectionsClone);
                        }}
                    />
                }
                key={section.tempID || section._id}
                bordered={false}>
                <TextArea
                    autoSize
                    placeholder="Beschreibung"
                    value={section.content}
                    onChange={(e) => {
                        this.handleSectionChange('content', index, e.target.value);
                    }}
                />
            </Card>
        ));

        return (
            <div>
                <Prompt
                    when={isDirty}
                    message="Sind Sie sich sicher, dass Sie diese Seite verlassen möchten? Ungespeicherte Daten gehen dabei verloren."
                />
                <Form onSubmit={this.handleSubmit}>
                    <FormItem>
                        <Col span={19}>
                            <FormItem label="Title:">
                                {getFieldDecorator('title', {
                                    rules: [
                                        {
                                            required: true,
                                            message:
                                                'Bitte geben Sie die Bezeichnung ihrer Stellenanzeige an.',
                                        },
                                    ],
                                    onChange: (e) => this.onDataChange('title', e.target.value),
                                    initialValue: job.title,
                                })(<Input placeholder="Bezeichnung" />)}
                            </FormItem>
                        </Col>
                        <Col span={2}>
                            <span
                                style={{
                                    display: 'inline-block',
                                    width: '100%',
                                    textAlign: 'center',
                                }}
                            />
                        </Col>
                        <Col span={3}>
                            <FormItem label="Sichtbarkeit:">
                                <Switch
                                    checkedChildren="sichtbar"
                                    unCheckedChildren="unsichtbar"
                                    defaultChecked={job.visible}
                                    onChange={(value) => {
                                        if (companyIsVisible) {
                                            this.onDataChange('visible', value);
                                        } else {
                                            Modal.warning({
                                                title: 'Achtung',
                                                content:
                                                    'Bitte schalten Sie zunächst Ihr Unternehmen auf sichtbar, bevor Sie diese Stellenanzeige sichtbar schalten können.',
                                            });
                                        }
                                    }}
                                />
                            </FormItem>
                        </Col>
                    </FormItem>

                    <FormItem label="Typ:">
                        {getFieldDecorator('type', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Bitte wählen Sie den Typ ihrer Stellenanzeige',
                                },
                            ],
                            initialValue: job.type ? job.type : undefined,
                            onChange: (value) => this.onDataChange('type', value),
                        })(
                            <Select placeholder="Typ">
                                {this.typeOpts.map((value) => (
                                    <Option value={value} key={value}>
                                        {value}
                                    </Option>
                                ))}
                            </Select>,
                        )}
                    </FormItem>
                    <FormItem label="Ort:">
                        {getFieldDecorator('location', {
                            rules: [
                                {
                                    required: true,
                                    message:
                                        'Bitte geben Sie einen Ort zu Ihrer Stellenanzeige an.',
                                },
                            ],
                            onChange: (value) => this.onDataChange('location', value),
                            initialValue: job.location,
                        })(
                            <Select>
                                {this.props.addresses.map((address) => (
                                    <Option
                                        value={`${address.street}, ${address.postalCode}, ${address.city}`}
                                        key={address.tempID || address._id}>
                                        {`${address.street}, ${address.postalCode}, ${address.city}`}
                                    </Option>
                                ))}
                            </Select>,
                        )}
                    </FormItem>

                    <FormItem>
                        <TagList
                            tags={job.studies}
                            autoCompleteSource={['Informatik']}
                            onInputConfirm={(studies) => this.onDataChange('studies', studies)}
                        />
                    </FormItem>

                    <FormItem label="Beschreibung:">
                        {getFieldDecorator('summary', {
                            rules: [
                                {
                                    required: true,
                                    message:
                                        'Bitte geben Sie die Beschreibung ihrer Stellenanzeige an.',
                                },
                            ],
                            onChange: (e) => this.onDataChange('summary', e.target.value),
                            initialValue: job.summary,
                        })(<TextArea placeholder="Jobbeschreibung" />)}
                    </FormItem>

                    <FormItem>
                        <GenericList
                            addItemText="Abteilung hinzufügen"
                            items={sections}
                            onAddItem={() => {
                                const newSection = { ...defaultSection, tempID: generateID() };
                                this.onDataChange('sections', [...job.sections, newSection]);
                            }}
                        />
                    </FormItem>
                </Form>
            </div>
        );
    }
}

JobForm.contextTypes = {
    createJob: PropTypes.func.isRequired,
    updateJob: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    companyIsVisible: state.company.profile.visible,
    job: state.jobs.joblist.find((el) => el.uuid === ownProps.match.params.uuid),
    addresses: state.company.profile.addresses,
});

const WrappedJobForm = Form.create()(JobForm);
export default connect(mapStateToProps)(WrappedJobForm);
