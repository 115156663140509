import React from 'react';

import { Row, Icon } from 'antd';
import gradient from '../../assets/images/gradient.svg';

export default function ContactSection() {
    return (
        <div>
            <div
                style={{
                    backgroundImage: `url(${gradient})`,
                    backgroundPosition: '50% 50%',
                    backgroundSize: 'cover',
                    width: '100%',
                    zIndex: 20,
                    textAlign: 'center',
                    color: '#FFFFFF',
                }}>
                <div style={{ padding: '20px 35px 20px 20px', textAlign: 'left' }}>
                    Bei Fragen, Problemen und wilden Feature-Wünschen kannst du dich gerne jederzeit
                    bei uns melden.
                    <br />
                    <br />
                    So erreichst du uns am schnellsten:
                </div>
            </div>
            <div style={{ padding: '20px 35px' }}>
                <Row type="flex" align="middle">
                    <Icon type="mail" style={{ fontSize: '1.2em', paddingRight: 15 }} />
                    <a className="clickable-primary" href="mailto:support@uninow.de">
                        support@uninow.de
                    </a>
                </Row>
            </div>
        </div>
    );
}
