import { persistCombineReducers, persistStore } from 'redux-persist';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import company from './core/redux/reducer/company';
import companyList from './core/redux/reducer/companyList';
import memberList from './core/redux/reducer/memberList';
import jobs from './core/redux/reducer/jobs';
import authentication from './core/redux/reducer/authentication';
import user from './core/redux/reducer/user';
import filter from './core/redux/reducer/filter';
import cubeTokenValidity from './core/redux/reducer/cubeTokenValidity';
import preview from './core/redux/reducer/feedPreview';
import post from './core/redux/reducer/post';
import currentPageOfLists from './core/redux/reducer/currentPageOfLists';

const encryptor = encryptTransform({
    secretKey: 'dannys-super-duper-secret-key-of-keys',
});

const config = {
    key: 'root',
    storage,
    blacklist: ['company', 'jobs', 'companyList', 'memberList'],
    transforms: [encryptor],
};
config.debug = true;

const reducer = persistCombineReducers(config, {
    company,
    companyList,
    memberList,
    jobs,
    authentication,
    user,
    filter,
    cubeTokenValidity,
    preview,
    post,
    currentPageOfLists,
});

export function configureStore() {
    const store = createStore(reducer, applyMiddleware(thunk));
    const persistor = persistStore(store);
    // store.subscribe(() => console.log('Store has been updated!', store.getState().company));
    return { persistor, store };
}
