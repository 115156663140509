import { CHANGE_CUBE_TOKEN_VALIDITY } from '../actions';

const cubeTokenValidity = (
    state = {
        isCubeTokenValid: false,
    },
    action,
) => {
    if (action.type === CHANGE_CUBE_TOKEN_VALIDITY) {
        return { ...state, isCubeTokenValid: action.isValid };
    }
    return state;
};

export default cubeTokenValidity;
