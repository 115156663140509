import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { CompanyContext } from './Session/CompanyProvider';
import { Spinner } from './index';

function OpenProfile() {
    const { switchCompany } = useContext(CompanyContext);
    const { uuid } = useParams();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const doSwitch = async () => {
            await switchCompany(uuid);
            setLoading(false);
        };
        doSwitch();
    }, [uuid]);
    return loading ? <Spinner tip="Profil wird geladen..." /> : <Redirect to="/profil" />;
}

export default OpenProfile;
