import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Table, Row, Col, Input } from 'antd';

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'Gesamtanzahl',
        dataIndex: 'overall',
        sorter: (a, b) => a.overall - b.overall,
        defaultSortOrder: 'descend',
    },
    {
        title: 'Nutzer',
        dataIndex: 'userCount',
        sorter: (a, b) => a.userCount - b.userCount,
    },
    {
        title: 'Offen / In Bearbeitung / Abgeschlossen / Widerrufen',
        key: 'status',
        render: (record) =>
            `${record.OPEN} / ${record.PROCESSING} / ${record.CLOSED} / ${record.REVOKED}`,
    },
];

class ApplicantList extends Component {
    state = {
        data: [],
        loading: true,
        search: '',
    };

    fetchApplicants = async () => {
        const { request } = this.context;

        const { body } = await request('recruiting/administration/applicants');

        if (body) {
            this.setState({ data: body, loading: false });
        }
    };

    handleFilterChange = ({ target }) => {
        this.setState({ search: (target.value || '').toLowerCase() });
    };

    componentDidMount() {
        this.fetchApplicants();
    }

    render() {
        const { data, loading, search } = this.state;

        const filteredData = search
            ? data.filter(
                  (company) =>
                      company.name.toLowerCase().includes(search) ||
                      company.children.some((job) => job.name.toLowerCase().includes(search)),
              )
            : data;

        return (
            <div>
                <Row style={{ paddingBottom: 15 }}>
                    <Col span={12}>
                        <Input
                            onChange={this.handleFilterChange}
                            placeholder="Suche nach Unternehmen oder Jobs"
                        />
                    </Col>
                </Row>
                <Table
                    loading={loading}
                    rowKey={(record) => `${record.type}:${record._id}`}
                    dataSource={filteredData}
                    columns={columns}
                />
            </div>
        );
    }
}

ApplicantList.contextTypes = {
    request: PropTypes.func.isRequired,
};

export default ApplicantList;
