import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import isMobileDevice from '../../../core/helper/isMobileDevice';

function AdvertLink({ link, children }) {
    const style = {
        fontSize: '1.2em',
        fontWeight: '200',
        color: '#f21852',
    };
    if (!isMobileDevice() && link?.startsWith('uninow')) {
        let desktopLink = link;
        let params;
        if (link.startsWith('uninow://feed/companyEvent')) {
            const jobRegex = new RegExp('(?:eventId=)([^&]+)');
            const id = link.match(jobRegex)[1];
            params = `?eventId=${id}`;
            desktopLink = '/profil/events';
        } else if (link.startsWith('uninow://feed/company')) {
            desktopLink = '/profil';
        } else if (link.startsWith('uninow://feed/job')) {
            const jobRegex = new RegExp('(?:\\?id=)([^&]+)');
            const id = link.match(jobRegex)[1];
            desktopLink = `/stellenanzeigen/editieren/${id}`;
        }

        return (
            <Link to={{ pathname: desktopLink, search: params }} target="_blank" style={style}>
                {children}
            </Link>
        );
    }

    return (
        <a style={style} href={link} target="_blank" rel="noreferrer">
            {children}
        </a>
    );
}

AdvertLink.propTypes = {};

export default AdvertLink;
