import React, { Component } from 'react';
import { withRouter } from 'react-router';

import './style.css';

class PageContainer extends Component {
    render() {
        const { location } = this.props;
        const isBackgroundGradient = ['/login', '/passwortvergessen'].includes(location.pathname);
        const style = {
            zIndex: 20,
            position: 'fixed',
            paddingRight: 40,
            paddingTop: 20,
            bottom: 0,
            right: 0,
            left: 0,
            height: 60,
            textAlign: 'center',
            ...(window.innerWidth <= 576 && {
                height: 120,
                marginTop: 'auto',
            }),
        };
        if (!isBackgroundGradient) {
            style.borderTop = '2px solid #f39247';
            style.backgroundColor = '#fff';
        }
        return (
            <div
                style={
                    isBackgroundGradient
                        ? { height: '100vh' }
                        : { height: '100%', paddingBottom: 20 }
                }>
                {this.props.children}
                <div style={style}>
                    <a
                        className="policy-link"
                        target='blank'
                        rel="noopener noreferrer"
                        href="https://legal.uninow.com/recruiting/terms-of-use-uninow-recruiting/de/latest"
                        style={isBackgroundGradient ? { color: '#ffffff' } : {}}>
                        Nutzungsvereinbarung
                    </a>
                    <a
                        className="policy-link"
                        target='blank'
                        rel="noopener noreferrer"
                        href="https://legal.uninow.com/recruiting/privacy-policy-uninow-recruiting/de/latest"
                        style={isBackgroundGradient ? { color: '#ffffff' } : {}}>
                        Datenschutzerklärung
                    </a>
                    <a
                        className="policy-link"
                        target="blank"
                        rel="noopener noreferrer"
                        href="https://legal.uninow.com/web/imprint/de/latest"
                        style={isBackgroundGradient ? { color: '#ffffff' } : {}}>
                        Impressum
                    </a>
                    <span
                        className="policy-link"
                        style={isBackgroundGradient ? { color: '#ffffff' } : {}}>
                        {process.env.REACT_APP_VERSION}
                    </span>
                </div>
            </div>
        );
    }
}

export default withRouter(PageContainer);
