import React, { Component } from 'react';

class FollowButton extends Component {
    render() {
        return (
            <div
                className="color-secondary"
                style={{
                    borderRadius: '25px',
                    border: '1px solid #82828c',
                    padding: '2px 22px',
                    fontSize: '0.9em',
                    display: 'inline-block',
                }}>
                Folgen
            </div>
        );
    }
}

export default FollowButton;
