import React, { useState, useRef } from 'react';

import { Icon, Progress } from 'antd';

import './style.css';

const defaultPlayState = {
    currentTime: '-:-',
    duration: '-:-',
    progress: 0,
};

const toTimeString = (value) =>
    `${Math.floor(value / 60)}:${Math.floor(value % 60)
        .toString()
        .padStart(2, '0')}`;

const AudioPlayer = ({ source, onLoadedMetadata }) => {
    const audioElement = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [playState, setPlayState] = useState(defaultPlayState);

    const updateTime = () => {
        setPlayState({
            currentTime: toTimeString(audioElement.current.currentTime),
            duration: toTimeString(audioElement.current.duration),
            progress: Math.round(
                (audioElement.current.currentTime * 100) / audioElement.current.duration,
            ),
        });
    };

    const handleOnLoadedMetadata = () => {
        if (onLoadedMetadata) {
            onLoadedMetadata(audioElement.current);
        }

        updateTime();
    };

    const handleProgressClick = (evt) => {
        const progress = Math.round(
            (evt.nativeEvent.layerX * 100) / evt.nativeEvent.target.clientWidth,
        );
        const currentTime = (progress / 100) * audioElement.current.duration;

        audioElement.current.currentTime = currentTime;
    };

    const toggle = () => {
        if (isPlaying) {
            audioElement.current.pause();
        } else {
            audioElement.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    return (
        <div
            id="AudioPlayer"
            style={{
                padding: '10px 0px 0px 0px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
            <div style={{ paddingRight: 10, flex: '0 1 auto' }}>
                <audio
                    onEnded={toggle}
                    onLoadedMetadata={handleOnLoadedMetadata}
                    onTimeUpdate={updateTime}
                    ref={audioElement}
                    src={source}
                />
                <Icon
                    onClick={toggle}
                    type={isPlaying ? 'pause' : 'play-circle'}
                    style={{
                        fontSize: '1.5em',
                        color: '#f21850',
                        cursor: 'pointer',
                    }}
                />
            </div>
            <div style={{ padding: '0px 10px', textAlign: 'center', flex: '0 1 auto' }}>
                <span className="weight-bold size-tiny color-secondary">
                    {playState.currentTime}
                </span>
            </div>
            <div style={{ flex: '1 1 auto' }}>
                <div onClick={handleProgressClick} style={{ width: '100%', cursor: 'pointer' }}>
                    <Progress
                        style={{ width: '100%' }}
                        percent={playState.progress}
                        showInfo={false}
                        strokeColor="#f21850"
                        strokeWidth={5}
                    />
                </div>
            </div>
            <div style={{ padding: '0px 10px', textAlign: 'center', flex: '0 1 auto' }}>
                <span className="weight-bold size-tiny color-secondary">{playState.duration}</span>
            </div>
        </div>
    );
};

export default AudioPlayer;
