import { connect } from 'react-redux';
import { Badge, Col, Icon, Row } from 'antd';
import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Query, withApollo } from 'react-apollo';
import translate from '../../../core/helper/translate';
import placeholder from '../../../core/images/placeholder.jpg';
import AudioPlayer from '../../AudioPlayer';
import buildTimeString from '../../../core/helper/buildTimeString';
import defaultAvatar from '../../../core/images/avatar_placeholder.png';
import formatText from '../../../core/helper/markdown';
import { PROFILE } from '../../../core/gql/queries';
import { Map } from '../../index';

class FeedPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowHeight: null,
            windowWidth: null,
            primaryLanguage: 'de',
        };
    }

    updateDimensions() {
        this.setState({
            windowWidth: window.outerWidth,
            windowHeight: window.outerHeight,
        });
    }

    UNSAFE_componentWillMount() {
        this.updateDimensions();
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions.bind(this));
    }

    renderAttachment(SCALE) {
        if (!this.props.post || !this.props.post.type) {
            return null;
        }
        const { type, attachment } = this.props.post;

        const translationImage =
            attachment?.translations?.image?.[this.props.language || 'de'] ||
            attachment?.translations?.image?.[this.state.primaryLanguage || 'de'];
        const image = translationImage ? translationImage.large || translationImage.preview : null;
        const title =
            attachment?.translations?.title?.[this.props.language || 'de'] ||
            attachment?.translations?.title?.[this.state.primaryLanguage];
        const url =
            attachment?.translations?.url?.[this.props.language || 'de'] ||
            attachment?.translations?.url?.[this.state.primaryLanguage];
        const subtitle =
            attachment?.translations?.subtitle?.[this.props.language || 'de'] ||
            attachment?.translations?.subtitle?.[this.state.primaryLanguage];
        const description =
            attachment?.translations?.description?.[this.props.language || 'de'] ||
            attachment?.translations?.description?.[this.state.primaryLanguage];
        const translationAudio =
            attachment?.translations?.audio?.[this.props.language || 'de'] ||
            attachment?.translations?.audio?.[this.state.primaryLanguage || 'de'];
        const audio = translationAudio ? translationAudio.preview || translationAudio.raw : null;
        const images = (
            !isEmpty(attachment?.translations?.images?.[this.props.language || 'de'])
                ? attachment?.translations?.images?.[this.props.language || 'de']
                : !isEmpty(attachment?.translations?.images?.[this.state.primaryLanguage || 'de'])
                ? attachment?.translations?.images?.[this.state.primaryLanguage || 'de']
                : []
        ).filter((image) => image.type === 'image');
        const translationVideo =
            attachment?.translations?.video?.[this.props.language || 'de'] ||
            attachment?.translations?.video?.[this.state.primaryLanguage || 'de'];
        const video = translationVideo ? translationVideo.preview || translationVideo.raw : null;
        switch (type) {
            // Link
            case 'blog':
                return (
                    <div className="blog">
                        <a target="_blank" href={url} rel="noopener noreferrer">
                            {image && <img src={image} alt="" />}
                            <div style={{ padding: '1rem' }}>
                                <div className="weight-bold color-secondary">
                                    {title ? title.toUpperCase() : null}
                                </div>

                                <div
                                    className="weight-bold"
                                    style={{
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                    }}>
                                    {subtitle}
                                </div>

                                <div
                                    style={{
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                    }}>
                                    {description}
                                </div>
                            </div>
                        </a>
                    </div>
                );
            case 'podcast':
                return (
                    <div className="podcast">
                        {image && <img src={image} alt="" />}
                        <div style={{ padding: '1rem' }}>
                            <div
                                className="weight-bold"
                                style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                }}>
                                {title}
                            </div>
                            <div
                                style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                }}>
                                {description}
                            </div>
                            <AudioPlayer source={audio} />
                        </div>
                    </div>
                );
            case 'gallery':
                if (images.length === 0) {
                    return null;
                }
                return (
                    <Row>
                        <div className="gallery">
                            <Col span={24}>
                                <img src={images[0].preview} alt="" className="gallery" />
                            </Col>
                            <Col span={24} style={{ textAlign: 'center', fontSize: 10 * SCALE }}>
                                {images.length > 1 &&
                                    images.map((image, index) => (
                                        <Badge
                                            key={image.name}
                                            status={index === 0 ? 'error' : 'default'}
                                        />
                                    ))}
                            </Col>
                        </div>
                    </Row>
                );
            case 'event':
                const { addition, label, geoPoint } = attachment?.location || {};
                let locationString =
                    label ||
                    ['city', 'street']
                        .map((prop) => (attachment?.location || {})[prop])
                        .concat([attachment?.addition])
                        .filter((prop) => prop)
                        .join(', ');

                if (!locationString) {
                    locationString = 'Kein Ort angegeben';
                }

                const timeString = buildTimeString(attachment?.start, attachment?.end);

                return (
                    <div className="event">
                        {geoPoint && (
                            <Map
                                showMarker
                                zoom={15}
                                containerElement={<div style={{ height: 200 * SCALE }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                                lat={geoPoint.lat}
                                lng={geoPoint.lng}
                            />
                        )}
                        <div style={{ padding: '10px 10px' }}>
                            <div
                                className="weight-bold"
                                style={{
                                    lineHeight: 3 * SCALE,
                                }}>
                                {title}
                            </div>
                            <div className="color-secondary">
                                <Row type="flex" align="middle" gutter={10}>
                                    <Col span={20}>
                                        <Row>
                                            <Col span={3}>
                                                &nbsp;
                                                <Icon
                                                    type="clock-circle-o"
                                                    style={{ color: '#F21852' }}
                                                />
                                            </Col>
                                            <Col span={21}>{timeString}</Col>
                                        </Row>
                                        <Row>
                                            <Col span={3}>
                                                &nbsp;
                                                <Icon
                                                    type="environment-o"
                                                    style={{ color: '#F21852' }}
                                                />
                                            </Col>
                                            <Col span={21}>
                                                <div className="overflow-ellipsis">
                                                    {locationString}
                                                </div>
                                                {addition && (
                                                    <div>
                                                        {translate('PLACE', this.props.language)}
                                                        {addition}
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={4} style={{ textAlign: 'right' }}>
                                        <Icon
                                            type="calendar"
                                            style={{
                                                color: '#F21852',
                                                fontSize: 18 * SCALE,
                                                paddingRight: 5,
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                );
            case 'poll':
                return (
                    <Row>
                        {(attachment?.translations?.options?.length > 1
                            ? attachment?.translations?.options
                            : [null, null]
                        ).map((option, index) => (
                            <Col span={24} className="poll" key={index}>
                                <Icon
                                    type="right"
                                    theme="outlined"
                                    className="color-secondary"
                                    style={{ float: 'right', padding: 2 * SCALE }}
                                />
                                {option?.[this.props.language || 'de']
                                    ? option[this.props.language || 'de']
                                    : option?.[this.state.primaryLanguage]
                                    ? option[this.state.primaryLanguage]
                                    : `Option ${index + 1}`}
                            </Col>
                        ))}
                        <Col span={24} style={{ marginTop: 10 }}>
                            <span
                                className="color-secondary"
                                style={{ fontSize: 12 * SCALE, display: 'block' }}>
                                {translate('POLL_I', this.props.language)}
                                <br />
                                {translate('POLL_II', this.props.language)}
                                {attachment?.end
                                    ? moment
                                          .duration(moment(attachment?.end).diff(moment()))
                                          .humanize(true)
                                    : translate('POLL_III', this.props.language)}
                            </span>
                        </Col>
                    </Row>
                );

            case 'video':
                const thumbnail = image;

                return video ? (
                    <div style={{ paddingTop: '0.5rem' }}>
                        <video
                            preload="metadata"
                            poster={thumbnail}
                            src={video}
                            autoPlay={false}
                            muted
                            controls
                            style={{
                                width: '100%',
                            }}
                        />
                    </div>
                ) : null;
            default:
                return null;
        }
    }

    render() {
        const maxWidth = this.state.windowWidth * (6 / 24);
        const SCALE = Math.min(maxWidth / 346, this.state.windowHeight / (721 + 450));
        return (
            <Query query={PROFILE}>
                {({ loading, error, data }) => {
                    if (loading || error || !data.me) {
                        return null;
                    }
                    const { avatar, username = '', displayName = '' } = data.me;
                    const avatarImg = this.state.avatar || (avatar ? avatar.large : null);
                    const { post } = this.props;

                    let content = null;
                    let attachmentType = null;
                    if (post) {
                        content =
                            post.translations?.content[this.props.language] ||
                            post.translations?.content[this.state.primaryLanguage] ||
                            post.content ||
                            null;
                        const changes = this.renderAttachment(SCALE);
                        attachmentType =
                            post.attachment && post.attachment[0] ? post.attachment[0].type : null;
                    }
                    return (
                        <div
                            id="Preview"
                            style={{
                                margin: '15px',
                            }}>
                            <div style={{ backgroundColor: '#fff' }}>
                                <Row style={{ backgroundColor: '#fff', width: '100%' }}>
                                    <Col span={5}>
                                        <img
                                            src={avatarImg || defaultAvatar}
                                            alt=""
                                            className="avatar"
                                            style={{ height: 50 * SCALE, width: 50 * SCALE }}
                                        />
                                    </Col>
                                    <Col
                                        span={19}
                                        style={{
                                            lineHeight: 2.2 * SCALE,
                                        }}>
                                        <div
                                            style={{
                                                marginLeft: '10px',
                                            }}>
                                            <div
                                                className="weight-bold"
                                                style={{
                                                    fontSize: 14 * SCALE,
                                                }}>
                                                {displayName || ''}
                                                <br />
                                            </div>
                                            <div
                                                className="weight-regular color-secondary"
                                                style={{
                                                    fontSize: 10 * SCALE,
                                                }}>
                                                {username ? `@${username}` : ''}
                                            </div>
                                            <div
                                                className="weight-regular color-secondary"
                                                style={{
                                                    fontSize: 11 * SCALE,
                                                }}>
                                                {translate('PHONE_TIME', this.props.language)}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <Row
                                            style={{
                                                paddingTop: 15 * SCALE,
                                                paddingBottom: 15 * SCALE,
                                                fontSize: 14 * SCALE,
                                                whiteSpace: 'pre-line',
                                            }}>
                                            <Col span={24} style={{ paddingBottom: '1.0em' }}>
                                                <Col
                                                    span={24}
                                                    style={{
                                                        maxWidth: '100%',
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                    }}>
                                                    {content ? (
                                                        <Row>
                                                            <Col
                                                                span={24}
                                                                style={{
                                                                    whiteSpace: 'normal',
                                                                    wordWrap: 'break-word',
                                                                }}>
                                                                {formatText(content)}
                                                            </Col>
                                                        </Row>
                                                    ) : (
                                                        <div className="color-secondary">
                                                            {translate(
                                                                'NEW_POST',
                                                                this.props.language,
                                                            )}{' '}
                                                        </div>
                                                    )}
                                                </Col>
                                                <Col span={24} style={{ paddingBottom: '1.0em' }}>
                                                    {this.renderAttachment(SCALE)}
                                                </Col>
                                                <Col
                                                    span={24}
                                                    style={{ textAlign: 'left', paddingTop: 10 }}
                                                    className="color-secondary">
                                                    <Icon type="heart-o" />
                                                </Col>
                                                <Col span={24}>
                                                    <img
                                                        src={placeholder}
                                                        alt="placeholder"
                                                        style={{ marginTop: 20, width: '100%' }}
                                                    />
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    );
                }}
            </Query>
        );
    }
}

const mapStateToProps = ({ company, preview }) => {
    return {
        name: company.profile.name,
        logo: company.profile.logo.small,
        type: preview.type,
        username: company.contact.name,
        post: preview.post,
        language: preview.language,
    };
};

export default connect(mapStateToProps)(withApollo(FeedPreview));
