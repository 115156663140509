import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Carousel, Row, Col } from 'antd';
import { truncate } from 'lodash';

import * as MdIconPack from 'react-icons/lib/md';
import { IoPricetag } from 'react-icons/lib/io';
import { withApollo } from 'react-apollo';
import gradient from '../../../assets/images/gradient.svg';

import parseMarkdown from '../../../core/helper/markdown';

import UNIcon from '../../UNIcon';
import Map from '../../Map';
import { resetPreview } from '../../../core/redux/actions/feedPreview';

function buildEventTime(dateString) {
    const date = moment.utc(dateString);
    const time = date.format('HH:mm');

    return `${date.format('dd[,] DD. MMM YY[,]')} ${time === '00:00' ? 'ganztätig' : time}`;
}

class Preview extends Component {
    state = {
        aboutUsCollapsed: true,
        showToolbar: false,
    };

    componentDidMount() {
        this.refs.container.addEventListener('scroll', this.showToolbar);
    }

    componentWillUnmount() {
        this.refs.container.removeEventListener('scroll', this.showToolbar);
    }

    renderAboutUs = () => {
        const { aboutUs } = this.props;
        const { aboutUsCollapsed } = this.state;

        return (
            <div className="sectionContainer">
                <Row className="sectionHeadline">Über uns</Row>
                <Row>
                    <Col className="basicFont">
                        {aboutUs
                            ? parseMarkdown(
                                  truncate(aboutUs, {
                                      length: this.state.aboutUsCollapsed ? 250 : aboutUs.length,
                                  }),
                              )
                            : 'Beschreibung Ihres Unternehmens...'}
                    </Col>
                </Row>

                {aboutUsCollapsed && aboutUs.length > 250 && (
                    <Row>
                        <Col>
                            <div
                                style={{ color: '#f21850' }}
                                onClick={() => {
                                    this.setState({ aboutUsCollapsed: false });
                                }}>
                                Erfahre mehr
                            </div>
                        </Col>
                    </Row>
                )}
            </div>
        );
    };

    renderMeta = () => {
        const { name, subtitle, logo, industry, statement, specialization, jobs } = this.props;
        const { edited, large } = logo || {};
        const displayLogoURL = edited || large;

        return (
            <div className="sectionContainer">
                <Row style={{ textAlign: 'right' }}>
                    <img src={displayLogoURL} alt="Logo" className="logo-overview" />
                    <div className="follow-button">Folgen</div>
                </Row>
                <Row>
                    <Col>
                        <span className="headline">{name}</span>
                    </Col>
                </Row>
                {subtitle && (
                    <Row>
                        <Col>
                            <span className="subtitle">{subtitle}</span>
                        </Col>
                    </Row>
                )}
                <Row style={{ padding: '1em 0' }} />
                <Row className="basicFont">{statement}</Row>
                <br />
                <Row>
                    <Col>{specialization || industry}</Col>
                </Row>
                <Row>
                    <Col>
                        <a
                            onClick={() => this.handleScrollToElement('jobSection')}
                            className="basicLink">
                            {jobs.length} {`Stellenangebot${jobs.length !== 1 ? 'e' : ''}`}
                        </a>
                    </Col>
                </Row>
            </div>
        );
    };

    handleScrollToElement = (ref) => {
        const mapSection = ReactDOM.findDOMNode(this.refs[ref]);
        this.refs.container.scrollTo({ behavior: 'smooth', top: mapSection.offsetTop });
    };

    renderInfo = () => {
        const { addresses = [], yearOfFoundation, employees } = this.props;

        let numOfInfos = addresses.length > 0 ? 1 : 0;
        if (yearOfFoundation) {
            numOfInfos++;
        }
        if (employees) {
            numOfInfos++;
        }

        const colSpan = numOfInfos > 0 ? 24 / numOfInfos : false;

        if (!colSpan) {
            return null;
        }

        return (
            <div className="sectionContainer">
                <Row type="flex" justify="space-between">
                    {employees && (
                        <Col span={colSpan} style={{ color: '#8E8E8E' }}>
                            <Row type="flex" justify="space-around">
                                <MdIconPack.MdPeople style={{ fontSize: 18 }} />
                            </Row>
                            <Row style={{ paddingTop: 5, textAlign: 'center' }}>
                                Angestellte
                                <br />
                                {employees}
                            </Row>
                        </Col>
                    )}
                    {yearOfFoundation && (
                        <Col span={colSpan} style={{ color: '#8E8E8E' }}>
                            <Row type="flex" justify="space-around">
                                <MdIconPack.MdHome style={{ fontSize: 18 }} />
                            </Row>

                            <Row style={{ paddingTop: 5, textAlign: 'center' }}>
                                Gründung
                                <br />
                                {yearOfFoundation}
                            </Row>
                        </Col>
                    )}

                    {addresses.length > 0 && (
                        <Col
                            span={colSpan}
                            style={{ color: '#f21850', cursor: 'pointer' }}
                            onClick={() => this.handleScrollToElement('mapSection')}>
                            <Row type="flex" justify="space-around">
                                <MdIconPack.MdLocationOn style={{ fontSize: 18 }} />
                            </Row>
                            <Row style={{ paddingTop: 5, textAlign: 'center' }}>
                                {`Standort${addresses.length > 1 ? 'e' : ''}`}
                                <br />
                                {`${addresses.length}`}
                            </Row>
                        </Col>
                    )}
                </Row>
            </div>
        );
    };

    renderHeader = () => {
        const { gallery } = this.props;

        return (
            <Row ref="headerSection">
                <Col>
                    <Carousel autoplay dots={false}>
                        {gallery.map((img) => (
                            <img
                                alt="Gallery"
                                key={img.large || img._id || img.tempID}
                                src={img.edited || img.large}
                                style={{ width: '100%', height: 'auto' }}
                            />
                        ))}
                    </Carousel>
                </Col>
            </Row>
        );
    };

    renderDivider = () => <div className="divider" />;

    renderAwards = () => {
        if (this.props.awards.length === 0) {
            return null;
        }

        const showPlus = this.props.awards.length > 3;

        return (
            <div ref="awardsSection" className="sectionContainer">
                <Row className="sectionHeadline">Auszeichnungen</Row>
                <Row>
                    {this.props.awards.slice(0, 3).map((el) => (
                        <Col key={el.title} span={7}>
                            <Row type="flex" justify="space-around">
                                {el.image ? (
                                    <img
                                        alt="Award"
                                        key={el.image.edited || el.image.large}
                                        src={el.image.edited || el.image.large}
                                        style={{ width: '100%', height: '100%' }}
                                    />
                                ) : (
                                    <img
                                        alt="Award"
                                        key="https://images.uninow.com/recruiting/default/award00.jpg"
                                        src="https://images.uninow.com/recruiting/default/award00.jpg"
                                        style={{ width: '100%', height: '100%' }}
                                    />
                                )}
                            </Row>
                            <Row type="flex" justify="space-around" className="subline">
                                {el.title.length > 9 ? `${el.title.substring(0, 9)}...` : el.title}
                            </Row>
                        </Col>
                    ))}
                    {showPlus && (
                        <Col span={3} className="plusLink">
                            +{this.props.awards.slice(3, this.props.awards.length).length}
                        </Col>
                    )}
                </Row>
            </div>
        );
    };

    renderBenefits = () => {
        if (this.props.benefits.length === 0) {
            return null;
        }

        const showPlus = this.props.benefits.length > 3;

        return (
            <div ref="benefitsSection" className="sectionContainer">
                <Row className="sectionHeadline">Benefits</Row>
                <Row>
                    {this.props.benefits.slice(0, 3).map((el) => (
                        <Col key={el.key} span={7}>
                            <Row type="flex" justify="space-around">
                                <UNIcon icon={el.icon} className="benefitsIcon" />
                            </Row>
                            <Row type="flex" justify="space-around" className="subline">
                                {el.text.length > 9 ? `${el.text.substring(0, 9)}...` : el.text}
                            </Row>
                        </Col>
                    ))}
                    {showPlus && (
                        <Col span={3} className="plusLink">
                            <Link to="/profil/benefits">
                                +{this.props.benefits.slice(3, this.props.benefits.length).length}
                            </Link>
                        </Col>
                    )}
                </Row>
            </div>
        );
    };

    renderEvents = () => {
        const threshold = 2;
        const { events = [] } = this.props;

        if (events.length === 0) {
            return null;
        }

        const showPlus = events.length > threshold;

        return (
            <div ref="eventsSection" className="sectionContainer">
                <Row className="sectionHeadline">Aktuelle Veranstaltungen</Row>

                {events.slice(0, threshold).map((ev) => {
                    const start = (ev.start ? buildEventTime(ev.start) : ev.date) || '';
                    const city = ev.location ? ev.location.city : null;

                    return (
                        <Row
                            key={ev._id || ev.tempID}
                            style={{ paddingBottom: '7%' }}
                            type="flex"
                            align="middle"
                            gutter={8}>
                            <Col>
                                <UNIcon
                                    className="color-primary"
                                    style={{ fontSize: '1.5em' }}
                                    icon="calendar-clock"
                                />
                            </Col>
                            <Col>
                                <div className="color-primary">{ev.title}</div>
                                <div style={{ color: '#8e8e8e', fontSize: '0.9em' }}>{start}</div>
                                {city && (
                                    <div style={{ color: '#8e8e8e', fontSize: '0.9em' }}>
                                        {city}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    );
                })}

                {showPlus && (
                    <Row>
                        <Link to="/profil/events">Alle Veranstaltungen anzeigen</Link>
                    </Row>
                )}
            </div>
        );
    };

    renderSocialMedia = () => {
        const { socialMedia } = this.props;

        if (socialMedia.length === 0) {
            return null;
        }

        const showPlus = socialMedia.length > 3;

        return (
            <div ref="socialMediaSection" className="sectionContainer">
                <Row>
                    {socialMedia.slice(0, 3).map((el) => (
                        <Col key={el.uri} span={7}>
                            <Row type="flex" justify="space-around">
                                <UNIcon style={{ fontSize: '2em' }} icon={el.icon} />
                            </Row>
                        </Col>
                    ))}
                    {showPlus && (
                        <Col span={3} className="plusLink">
                            <Link to="/profil/sozialemedien">
                                +{socialMedia.slice(3, socialMedia.length).length}
                            </Link>
                        </Col>
                    )}
                </Row>
            </div>
        );
    };

    renderRatings = () => {
        const rating = this.props.ratings[0];

        if (!rating) {
            return null;
        }

        return (
            <div className="sectionContainer">
                <Row className="sectionHeadline">Insights</Row>
                <Row style={{ marginTop: 20 }}>
                    <Col span={5}>
                        <img
                            alt="Avatar"
                            key={
                                rating.image
                                    ? rating.image.small
                                    : 'https://images.uninow.com/uninow-recruiting/default/person00.jpg'
                            }
                            src={
                                rating.image
                                    ? rating.image.small
                                    : 'https://images.uninow.com/uninow-recruiting/default/person00.jpg'
                            }
                            className="avatar"
                        />
                    </Col>
                    <Col offset={1} span={18}>
                        <div className="basicFont">{rating.title}</div>
                        <div className="basicFontLight">{rating.subtitle}</div>
                    </Col>
                </Row>
                <Row className="basicFont textContainer">
                    {truncate(rating.text, { length: 140 })}
                </Row>

                <Row className="basicLink">Erfahre mehr</Row>
            </div>
        );
    };

    renderJobs = () => (
        <div className="sectionContainer" ref="jobSection">
            <Row className="sectionHeadline">Aktuelle Jobangebote</Row>
            <Row>
                {this.props.jobs.slice(0, 5).map((el) => (
                    <Row key={el.uuid} style={{ marginBottom: 7 }}>
                        <Link to={`/stellenanzeigen/editieren/${el.uuid}`}>
                            <Col span={3}>
                                <IoPricetag
                                    className="sectionIcon"
                                    style={{
                                        fontSize: '1.5em',
                                        color: '#f21850',
                                        margin: '8px auto',
                                    }}
                                />
                            </Col>
                            <Col className="basicLink" span={21}>
                                <div style={{ color: '#f21850' }}>{el.title}</div>
                                <div style={{ fontSize: 7, color: 'rgba(0, 0, 0, 0.45)' }}>
                                    {el.type}
                                </div>
                                <div style={{ fontSize: 7, color: 'rgba(0, 0, 0, 0.45)' }}>
                                    {el.location}
                                </div>
                            </Col>
                        </Link>
                    </Row>
                ))}
            </Row>
            {this.props.jobs.length > 5 && (
                <Row style={{ marginTop: 10 }}>
                    <Link to="/stellenanzeigen" style={{ color: '#f21850' }}>
                        Alle Stellenangebote anzeigen
                    </Link>
                </Row>
            )}
        </div>
    );

    renderMap = () => {
        if (this.props.addresses.length === 0 || !this.props.addresses[0].geoPoint) {
            return null;
        }

        const { lat, lng } = this.props.addresses[0].geoPoint;
        return (
            <Row ref="mapSection">
                <Map
                    zoom={15}
                    showMarker
                    lat={lat}
                    lng={lng}
                    containerElement={<div style={{ height: '15em' }} />}
                    mapElement={<div style={{ height: '100%' }} />}
                />
            </Row>
        );
    };

    showToolbar = () => {
        const container = ReactDOM.findDOMNode(this.refs.container);
        if (container.scrollTop >= 150 && this.state.showToolbar === false) {
            this.setState({ showToolbar: true });
        } else if (container.scrollTop < 150 && this.state.showToolbar === true) {
            this.setState({ showToolbar: false });
        }
    };

    render() {
        return (
            <div
                ref="container"
                style={{
                    height: '100%',
                    overflowY: 'scroll',
                }}>
                <div
                    className="navbar centerText"
                    style={{
                        backgroundImage: this.state.showToolbar ? `url(${gradient}` : 'none',
                        backgroundColor: this.state.showToolbar ? '#f21850' : 'transparent',
                    }}
                />
                {this.renderHeader()}
                {this.renderMeta()}
                {this.renderDivider()}
                {this.renderInfo()}
                {this.renderDivider()}
                {this.renderAboutUs()}
                {this.renderDivider()}
                {this.renderBenefits()}
                {this.renderDivider()}
                {this.renderAwards()}
                {this.renderDivider()}
                {this.renderEvents()}
                {this.renderSocialMedia()}
                {this.renderDivider()}
                {this.renderJobs()}
                {this.renderDivider()}
                {this.renderRatings()}
                {this.renderDivider()}
                {this.renderMap()}
            </div>
        );
    }
}

const mapStateToProps = ({ company, jobs }) => ({
    gallery: company.profile.gallery,
    name: company.profile.name,
    subtitle: company.profile.subtitle,
    aboutUs: company.profile.aboutUs,
    statement: company.profile.statement,
    logo: company.profile.logo,
    specialization: company.profile.specialization,
    industry: company.profile.industry,
    jobs: jobs.joblist.filter((el) => el.visible && !el.archived && el.uuid),
    benefits: company.profile.benefits,
    ratings: company.profile.ratings,
    addresses: company.profile.addresses,
    employees: company.profile.employees,
    yearOfFoundation: company.profile.yearOfFoundation,
    socialMedia: company.profile.socialMedia || [],
    awards: company.profile.awards || [],
    events: company.profile.events || [],
});
const mapDispatchToProps = (dispatch) => ({
    resetPreview: () => dispatch(resetPreview()),
    //  resetPost: () => dispatch(resetPost()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withApollo(Preview));
