import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import { Row, Col, Icon } from 'antd';

import UNIcon from '../../UNIcon';

class SocialMedia extends Component {
    renderSocialIcon = (el) => {
        let { icon } = el;
        if (el.uri === 'Kununu') {
            icon = 'kununu';
        } else if (icon === 'web') {
            icon = 'globe';
        }
        return (
            <Row className="rowContainer" key={`${el.key}${el.text}`} type="flex" align="middle">
                <Col span={4}>
                    <UNIcon style={{ fontSize: '2em' }} icon={icon} />
                </Col>
                <Col span={18}>{el.text}</Col>
            </Row>
        );
    };

    render() {
        return (
            <div
                style={{
                    height: '100%',
                    overflowY: 'scroll',
                }}>
                <div
                    className="navbar centerText"
                    style={{
                        borderBottom: '1px solid #edeff4',
                        backgroundColor: '#ffffff',
                    }}>
                    <Link to="/profil">
                        <Icon
                            type="left"
                            style={{
                                fontSize: 12,
                                margin: 6,
                                float: 'left',
                            }}
                            className="color-primary"
                        />
                    </Link>
                </div>

                <div className="navbarSpacer" />

                {this.props.socialMedia.length > 0 &&
                    this.props.socialMedia
                        .map(this.renderSocialIcon)
                        .reduce((prev, curr) => [
                            prev,
                            <div key={`${curr.key}_divider`} className="divider" />,
                            curr,
                        ])}
            </div>
        );
    }
}

const mapStateToProps = ({ company }) => ({
    socialMedia: company.profile.socialMedia,
});

export default connect(mapStateToProps)(SocialMedia);
