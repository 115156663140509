import { Row } from 'antd';
import React, { useMemo, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import useCubeWithQueryErrorHandling from '../../hooks/useCubeWithQueryErrorHandling';

function TopUniversitiesChart({ chartOptions, companyFilterValue, chartColors }) {
    const [isEmpty, setIsEmpty] = useState(false);
    const query = {
        measures: [`CompanyTopUniversities${companyFilterValue}.percent`],
        dimensions: [`CompanyTopUniversities${companyFilterValue}.universityName`],
        limit: 10,
        order: {
            [`CompanyTopUniversities${companyFilterValue}.percent`]: 'desc',
        },
    };
    const { resultSet, isLoading } = useCubeWithQueryErrorHandling(query);

    const chartData = useMemo(() => {
        const uniData = resultSet?.tablePivot();

        function getChartData(dataSource) {
            let chartLabels = [];
            const data = [];
            const backgroundColor = [];
            let totalPercent = 0;

            // get Top 5 Uni
            const topUnis = dataSource.slice(0, 10);

            // get labels and data
            topUnis.forEach((item, index) => {
                const label = item[`CompanyTopUniversities${companyFilterValue}.universityName`];
                const dataItem = item[`CompanyTopUniversities${companyFilterValue}.percent`];
                if (label) {
                    chartLabels.push(label);
                }
                if (dataItem) {
                    data.push(dataItem);
                    totalPercent += +dataItem;
                }
                backgroundColor.push(chartColors[index]);
            });

            // add label "Andere" if all data sum is not 100% or there are more than 5 Unis
            if (totalPercent < 100 && totalPercent !== 0) {
                const otherPercent =
                    100 -
                    topUnis.reduce(
                        (acc, item) =>
                            acc +
                            parseFloat(item[`CompanyTopUniversities${companyFilterValue}.percent`]),
                        0,
                    );
                chartLabels.push('Andere');
                data.push(otherPercent.toFixed(2));
                backgroundColor.push(chartColors[10]);
            }

            if (totalPercent === 0) {
                chartLabels = [];
            }

            const chartLabelsEmpty = chartLabels.length === 0;
            setIsEmpty(chartLabelsEmpty);

            return {
                labels: chartLabels,
                datasets: [
                    {
                        backgroundColor,
                        data,
                    },
                ],
            };
        }

        if (uniData?.length) {
            return getChartData(uniData);
        }

        return null;
    }, [resultSet, companyFilterValue]);

    return (
        <Row>
            {chartData ? (
                <div className="chartContainer">
                    <Doughnut data={chartData} options={chartOptions} />
                    <div className="chartInner">
                        <div className="chartTitle">Hochschulen</div>
                        <div className="chartRankingLabel">Top 10</div>
                    </div>
                </div>
            ) : (
                !isLoading &&
                isEmpty && (
                    <div
                        style={{
                            color: '#c1c1c1',
                        }}>
                        Es wurden keine Daten gefunden
                    </div>
                )
            )}
        </Row>
    );
}

TopUniversitiesChart.propTypes = {
    chartOptions: PropTypes.shape({}).isRequired,
};

export default TopUniversitiesChart;
