import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { uniqBy } from 'lodash';
import { Spin, Row, Col, Icon } from 'antd';

import { Post } from '..';

import './style.css';

class PostGrid extends Component {
    constructor(props) {
        super(props);

        const { initialPost } = props;

        this.state = {
            initialLoading: true,
            loading: false,
            error: false,
            posts: [],
            cursor: null,
            hasNext: true,
            selected: initialPost ? initialPost._id : null,
        };
    }

    loadPosts = async (before = null, limit = 10) => {
        const { posts, selected } = this.state;
        const { initialPost } = this.props;
        const { request } = this.context;

        const { body, error } = await request('recruiting/post/list', {
            limit,
            before,
        });

        if (error) {
            this.setState({
                initialLoading: false,
                loading: false,
                error: true,
            });
        } else {
            const cursor = body.posts.length > 0 ? body.posts[body.posts.length - 1]._id : null;
            const nextPosts = uniqBy(
                (initialPost ? [initialPost] : []).concat(posts, body.posts),
                '_id',
            );

            if (!selected && nextPosts.length) {
                this.props.onSelect(nextPosts[0]);
            }

            this.setState({
                selected: selected || (nextPosts[0] || {})._id,
                cursor,
                hasNext: body.hasNext,
                posts: nextPosts,
                initialLoading: false,
                loading: false,
            });
        }
    };

    componentDidMount() {
        this.loadPosts();
    }

    onLoadMore = () => {
        this.setState({ loading: true });

        const { cursor } = this.state;

        this.loadPosts(cursor);
    };

    onPostSelect = (post) => {
        this.setState({ selected: post._id });

        this.props.onSelect(post);
    };

    renderPost = (post) => {
        const { selected } = this.state;
        const { _id } = post;

        const isSelected = selected === _id;

        return (
            <Col key={_id} xs={24} sm={12} md={12} lg={8} xl={6}>
                <div
                    style={{
                        margin: '10px 0px',
                        height: 200,
                        overflow: 'hidden',
                        border: isSelected ? '1px solid #f21850' : '1px solid rgba(1, 1, 1, 0.1)',
                        cursor: 'pointer',
                        position: 'relative',
                    }}
                    onClick={() => (isSelected ? null : this.onPostSelect(post))}>
                    {isSelected && (
                        <Icon
                            type="check-circle"
                            style={{
                                zIndex: 40,
                                position: 'absolute',
                                right: 10,
                                top: 10,
                                color: '#f21850',
                                fontSize: 30,
                            }}
                        />
                    )}
                    <div className="post-grid-item" style={{ opacity: isSelected ? 0.5 : null }}>
                        <span style={{ pointerEvents: 'none' }}>
                            <Post post={post} />
                        </span>
                    </div>
                </div>
            </Col>
        );
    };

    renderLoadMore = () => {
        const { loading, hasNext } = this.state;

        if (!hasNext) {
            return null;
        }

        return (
            <div
                style={{
                    margin: '10px 0px',
                    height: 200,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                {loading ? (
                    <Spin />
                ) : (
                    <div
                        className="post-grid-load-more-button"
                        style={{
                            position: 'relative',
                            cursor: 'pointer',
                            height: '100%',
                            width: '100%',
                            textAlign: 'center',
                            border: '1px solid rgba(1, 1, 1, 0.1)',
                        }}
                        onClick={this.onLoadMore}>
                        <div
                            style={{
                                margin: 0,
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}>
                            <Icon type="right" style={{ fontSize: 25, color: '#f21850' }} />
                            <div style={{ color: '#8e8e8e' }}>Weitere Posts</div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    render() {
        const { initialLoading, posts } = this.state;

        return (
            <div
                id="PostGrid"
                ref={(listContainer) => (this.listContainer = listContainer)}
                style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
                {initialLoading ? (
                    <div style={{ textAlign: 'center', padding: 25 }}>
                        <Spin />
                    </div>
                ) : (
                    <Row gutter={20}>
                        {posts.map(this.renderPost)}
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            {this.renderLoadMore()}
                        </Col>
                    </Row>
                )}
            </div>
        );
    }
}

PostGrid.contextTypes = {
    request: PropTypes.func,
};

PostGrid.propTypes = {
    onSelect: PropTypes.func,
    initialPost: PropTypes.object,
};

export default PostGrid;
