import './style.css';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PostCreator from '../../components/PostCreator';
import ContactOverlay from '../../components/ContactOverlay';
import ThreeColumnLayout from './components/ThreeColumnLayout';
import FeedPostList from '../../components/FeedPostList';
import './post.css';
import FeedProfileOverview from '../../components/FeedProfileOverview';
import { useHistory, useLocation } from 'react-router-dom';

function Feed({ post, closedProgress = false, successModal = false, user }) {
    const history = useHistory();
    const location = useLocation();

    const prevPostRef = useRef();

    useEffect(() => {
        if (post && (!prevPostRef.current || post.id !== prevPostRef.current.id)) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }

        prevPostRef.current = post;
    }, [post]);

    useEffect(() => {
        const focusPostCreator =
            history.action === 'PUSH' && location.state && location.state.focusPostCreator;
        if (focusPostCreator) {
            const element = document.getElementById('PostCreator');
            element && element.scrollIntoView({ behavior: 'smooth' });
        }
    }, [history.action, location.state]);

    return (
        <div>
            <ThreeColumnLayout>
                <>
                    <FeedProfileOverview />
                </>
                <>
                    <div>
                        <PostCreator post={post} />
                    </div>
                    <br />
                    <div>
                        <FeedPostList />
                    </div>
                </>
                <></>
            </ThreeColumnLayout>
            <ContactOverlay />
        </div>
    );
}

const mapStateToProps = ({ post, user }) => ({
    post: post.post,
    user: user.userData,
});

export default connect(mapStateToProps)(Feed);
