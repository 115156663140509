import { useEffect } from 'react';

import { connect } from 'react-redux';

function ChatwootBubble({ context, companyName, email, children }) {
    const { accountId, companyId } = context;

    useEffect(() => {
        if (!accountId || !companyName) {
            return null;
        }

        const setUpWidget = () => {
            const windowWithChatwoot = window;

            (function (d, t) {
                const BASE_URL = 'https://chatwoot.uninow.io';
                const g = d.createElement(t);
                const s = d.getElementsByTagName(t)[0];
                g.src = `${BASE_URL}/packs/js/sdk.js`;
                s.parentNode.insertBefore(g, s);
                g.async = !0;
                g.onload = () => {
                    windowWithChatwoot.chatwootSDK.run({
                        websiteToken: 'k52XMmtV4P999kpqvRzuhf6n',
                        baseUrl: BASE_URL,
                    });
                };
            })(document, 'script');

            // add event listeners
            windowWithChatwoot.addEventListener('chatwoot:ready', () => {
                windowWithChatwoot.$chatwoot.setUser(accountId, {
                    company_name: companyName,
                    email,
                    name: email,
                });

                windowWithChatwoot.addEventListener('conversation_created', () => {
                    windowWithChatwoot.$chatwoot.setCustomAttributes({
                        user_id: accountId,
                        user_email: email,
                        email,
                        company_name: companyName,
                        company_id: companyId,
                    });
                    windowWithChatwoot.$chatwoot.setConversationCustomAttributes({
                        URL: window.location.href,
                        university: window.location.hostname.split('.')[0],
                    });
                });

                windowWithChatwoot.$chatwoot.setLabel(`${window.location.hostname.split('.')[0]}`);

                windowWithChatwoot.addEventListener('chatwoot:error', (err) => {
                    console.error('error occurred', err);
                });
            });
        };

        setUpWidget();
    }, [accountId, companyId, companyName]);

    return children;
}

const mapStateToProps = (state) => ({
    visible: state.company.profile.visible,
    companyName: state.company.profile.name,
    email: state.authentication.username,
    role: state.authentication.role,
});

export default connect(mapStateToProps)(ChatwootBubble);
