import gql from 'graphql-tag';

import { FRAGMENT_POST_DATA, FRAGMENT_PROFILE_DATA } from './fragments';

export const GET_ME = gql`
    query me {
        me {
            id
            role
            feedEdge {
                profile
                accountId
                role
            }
        }
    }
`;

export const GET_MY_POSTS = gql`
    query getMyPosts(
        $paginationInput: PaginationInput = { first: 5, before: null }
        $filter: PostFilter
    ) {
        me {
            ...ProfileData
            posts(
                pagination: $paginationInput
                orderBy: { direction: DESCENDING, criteria: "publishDate" }
                filter: $filter
            ) {
                edges {
                    cursor
                    node {
                        ...PostData
                    }
                }
                pageInfo {
                    hasPreviousPage
                }
            }
        }
    }
    ${FRAGMENT_POST_DATA}
    ${FRAGMENT_PROFILE_DATA}
`;

export const GET_POSTS = gql`
    query posts(
        $paginationInput: PaginationInput = { first: 10, before: null }
        $authorRole: Role
    ) {
        posts(pagination: $paginationInput, filter: ALL, authorRole: $authorRole) {
            edges {
                cursor
                node {
                    ...PostData
                }
            }
            pageInfo {
                hasPreviousPage
            }
        }
    }
    ${FRAGMENT_POST_DATA}
`;

export const GET_PINNED_POST = gql`
    query pinnedPost($author: ID) {
        pinnedPost(author: $author) {
            ...PostData
        }
    }
    ${FRAGMENT_POST_DATA}
`;

export const GET_POST_STATISTICS = gql`
    query post($id: ID!) {
        post(id: $id) {
            likesCount
            createdAt
            publishDate
            statistics {
                seen
                uniqueSeen
                clicks {
                    label
                    value
                }
                affiliations {
                    label
                    value
                }
                universities {
                    label
                    value
                }
            }
        }
    }
`;

export const PROFILE = gql`
    {
        me {
            ...ProfileData
        }
    }
    ${FRAGMENT_PROFILE_DATA}
`;

export const PROFILE_ACCOUNTS = gql`
    {
        me {
            id
            accounts {
                id
                email
                role
                name
            }
        }
    }
`;

export const PROFILE_APP_CONNECTED = gql`
    {
        me {
            id
            appConnected
        }
    }
`;

export const GET_FRESHMAN_MAPS = gql`
    {
        selectedMap @client
        tmpLocation @client

        me {
            ...ProfileData
            maps {
                id
                name
                description
                visible
                locations {
                    id
                    name
                    type
                    order
                    location {
                        country
                        city
                        postalCode
                        street
                        state
                        addition
                        label
                        geoPoint {
                            lat
                            lng
                        }
                    }
                }
            }
        }
    }
    ${FRAGMENT_PROFILE_DATA}
`;

export const GET_LINK_COLLECTION = gql`
    {
        me {
            ...ProfileData
            linkCollections {
                id
                links {
                    title
                    icon
                    link
                }
            }
        }
    }
    ${FRAGMENT_PROFILE_DATA}
`;

export const PROFILE_INFO = gql`
    {
        me {
            id
            visible
            accessToken
            notificationChannels {
                globalChannel
                targeting {
                    roles
                }
            }
            instagramConnection {
                active
                error
            }
        }
    }
`;

export const PROOF_EMAIL = gql`
    query checkEmail($email: String!) {
        checkEmail(email: $email, origin: FEED)
    }
`;

export const PROOF_USERNAME = gql`
    query checkUsername($username: String!) {
        checkUsername(username: $username)
    }
`;

export const POSSIBLE_TARGETS = gql`
    query possibleTargets {
        possibleTargets {
            affiliations
            universities {
                id
                name
            }
            notificationChannels {
                id
                name
                targeting {
                    roles
                }
            }
        }
    }
`;

export const CURRENT_TARGETS = gql`
    query currentTargets($userId: ID!) {
        user(id: $userId) {
            targeting {
                universities
                affiliations
                allUniversities
                roles
            }
            notificationChannels {
                globalChannel
                name
                targeting {
                    roles
                }
            }
            accounts {
                id
                email
                role
            }
        }
    }
`;

export const USERS = gql`
    query users($pagination: PaginationInput, $orderBy: InputOrder, $filter: UsersFilter) {
        users(pagination: $pagination, orderBy: $orderBy, filter: $filter) {
            edges {
                node {
                    username
                    displayName
                    publishMembership
                }
            }
        }
    }
`;

export const SEARCH_PROFILE = gql`
    query searchProfile($text: String) {
        searchProfile(text: $text) {
            id
            visible
            autoFollow
            username
            displayName
            followerCount
            pipedriveId
            permissions
        }
    }
`;

export const SEARCH_COMPANY = gql`
    query companies($pagination: PaginationInput, $text: String) {
        companies(pagination: $pagination, text: $text) {
            edges {
                node {
                    id
                    title
                    header {
                        large
                    }
                }
            }
        }
    }
`;

export const SEARCH_JOB = gql`
    query jobs($pagination: PaginationInput, $text: String, $filterBy: InputJobFilter) {
        jobs(pagination: $pagination, text: $text, filterBy: $filterBy) {
            edges {
                node {
                    id
                    title
                    type
                    company {
                        title
                    }
                    address {
                        city
                    }
                }
            }
        }
    }
`;

export const FOLLOWER_TREND = gql`
    query followerTrend($unit: TimeUnit, $amount: Int) {
        followerTrend(unit: $unit, amount: $amount) {
            values {
                x
                y
            }
        }
    }
`;

export const MEDIA_STATUS = gql`
    query mediaStatus($key: String!) {
        mediaStatus(key: $key) {
            status
            disconnectedAt
        }
    }
`;

export const GET_REGISTER_LINK = gql`
    query getRegisterLink($id: ID!) {
        getRegisterLink(id: $id)
    }
`;
