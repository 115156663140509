import updateState from '../../helper/redux';
import { SESSIONDATA_LOAD_SUCCESS } from '../actions/index';
import { defaultCompany } from '../../helper/defaultObjects';

const company = (
    state = {
        ...defaultCompany,
        profile: { ...defaultCompany.profile },
        contact: { ...defaultCompany.contact },
    },
    action,
) => {
    const { type, company } = action;
    if (type === SESSIONDATA_LOAD_SUCCESS && company) {
        return {
            ...defaultCompany,
            ...company,
            profile: { ...defaultCompany.profile, ...company.profile },
            contact: { ...defaultCompany.contact, ...company.contact },
        };
    }

    if (action.reducer === 'company') {
        return updateState(state, action);
    }

    return state;
};

export default company;
