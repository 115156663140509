import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router';
import { Alert } from 'antd';
import { FormFooter, FormBody, LeftMargin } from './styles';

class FormLayout extends Component {
    render() {
        const { promptMessage, formIsDirty, buttons, children, infoText } = this.props;
        const { role } = this.context;

        const readonly = role === 'READONLY';

        return (
            <FormBody>
                {infoText && (
                    <Alert
                        message={`${infoText}`}
                        type="info"
                        style={{ marginBottom: '20px' }}
                        showIcon
                    />
                )}
                {children}
                <FormFooter>
                    {!readonly &&
                        buttons.map((Button, index) =>
                            index > 0 ? <LeftMargin key={Button.key}>{Button}</LeftMargin> : Button,
                        )}
                </FormFooter>
            </FormBody>
        );
    }
}

FormLayout.contextTypes = {
    role: PropTypes.string,
};

export default FormLayout;
