import { Table } from 'antd';
import React from 'react';
import useCubeWithQueryErrorHandling from '../../hooks/useCubeWithQueryErrorHandling';

function TopStudiesTable({ companyFilterValue, chartColors }) {
    const studiesColumns = [
        {
            title: 'Studiengänge',
            key: `CompanyTopStudies${companyFilterValue}.studies`,
            dataIndex: `CompanyTopStudies${companyFilterValue}.studies`,
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span
                        style={{
                            height: '15px',
                            width: '15px',
                            backgroundColor: record.color,
                            borderRadius: '50%',
                            display: 'inline-block',
                        }}
                    />
                    <span style={{ marginLeft: 8, fontSize: '16px' }}>{text}</span>
                </div>
            ),
        },
        {
            title: 'Anteil in %',
            key: `CompanyTopStudies${companyFilterValue}.percent`,
            dataIndex: `CompanyTopStudies${companyFilterValue}.percent`,
            align: 'right',
        },
    ];
    const query = {
        measures: [`CompanyTopStudies${companyFilterValue}.percent`],
        dimensions: [
            `CompanyTopStudies${companyFilterValue}.studies`,
            `CompanyTopStudies${companyFilterValue}.companyId`,
        ],
        limit: 10,
        order: {
            [`CompanyTopStudies${companyFilterValue}.percent`]: 'desc',
        },
    };
    const { resultSet, isLoading } = useCubeWithQueryErrorHandling(query);

    if (!resultSet) {
        return null;
    }

    const dataSource = resultSet.tablePivot().map((item) => {
        const keyForPercent = `CompanyTopStudies${companyFilterValue}.percent`;
        item[keyForPercent] = item[keyForPercent]?.replace('.', ',') ?? item[keyForPercent];
        return item;
    });

    const modifiedData = dataSource.map((item, index) => {
        const keyForPercent = `CompanyTopStudies${companyFilterValue}.percent`;

        if (/^\d+\.$/.test(item[keyForPercent]) || /^\d+$/.test(item[keyForPercent])) {
            item[keyForPercent] += ',0';
        } else {
            item[keyForPercent] = item[keyForPercent]?.replace('.', ',') ?? item[keyForPercent];
        }
        return {
            ...item,
            color: chartColors[index % chartColors.length],
        };
    });

    return (
        <Table
            style={{ height: '100%' }}
            dataSource={modifiedData}
            columns={studiesColumns}
            loading={isLoading}
            locale={{ emptyText: 'Es wurden keine Daten gefunden' }}
            pagination={false}
            rowKey={dataSource[`CompanyTopStudies${companyFilterValue}.companyId`]}
        />
    );
}

export default TopStudiesTable;
