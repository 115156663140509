import { Table } from 'antd';
import React from 'react';
import useCubeWithQueryErrorHandling from '../../hooks/useCubeWithQueryErrorHandling';

function TopUniversitiesTable({ companyFilterValue, chartColors }) {
    const universitiesColumns = [
        {
            title: 'Hochschule',
            key: `CompanyTopUniversities${companyFilterValue}.universityName`,
            dataIndex: `CompanyTopUniversities${companyFilterValue}.universityName`,
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span
                        style={{
                            height: '15px',
                            width: '15px',
                            minWidth: '15px',
                            minHeight: '15px',
                            backgroundColor: record.color,
                            borderRadius: '50%',
                            display: 'inline-block',
                        }}
                    />
                    <span style={{ marginLeft: 8, fontSize: '16px' }}>{text}</span>
                </div>
            ),
        },
        {
            title: 'Anteil in %',
            key: `CompanyTopUniversities${companyFilterValue}.percent`,
            dataIndex: `CompanyTopUniversities${companyFilterValue}.percent`,
            align: 'right',
        },
    ];
    const query = {
        measures: [`CompanyTopUniversities${companyFilterValue}.percent`],
        dimensions: [
            `CompanyTopUniversities${companyFilterValue}.universityName`,
            `CompanyTopUniversities${companyFilterValue}.companyId`,
        ],
        limit: 10,
        order: {
            [`CompanyTopUniversities${companyFilterValue}.percent`]: 'desc',
        },
    };
    const { resultSet, isLoading } = useCubeWithQueryErrorHandling(query);

    if (!resultSet) {
        return null;
    }

    const dataSource = resultSet.tablePivot().map((item) => {
        const keyForPercent = `CompanyTopUniversities${companyFilterValue}.percent`;
        item[keyForPercent] = item[keyForPercent]?.replace('.', ',') ?? item[keyForPercent];
        return item;
    });

    const modifiedData = dataSource.map((item, index) => {
        const keyForPercent = `CompanyTopUniversities${companyFilterValue}.percent`;

        if (/^\d+\.$/.test(item[keyForPercent]) || /^\d+$/.test(item[keyForPercent])) {
            item[keyForPercent] += ',0';
        } else {
            item[keyForPercent] = item[keyForPercent]?.replace('.', ',') ?? item[keyForPercent];
        }

        return {
            ...item,
            color: chartColors[index % chartColors.length],
        };
    });

    return (
        <Table
            style={{ height: '100%' }}
            dataSource={modifiedData}
            columns={universitiesColumns}
            loading={isLoading}
            pagination={false}
            locale={{ emptyText: 'Es wurden keine Daten gefunden' }}
            rowKey={dataSource[`CompanyTopUniversities${companyFilterValue}.companyId`]}
        />
    );
}

export default TopUniversitiesTable;
