import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Select } from 'antd';
import { difference, cloneDeep } from 'lodash';

class AdvertDevicesForm extends Component {
    constructor(props) {
        super(props);

        const { devices = [] } = props;

        this.state = {
            devices: cloneDeep(devices),
        };
    }

    handleChange = (devices) => {
        const { devices: initialDevices = [] } = this.props;

        const changes =
            initialDevices.length !== devices.length ||
            difference(initialDevices, devices).length > 0;

        this.props.onChange(changes ? devices : null);
    };

    render() {
        const { disabled } = this.props;
        const { devices } = this.state;

        return (
            <div>
                Wählen Sie die Devices aus, an denen Ihre Werbeanzeige ausgeliefert werden soll.
                <br />
                <br />
                <Select
                    disabled={disabled}
                    mode="tags"
                    style={{ width: '100%' }}
                    placeholder="Nutzer auswählen"
                    defaultValue={devices}
                    onChange={this.handleChange}
                />
            </div>
        );
    }
}

AdvertDevicesForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

export default AdvertDevicesForm;
