import { useSearchBox } from 'react-instantsearch';
import { Icon, Input } from 'antd';
import { useRef, useState } from 'react';

const CustomConnectedSearchBox = (props) => {
    const { query, refine } = useSearchBox(props);
    const [inputValue, setInputValue] = useState(query);
    const inputRef = useRef(null);

    function setQuery(newQuery) {
        setInputValue(newQuery);
        refine(newQuery);
    }

    return (
        <Input
            ref={inputRef}
            style={{ width: '360px' }}
            type="text"
            placeholder="Suche Studierende"
            value={inputValue}
            onChange={(event) => {
                setQuery(event.currentTarget.value);
            }}
            prefix={<Icon type="search" style={{ color: '#82828C' }} />}
        />
    );
};

export default CustomConnectedSearchBox;
