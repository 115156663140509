import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import { DatePicker, Checkbox, Row, Col, Form } from 'antd';

import { MemberSelect } from '..';

const now = moment();

class EndOfTermForm extends Component {
    constructor(props) {
        super(props);

        const mailing = this.props.mailing || [];

        this.state = {
            notificationsEnabled: mailing.length > 0,
        };
    }

    toggleNotification = () => {
        const { mails } = this.state;
        const { onChange, mailing } = this.props;

        this.setState((state) => {
            const notificationsEnabled = !state.notificationsEnabled;

            onChange({ mailing: notificationsEnabled ? mails || mailing : [] });

            return { notificationsEnabled };
        });
    };

    handleMailChange = (mails) => {
        const { onChange } = this.props;

        this.setState({ mails });
        onChange({ mailing: mails });
    };

    render() {
        const { notificationsEnabled } = this.state;
        const { onChange, endOfTerm, productName, mailing = [] } = this.props;

        return (
            <div>
                <Row>
                    <Col xs={10} sm={8} md={8} lg={6} xl={6} xxl={4}>
                        <div style={{ paddingTop: 10 }}>Laufzeit:</div>
                    </Col>
                    <Col xs={14} sm={16} md={16} lg={18} xl={18} xxl={20}>
                        <Form.Item
                            extra={`Am Ende der Laufzeit wird Ihre ${productName} automatisch deaktiviert.`}>
                            <DatePicker
                                placeholder="Laufzeit"
                                onChange={(date) => onChange({ endOfTerm: date })}
                                defaultValue={endOfTerm ? moment(endOfTerm) : null}
                                disabledDate={(date) => date.isBefore(now)}
                                format="DD.MM.YYYY"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={10} sm={8} md={8} lg={6} xl={6} xxl={4}>
                        <div style={{ paddingTop: 10 }}>Benachrichtigung:</div>
                    </Col>
                    <Col xs={14} sm={16} md={16} lg={18} xl={18} xxl={20}>
                        <Form.Item
                            extra={`Diese Personen erhalten eine Benachrichtigung, wenn Ihre ${productName} ausläuft.`}>
                            <MemberSelect
                                initialMembers={mailing || []}
                                onChange={this.handleMailChange}
                                disabled={!notificationsEnabled}
                            />
                        </Form.Item>
                        <Checkbox
                            onChange={this.toggleNotification}
                            checked={!notificationsEnabled}>
                            Keine Benachrichtigung
                        </Checkbox>
                    </Col>
                </Row>
            </div>
        );
    }
}

EndOfTermForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    endOfTerm: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    mailing: PropTypes.array,
    productName: PropTypes.string,
};

EndOfTermForm.defaultProps = {
    mailing: ['all'],
    productName: 'Werbeanzeige',
};

export default EndOfTermForm;
