import React from 'react';
import PropTypes from 'prop-types';

import { CheckboxGrid } from '..';

const options = [
    {
        label: 'Deutsch',
        value: 'de',
    },
    { label: 'Englisch', value: 'en' },
];

const AdvertLocaleForm = ({ onChange, disabled, description, initialLocales }) => (
    <CheckboxGrid
        options={options}
        onChange={onChange}
        disabled={disabled}
        description={description}
        initialValues={initialLocales}
        otherOption
    />
);

AdvertLocaleForm.defaultProps = {
    initialLocales: [],
    description:
        'Wählen Sie die Lokalisierungen aus, an denen Ihre Werbeanzeige ausgeliefert werden soll.',
    disabled: false,
};

AdvertLocaleForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    initialLocales: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
    disabled: PropTypes.bool,
};

export default AdvertLocaleForm;
