import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Spin, Card, Notification, Icon, Tooltip, Modal, InputNumber, Button } from 'antd';

import './style.css';
import StatisticsCard from '../../screens/Statistics/StatisticsCard';

class AdvertContingent extends Component {
    state = {
        modalVisible: false,
        modalLoading: false,
        impressionsInput: 1000,
    };

    handleContingentClick = () => {
        this.setState({
            modalVisible: true,
        });
    };

    handleImpressionsChange = (value) => {
        this.setState({
            impressionsInput: value,
        });
    };

    hideModal = () => {
        this.setState({
            modalVisible: false,
        });
    };

    addContingent = async () => {
        const { impressionsInput } = this.state;
        const { request } = this.context;

        this.setState({
            modalLoading: true,
        });

        const { error } = await request('recruiting/company/addCoins', {
            amount: impressionsInput,
        });

        if (error) {
            Notification.error({
                message: 'Aktualisierung fehlgeschlagen',
                description: 'Kontingent konnte nicht aktualisiert werden.',
            });
        } else if (this.props.onUpdate) {
            this.props.onUpdate();
        }

        this.setState({
            modalVisible: false,
            modalLoading: false,
        });
    };

    render() {
        const { modalVisible, modalLoading, impressionsInput } = this.state;
        const { adCoins, loading } = this.props;
        const { role } = this.context;

        const contingentClickable = ['ADMIN', 'SUPERADMIN'].includes(role.toUpperCase());

        return (
            <div id="AdvertContingent">
                <Modal
                    onCancel={this.hideModal}
                    footer={[
                        <Button key="back" onClick={this.hideModal}>
                            Abbrechen
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            loading={modalLoading}
                            onClick={this.addContingent}>
                            Hinzufügen
                        </Button>,
                    ]}
                    title="Einblendungen hinzufügen"
                    visible={modalVisible}>
                    <div>
                        Wieviele AdCoins möchten Sie hinzufügen:
                        <br />
                        <br />
                        <InputNumber
                            value={impressionsInput}
                            onChange={this.handleImpressionsChange}
                            formatter={(value) => value?.toLocaleString('de')}
                            parser={(value) => Math.round(value.replace('.', ''))}
                            step={10}
                        />
                    </div>
                </Modal>
                {/* <Card */}
                {/*    loading={loading} */}
                {/*    style={{ display: 'inline-block' }} */}
                {/*    headStyle={{ backgroundColor: '#fafafa' }} */}
                {/*    title={<div className="title">AdCoins</div>} */}
                {/*    extra={ */}
                {/*        <Tooltip title="Verbleibendes Kontingent an AdCoins, welche für die Schaltung von Werbeanzeigen zur Verfügung steht"> */}
                {/*            <Icon */}
                {/*                type="question-circle" */}
                {/*                theme="outlined" */}
                {/*                style={{ opacity: 0.5 }} */}
                {/*            /> */}
                {/*        </Tooltip> */}
                {/*    } */}
                {/*    className="card"> */}
                {/*    <div */}
                {/*        className="number" */}
                {/*        style={{ cursor: contingentClickable ? 'pointer' : 'default' }} */}
                {/*        onClick={() => (contingentClickable ? this.handleContingentClick() : null)}> */}
                {/*        {adCoins?.toLocaleString('de')} */}
                {/*    </div> */}
                {/*    <div>verbleibend</div> */}
                {/* </Card> */}
                <StatisticsCard
                    title="AdCoins"
                    tooltipTitle="Verbleibendes Kontingent an AdCoins, welche für die Schaltung von Werbeanzeigen zur Verfügung steht"
                    isLoading={loading}
                    data={
                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                            {adCoins?.toLocaleString('de')}
                            <div
                                style={{
                                    paddingLeft: '6px',
                                    paddingBottom: '10px',
                                    fontSize: '14px',
                                    color: '#202533',
                                }}>
                                verbleibend
                            </div>
                        </div>
                    }
                    onClick={contingentClickable ? () => this.handleContingentClick() : null}
                />
            </div>
        );
    }
}

AdvertContingent.contextTypes = {
    role: PropTypes.string.isRequired,
    request: PropTypes.func.isRequired,
};
AdvertContingent.defaultProps = {
    adCoins: 0,
};

AdvertContingent.propTypes = {
    adCoins: PropTypes.number,
    onUpdate: PropTypes.func,
};

export default AdvertContingent;
