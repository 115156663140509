import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query, withApollo } from 'react-apollo';
import { Icon, Spin } from 'antd';
import update from 'immutability-helper';
import moment from 'moment';
import VisibilitySensor from 'react-visibility-sensor';
import { GET_MY_POSTS } from '../../core/gql/queries';
import FuturePostList from './components/FuturePostList';
import ReviewPostList from './components/ReviewPostList';
import DefaultListItem from './components/DefaultListItem';
import StatisticModal from '../StatisticModal';

import FeedPost from '../FeedPost/index';

import './style.css';

const DefaultListItemEnd = ({ content, style = {} }) => (
    <div className="uninow-post postListEnd color-secondary size-tiny" style={style}>
        {content}
    </div>
);

class FeedPostList extends Component {
    state = {
        modalVisible: false,
        selectedPost: null,
    };

    loadPosts = async (fetchMore, data = {}) => {
        const { postFilter } = this.props;
        const cursor =
            data.me && data.me.posts
                ? data.me.posts.edges[data.me.posts.edges.length - 1].cursor
                : null;

        fetchMore({
            variables: {
                filter: postFilter,
                paginationInput: {
                    first: 5,
                    before: cursor,
                },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return prev;
                }

                const newPosts = fetchMoreResult.me.posts.edges;
                const { pageInfo } = fetchMoreResult.me.posts;
                return update(prev, {
                    me: {
                        posts: {
                            edges: { $push: [...newPosts] },
                            pageInfo: { $set: pageInfo },
                        },
                    },
                });
            },
        });
    };

    hasMorePosts = (loading, error, data = {}) => {
        if (loading) {
            return false;
        }
        if (error) {
            return false;
        }
        if (data.me && data.me.posts && data.me.posts.pageInfo.hasPreviousPage) {
            return true;
        }
        return false;
    };

    toggleModal = (visible, post = this.state.selectedPost) => {
        this.setState({ modalVisible: visible, selectedPost: post });
    };

    renderPost = (edge, props) => {
        return (
            <FeedPost
                {...props}
                key={edge.cursor}
                post={edge.node}
                onStatisticClick={() => this.toggleModal(true, edge.node)}
            />
        );
    };

    render() {
        const { plannedPostFilter, postFilter, draftPostFilter } = this.props;
        const { modalVisible, selectedPost } = this.state;

        return (
            <div>
                <StatisticModal
                    visible={modalVisible}
                    onCancel={() => this.toggleModal(false)}
                    post={selectedPost}
                />
                <Query
                    query={GET_MY_POSTS}
                    variables={{ filter: plannedPostFilter, paginationInput: { first: 200 } }}>
                    {({ loading, error, data = {} }) => {
                        if (loading) {
                            return null;
                        }
                        if (error) {
                            return null;
                        }
                        if (data.me && data.me.posts && data.me.posts.edges.length > 0) {
                            const canAdvertisePosts = (data.me.permissions || []).includes(
                                'ADVERTISE_POST',
                            );

                            return (
                                <FuturePostList
                                    posts={[...data.me.posts.edges].sort((a, b) =>
                                        moment(a.node.publishDate).diff(moment(b.node.publishDate)),
                                    )}
                                    postProps={{ renderAdvertiseButton: canAdvertisePosts }}
                                />
                            );
                        }
                        return null;
                    }}
                </Query>
                <Query
                    query={GET_MY_POSTS}
                    variables={{ filter: draftPostFilter, paginationInput: { first: 200 } }}>
                    {({ loading, error, data = {} }) => {
                        if (loading) {
                            return null;
                        }
                        if (error) {
                            return null;
                        }
                        if (data.me && data.me.posts && data.me.posts.edges.length > 0) {
                            const canAdvertisePosts = (data.me.permissions || []).includes(
                                'ADVERTISE_POST',
                            );

                            return (
                                <ReviewPostList
                                    posts={[...data.me.posts.edges].sort((a, b) =>
                                        moment(a.node.publishDate).diff(moment(b.node.publishDate)),
                                    )}
                                    postProps={{ renderAdvertiseButton: canAdvertisePosts }}
                                />
                            );
                        }
                        return null;
                    }}
                </Query>
                <Query query={GET_MY_POSTS} variables={{ filter: postFilter }}>
                    {({ loading, error, data = {}, fetchMore }) => {
                        let render = <div />;
                        if (error) {
                            render = (
                                <div id="PostList">
                                    <DefaultListItem
                                        content={
                                            <div>
                                                Deine Posts konnten leider nicht geladen werden.
                                                <br />
                                                <br />
                                                Versuche es doch später noch einmal oder kontaktiere
                                                uns über{' '}
                                                <a href="mailto:support@uninow.de">
                                                    support@uninow.de
                                                </a>
                                                .
                                            </div>
                                        }
                                    />
                                </div>
                            );
                        }
                        if (data.me && data.me.posts && data.me.posts.edges.length === 0) {
                            render = (
                                <div id="PostList">
                                    <DefaultListItem
                                        content={
                                            <div>
                                                Verfasse jetzt deinen ersten <b>Post</b> und halte
                                                deine Follower auf dem Laufenden.
                                                <br />
                                                <br />
                                                <Icon
                                                    type="smile-o"
                                                    style={{
                                                        fontSize: 24,
                                                    }}
                                                />
                                            </div>
                                        }
                                    />
                                </div>
                            );
                        }
                        if (data.me && data.me.posts && data.me.posts.edges.length > 0) {
                            const canReadStatistics = (data.me.permissions || []).includes(
                                'READ_POST_STATISTICS',
                            );

                            const canAdvertisePosts = (data.me.permissions || []).includes(
                                'ADVERTISE_POST',
                            );

                            render = (
                                <div>
                                    <div id="PostList">
                                        {data.me.posts.edges.map((edge) =>
                                            this.renderPost(edge, {
                                                renderStatisticButton: canReadStatistics,
                                                renderAdvertiseButton: canAdvertisePosts,
                                            }),
                                        )}
                                        {this.hasMorePosts(loading, error, data) && (
                                            <VisibilitySensor
                                                partialVisibility
                                                offset={{ top: 150 }}
                                                onChange={(value) =>
                                                    value && this.loadPosts(fetchMore, data)
                                                }>
                                                <DefaultListItemEnd
                                                    key="spinner"
                                                    content={<Spin />}
                                                />
                                            </VisibilitySensor>
                                        )}
                                    </div>
                                </div>
                            );
                        }

                        return (
                            <div>
                                {render}
                                {(!data.me && loading) ||
                                    (data.me &&
                                        data.me.posts &&
                                        data.me.posts.pageInfo.hasPreviousPage &&
                                        loading && (
                                            <DefaultListItemEnd
                                                content={
                                                    <div>
                                                        <Spin />
                                                    </div>
                                                }
                                            />
                                        ))}
                                {data.me &&
                                    data.me.posts &&
                                    !data.me.posts.pageInfo.hasPreviousPage &&
                                    data.me.posts.edges.length > 0 && (
                                        <DefaultListItemEnd
                                            content={
                                                <div className="size-tiny">
                                                    Du hast keine weiteren Posts
                                                </div>
                                            }
                                            style={{
                                                padding: 0,
                                                marginTop: -20,
                                            }}
                                        />
                                    )}
                            </div>
                        );
                    }}
                </Query>
            </div>
        );
    }
}

FeedPostList.propTypes = {
    postFilter: PropTypes.string,
    plannedPostFilter: PropTypes.string,
};

FeedPostList.defaultProps = {
    postFilter: 'PUBLISHED',
    plannedPostFilter: 'PLANNED',
    draftPostFilter: 'DRAFT',
};

export default withApollo(FeedPostList);
