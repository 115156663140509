export const freshmanGlobalChannelId = '5b864f12d814aa3b5925af56';
export const emergencyGlobalChannelId = '5c643e5f86db85152f1e6b5f';
export const mapTypes = [
    {
        id: 'food',
        icon: 'coffee',
        name: 'Essen',
    },
    {
        id: 'shopping',
        icon: 'shop',
        name: 'Einkaufen',
    },
    {
        id: 'sport',
        icon: 'dribbble',
        name: 'Sport',
    },
    {
        id: 'party',
        icon: 'sound',
        name: 'Party',
    },
    {
        id: 'relaxe',
        icon: 'heart',
        name: 'Relaxen',
    },
    {
        id: 'sightseeing',
        icon: 'bank',
        name: 'Sightseeing',
    },
    {
        id: 'bib',
        icon: 'book',
        name: 'Bibliothek',
    },
    {
        id: 'room',
        icon: 'home',
        name: 'Hörsaal',
    },
    {
        id: 'default',
        icon: 'environment',
        name: 'Sonstige',
    },
];
export const ATTACHMENT_TYPES = [
    {
        title: 'Company/Jobs of the day',
        type: 'recruiting',
        icon: 'fire',
        roles: ['RECRUITING_PROMOTER'],
    },
    {
        title: 'Link-Vorschau',
        type: 'blog',
        icon: 'link',
    },
    {
        title: 'Podcast',
        type: 'podcast',
        icon: 'audio',
    },
    {
        title: 'Video',
        type: 'video',
        icon: 'video-camera',
    },
    {
        title: 'Galerie',
        type: 'gallery',
        icon: 'picture',
    },
    {
        title: 'Veranstaltung',
        type: 'event',
        icon: 'calendar',
    },
    {
        title: 'Umfrage',
        type: 'poll',
        icon: 'bar-chart',
    },
];

export const LANGUAGES = {
    de: 'Deutsch',
    en: 'Englisch',
};
