import React from 'react';
import { Button, Col, Divider } from 'antd';
import { connect } from 'react-redux';
import { changeArrayElementPos } from '../core/redux/actions';

export function ListSorter({ index, maxLength, onUp, onDown }) {
    return (
        <Col span={1} style={{ paddingTop: 10 }}>
            <Button
                type="secondary"
                shape="circle"
                icon="up"
                onClick={onUp}
                disabled={index === 0}
            />
            <div style={{ padding: 10 }} />
            <Button
                type="secondary"
                shape="circle"
                icon="down"
                onClick={onDown}
                disabled={index === maxLength - 1}
            />
        </Col>
    );
}

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch, { attributeName, index }) => ({
    onUp: () => dispatch(changeArrayElementPos(attributeName, index, -1)),
    onDown: () => dispatch(changeArrayElementPos(attributeName, index, 1)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ListSorter);
