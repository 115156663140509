import React, { PureComponent } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import buttonWithPopconfirm from '../../decorators/buttonWithPopconfirm';
import { ImageEditor } from '../index';

export default class EditorModal extends PureComponent {
    constructor() {
        super();
        this.state = {
            lowQuality: false,
            imageLoading: false,
        };
    }

    render() {
        const {
            editMode,
            deleteDialogueTitle = 'Wollen Sie dieses Bild wirklich löschen?',
            editorTitle = 'Bearbeiten Sie ihr ausgewähltes Bild',
            onCancel,
            onSave,
            onDelete,
            EditComponent,
            style = { top: '5%' },
            disableSaveButton,
            disabledTooltip = 'Die Bildqualität ist unzureichend.',
            loading = false,
            imageToEdit,
            optimalWidth,
            optimalHeight,
            enableZoomOut,
            onRef,
            visible,
        } = this.props;
        const { lowQuality, imageLoading } = this.state;
        let buttons = [
            <Button style={{ marginLeft: 8 }} key="back" size="large" onClick={onCancel}>
                Abbrechen
            </Button>,
            <Tooltip title={lowQuality || disableSaveButton ? disabledTooltip : null}>
                <Button
                    style={{ marginLeft: 8 }}
                    disabled={lowQuality || disableSaveButton || loading || imageLoading}
                    key="submit"
                    type="primary"
                    size="large"
                    onClick={onSave}>
                    {editMode ? 'Änderungen hinzufügen' : 'Hinzufügen'}
                </Button>
            </Tooltip>,
        ];

        if (editMode) {
            buttons = [
                buttonWithPopconfirm({
                    key: 'delete',
                    placement: 'top',
                    title: deleteDialogueTitle,
                    onConfirm: onDelete,
                    okText: 'löschen',
                })(
                    <Button disabled={loading} type="danger" size="large">
                        Löschen
                    </Button>,
                ),
                ...buttons,
            ];
        }

        const ratio = optimalHeight / optimalWidth;
        const editorWidth = Math.min(Math.round(window.innerWidth * 0.3), optimalWidth);
        const editorHeight = editorWidth * ratio;
        return (
            <Modal
                width={editorWidth + 200}
                style={{ top: '10%', ...style }}
                title={editorTitle}
                onCancel={onCancel}
                footer={buttons}
                visible={visible}>
                {EditComponent || (
                    <ImageEditor
                        onRef={onRef}
                        imageToEdit={imageToEdit}
                        optimalWidth={optimalWidth}
                        optimalHeight={optimalHeight}
                        width={editorWidth}
                        height={editorHeight}
                        enableZoomOut={enableZoomOut}
                        onLoadBegin={() => this.setState({ imageLoading: true })}
                        onLoadEnd={() => this.setState({ imageLoading: false })}
                        onQualityChange={(lq) => {
                            if (lq !== lowQuality) {
                                this.setState({ lowQuality: lq });
                            }
                        }}
                    />
                )}
            </Modal>
        );
    }
}
