import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Checkbox } from 'antd';
import { cloneDeep } from 'lodash';

import { RequestContext } from '../Session/RequestProvider';
import { Spinner } from '../index';

function AffiliationSelection({ initialAffiliations, onChange, disabled, description }) {
    const [availableAffiliations, setAvailableAffiliations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [affiliations, setAffiliations] = useState(cloneDeep(initialAffiliations || []));
    const { request } = useContext(RequestContext);
    useEffect(async () => {
        setLoading(true);
        const { body } = await request('recruiting/config/affiliations');
        setAvailableAffiliations(body?.map(({ name }) => name) ?? []);
        setLoading(false);
    }, []);

    const checkAllAffiliations = () => {
        setAffiliations(availableAffiliations);
        onChange(availableAffiliations);
    };

    const uncheckAllAffiliations = () => {
        setAffiliations([]);
        onChange([]);
    };

    const onAffilationCheck = ({ target }) => {
        const { value, checked } = target;

        if (checked) {
            const checkedAffiliations = [...affiliations, value];
            setAffiliations(checkedAffiliations);
            onChange(checkedAffiliations);
        } else {
            const checkedAffiliations = affiliations.filter((affiliation) => affiliation !== value);
            setAffiliations(checkedAffiliations);
            onChange(checkedAffiliations);
        }
    };

    return (
        <div>
            {description && (
                <div>
                    {description}
                    <br /> <br />
                </div>
            )}
            <Row>
                <span className="primary-clickable" onClick={checkAllAffiliations}>
                    Alle auswählen
                </span>{' '}
                |{' '}
                <span className="primary-clickable" onClick={uncheckAllAffiliations}>
                    Keine auswählen
                </span>
            </Row>
            <br />
            {loading && <Spinner />}
            <Row>
                {availableAffiliations.map((affi) => (
                    <Col span={12} key={affi}>
                        <Checkbox
                            disabled={disabled}
                            value={affi}
                            checked={affiliations.some((a) => a === affi)}
                            onChange={onAffilationCheck}
                            style={{ padding: '2px 0px' }}>
                            {affi}
                        </Checkbox>
                    </Col>
                ))}
            </Row>
        </div>
    );
}

AffiliationSelection.propTypes = {
    onChange: PropTypes.func.isRequired,
    initialAffiliations: PropTypes.array,
    description: PropTypes.string,
    disabled: PropTypes.bool,
};

export default AffiliationSelection;
