import { isRegExp, escapeRegExp, isString, isArray, flatten } from 'lodash';

const CONTROL_CHARACTER_MAP = {
    '*': '<i>',
    '**': '<b>',
    '***': '<ib>',
    _: '<i>',
    __: '<b>',
    ___: '<ib>',
};

function replaceControlCharacters(data) {
    const items = data.split(/(\\[*|_]|[*|_]+)/gm);

    const text = [];
    const controlStack = [];

    let workingStack = [];

    while (items.length) {
        const item = items.shift();

        if (item) {
            if (/^[*|_]+$/gm.test(item)) {
                if (controlStack.length) {
                    const lastControlCharacter = controlStack.pop();

                    if (lastControlCharacter === item) {
                        text.push(
                            CONTROL_CHARACTER_MAP[item],
                            workingStack.join(''),
                            CONTROL_CHARACTER_MAP[item],
                        );
                        workingStack = [];
                    } else {
                        text.push(
                            lastControlCharacter,
                            workingStack.join(''),
                            item,
                            items.join(''),
                        );
                        workingStack = [];
                        break;
                    }
                } else {
                    controlStack.push(item);
                }
            } else {
                const appendText = /^\\[*|_]$/gm.test(item) ? item.substr(1) : item;

                if (controlStack.length) {
                    workingStack.push(appendText);
                } else {
                    text.push(appendText);
                }
            }
        }
    }

    if (controlStack.length) {
        text.push(controlStack.join(''));
    }

    if (workingStack.length) {
        text.push(workingStack.join(''));
    }

    return text.join('');
}

function replaceString(str, match, fn, index) {
    let curCharStart = 0;
    let curCharLen = 0;

    if (str === '') {
        return str;
    }
    if (!str || !isString(str)) {
        throw new TypeError(
            'First argument to react-string-replace#replaceString must be a string',
        );
    }

    let re = match;
    if (!isRegExp(re)) {
        re = new RegExp(`(${escapeRegExp(re)})`, 'gi');
    }

    const result = str.split(re);

    // Apply fn to all odd elements
    for (let i = 1, { length } = result; i < length; i += 2) {
        curCharLen = result[i].length;
        curCharStart += result[i - 1].length;
        result[i] = fn(result[i], i, curCharStart, `${index}_${i}`);
        curCharStart += curCharLen;
    }

    return result;
}

export function executeReplaceControlCharacters(data) {
    if (typeof data !== 'string') {
        return data;
    }

    return data
        .split('\n')
        .map((line) => replaceControlCharacters(line))
        .join('\n');
}

export function reactReplaceControlCharacters(data) {
    return isArray(data)
        ? data.map((item) => executeReplaceControlCharacters(item))
        : executeReplaceControlCharacters(data);
}

export function reactStringReplace(source, match, fn) {
    if (!Array.isArray(source)) {
        source = [source];
    }

    return flatten(source.map((x, i) => (isString(x) ? replaceString(x, match, fn, i) : x)));
}
