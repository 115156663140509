import React, { Component } from 'react';
import { Form, Input, Icon, Button, Message } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import logo from '../../assets/images/uninow_logo_vertical_white.svg';
import background from '../../assets/images/background.jpg';
import { LoginFormLayout, InfoText } from './styles';

const FormItem = Form.Item;

class PasswortForgotten extends Component {
    handleSubmit = async (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const { email } = values;
                this.setState({ isLoading: true });

                this.context.sendPasswordEmail(email);

                Message.success('E-Mail versendet');
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <div
                style={{
                    backgroundImage: `url(${background})`,
                    backgroundSize: 'cover',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'scroll',
                    textAlign: 'center',
                }}>
                <div>
                    <img src={logo} alt="logo" width="30%" />
                    <LoginFormLayout>
                        <Form onSubmit={this.handleSubmit} hideRequiredMark>
                            <InfoText>
                                Geben Sie hier Ihre E-Mail Adresse an und klicken Sie auf{' '}
                                <b>E-Mail versenden</b>. Wir schicken Ihnen daraufhin ein neues
                                Passwort zu, welches <b>30 Minuten</b> gültig ist.
                            </InfoText>

                            <FormItem className="entry">
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}>
                                    {getFieldDecorator('email', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Bitte geben Sie eine E-Mail an.',
                                            },
                                            {
                                                type: 'email',
                                                message:
                                                    'Bitte geben Sie eine gültige E-Mail Adresse an.',
                                            },
                                        ],
                                    })(
                                        <Input
                                            style={{ marginRight: '20px' }}
                                            prefix={
                                                <Icon
                                                    type="mail"
                                                    style={{ color: 'rgba(0,0,0,.25)' }}
                                                />
                                            }
                                            placeholder="Geben Sie hier Ihre E-Mail Adresse an..."
                                            size="default"
                                        />,
                                    )}
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="login-form-button"
                                        style={{
                                            fontWeight: 700,
                                            padding: '0px 30px',
                                            borderWidth: 0,
                                            borderRadius: '100px',
                                        }}>
                                        E-Mail versenden
                                    </Button>
                                </div>
                            </FormItem>
                            <FormItem>
                                <Link to="/login">
                                    <Button
                                        style={{
                                            marginRight: '20px',
                                            fontWeight: 700,
                                            padding: '0px 30px',
                                            borderWidth: 0,
                                            borderRadius: '100px',
                                        }}
                                        type="primary"
                                        icon="arrow-left">
                                        Zurück
                                    </Button>
                                </Link>
                            </FormItem>
                        </Form>
                    </LoginFormLayout>
                </div>
            </div>
        );
    }
}

PasswortForgotten.contextTypes = {
    sendPasswordEmail: PropTypes.func.isRequired,
};

export default Form.create()(PasswortForgotten);
