import React, { Component } from 'react';
import logo from '../../assets/images/uninow_logo_vertical_white.svg';
import background from '../../assets/images/background.jpg';

class Error extends Component {
    render() {
        return (
            <div
                style={{
                    backgroundImage: `url(${background})`,
                    backgroundSize: 'cover',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    overflow: 'scroll',
                }}>
                <div>
                    <img src={logo} alt="logo" width="30%" />
                    <div
                        style={{
                            backgroundColor: '#fff',
                            boxShadow: '0 2px 41px 0 rgba(94, 94, 94, 0.5)',
                            textAlign: 'center',
                            margin: '50px auto 0px auto',
                            padding: '20px 50px',
                            width: 600,
                        }}>
                        {this.props.text}
                    </div>
                </div>
            </div>
        );
    }
}

export default Error;
