import gql from 'graphql-tag';

export const FRAGMENT_POST_DATA = gql`
    fragment PostData on Post {
        id
        createdAt
        editedAt
        publishDate
        planned
        pinnedAt
        content
        likesCount
        verificationNeeded
        status
        notificationChannels {
            name
            targeting {
                roles
            }
        }
        author {
            id
            username
            displayName
            avatar {
                large
            }
        }
        creator
        statistics {
            seen
        }
        translations {
            content {
                lang
                value
            }
        }
        attachments {
            id
            type
            ... on CompanyPromotion {
                company {
                    title
                    header {
                        large
                    }
                }
                image {
                    large
                }
                badgeTitle
            }
            ... on JobsListing {
                jobs {
                    id
                    title
                    type
                    company {
                        title
                    }
                    address {
                        city
                    }
                }
            }
            ... on Info {
                title
                description
                url
            }
            ... on Blog {
                title
                subtitle
                description
                url
                image {
                    large
                }
                translations {
                    title {
                        lang
                        value
                    }
                    subtitle {
                        lang
                        value
                    }
                    description {
                        lang
                        value
                    }
                    url {
                        lang
                        value
                    }
                    image {
                        lang
                        value {
                            large
                        }
                    }
                }
            }
            ... on Gallery {
                images {
                    raw
                    large
                }
                translations {
                    images {
                        lang
                        value {
                            raw
                            large
                        }
                    }
                }
            }
            ... on Event {
                title
                location {
                    geoPoint {
                        lat
                        lng
                    }
                    postalCode
                    city
                    street
                    country
                    addition
                    state
                    label
                }
                start
                end
                translations {
                    title {
                        lang
                        value
                    }
                }
            }
            ... on Poll {
                options {
                    option
                    votes
                }
                end
                total
                translations {
                    options {
                        value
                        lang
                    }
                }
            }
            ... on Video {
                video {
                    raw
                    payload {
                        autoplay
                        muted
                    }
                }
                image {
                    large
                }
                translations {
                    image {
                        lang
                        value {
                            large
                        }
                    }
                    video {
                        lang
                        value {
                            raw
                            payload {
                                autoplay
                                muted
                            }
                        }
                    }
                }
            }
            ... on Podcast {
                title
                description
                audio {
                    raw
                }
                duration
                image {
                    large
                }
                translations {
                    audio {
                        lang
                        value {
                            raw
                        }
                    }
                    image {
                        lang
                        value {
                            large
                        }
                    }
                    title {
                        lang
                        value
                    }
                    description {
                        lang
                        value
                    }
                    duration {
                        lang
                        value
                    }
                }
            }
        }
    }
`;

export const FRAGMENT_PROFILE_DATA = gql`
    fragment ProfileData on User {
        id
        email
        name
        accessToken
        publishMembership
        avatar {
            large
            placeholder
        }
        header {
            large
            placeholder
        }
        displayName
        username
        appConnected
        freshmanInUse
        instagramConnection {
            active
            error
        }
        mustReviewInstagramPosts
        permissions
        contact {
            email
            website
            imprint
            location {
                country
                city
                postalCode
                street
                state
                addition
                label
                geoPoint {
                    lat
                    lng
                }
            }
            telephoneNumber
        }
        autoFollow
        followerCount
        postsCount
        visible
        account {
            privacyPolicy {
                version
            }
            userAgreement {
                version
            }
        }
        notificationChannels {
            id
            name
            globalChannel
            targeting {
                roles
            }
        }
        settings {
            skippedSetupSteps
        }
    }
`;
