import { Col, Form, Row } from 'antd';
import { isArray } from 'lodash';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SubTargetsList from './SubTargetsList';

const targetingTypes = [
    {
        label: 'Alle Standorte und Fachrichtungen',
        index: 0,
        type: 'none',
    },
    {
        label: 'Standort',
        index: 1,
        type: 'location',
    },
    {
        label: 'Fachrichtung',
        index: 2,
        type: 'affiliation',
    },
];

const TargetSection = ({ targeting, visible }) => {
    const [activeTargetingType, values] = useMemo(() => {
        if (targeting?.targetGroup === '0' || !targeting.targetGroup) {
            return [targetingTypes[0], []];
        }

        if (targeting?.targetGroup === '1') {
            if (isArray(targeting?.affiliations) && targeting.affiliations.length > 0) {
                return [targetingTypes[2], targeting?.affiliations];
            }
            if (isArray(targeting?.universities) && targeting.universities.length > 0) {
                return [targetingTypes[1], targeting?.universities];
            }
        }

        return [targetingTypes[0], []];
    }, [targeting]);

    const changeAdHint = visible
        ? 'Die Targetierung kann nur geändert werden, indem die Werbeanzeige offline genommen wird und erneut online gestellt wird über die Werbeanzeigen-Liste'
        : 'Die Targetierung kann nur bei der Veröffentlichung der Anzeige über die Listenansicht eingestellt werden';

    return (
        <div>
            <Row style={{ paddingTop: 10 }}>
                <Col xs={10} sm={8} md={8} lg={6} xl={6} xxl={4}>
                    <div style={{ paddingTop: 10 }}>Targeting: </div>
                </Col>
                <Col xs={14} sm={16} md={16} lg={18} xl={18} xxl={20}>
                    <Form.Item extra={changeAdHint}>
                        <div style={{ fontWeight: 600 }}>{activeTargetingType.label}</div>
                        {activeTargetingType.index !== 0 && (
                            <SubTargetsList targets={values} type={activeTargetingType.type} />
                        )}
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

export default TargetSection;

TargetSection.propTypes = {
    targeting: PropTypes.shape({
        targetGroup: PropTypes.oneOf(['0', '1']),
        affiliations: PropTypes.arrayOf(PropTypes.string),
        universities: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    visible: PropTypes.bool.isRequired,
};
