import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Icon } from 'antd';

import { UNIcon } from '../../index';

class JobList extends Component {
    renderJob = (el) => {
        const { addresses = [] } = el;

        const { city } = addresses[0] || {};

        return (
            <Row className="rowContainer" key={el.uuid ?? el.tempID}>
                <Link to={`/stellenanzeigen/editieren/${el.uuid}`}>
                    <Col span={4}>
                        <img src={this.props.logo.medium} alt="logo" />
                    </Col>
                    <Col span={16} style={{ color: '#000' }} offset={1}>
                        <div
                            style={{
                                fontWeight: 'bold',
                                lineHeight: '1.5em',
                                maxHeight: '3em',
                                display: 'block',
                                overflow: 'hidden',
                            }}>
                            {el.title}
                        </div>
                        <div>{this.props.companyName}</div>
                        <div style={{ marginTop: 10, fontSize: 8, color: 'rgba(0, 0, 0, 0.45)' }}>
                            <UNIcon icon="briefcase" />
                            {el.type}
                        </div>
                        <div style={{ fontSize: 8, color: 'rgba(0, 0, 0, 0.45)' }}>
                            <UNIcon icon="pin" />
                            {city}
                            {addresses.length > 1 ? ` + ${addresses.length - 1} weitere` : ''}
                        </div>
                    </Col>
                    <Col span={1} offset={1}>
                        <Icon
                            type="heart"
                            style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: 12, float: 'left' }}
                        />
                    </Col>
                </Link>
            </Row>
        );
    };

    render() {
        return (
            <div
                style={{
                    height: '100%',
                    overflowY: 'scroll',
                    backgroundColor: '#edeff4',
                }}>
                <div
                    className="navbar centerText"
                    style={{
                        borderBottom: '1px solid #edeff4',
                        backgroundColor: '#ffffff',
                    }}>
                    <Link to="/profil">
                        <Icon
                            type="left"
                            style={{
                                fontSize: 12,
                                margin: 6,
                                float: 'left',
                            }}
                            className="color-primary"
                        />
                    </Link>
                </div>

                <div className="navbarSpacer" />

                {this.props.jobs.length > 0 ? (
                    this.props.jobs.map(this.renderJob)
                ) : (
                    <div style={{ padding: 25, color: 'rgba(0,0,0,0.45)', textAlign: 'center' }}>
                        Es sind keine aktiven Stellenanzeigen verfügbar
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ jobs, company }) => ({
    jobs: jobs.joblist.filter((el) => !el.archived && el.visible && el.uuid),
    logo: company.profile.logo,
    companyName: company.profile.name,
});

export default connect(mapStateToProps)(JobList);
