import React from 'react';

import { Row, Col, Icon } from 'antd';

import './style.css';

export const CARD_WIDTH = 240;

const AddressCard = ({ title, address, onClose, onClick }) => (
    <div
        id="AddressCard"
        style={{
            height: 150,
            width: CARD_WIDTH,
            border: '1px solid #d9d9d9',
        }}>
        <Row
            type="flex"
            justify="space-between"
            align="top"
            style={{ borderBottom: '1px solid #d9d9d9', padding: '15px 20px' }}>
            <Col
                span={20}
                style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                }}>
                {title || address.name || address.city}
            </Col>
            <Col span={4} style={{ textAlign: 'right' }}>
                {onClose && (
                    <Icon
                        type="close"
                        onClick={() => onClose(address)}
                        style={{ cursor: 'pointer', color: '#f21850' }}
                    />
                )}
            </Col>
        </Row>
        <Row type="flex" justify="space-between" align="top" style={{ padding: '15px 20px' }}>
            <Col span={24}>
                <div
                    style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        color: onClick ? '#f21850' : null,
                        cursor: onClick ? 'pointer' : null,
                    }}
                    onClick={onClick ? () => onClick(address) : null}>
                    {address.street}
                </div>
                <div style={{ color: '#8f8f8f' }}>
                    {address.postalCode} {address.city}
                </div>
                <div style={{ color: '#8f8f8f' }}>{address.country}</div>
            </Col>
        </Row>
    </div>
);

export default AddressCard;
