import {
    ADD_TO_ARRAY,
    REMOVE_FROM_ARRAY,
    CHANGE_ARRAY_ELEMENT,
    CHANGE_ARRAY_ELEMENT_POS,
    SET_ATTRIBUTEVALUE,
    SET_STATE,
} from '../redux/actions';

export default function updateState(oldState, action) {
    let attributesNames;
    if (action.attributeName) {
        attributesNames = action.attributeName.split('.');
    } else {
        attributesNames = 'iwas';
    }

    let state = { ...oldState };
    const setStateValue = (value) => {
        if (attributesNames.length > 1) {
            state[attributesNames[0]][attributesNames[1]] = value;
        } else {
            state[attributesNames[0]] = value;
        }
    };
    const getStateValue = () => {
        if (attributesNames.length > 1) {
            return state[attributesNames[0]][attributesNames[1]];
        }
        return state[attributesNames[0]];
    };
    switch (action.type) {
        case ADD_TO_ARRAY:
            // eslint-disable-next-line
          const currentValue = getStateValue().filter((item) =>
                attributesNames[0] === 'joblist' ? item.uuid : true,
            );
            setStateValue(
                action.prepend
                    ? [action.element, ...currentValue]
                    : [...currentValue, action.element],
            );

            break;
        case REMOVE_FROM_ARRAY: {
            const attribute = getStateValue();
            setStateValue([
                ...attribute.slice(0, action.index),
                ...attribute.slice(parseInt(action.index, 10) + 1),
            ]);
            break;
        }
        case CHANGE_ARRAY_ELEMENT: {
            const attribute = getStateValue();
            attribute[action.index] = action.updatedElement;
            setStateValue(attribute);
            break;
        }
        case CHANGE_ARRAY_ELEMENT_POS:
            {
                const { oldIndex } = action;
                const array = [...getStateValue()];
                const element = array[oldIndex];

                if (oldIndex > -1) {
                    let newIndex = oldIndex + action.positionChange;
                    if (newIndex < 0) {
                        newIndex = 0;
                    } else if (newIndex >= array.length) {
                        newIndex = array.length;
                    }

                    const arrayClone = [...array];
                    arrayClone.splice(oldIndex, 1);
                    arrayClone.splice(newIndex, 0, element);

                    setStateValue(arrayClone);
                }
            }

            break;

        case SET_ATTRIBUTEVALUE:
            setStateValue(action.value);
            break;
        case SET_STATE:
            state = { ...state, ...action.state };
            break;

        default:
            break;
    }
    return { ...state };
}

export const generateID = () => Date.now() + Math.random();
