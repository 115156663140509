import React, { Component } from 'react';
import { Checkbox, Row, Col, Divider, Form, Icon } from 'antd';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BenefitGroup } from './styles';
import { ProfileLayout, Spinner, UNIcon } from '../../components';
import {
    addToArray,
    removeFromArray,
    changeArrayElement,
    setAttributeValue,
} from '../../core/redux/actions';
import PlanFilter, { PLAN_PERMISSION } from '../../components/PlanFilter';

const CheckboxGroup = Checkbox.Group;

function findBenefit(groups, key) {
    for (let i = 0; i < groups.length; i += 1) {
        const { benefits = [] } = groups[i];

        const hit = benefits.find((b) => b.key === key);

        if (hit) {
            return hit;
        }
    }

    return null;
}

// eslint-disable-next-line
class Benefits extends Component {
    constructor() {
        super();
        this.state = { loading: true, benefitItems: [] };
    }

    componentDidMount() {
        this.fetchBenefits();
    }

    fetchBenefits = async () => {
        const { request } = this.context;

        const { body } = await request('recruiting/config/benefits');

        if (body) {
            this.setState({
                benefitItems: body,
            });
        }

        this.setState({ loading: false });
    };

    handleCheckboxChange = ({ target }) => {
        const { checkedBenefits = [] } = this.props;
        const { value, checked } = target;

        if (checked) {
            const benefit = findBenefit(this.state.benefitItems, value);

            if (benefit) {
                this.props.setAttributeValue('profile.benefits', [...checkedBenefits, benefit]);
            }
        } else {
            const index = checkedBenefits.findIndex((benefit) => benefit.key === value);

            if (index !== -1) {
                this.props.setAttributeValue(
                    'profile.benefits',
                    update(checkedBenefits, { $splice: [[index, 1]] }),
                );
            }
        }
    };

    handleSubmit = () =>
        new Promise((resolve, reject) =>
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    resolve(true);
                }
                resolve(false);
            }),
        );

    render() {
        if (this.state.loading) {
            return <Spinner tip="Daten werden geladen..." />;
        }
        const items = this.state.benefitItems;

        const benefitGroupsView = items.map((group) => {
            const benefits = [...group.benefits];

            const benefitsView = benefits.map((benefit) => (
                <Col key={benefit.key} span={12}>
                    <Checkbox onChange={this.handleCheckboxChange} value={benefit.key}>
                        <UNIcon icon={benefit.icon} />
                        {benefit.text}
                    </Checkbox>
                </Col>
            ));

            return (
                <BenefitGroup key={group.group}>
                    <h1>{group.group}</h1>
                    <Divider />

                    <CheckboxGroup
                        style={{ width: '100%' }}
                        value={this.props.checkedBenefits.map((benefit) => benefit.key)}>
                        <Row>{benefitsView}</Row>
                    </CheckboxGroup>
                </BenefitGroup>
            );
        });
        const isAdmin = ['SUPERADMIN'].includes(this.context.role.toUpperCase());
        return (
            <ProfileLayout
                subHeaderSelectedKey="Benefits"
                onSubmit={this.handleSubmit}
                infoText="Benefits
      steigern	Ihre	Attraktivität	bei	den
      Studierenden.	Geben	Sie	mithilfe	der	Checkboxen	an,
      welche	Vorteile	Sie	den	Studierenden	und
      Mitarbeitern	bieten.">
                <PlanFilter permission={PLAN_PERMISSION.BENEFITS}>{benefitGroupsView}</PlanFilter>
                {isAdmin && (
                    <Link to="/editBenefits">
                        <span className="color-primary">
                            <Icon
                                type="edit"
                                style={{
                                    fontSize: 12,
                                    margin: 6,
                                    float: 'left',
                                }}
                            />
                            Bearbeiten
                        </span>
                    </Link>
                )}
            </ProfileLayout>
        );
    }
}

const mapStateToProps = (state) => {
    const { benefits } = state.company.profile;

    const checkedBenefits = benefits.filter((benefit) => !benefit.key?.startsWith('other_'));

    return {
        benefits,
        checkedBenefits,
    };
};

Benefits.contextTypes = {
    role: PropTypes.string.isRequired,
    request: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    addToArray: (attributeName, element) => dispatch(addToArray(attributeName, element)),
    removeFromArray: (attributeName, index) => dispatch(removeFromArray(attributeName, index)),
    changeArrayElement: (attributeName, index, updatedElement) =>
        dispatch(changeArrayElement(attributeName, index, updatedElement)),
    setAttributeValue: (attributeName, value) => dispatch(setAttributeValue(attributeName, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Benefits));
