import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Badge, Table, Input, Icon, Tooltip } from 'antd';
import moment from 'moment';
import '../style.css';
import JobStatsModal from './JobStatsModal';
import RecruitingCards from './RecruitingCards';
import useCubeWithQueryErrorHandling from '../../hooks/useCubeWithQueryErrorHandling';
import { dotFormattedNumber } from '../../../../core/helper/numberFormatter';

const ALL_STATUSES = [
    { text: 'aktiv', value: 'ACTIVE' },
    { text: 'inaktiv', value: 'INACTIVE' },
    { text: 'gelöscht', value: 'REMOVED' },
    { text: 'archiviert', value: 'ARCHIVED' },
];
const DEFAULT_STATUS = ['ACTIVE', 'INACTIVE', 'ARCHIVED', 'REMOVED'];
const DEFAULT_CUBE_QUERY = {
    measures: ['JobStats.clicked', 'JobStats.seen', 'JobStats.interaction'],
    dimensions: [
        'JobStats.jobId',
        'JobStats.companyId',
        'JobStats.title',
        'JobStats.status',
        'JobStats.startDate',
        'JobStats.endDate',
        'JobStats.lastUpdate',
    ],
    limit: 100,
};
const getCubeFiltersQuery = (values) => ({
    filters: [
        {
            member: 'JobStats.status',
            operator: 'equals',
            values,
        },
    ],
});

const filterByStatus = (value, record) => {
    return record['JobStats.status'] === value;
};

const Recruiting = () => {
    let data = [];
    const [filter, setFilter] = useState(DEFAULT_STATUS);
    let searchInput = useRef(null);
    const query = () =>
        filter.length
            ? { ...getCubeFiltersQuery(filter), ...DEFAULT_CUBE_QUERY }
            : DEFAULT_CUBE_QUERY;
    const { resultSet, isLoading } = useCubeWithQueryErrorHandling(query());

    if (!resultSet) {
        return null;
    }

    if (resultSet) {
        data = resultSet.tablePivot();
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'JobStats.title',
            key: 'JobStats.title',
            filterIcon: (filtered) => (
                <Icon
                    type="filter"
                    style={{ color: filtered ? '#f21850' : '#aaa', marginBottom: 4 }}
                />
            ),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
                return (
                    <div id="TableFilter">
                        <Input.Search
                            ref={(ele) => {
                                searchInput = ele;
                            }}
                            placeholder="Suchen"
                            value={selectedKeys[0]}
                            onChange={(e) =>
                                setSelectedKeys(e.target.value ? [e.target.value] : [])
                            }
                            onSearch={() => confirm()}
                            onPressEnter={() => confirm()}
                            enterButton
                        />
                    </div>
                );
            },
            onFilter: (value, record) =>
                record['JobStats.title'].toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => {
                        searchInput.focus();
                    });
                }
            },
            render: (title, record) => {
                return (
                    <Link
                        to={`?jobId=${record['JobStats.jobId']}`}
                        style={{ color: '#f21850' }}
                        replace>
                        {title}
                    </Link>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'JobStats.status',
            key: 'JobStats.status',
            align: 'center',
            defaultFilteredValue: filter,
            filters: ALL_STATUSES,
            onFilter: filterByStatus,
            render: (visible, record) => {
                if (record['JobStats.status'] === 'REMOVED') {
                    return (
                        <span>
                            <Badge status="error" /> gelöscht
                        </span>
                    );
                }
                if (record['JobStats.status'] === 'ARCHIVED') {
                    return (
                        <span>
                            <Badge status="error" /> archiviert
                        </span>
                    );
                }
                return record['JobStats.status'] === 'INACTIVE' ? (
                    <span>
                        <Badge status="error" /> inaktiv
                    </span>
                ) : (
                    <span>
                        <Badge status="success" /> aktiv
                    </span>
                );
            },
        },
        {
            title: 'Startdatum',
            dataIndex: 'JobStats.startDate',
            key: 'JobStats.startDate',
            render: (createdAt) => moment(createdAt).format('DD.MM.YYYY'),
        },
        {
            title: 'Enddatum',
            dataIndex: 'JobStats.endDate',
            key: 'JobStats.endDate',
            render: (endDate) => (endDate ? moment(endDate).format('DD.MM.YYYY') : ''),
        },
        {
            title: (
                <Tooltip title="Wie häufig wurde Ihre Stellenanzeige in der App auf der Startseite oder in Suchergebnislisten angezeigt.">
                    Gesehen
                </Tooltip>
            ),
            dataIndex: 'JobStats.seen',
            key: 'JobStats.seen',
            render: (seen) => dotFormattedNumber(seen),
            sorter: (a, b) => a['JobStats.seen'] - b['JobStats.seen'],
        },
        {
            title: (
                <Tooltip title="Wie häufig wurde Ihre Stellenanzeige in der App aktiv aufgerufen.">
                    Klicks
                </Tooltip>
            ),
            dataIndex: 'JobStats.clicked',
            key: 'JobStats.clicked',
            render: (clicked) => dotFormattedNumber(clicked),
            sorter: (a, b) => a['JobStats.clicked'] - b['JobStats.clicked'],
        },
        {
            title: (
                <Tooltip title="Wie häufig wurde mit Ihrer Stellenanzeige in der App interagiert.">
                    Interaktionen
                </Tooltip>
            ),
            dataIndex: 'JobStats.interaction',
            key: 'JobStats.interactions',
            render: (interactions) => dotFormattedNumber(interactions),
            sorter: (a, b) => a['JobStats.interaction'] - b['JobStats.interaction'],
        },
    ];

    return (
        <div>
            <RecruitingCards filters={filter} />
            <Row>
                <Col span={24}>
                    <Table
                        style={{ height: '100%' }}
                        columns={columns}
                        dataSource={data}
                        loading={isLoading}
                        onChange={(pagination, filters, sorter, extra) => {
                            setFilter(filters['JobStats.status']);
                        }}
                        locale={{ emptyText: 'Es wurden keine Daten gefunden' }}
                        rowClassName={(record) => (record.total ? 'table-highlight-row' : '')}
                        pagination={{ defaultPageSize: 10, position: 'bottom' }}
                        rowKey={(record) => record['JobStats.jobId']}
                    />
                    <div
                        style={{
                            fontSize: '0.8rem',
                            opacity: 0.5,
                            color: '#000000',
                        }}>
                        Letzte Aktualisierung am{' '}
                        {moment(data['JobStats.lastUpdate']).format('DD.MM.YYYY')}
                    </div>
                </Col>
            </Row>
            {data && <JobStatsModal data={data} loading={isLoading} />}
        </div>
    );
};

export default Recruiting;
