import moment from 'moment';

export const defaultImage = {
    raw: '',
    payload: {
        scale: 1,
        posX: 0.5,
        posY: 0.5,
    },
};

export const defaultLocation = {
    geoPoint: {
        lat: 52.1079294,
        lng: 11.637266400000044,
    },
    street: '',
    postalCode: 0,
    city: '',
    country: '',
    isDefault: true,
};

export const defaultContact = {
    name: '',
    phone: '',
    email: '',
    street: '',
    postalCode: undefined,
    city: '',
    website: '',
};

export const defaultRating = {
    title: '',
    subtitle: '',
    text: '',
    image: undefined,
};

export const defaultAward = {
    title: '',
    subtitle: '',
    image: undefined,
};

export const defaultEvent = {
    title: 'Ihr Event',
    date: '',
    start: moment.utc().add(1, 'days').minute(0),
};

export const defaultSocialMedium = {
    text: 'Website',
    iconSet: 'MaterialCommunityIcons',
    icon: 'web',
    uri: '',
    key: 'social-web',
};

export const defaultSection = {
    title: '',
    content: '',
};

export const defaultJob = {
    title: '',
    type: '',
    summary: '',
    location: undefined,
    addresses: [],
    sections: [],
    archived: false,
    visible: false,
    notes: '', // weitere informationen
    studies: [],
    tags: [],
    mailing: ['all'],
    notificationRecipients: ['all'],
};

export const internshipJob = {
    title: 'Tagespraktikum (Expedition Wirtschaft)',
    type: 'Praktikum',
    summary:
        'Mit der Expedition Wirtschaft sicherst Du Dir so viele Tagespraktika wie Du für Deine Berufsorientierung benötigst. Dafür öffnen Dir über 160 Wirtschaftsprüfungsgesellschaften in ganz Deutschland ihre Türen.\n\nVereinbare einen Termin mit Deiner Wunschgesellschaft und begleite die Wirtschaftsprüfer/innen einen Tag lang durch ihren Arbeitsalltag. Damit erfährst Du hautnah, wie vielfältig die Arbeit eines Wirtschaftsprüfers sein kann.\n\nDie Expedition Wirtschaft ist ein kostenfreies Angebot vom Institut der Wirtschaftsprüfer in Deutschland e.V. (IDW) und seinen Mitgliedsunternehmen .',
    location: undefined,
    addresses: [],
    sections: [],
    archived: false,
    visible: false,
    notes: '', // weitere informationen
    studies: ['Ingenieurwissenschaften und Technik', 'Informatik', 'Wirtschaftswissenschaften'],
    tags: ['IDW'],
    department: 'Management & Beratung',
    mailing: ['all'],
};

export const defaultCompany = {
    profile: {
        visible: false,
        addresses: [],
        employees: '',
        name: '',
        industry: '',
        specialization: '',
        yearOfFoundation: '',
        statement: '',
        logo: {},
        header: {},
        aboutUs: '',
        gallery: [],
        benefits: [],
        ratings: [],
        awards: [],
        events: [],
        tags: [],
        socialMedia: [],
    },
    contact: {
        name: '',
        phone: '',
        email: '',
        street: '',
        postalCode: undefined,
        city: '',
        website: '',
    },
};
