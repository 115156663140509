import React, { useEffect, useState } from 'react';
import { Button, Tabs, Tag, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useInstantSearch } from 'react-instantsearch';

const { TabPane } = Tabs;

const statusTagStyles = {
    borderRadius: '9999px',
    fontSize: '14px',
    padding: '8px 12px',
    lineHeight: '20px',
    border: 'none',
    fontWeight: 600,
    marginLeft: '0',
};

const maxNameLength = 28;
const maxValueLength = 38;

function isoToTimestamp(isoString) {
    // Parse the ISO 8601 date-time string to a Date object
    const date = new Date(isoString);

    // Get the timestamp in milliseconds
    return date.getTime();
}

function formatDate(dateInt) {
    const date = new Date(dateInt);
    isoToTimestamp(date);
    if (!(date instanceof Date && !isNaN(date.getTime()))) {
        return '';
    }
    // format data
    return `Aktualisiert am ${date.toLocaleString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    })}`;
}

const renderInfoRows = (infoArray, windowWidth) => {
    return infoArray.map(({ label, value }) => (
        <div
            key={label}
            style={{
                display: 'flex',
                color: '#37373B',
                justifyContent: 'space-between',
                marginLeft: windowWidth < 850 ? '16px' : 0,
            }}>
            <div
                style={{
                    minWidth: '175px',
                    fontSize: '16px',
                    fontWeight: 600,
                    flex: 1,
                }}>
                {label}
            </div>
            <div
                style={{
                    fontSize: '16px',
                    color: '#37373B',
                    overflowWrap: 'break-word',
                    textAlign: 'right',
                    marginBottom: '8px',
                    maxWidth: '200px',
                }}>
                {value && value.length > maxValueLength ? (
                    <Tooltip title={value}>{value.substring(0, maxValueLength)}...</Tooltip>
                ) : (
                    value
                )}
            </div>
        </div>
    ));
};

const getStatusTag = (status) => {
    switch (status) {
        case 'pending':
            return (
                <Tag style={statusTagStyles} color="gold">
                    ausstehend
                </Tag>
            );
        case 'accepted':
            return (
                <Tag style={{ color: '#13C471', ...statusTagStyles }} color="#E7F9F1">
                    freigegeben
                </Tag>
            );
        default:
            return (
                <Tag style={{ color: '#F93B42', ...statusTagStyles }} color="#FEE4D7">
                    abgelehnt
                </Tag>
            );
    }
};

export default function ContactedHit({ hit, filter, searchFilter }) {
    const { indexUiState } = useInstantSearch();
    const [selectedStudy, setSelectedStudy] = useState({});
    const windowWidth = window.innerWidth;
    const [activeTabKey, setActiveTabKey] = useState('');
    const name = `${hit.firstname} ${hit.surname}`;
    const isStudies = !!hit?.studies.length;

    useEffect(() => {
        if (hit?.studies?.length > 0) {
            setSelectedStudy(hit.studies[0]);
            setActiveTabKey(`${hit?.studies[0]?.studyName}-0`);
        }
    }, [hit.studies]);

    useEffect(() => {
        if (hit?.studies?.length > 0) {
            setSelectedStudy(hit.studies[0]);
            setActiveTabKey(`${hit?.studies[0]?.studyName}-0`);
        }
    }, [hit.studies]);

    useEffect(() => {
        // check checkboxes and search box
        const overallFilter = indexUiState.query ? indexUiState.query.trim().toLowerCase() : '';
        // make first tab active by default

        if (!isStudies) {
            return null;
        }
        setActiveTabKey(`${hit?.studies[0]?.studyName}-0`);
        setSelectedStudy(hit.studies[0]);

        if (!overallFilter) {
            setActiveTabKey(`${hit?.studies[0]?.studyName}-0`);
            setSelectedStudy(hit.studies[0]);
            return;
        }

        // check studies array

        // find the index that matches the filter
        const matchedIndex = hit.studies.findIndex((study) =>
            [study.studyName, study.degree, study.startingSemester].some((field) =>
                field.toLowerCase().includes(overallFilter),
            ),
        );

        // find the matchedStudy by index
        const matchedStudy = matchedIndex !== -1 ? hit.studies[matchedIndex] : null;

        // if some value from studies or university value is matching the filter - make tab active
        if (
            matchedStudy ||
            (hit.university && hit.university.toLowerCase().includes(overallFilter))
        ) {
            const targetStudy = matchedStudy || hit.studies[0];
            // make tab key unique by concat studyName and index
            setActiveTabKey(`${targetStudy.studyName}-${matchedIndex}`);
            setSelectedStudy(targetStudy);
        }
    }, [filter, searchFilter, hit.studies, hit.university]);

    return (
        <div
            className="studentCard"
            key={hit.id}
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
            }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    paddingBottom: '32px',
                }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                    }}>
                    <div
                        style={{
                            fontSize: '24px',
                            fontWeight: '600',
                            lineHeight: '32px',
                            overflowWrap: 'break-word',
                            width: '220px',
                            color: '#1A1D27',
                        }}>
                        {hit.surname && hit.firstname ? (
                            <span>
                                {name.length > maxNameLength ? (
                                    <Tooltip title={name}>
                                        {name.substring(0, maxNameLength)}
                                        ...
                                    </Tooltip>
                                ) : (
                                    <span style={{ fontSize: '20px', fontWeight: 600 }}>
                                        {name}
                                    </span>
                                )}
                            </span>
                        ) : (
                            <span style={{ fontSize: '20px', fontWeight: 600 }}>Anonym</span>
                        )}
                    </div>
                    <span>{formatDate(hit.updatedAt)}</span>
                </div>
                {getStatusTag(hit.status || 'default')}
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'space-between',
                    width: '100%',
                }}>
                <div>
                    {hit?.studies?.length > 1 && (
                        <Tabs
                            className="studies-tabs"
                            tabBarStyle={{
                                border: 'none',
                            }}
                            tabBarGutter={4}
                            size="small"
                            defaultActiveKey="0"
                            activeKey={activeTabKey}
                            onChange={(key) => {
                                // split the key to get index
                                const [studyName, index] = key.split('-');
                                const newSelectedStudy = hit.studies[index];
                                setSelectedStudy(newSelectedStudy);
                                setActiveTabKey(key);
                            }}>
                            {hit.studies.map((study, index) => {
                                // make tab key unique by concat studyName and index
                                const uniqueKey = `${study.studyName}-${index}`;
                                return <TabPane tab={`Studium ${index + 1}`} key={uniqueKey} />;
                            })}
                        </Tabs>
                    )}
                    {renderInfoRows(
                        [
                            { label: 'Hochschule', value: hit.university },
                            { label: 'Studiengang', value: selectedStudy.studyName },
                            { label: 'Angestr. Abschluss', value: selectedStudy.degree },
                            {
                                label: 'Startsemester',
                                value: selectedStudy.startingSemester,
                            },
                            {
                                label: 'E-Mailadresse',
                                value:
                                    hit.status === 'accepted'
                                        ? hit.contactMail
                                        : 'Nicht freigegeben',
                            },
                            {
                                label: 'Telefonnummer',
                                value:
                                    hit.status === 'accepted'
                                        ? hit.contactPhone
                                        : 'Nicht freigegeben',
                            },
                        ],
                        windowWidth,
                    )}
                </div>
                {hit.contactMail && (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            style={{ background: '#F42847' }}
                            type="primary"
                            onClick={() => {
                                window.location.href = `mailto:${hit.contactMail}`;
                            }}>
                            Anschreiben
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

ContactedHit.propTypes = {
    hit: PropTypes.shape({
        id: PropTypes.string.isRequired,
        profileId: PropTypes.string,
        companyId: PropTypes.string,
        status: PropTypes.oneOf(['pending', 'declined', 'accepted']).isRequired,
        university: PropTypes.string.isRequired,
        studies: PropTypes.array,
        updatedAt: PropTypes.any,
        surname: PropTypes.string,
        firstname: PropTypes.string,
        contactPhone: PropTypes.string,
        contactMail: PropTypes.string,
    }).isRequired,
    filter: PropTypes.string.isRequired,
    searchFilter: PropTypes.string.isRequired,
};
