import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import { Row, Col, Icon } from 'antd';

import parseMarkdown from '../../../core/helper/markdown';

class Awards extends Component {
    renderAward = (el) => (
        <Row className="rowContainer" key={el.key} type="flex" align="middle">
            <Col span={4}>
                {el.image ? (
                    <img
                        alt="Award"
                        key={el.image.large}
                        src={el.image.edited || el.image.large}
                        style={{ width: '100%', height: '100%' }}
                    />
                ) : (
                    <img
                        alt="Award"
                        key="https://images.uninow.com/recruiting/default/award00.jpg"
                        src="https://images.uninow.com/recruiting/default/award00.jpg"
                        style={{ width: '100%', height: '100%' }}
                    />
                )}
            </Col>
            <Col offset={1} span={18}>
                <div>{el.title}</div>
                <div className="color-secondary" style={{ fontSize: '0.9em' }}>
                    {parseMarkdown(el.subtitle)}
                </div>
            </Col>
        </Row>
    );

    render() {
        return (
            <div
                style={{
                    height: '100%',
                    overflowY: 'scroll',
                }}>
                <div
                    className="navbar centerText"
                    style={{
                        borderBottom: '1px solid #edeff4',
                        backgroundColor: '#ffffff',
                    }}>
                    <Link to="/profil">
                        <Icon
                            type="left"
                            style={{
                                fontSize: 12,
                                margin: 6,
                                float: 'left',
                            }}
                            className="color-primary"
                        />
                    </Link>
                </div>

                <div className="navbarSpacer" />

                {this.props.awards.length > 0 &&
                    this.props.awards
                        .map(this.renderAward)
                        .reduce((prev, curr) => [
                            prev,
                            <div key={`${curr.key}_divider`} className="divider" />,
                            curr,
                        ])}
            </div>
        );
    }
}

const mapStateToProps = ({ company }) => ({
    awards: company.profile.awards,
});

export default connect(mapStateToProps)(Awards);
