import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Select } from 'antd';

import './style.css';

class MemberSelect extends Component {
    constructor(props, context) {
        super(props, context);

        const { username } = context;
        const { initialMembers = [], memberList = [] } = props;

        const memberMails = memberList.map(({ username }) => username.toLowerCase());

        const customMails = initialMembers.filter(
            (member) => !['all', 'onlyme'].includes(member) && !memberMails.includes(member),
        );

        const hasAllMembers =
            initialMembers.includes('all') ||
            memberMails.every((mail) => initialMembers.includes(mail));

        const values = hasAllMembers ? ['all'].concat(customMails) : initialMembers;

        const members = hasAllMembers
            ? values.concat(memberMails)
            : values.concat(
                  values.length === 1 && values.includes(username.toLowerCase()) ? ['onlyme'] : [],
              );

        this.state = {
            members,
            search: null,
            maxTagCount: values.length,
        };
    }

    handleSearch = (search) => {
        this.setState({ search });
    };

    handleChange = (values) => {
        this.setState({ search: null, members: values });
    };

    handleSelectAll = () => {
        const { members } = this.state;
        const { initialMembers, memberList = [] } = this.props;

        const currentMembers = members || initialMembers;

        const memberMails = memberList.map(({ username }) => username.toLowerCase());

        this.setState({
            search: null,
            members: currentMembers.concat(
                memberMails.filter((mail) => !currentMembers.includes(mail)),
            ),
        });
    };

    handleSelectMe = () => {
        const { username } = this.context;

        this.setState({ search: null, members: [username] });
    };

    handleSelect = (value) => {
        const { username } = this.context;
        const { members } = this.state;
        const { onChange, initialMembers, memberList = [] } = this.props;

        const currentMembers = members || initialMembers;

        let memberMails;
        let currentCustomMails;

        let changedMembers;
        let nextMembers;

        switch (value) {
            case 'all':
                memberMails = memberList.map(({ username }) => username.toLowerCase());

                currentCustomMails = currentMembers.filter(
                    (member) =>
                        !['all', 'onlyme'].includes(member) && !memberMails.includes(member),
                );

                changedMembers = [value].concat(currentCustomMails);

                nextMembers = changedMembers.concat(memberMails);
                break;

            case 'onlyme':
                changedMembers = [username.toLowerCase()];

                nextMembers = [username.toLowerCase(), value];
                break;

            default:
                const containsAll = currentMembers.includes('all');

                memberMails = memberList.map(({ username }) => username.toLowerCase());

                currentCustomMails = currentMembers.filter(
                    (member) =>
                        !['all', 'onlyme'].includes(member) && !memberMails.includes(member),
                );

                if (containsAll) {
                    changedMembers = ['all'].concat(currentCustomMails).concat([value]);

                    nextMembers = changedMembers.concat(memberMails);
                } else {
                    changedMembers = currentMembers
                        .filter((member) => !['onlyme'].includes(member))
                        .concat([value]);

                    const containsAllMembers = memberMails.every((mail) =>
                        changedMembers.includes(mail),
                    );

                    if (containsAllMembers) {
                        changedMembers = ['all'].concat(currentCustomMails);

                        nextMembers = changedMembers.concat(memberMails);
                    } else {
                        nextMembers = changedMembers;
                    }
                }
                break;
        }

        this.setState({ search: null, members: nextMembers, maxTagCount: changedMembers.length });
        onChange(changedMembers);
    };

    handleDeselect = (value) => {
        const { username } = this.context;
        const { members } = this.state;
        const { onChange, initialMembers, memberList = [] } = this.props;

        const currentMembers = members || initialMembers;

        let memberMails;
        let currentCustomMails;

        let changedMembers;
        let nextMembers;

        switch (value) {
            case 'all':
                memberMails = memberList.map(({ username }) => username.toLowerCase());

                changedMembers = currentMembers.filter(
                    (member) =>
                        !['all', 'onlyme'].includes(member) && !memberMails.includes(member),
                );

                nextMembers = changedMembers.concat(
                    changedMembers.length === 1 && changedMembers.includes(username.toLowerCase())
                        ? ['onlyme']
                        : [],
                );
                break;

            case 'onlyme':
                changedMembers = [];

                nextMembers = changedMembers;
                break;

            default:
                const containsAll = currentMembers.includes('all');

                if (containsAll) {
                    memberMails = memberList.map(({ username }) => username.toLowerCase());

                    const isMember = memberMails.includes(value);

                    if (isMember) {
                        changedMembers = currentMembers.filter(
                            (member) => !['all', 'onlyme'].includes(member) && member !== value,
                        );

                        nextMembers = changedMembers.concat(
                            changedMembers.length === 1 &&
                                changedMembers.includes(username.toLowerCase())
                                ? ['onlyme']
                                : [],
                        );
                    } else {
                        currentCustomMails = currentMembers.filter(
                            (member) =>
                                !['all', 'onlyme'].includes(member) &&
                                !memberMails.includes(member) &&
                                member !== value,
                        );

                        changedMembers = ['all'].concat(currentCustomMails);

                        nextMembers = changedMembers.concat(memberMails);
                    }
                } else {
                    changedMembers = currentMembers.filter(
                        (member) => !['onlyme'].includes(member) && member !== value,
                    );

                    nextMembers = changedMembers.concat(
                        changedMembers.length === 1 &&
                            changedMembers.includes(username.toLowerCase())
                            ? ['onlyme']
                            : [],
                    );
                }
                break;
        }

        this.setState({ search: null, members: nextMembers, maxTagCount: changedMembers.length });
        onChange(changedMembers);
    };

    render() {
        const { search, members, maxTagCount } = this.state;
        const { initialMembers, disabled, memberList = [], limitToOne } = this.props;

        const mails = memberList.map(({ username }) => username.toLowerCase());

        const showAddOption =
            search &&
            !(members || initialMembers).some((member) => member.includes(search.toLowerCase()));

        return (
            <div id="MemberSelect">
                <Select
                    mode="tags"
                    style={{ width: '100%' }}
                    onSelect={this.handleSelect}
                    onDeselect={this.handleDeselect}
                    onSearch={this.handleSearch}
                    filterOption={(input, { key }) =>
                        !['all', 'onlyme'].includes(key) && key.includes(input.toLowerCase())
                    }
                    placeholder="Mitglieder auswählen"
                    maxTagCount={limitToOne ? 1 : maxTagCount}
                    maxTagPlaceholder={(omitted) =>
                        `${omitted.length} weitere E-Mail${omitted.length > 1 ? 's' : ''}`
                    }
                    value={members || initialMembers}
                    defaultValue={initialMembers}
                    disabled={disabled}>
                    {!limitToOne && <Select.Option key="all">Alle Mitglieder</Select.Option>}
                    <Select.Option key="onlyme" style={{ borderBottom: '1px solid #efefef' }}>
                        Nur ich
                    </Select.Option>

                    {mails.map((mail) => (
                        <Select.Option key={mail}>{mail}</Select.Option>
                    ))}
                    {showAddOption && (
                        <Select.Option key={search}>{search} hinzufügen</Select.Option>
                    )}
                </Select>
            </div>
        );
    }
}

MemberSelect.contextTypes = {
    username: PropTypes.string.isRequired,
};

MemberSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    initialMembers: PropTypes.array,
    disabled: PropTypes.bool,
};

MemberSelect.defaultProps = {
    initialMembers: ['all'],
    disabled: false,
};

const mapStateToProps = (state) => ({
    memberList: state.memberList.list,
});

export default connect(mapStateToProps)(MemberSelect);
