import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Icon } from 'antd';
import PostVideoDropzone from '../../../../PostVideoDropzone';

class Video extends Component {
    constructor(props) {
        super(props);

        let { video = null } = props.data || {};
        if (props.data?.translations?.video) {
            video = props.data?.translations?.video?.[props.language] || null;
        }

        this.state = {
            video,
        };
    }

    onVideoFileSelected = async (files) => {
        const { setFieldsValue } = this.props.form;

        setFieldsValue({
            [`attachment.translations.video.${this.props.language}`]: files[0],
        });

        this.setState({
            video: files[0],
        });
    };

    removeVideo = () => {
        const { setFieldsValue } = this.props.form;

        setFieldsValue({
            [`attachment.translations.video.${this.props.language}`]: null,
        });

        this.setState({
            video: null,
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { video } = this.state;

        return (
            <Row type="flex" align="middle">
                <Col span={24} style={{ height: video ? 250 : 100 }}>
                    {getFieldDecorator(`attachment.translations.video.${this.props.language}`, {
                        initialValue: video,
                    })(
                        video ? (
                            <div style={{ textAlign: 'center' }}>
                                <video
                                    preload="metadata"
                                    src={video.preview || video.raw}
                                    autoPlay={false}
                                    muted
                                    controls={false}
                                    style={{
                                        height: 250,
                                    }}
                                />
                                <div className="video-overlay">
                                    <div className="inner-overlay-two">
                                        <div
                                            className="inner-overlay-three"
                                            onClick={this.removeVideo}>
                                            <Icon type="close" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <PostVideoDropzone
                                icon="plus"
                                multiple={false}
                                onFileSelected={this.onVideoFileSelected}
                            />
                        ),
                    )}
                </Col>
            </Row>
        );
    }
}

Video.propTypes = {
    form: PropTypes.object.isRequired,
};

export default Video;
