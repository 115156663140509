import React, { PureComponent } from 'react';
import { Col, Input, List, Row, Spin } from 'antd';
import styled from 'styled-components';
import { Spinner } from '../index';

const NoItemsTextContainer = styled.div`
    width: 100%;
    margin: 15px 0 15px 0;
    display: flex;
    justify-content: center;
    color: gray;
`;

export default class UniNowList extends PureComponent {
    // componentDidMount = () => {
    //   const list = document.getElementById('list');
    //   const scrollToLastElement = () => {
    //     list.scrollTop = list.scrollHeight;
    //   }
    //   const observer = new MutationObserver(scrollToLastElement);
    //   const config = {childList: true};
    //   observer.observe(list, config);
    // }
    constructor(props) {
        super(props);
        this.state = {
            searchInput: '',
        };
    }

    render() {
        const {
            noItemsText,
            children,
            dataSource,
            searchFilter,
            optionsElement,
            loading,
            ...rest
        } = this.props;
        const { searchInput } = this.state;
        const filteredData =
            searchInput.length === 0
                ? dataSource
                : dataSource.filter((item) => searchFilter(item, searchInput.toLowerCase()));
        return (
            <div>
                {searchFilter && (
                    <Input
                        value={searchInput}
                        placeholder="Suche..."
                        onChange={({ target: { value: searchInput = '' } }) =>
                            this.setState({ searchInput })
                        }
                    />
                )}
                {optionsElement}
                <div id="list">
                    {filteredData && filteredData.length > 0 ? (
                        <List dataSource={filteredData} {...rest} />
                    ) : loading ? (
                        <div
                            style={{
                                width: '100%',
                                justifyContent: 'center',
                                display: 'flex',
                                padding: 30,
                            }}>
                            <Spin />
                        </div>
                    ) : (
                        <NoItemsTextContainer>{noItemsText}</NoItemsTextContainer>
                    )}
                </div>
            </div>
        );
    }
}
