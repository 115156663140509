import React from 'react';

import { Row, Col } from 'antd';

import './style.css';

export default function ThreeColumnLayout({
    leftSpans = {},
    middleSpans = {},
    rightSpans = {},
    children = [],
}) {
    return (
        <div id="ThreeColumnLayout" className="uninow-feed-content">
            <Row type="flex" justify="center" gutter={25}>
                <Col xs={24} md={24} lg={8} xl={7} {...leftSpans}>
                    {children[0] || null}
                </Col>
                <Col xs={24} md={24} lg={16} xl={17} {...middleSpans}>
                    {children[1] || null}
                </Col>
                <Col xs={0} md={0} lg={0} xl={0} {...rightSpans}>
                    {children[2] || null}
                </Col>
            </Row>
        </div>
    );
}
