import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Button, Spin, notification } from 'antd';

import imageToBlob from '../../core/helper/imageToBlob';

import gradient from '../../assets/images/gradient.svg';
import logo from '../../assets/images/uninow_logo_white.svg';

import { Spinner, Error, FormLayout, AdvertForm, AdvertPreview } from '../../components';

class AdStandalone extends Component {
    state = {
        loading: true,
        error: false,
        ad: {},
        unsavedChanges: false,
        formError: false,
        saving: false,
    };

    async componentDidMount() {
        const { request } = this.context;
        const { shortid } = this.props.match.params;
        if (shortid) {
            const response = await request('recruiting/guest/login', { shortid });

            if (!response.error) {
                this.setState({
                    loading: false,
                    error: false,
                    ad: response.body.advert.data,
                    type: response.body.advert.type,
                });
            } else {
                this.setState({
                    loading: false,
                    error: true,
                });
            }
        } else {
            this.setState({
                loading: false,
                error: true,
            });
        }
    }

    handleChange = ({ errors, changes, data }) => {
        this.setState({
            ad: data || this.state.ad,
            unsavedChanges: changes,
            formError: !!errors,
        });
    };

    handleSave = async () => {
        this.setState({
            saving: true,
        });

        const { shortid } = this.props.match.params;
        const { request, upload } = this.context;
        const { ad: data } = this.state;
        const uploads = [];

        // Upload assets if necessary
        if (data.logo && data.logo.edited) {
            const files = {
                raw: await imageToBlob(data.logo.raw),
                cropped: await imageToBlob(data.logo.edited),
            };

            uploads.push({
                key: 'logo',
                files,
            });
        }

        if (data.image && data.image.edited) {
            const files = {
                raw: await imageToBlob(data.image.raw),
                cropped: await imageToBlob(data.image.edited),
            };

            uploads.push({
                key: 'image',
                files,
            });
        }

        if (uploads.length > 0) {
            notification.open({
                key: 'UPLOAD',
                icon: <Spin />,
                message: 'Bilder werden hochgeladen',
                description:
                    'Bitte warten Sie einen Moment. Anschließend werden Sie automatisch auf die Übersichtsseite weitergeleitet.',
            });
        }

        await Promise.all(
            uploads.map(async (up) => {
                const { body } = await upload('vposts/asset/upload', up.files);

                if (body) {
                    data[up.key] = body;
                }
            }),
        );

        const { body } = await request('vposts/update', {
            shortid,
            data,
        });

        if (uploads.length > 0) {
            notification.close('UPLOAD');
        }

        this.setState({
            saving: false,
        });

        if (body) {
            this.setState(
                {
                    unsavedChanges: false,
                    saving: false,
                    formError: false,
                },
                () =>
                    notification.success({
                        message: 'Aktualisierung erfolgreich',
                        description: 'Ihre Anzeige wurde erfolgreich gespeichert.',
                    }),
            );
        } else {
            notification.error({
                message: 'Aktualisierung fehlgeschlagen',
                description:
                    'Ihre Anzeige konnte leider nicht gespeichert werden. Bitte kontaktieren Sie support@uninow.de.',
            });
        }
    };

    render() {
        const { loading, error, saving, ad, unsavedChanges, formError, type } = this.state;

        if (loading) {
            return <Spinner tip="Daten werden geladen..." />;
        }
        if (error) {
            return <Error text="Der Zugriff zu diesem Bereich ist ihnen nicht gestattet." />;
        }

        const advertFormProps =
            type === '2' ? { disableVideo: true, imageWidth: 1200, imageHeight: 600 } : {};

        return (
            <FormLayout
                formIsDirty={unsavedChanges}
                buttons={[
                    <Button
                        key="save"
                        type="primary"
                        icon="save"
                        htmlType="submit"
                        loading={saving}
                        disabled={!unsavedChanges || formError}
                        onClick={this.handleSave}>
                        Speichern
                    </Button>,
                ]}>
                <Row>
                    <Col
                        span={24}
                        style={{
                            backgroundImage: `url(${gradient})`,
                            backgroundPosition: '50% 50%',
                            backgroundSize: 'cover',
                            backgroundColor: '#f21850',
                            height: 63,
                            padding: '0 50px',
                            verticalAlign: 'middle',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                        }}>
                        <img src={logo} style={{ height: 37, marginTop: 13 }} alt="uninow" />
                    </Col>
                    <Col span={16} style={{ padding: 100 }}>
                        <AdvertForm data={ad} onChange={this.handleChange} {...advertFormProps} />
                    </Col>
                    <Col offset={2} xxl={6} xl={4} span={6}>
                        <AdvertPreview
                            data={ad}
                            type={type}
                            hideSecondPage={ad?.action?.link?.indexOf('uninow://') >= 0}
                        />
                    </Col>
                </Row>
            </FormLayout>
        );
    }
}

AdStandalone.contextTypes = {
    request: PropTypes.func,
    upload: PropTypes.func,
};

export default AdStandalone;
