export default function imageToBlob(imageURL) {
    return new Promise((resolve) => {
        const image = new Image();
        const onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0);

            canvas.toBlob((blob) => {
                resolve(blob);
            });
        };

        image.onload = onload;
        image.src = imageURL;
    });
}
