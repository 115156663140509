import updateState from '../../helper/redux';
import { SORT_ADVERTS, SORT_JOBS } from '../actions';

const filter = (
    state = {
        companyFilter: null,
        companiesFilter: ['true', 'false'],
        advertSortOptions: { visibility: 'desc', creationDate: 'desc' },
        jobSortOptions: { visibility: 'desc', creationDate: 'desc' },
    },
    action,
) => {
    if (action.reducer === 'filter') {
        return updateState(state, action);
    }
    if (action.type === SORT_ADVERTS) {
        return { ...state, advertSortOptions: action.sortOptions };
    }

    if (action.type === SORT_JOBS) {
        return { ...state, jobSortOptions: action.sortOptions };
    }
    return state;
};

export default filter;
