import { SESSIONDATA_LOAD_SUCCESS } from '../actions/index';
import updateState from '../../helper/redux';

const memberList = (
    state = {
        list: [],
    },
    action,
) => {
    const { type, memberList } = action;

    if (type === SESSIONDATA_LOAD_SUCCESS && memberList) {
        return { list: [...memberList] };
    }
    if (action.reducer === 'memberList') {
        return updateState(state, action);
    }
    return state;
};

export default memberList;
