import React from 'react';
import { Col, DatePicker, Form, Icon, Row, Tooltip } from 'antd';
import locale from 'antd/lib/date-picker/locale/de_DE';
import moment from 'moment';

function ReleaseDatePicker({ accountRole, publishDateSelection, onChangePublishDateSelection, form }) {
  const { getFieldDecorator, getFieldError } = form;
  return (
    <Form.Item className="align-left verify-section">
      <div>
        <div className="color-secondary weight-medium verify-section-headline">
          Veröffentlichung &nbsp;
          <Tooltip placement="right" title="Stelle ein, wann dein Post veröffentlicht werden soll.">
            <Icon type="question-circle-o" />
          </Tooltip>
        </div>
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 11, offset: 0 }}
            className={publishDateSelection === 'now' ? 'publish-button active' : 'publish-button'}
            onClick={() => onChangePublishDateSelection('now')}
          >
            {accountRole === 'DRAFT' ? 'sofort nach Freigabe' : 'Jetzt'}
            {publishDateSelection === 'now' && <Icon type="check" style={{ float: 'right', marginTop: 10 }} />}
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 11, offset: 2 }}
            onClick={() => onChangePublishDateSelection('costum')}
          >
            {getFieldDecorator('publishDate', {
              rules: [
                {
                  validator: (rule, value, callback) => {
                    if (publishDateSelection === 'costum' && !value) {
                      callback('Du musst ein Datum setzen, an dem dein Post veröffentlicht werden soll.');
                    } else {
                      callback();
                    }
                  },
                },
              ],
            })(
              <DatePicker
                showTime={{ format: 'HH:mm' }}
                format="DD.MM.YYYY HH:mm"
                placeholder="Benutzerdefiniert"
                allowClear={false}
                locale={locale}
                suffixIcon={
                  publishDateSelection === 'costum' ? (
                    getFieldError('publishDate') ? (
                      <Icon type="close" />
                    ) : (
                      <Icon type="check" />
                    )
                  ) : (
                    <Icon type="calendar" />
                  )
                }
                width="100%"
                className={publishDateSelection === 'costum' ? 'publish-button-costum active' : 'publish-button-costum'}
                disabledDate={(date) => moment(date).diff(moment(), 'seconds') < 0}
              />
            )}
            {accountRole === 'DRAFT' && <Row>(frühstens nach Freigabe)</Row>}
          </Col>
        </Row>
      </div>
    </Form.Item>
  );
}

export default ReleaseDatePicker;
