import React, { Component } from 'react';

import Row from 'antd/lib/row';
import Col from 'antd/lib/col';

import Icon from 'react-icons-kit';

import { university, iosAlbums, iosBriefcase, iosGear } from 'react-icons-kit/ionicons';

class Header extends Component {
    renderItem = (label, icon, active = false) => (
        <Col span={6} align="middle">
            <Icon style={{ color: active ? '#f21850' : '##8E8E8E' }} size={26} icon={icon} />
            <div
                style={{
                    fontSize: 9,
                    color: active ? '#f21850' : '##8E8E8E',
                    letterSpacing: 0.5,
                    fontWeight: 'bold',
                }}>
                {label.toUpperCase()}
            </div>
        </Col>
    );

    render() {
        return (
            <Row
                type="flex"
                align="middle"
                style={{
                    height: 55,
                    backgroundColor: 'white',
                }}>
                {this.renderItem('Dashboard', iosAlbums, true)}
                {this.renderItem('Studium', university)}
                {this.renderItem('Karriere', iosBriefcase)}
                {this.renderItem('Einstellungen', iosGear)}
            </Row>
        );
    }
}

export default Header;
