import { PURGE } from 'redux-persist';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const SWITCH_PROFILE = 'SWITCH_PROFILE';

export function requestUserData(loginUsername) {
    return { type: LOGIN_REQUEST, username: loginUsername };
}

export function saveUserData(username, token, role = '') {
    return {
        type: LOGIN_SUCCESS,
        username,
        token,
        role: role.toUpperCase(),
    };
}

export function switchProfile(profileId) {
    return {
        type: SWITCH_PROFILE,
        profileId,
    };
}

export function clearUserData() {
    return { type: PURGE, key: 'root', result: () => null };
}
