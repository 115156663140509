import React, { Component } from 'react';

import { Modal, Timeline } from 'antd';
import moment from 'moment';

class MessagesModal extends Component {
    renderItem = (item, i) => (
        <Timeline.Item key={i}>
            <p style={{ whiteSpace: 'pre-wrap' }}>
                sichtbar vom {moment(item.start).format('DD.MM.YYYY HH:mm')} bis{' '}
                {item.end ? moment(item.end).format('DD.MM.YYYY HH:mm') : 'jetzt'}
                {item.addition && <br />}
                {item.addition && item.addition}
            </p>
        </Timeline.Item>
    );

    render() {
        const { handleOk, handleCancel, activities } = this.props;
        return (
            <Modal
                title="Sichtbarkeitsaktivitäten"
                visible={!!activities}
                onOk={handleOk}
                onCancel={handleCancel}
                cancelText="Abbrechen"
                bodyStyle={{ overflowY: 'scroll', maxHeight: '50vh' }}>
                {activities && (
                    <Timeline>{[...activities].reverse().map(this.renderItem)}</Timeline>
                )}
            </Modal>
        );
    }
}

export default MessagesModal;
