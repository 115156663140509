import { Row } from 'antd';
import React from 'react';
import useCubeWithQueryErrorHandling from '../../hooks/useCubeWithQueryErrorHandling';
import StatisticsCard from '../../StatisticsCard';
import { dotFormattedNumber } from '../../../../core/helper/numberFormatter';

const DEFAULT_CUBE_QUERY = {
    measures: ['JobStats.clicked', 'JobStats.seen', 'JobStats.interaction'],
};
const getCubeFiltersQuery = (values) => ({
    filters: [
        {
            member: 'JobStats.status',
            operator: 'equals',
            values,
        },
    ],
});

function RecruitingCards({ filters }) {
    let data = [];
    const query = (adFilter) =>
        adFilter.length
            ? { ...getCubeFiltersQuery(adFilter), ...DEFAULT_CUBE_QUERY }
            : DEFAULT_CUBE_QUERY;
    const { resultSet, isLoading } = useCubeWithQueryErrorHandling(query(filters));

    if (!resultSet) {
        return null;
    }

    if (resultSet) {
        data = resultSet.tablePivot();
    }

    return (
        <Row id="CompanyTotal" type="flex" align="top">
            <StatisticsCard
                title="Gesehen"
                tooltipTitle="Wie häufig wurden Ihre Stellenanzeigen in der App auf der Startseite oder in Suchergebnislisten angezeigt (Auch gelöschte Stellenanzeigen werden berücksichtigt)."
                data={data.map((measure) => (
                    <>{dotFormattedNumber(measure['JobStats.seen'])}</>
                ))}
                isLoading={isLoading}
                key={data['CompanyStats.companyId']}
            />
            <StatisticsCard
                title="Klicks"
                tooltipTitle="Wie häufig wurden Ihre Stellenanzeigen in der App aktiv aufgerufen (Auch gelöschte Stellenanzeigen werden berücksichtigt)."
                data={data.map((measure) => (
                    <>{dotFormattedNumber(measure['JobStats.clicked'])}</>
                ))}
                isLoading={isLoading}
            />
            <StatisticsCard
                title="Interaktionen"
                tooltipTitle="Wie häufig wurde mit Ihren Stellenanzeigen in der App interagiert (Auch gelöschte Stellenanzeigen werden berücksichtigt)."
                data={data.map((measure) => (
                    <>{dotFormattedNumber(measure['JobStats.interaction'])}</>
                ))}
                isLoading={isLoading}
            />
        </Row>
    );
}

export default RecruitingCards;
