import React from 'react';
import { useSortBy } from 'react-instantsearch';
import { Select } from 'antd';

const SortBy = (props) => {
    const { currentRefinement, options, refine } = useSortBy(props);

    return (
        <Select
            defaultValue={currentRefinement}
            onChange={(event) => refine(event)}
            value={currentRefinement}
            style={{ width: 100 }}>
            {options.map((option) => (
                <Option key={option.value} value={option.value}>
                    {option.label}
                </Option>
            ))}
        </Select>
    );
};

export default SortBy;
