import React from 'react';
import { Alert, Col, Form, Icon, Row, Tooltip } from 'antd';

function AudienceChannelSelection({ channels, selectedChannels, toggleChannel }) {
    const hasRoleSelection = channels.length > 0;
    const hasLimitedVisibility =
        hasRoleSelection && channels.some((channel) => selectedChannels[channel.id]);

    return (
        <Form.Item className="align-left verify-section">
            <Row className="color-secondary weight-medium verify-section-headline">
                Sichtbarkeit{hasRoleSelection && ' nach Rollen'}&nbsp;
                <Tooltip
                    placement="right"
                    title="Nur Nutzer mit der ensprechenden Rolle können diesen Post sehen. Wenn keine Rolle eingestellt ist, so ist der Post für alle Nutzer sichtbar">
                    <Icon type="question-circle-o" />
                </Tooltip>
            </Row>
            <div className="channels">
                {channels.map((channel) => (
                    <Row key={channel.id} className="channel">
                        <Col span={19} offset={1}>
                            {channel.name}
                        </Col>
                        <Col span={4}>
                            <Icon
                                type="check"
                                className={selectedChannels[channel.id] ? 'check active' : 'check'}
                                onClick={() => toggleChannel(channel)}
                            />
                        </Col>
                    </Row>
                ))}
            </div>
            {!hasLimitedVisibility && (
                <Alert
                    message={`Dieser Post wird für alle Nutzer sichtbar sein${
                        hasRoleSelection ? ', solange keine Rolle explizit ausgewählt ist' : ''
                    }.`}
                    type="info"
                />
            )}
        </Form.Item>
    );
}

export default AudienceChannelSelection;
