import React from 'react';
import PropTypes from 'prop-types';

import { CheckboxGrid } from '..';

const options = [
    {
        label: '1. Semester',
        value: '1',
    },
    {
        label: '2. Semester',
        value: '2',
    },
    {
        label: '3. Semester',
        value: '3',
    },
    {
        label: '4. Semester',
        value: '4',
    },
    {
        label: '5. Semester',
        value: '5',
    },
    {
        label: '6. Semester',
        value: '6',
    },
    {
        label: '7. Semester',
        value: '7',
    },
    {
        label: '8. Semester',
        value: '8',
    },
    {
        label: '9. Semester',
        value: '9',
    },
    {
        label: '10. Semester',
        value: '10',
    },
];

const AdvertSemesterForm = ({ onChange, disabled, description, initialSemesters }) => (
    <CheckboxGrid
        options={options}
        onChange={onChange}
        disabled={disabled}
        description={description}
        initialValues={initialSemesters}
        otherOption
    />
);

AdvertSemesterForm.defaultProps = {
    initialSemesters: [],
    description:
        'Wählen Sie die Semester aus, an denen Ihre Werbeanzeige ausgeliefert werden soll.',
    disabled: false,
};

AdvertSemesterForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    initialSemesters: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
    disabled: PropTypes.bool,
};

export default AdvertSemesterForm;
