import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Row, Spin, Statistic } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Phone } from '../../../../components';
import { JobDetail } from '../../../../components/Preview/pages';
import '../../../../components/Preview/preview.css';
import { dotFormattedNumber } from '../../../../core/helper/numberFormatter';

function StatisticsModal({ data, loading }) {
    const { search, pathname } = useLocation();
    const query = new URLSearchParams(search);
    const jobId = query.get('jobId');
    const history = useHistory();
    const handleOk = () => {
        history.replace(pathname);
    };

    const job = data.find((jobData) => jobData['JobStats.jobId'] === jobId) || {};

    const loadingIndicator = (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                height: '100%',
            }}>
            <Spin />
        </div>
    );

    const clippedTitle =
        job['JobStats.title'] && job['JobStats.title'].length > 50
            ? `${job['JobStats.title'].slice(0, 47)}...`
            : job['JobStats.title'];

    return (
        <Modal
            visible={jobId}
            width={800}
            title="Stellenanzeige"
            closable
            onOk={handleOk}
            onCancel={handleOk}
            footer={[
                <Link to={`/stellenanzeigen/editieren/${jobId}`}>
                    <Button key="edit" type="link" onClick={handleOk}>
                        Bearbeiten
                    </Button>
                </Link>,
                <Button key="submit" type="primary" onClick={handleOk}>
                    OK
                </Button>,
            ]}>
            <div style={{ flexDirection: 'row', display: 'flex' }}>
                <div
                    style={{
                        paddingRight: 20,
                        borderRight: '1px solid #e8e8e8',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                    className="preview">
                    <Phone position="absolute">
                        {loading && loadingIndicator}
                        {!loading && jobId && <JobDetail jobId={jobId} />}
                    </Phone>
                </div>
                <div style={{ flex: 1, paddingRight: 10, paddingLeft: 10 }}>
                    {loading && loadingIndicator}
                    {!loading && (
                        <>
                            <Row gutter={[16, 24]}>
                                <Col span={24}>
                                    <Statistic title="Titel" value={clippedTitle} />
                                </Col>
                                <Col span={12}>
                                    <Statistic
                                        title="Gesehen"
                                        value={dotFormattedNumber(job['JobStats.seen']) || 0}
                                    />
                                </Col>
                                <Col span={12}>
                                    <Statistic
                                        title="Klicks"
                                        value={dotFormattedNumber(job['JobStats.clicked']) || 0}
                                    />
                                </Col>
                                <Col span={12}>
                                    <Statistic
                                        title="Interaktionen"
                                        value={dotFormattedNumber(job['JobStats.interaction'] || 0)}
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                </div>
            </div>
        </Modal>
    );
}

StatisticsModal.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    loading: PropTypes.bool.isRequired,
};

export default StatisticsModal;
