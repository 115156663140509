import React, { Component } from 'react';
// import styled from 'styled-components';
import { Form, Input, Icon, Button } from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormLayout } from '../../components';
import passwordRules from '../../core/constants/passwordRules';

const FormItem = Form.Item;

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isDirty: false,
        };
    }

    handleSubmit = () => {
        this.props.form.validateFields(async (err, values) => {
            const { newPassword, currentPassword } = values;
            if (!err) {
                this.setState({ isLoading: true });
                const successful = await this.context.changePassword(currentPassword, newPassword);
                if (successful) {
                    await this.setState({ isLoading: false, isDirty: false });
                    this.props.history.goBack();
                } else {
                    this.setState({ isLoading: false });
                }
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { isLoading, isDirty } = this.state;

        return (
            <FormLayout
                formIsDirty={isDirty}
                infoText="Achten Sie darauf, dass sich Ihr altes und neues Passwort nicht gleichen dürfen."
                buttons={[
                    <Button
                        key="save"
                        icon="save"
                        disabled={!isDirty}
                        loading={isLoading}
                        onClick={this.handleSubmit}>
                        Speichern
                    </Button>,
                ]}>
                <Form className="login-form">
                    <FormItem label="Aktuelles Passwort:">
                        {getFieldDecorator('currentPassword', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Bitte geben Sie Ihr aktuelles Passwort an.',
                                },
                            ],
                        })(
                            <Input
                                autoComplete="off"
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder="Geben Sie hier ihr aktuelles Passwort ein..."
                            />,
                        )}
                    </FormItem>
                    <FormItem label="Neues Password:">
                        {getFieldDecorator('newPassword', {
                            validateFirst: true,
                            rules: [
                                {
                                    validator: (rule, value, callback) => {
                                        const { getFieldValue } = this.props.form;
                                        if (value && value === getFieldValue('currentPassword')) {
                                            callback(
                                                'Das neue Passwort darf nicht dem aktuellen entsprechen.',
                                            );
                                        }
                                        callback();
                                    },
                                },
                                {
                                    required: true,
                                    message: 'Bitte geben Sie Ihr neues Passwort an.',
                                },
                                ...passwordRules,
                            ],
                        })(
                            <Input
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder="Geben Sie hier ihr neues Passwort ein..."
                                onChange={() => this.setState({ isDirty: true })}
                            />,
                        )}
                    </FormItem>
                    <FormItem label="Neues Passwort (Wiederholung):">
                        {getFieldDecorator('newPasswordRep', {
                            validateFirst: true,
                            rules: [
                                {
                                    validator: (rule, value, callback) => {
                                        const { getFieldValue } = this.props.form;
                                        if (value && value !== getFieldValue('newPassword')) {
                                            callback(
                                                'Das neue Passwort und die Wiederholung müssen übereinstimmen.',
                                            );
                                        }
                                        callback();
                                    },
                                },
                                {
                                    required: true,
                                    message: 'Bitte geben Sie Ihr neues Passwort an.',
                                },
                                ...passwordRules,
                            ],
                        })(
                            <Input
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder="Wiederholen Sie ihr neues Passwort..."
                                onChange={() => this.setState({ isDirty: true })}
                            />,
                        )}
                    </FormItem>
                </Form>
            </FormLayout>
        );
    }
}

Settings.contextTypes = {
    changePassword: PropTypes.func.isRequired,
};

export default Form.create()(withRouter(Settings));
