import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Geosuggest from 'react-geosuggest';

import './style.css';

class Geosearch extends Component {
    onChange = (value) => {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    onSuggestSelect = (suggest) => {
        if (!suggest || !suggest.gmaps) {
            return;
        }

        const location = {
            geoPoint: suggest.location,
            country: '',
            city: '',
            postalCode: '',
            street: '',
            label: suggest.gmaps.formatted_address,
        };

        const tmpLocation = {
            street: '',
            streetNumber: '',
        };

        suggest.gmaps.address_components.forEach((component) => {
            component.types.forEach((type) => {
                switch (type) {
                    case 'street_number':
                        tmpLocation.streetNumber = component.long_name;
                        break;

                    case 'route':
                        tmpLocation.street = component.long_name;
                        location.street = component.long_name;
                        break;

                    case 'locality':
                        location.city = component.long_name;
                        break;

                    case 'administrative_area_level_1':
                        location.state = component.long_name;
                        break;

                    case 'country':
                        location.country = component.long_name;
                        break;

                    case 'postal_code':
                        location.postalCode = component.long_name;
                        break;

                    default:
                        break;
                }
            });
        });

        if (tmpLocation.street && tmpLocation.streetNumber) {
            location.street = `${tmpLocation.street} ${tmpLocation.streetNumber}`;
        }

        if (this.props.onSelect) {
            this.props.onSelect(location);
        }
    };

    render() {
        const { size } = this.props;
        const antSizeClass =
            size === 'large' ? 'ant-input-lg' : size === 'small' ? 'ant-input-sm' : '';
        return (
            <Geosuggest
                className="ant-form-item-children"
                inputClassName={`ant-input ${antSizeClass}`}
                onChange={this.onChange}
                placeholder={this.props.placeholder}
                onSuggestSelect={this.onSuggestSelect}
                initialValue={this.props.initialValue}
                disabled={this.props.disabled}
            />
        );
    }
}

Geosearch.propTypes = {
    initialValue: PropTypes.string,
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(['default', 'large', 'small']),
};

Geosearch.defaultProps = {
    placeholder: 'Standort suchen',
    size: 'default',
    disabled: false,
};

export default Geosearch;
