import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Form, Input, Row, Col, Select, Divider } from 'antd';
import imageConfig from '../../core/constants/imageConfig';

import { FileManagerAd } from '..';

import { ADVERT_DATA } from './constants';

import './style.css';
import urlPattern from '../../core/helper/urlPattern';

const getNestedValue = (obj, path) => {
    return path.split('.').reduce((part, key) => {
        return part?.[key];
    }, obj);
};

const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));

class AdvertForm extends Component {
    constructor(props) {
        super(props);

        const imageWidth = Math.round(props.imageWidth || imageConfig.advertImageOptimalSize.width);
        const imageHeight = Math.round(
            props.imageHeight || imageConfig.advertImageOptimalSize.height,
        );

        const gcdValue = gcd(imageWidth, imageHeight);

        this.state = {
            selectedCallToAction: this.callToActionToSelectionType(props.data?.action?.link),
            imageWidth,
            imageHeight,
            imageRatio: { x: imageWidth / gcdValue, y: imageHeight / gcdValue },
        };
        this.initialData = props.data;
        this.validData = props.data;
        this.initialLinkApp =
            this.state.selectedCallToAction === 'app' ? props.data?.action?.link : undefined;
        this.initialLinkWebsite =
            this.state.selectedCallToAction === 'website' ? props.data?.action?.link : undefined;
    }

    callToActionToSelectionType = (action = '') => {
        if (action.indexOf('uninow://') >= 0) {
            return 'app';
        }
        return 'website';
    };

    onContactSelect = (value) => {
        this.props.onHideSecondPage?.(value === 'app');
        this.setState({
            selectedCallToAction: value,
        });
    };

    handleChange = ({ target }) => {
        const { form } = this.props;
        const { validateFields } = form;
        const { id, value } = target;

        validateFields((errors, values) => {
            if (errors) {
                this.props.setFormError(true);
            } else {
                this.props.setFormError(false);
            }

            if (
                errors &&
                errors.action &&
                !errors.action.linkApp &&
                target.id === 'action.linkApp'
            ) {
                this.props.onChange({
                    errors,
                    changes: true,
                    data: null,
                });
                return;
            }

            let dataObj = {};
            if (id === 'action.linkApp') {
                dataObj = { action: { link: value } };
                this.initialLinkApp = value;
            } else if (id === 'action.linkWebsite') {
                dataObj = { action: { link: value } };
                this.initialLinkWebsite = value;
            } else if (id.includes('action.')) {
                dataObj = {
                    action: {
                        [id.split('.')[1]]: value,
                    },
                };
            } else {
                dataObj = {
                    [id]: value,
                };
            }
            _.unset(values, 'action.linkApp');
            _.unset(values, 'action.linkWebsite');
            const data = _.defaultsDeep(dataObj, values, this.validData);
            this.validData = data;

            if (this.props.onChange) {
                this.props.onChange({
                    errors: null,
                    changes: !_.isEqual(data, this.initialData),
                    data,
                });
            }
        });
    };

    handleAssetChange = (key, asset) => {
        const { imageWidth, imageHeight } = this.state;
        const { form } = this.props;
        const { validateFields } = form;

        validateFields((errors, values) => {
            const data = _.defaultsDeep(
                {
                    [key]: asset || null,
                },
                values,
                this.validData,
            );

            if (data[key]) {
                switch (key) {
                    case 'logo':
                        data[key].payload.maxWidth = imageConfig.advertLogoOptimalSize.width;
                        data[key].payload.maxHeight = imageConfig.advertLogoOptimalSize.height;
                        break;

                    case 'image':
                        data[key].payload.maxWidth = imageWidth;
                        data[key].payload.maxHeight = imageHeight;
                        break;

                    default:
                        break;
                }
            }

            this.validData = data;

            if (this.props.onChange) {
                this.props.onChange({
                    errors,
                    changes: !_.isEqual(data, this.initialData),
                    data,
                });
            }
        });
    };

    generateSingleLineInput = (key, { maxLength = 32, disabled }, name) => {
        const { form, data } = this.props;
        const { getFieldDecorator } = form;

        return (
            <div>
                <div>
                    {name}
                    {maxLength && ` (max. ${maxLength} Zeichen)`}
                </div>
                <Form.Item hasFeedback style={{ marginBottom: 20 }}>
                    {getFieldDecorator(key, {
                        initialValue: getNestedValue(data, key),
                        rules: [
                            {
                                max: maxLength,
                                validator: this.validateInput,
                                message: `${name} darf nicht länger als ${maxLength} sein.`,
                            },
                        ],
                    })(
                        <Input
                            placeholder={getNestedValue(ADVERT_DATA, key)}
                            disabled={disabled}
                        />,
                    )}
                </Form.Item>
            </div>
        );
    };

    generateMultiLineInput = (key, { maxLength = 32, lines = 2, disabled }, name) => {
        const { form, data } = this.props;
        const { getFieldDecorator } = form;

        return (
            <div>
                <div>
                    {name}
                    {maxLength && ` (max. ${maxLength} Zeichen)`}
                </div>
                <Form.Item hasFeedback style={{ marginBottom: 20 }}>
                    {getFieldDecorator(key, {
                        initialValue: getNestedValue(data, key),
                        rules: [
                            {
                                max: maxLength,
                                validator: this.validateInput,
                                message:
                                    maxLength && `${name} darf nicht länger als ${maxLength} sein.`,
                            },
                        ],
                    })(
                        <Input.TextArea
                            placeholder={ADVERT_DATA[key]}
                            autoSize={{
                                minRows: lines,
                                maxRows: lines,
                            }}
                            disabled={disabled}
                        />,
                    )}
                </Form.Item>
            </div>
        );
    };

    renderCallToAction(selectedCallToAction, getFieldDecorator, companyUuid, jobs, events) {
        return (
            <>
                <div>Call to action link</div>
                <Form.Item>
                    <Row gutter={20}>
                        <Col xs={24} sm={9} md={9} lg={8} xl={6}>
                            <Select
                                onSelect={this.onContactSelect}
                                defaultValue={selectedCallToAction}>
                                <Select.Option value="website">Website Verlinkung</Select.Option>
                                <Select.Option value="app">App Verlinkung</Select.Option>
                            </Select>
                        </Col>
                        <Col xs={24} sm={15} md={15} lg={16} xl={18}>
                            {selectedCallToAction === 'website'
                                ? getFieldDecorator('action.linkWebsite', {
                                      initialValue: this.initialLinkWebsite,
                                      rules: [
                                          {
                                              required: true,
                                              pattern: urlPattern,
                                              message:
                                                  'Bitte geben Sie eine gültige Adresse an (z.B. https://example.com )',
                                          },
                                      ],
                                  })(
                                      <Input
                                          onChange={this.handleChange}
                                          placeholder="Wohin soll die Werbeanzeige verlinken?"
                                      />,
                                  )
                                : getFieldDecorator('action.linkApp', {
                                      rules: [
                                          {
                                              required: true,
                                              message: 'Wählen Sie bitte eine Verlinkung aus',
                                          },
                                      ],
                                      initialValue: this.initialLinkApp,
                                  })(
                                      <Select
                                          style={{ width: '100%' }}
                                          placeholder="interne Verlinkung auswählen"
                                          notFoundContent="Keine Treffer"
                                          filterOption={(input, option) =>
                                              option.props.children
                                                  ? option.props.children
                                                        .toLowerCase()
                                                        .indexOf(input.toLowerCase()) !== -1
                                                  : false
                                          }
                                          onSelect={(e) =>
                                              this.handleChange({
                                                  target: {
                                                      id: 'action.linkApp',
                                                      value: e,
                                                  },
                                              })
                                          }
                                          showSearch>
                                          <Select.Option
                                              key="company"
                                              value={`uninow://feed/company?id=${companyUuid}`}
                                              style={{ borderBottom: '1px solid #efefef' }}>
                                              Verlinkung zum Unternehmensprofil
                                          </Select.Option>
                                          {jobs &&
                                              jobs
                                                  .filter(
                                                      (job) =>
                                                          !job.archived &&
                                                          job.visible &&
                                                          job.uuid &&
                                                          job.uuid &&
                                                          job.title,
                                                  )
                                                  .sort((a, b) =>
                                                      a.title
                                                          .toUpperCase()
                                                          .localeCompare(b.title.toUpperCase()),
                                                  )
                                                  .map((job) => (
                                                      <Select.Option
                                                          key={job.uuid}
                                                          value={`uninow://feed/job?id=${job.uuid}&companyID=${companyUuid}`}>
                                                          {`Verlinkung zur Stellenanzeige: ${job.title}`}
                                                      </Select.Option>
                                                  ))}
                                          {events &&
                                              events
                                                  .filter((event) => event._id && event.title)
                                                  .sort((a, b) =>
                                                      a.title
                                                          .toUpperCase()
                                                          .localeCompare(b.title.toUpperCase()),
                                                  )
                                                  .map((event) => (
                                                      <Select.Option
                                                          key={event.uuid}
                                                          value={`uninow://feed/companyEvent?id=${companyUuid}&eventId=${event._id}`}>
                                                          {`Verlinkung zu Event: ${event.title}`}
                                                      </Select.Option>
                                                  ))}
                                      </Select>,
                                  )}
                        </Col>
                    </Row>
                </Form.Item>
            </>
        );
    }

    render() {
        const { data, jobs, events, companyUuid, disableVideo = false } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { selectedCallToAction, imageWidth, imageHeight, imageRatio } = this.state;
        const disablePreviewItems = this.props.type === '3' && selectedCallToAction === 'app';

        return (
            <Form id="AdvertForm" onChange={this.handleChange}>
                {this.props.type === '3' && (
                    <>
                        {this.renderCallToAction(
                            selectedCallToAction,
                            getFieldDecorator,
                            companyUuid,
                            jobs,
                            events,
                        )}
                        <Divider type="horizontal" />
                    </>
                )}
                <Row style={{ marginBottom: 20 }}>
                    <Col span={8}>
                        <div>
                            Logo (optimal {imageConfig.advertLogoOptimalSize.width}px *{' '}
                            {imageConfig.advertLogoOptimalSize.width}px)
                        </div>
                        <FileManagerAd
                            files={{ image: data.logo }}
                            onChange={(files) => this.handleAssetChange('logo', files.image)}
                            height={100}
                            optimalWidth={imageConfig.advertLogoOptimalSize.width}
                            optimalHeight={imageConfig.advertLogoOptimalSize.height}
                            enableZoomOut
                            imageRatio={{
                                x: 1,
                                y: 1,
                            }}
                            fileTypes={['image']}
                            maxFileSize={20000000}
                        />
                    </Col>
                    {!disablePreviewItems && (
                        <Col span={16}>
                            <div>
                                Bild (optimal {imageWidth}px * {imageHeight}px)
                                {disableVideo ? '' : ' oder Video (max. 20MB)'}
                            </div>
                            <FileManagerAd
                                files={{
                                    image: data.image,
                                    video: data.video,
                                }}
                                fileTypes={disableVideo ? ['image'] : ['image', 'video']}
                                onChange={(files) => {
                                    this.handleAssetChange('video', files.video);
                                    this.handleAssetChange('image', files.image);
                                }}
                                height={100}
                                optimalWidth={imageWidth}
                                optimalHeight={imageHeight}
                                imageRatio={imageRatio}
                            />
                        </Col>
                    )}
                </Row>
                {this.generateSingleLineInput('title', { maxLength: 70 }, 'Titel')}
                {!disablePreviewItems &&
                    this.generateMultiLineInput(
                        'teaser',
                        {
                            maxLength: this.props.type === '3' ? null : 140,
                            lines: 3,
                        },
                        'Teaser',
                    )}
                {this.generateSingleLineInput(
                    'headline',
                    {
                        maxLength:
                            this.props.type === '3'
                                ? selectedCallToAction === 'app'
                                    ? 30
                                    : null
                                : 45,
                    },
                    'Headline',
                )}
                {this.generateMultiLineInput('subline', { maxLength: 90 }, 'Subline')}
                {!disablePreviewItems &&
                    this.generateSingleLineInput(
                        'action.label',
                        { maxLength: 35 },
                        'Call to action',
                    )}
                {this.props.type !== '3' &&
                    this.renderCallToAction(
                        selectedCallToAction,
                        getFieldDecorator,
                        companyUuid,
                        jobs,
                        events,
                    )}
            </Form>
        );
    }
}

AdvertForm.propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    disableVideo: PropTypes.bool,
    imageWidth: PropTypes.number,
    imageHeight: PropTypes.number,
};
const mapStateToProps = ({ jobs, company }) => ({
    jobs: jobs.joblist.filter((job) => job.uuid),
    companyUuid: company.uuid,
    events: company.profile.events,
});

export default Form.create()(connect(mapStateToProps)(AdvertForm));
