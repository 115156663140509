import React from 'react';
import { Card, Icon, Tooltip } from 'antd';

const StatisticsCard = ({
    data,
    isLoading,
    title,
    tooltipTitle,
    onClick = null,
    active = false,
}) => {
    return (
        // TODO: here
        <Card
            bodyStyle={{ padding: '16px' }}
            style={{
                marginRight: '16px',
                marginBottom: '16px',
                display: 'inline-block',
                borderRadius: '8px',
                border: active ? '1px solid #F21850' : '1px solid #e8e8e8',
                width: '250px',
                cursor: onClick ? 'pointer' : 'default',
            }}
            onClick={onClick}
            className="card"
            loading={isLoading}>
            <div style={{ borderRadius: '8px' }}>
                <div
                    style={{
                        display: 'flex',
                        fontSize: '16px',
                        alignItems: 'center',
                    }}>
                    <span style={{ paddingRight: '8px', color: '#202533' }}>{title}</span>
                    <Tooltip title={tooltipTitle}>
                        <Icon
                            type="question-circle"
                            theme="outlined"
                            style={{ color: '#202533', width: '16px', height: '16px' }}
                        />
                    </Tooltip>
                </div>
                <div
                    style={{
                        color: '#F21850',
                        fontSize: '36px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                    }}
                    className="number">
                    {data}
                </div>
            </div>
        </Card>
    );
};

export default StatisticsCard;
