import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Modal } from 'antd';

export default class ImageDropzone extends Component {
    render() {
        const { style, children } = this.props;
        return (
            <Dropzone
                accept="image/jpeg, image/png"
                maxSize={20000000}
                style={{
                    cursor: 'pointer',
                    backgroundColor: '#404040',
                    ...style,
                }}
                rejectStyle={{ boxShadow: 'inset 0 0 20px red' }}
                acceptStyle={{ boxShadow: 'inset 0 0 20px green' }}
                multiple={false}
                onDropRejected={(rejected) => {
                    let error = '';
                    const { type, size } = rejected[0];
                    if (type !== 'image/jpeg' && type !== 'image/png') {
                        error =
                            'Es werden ausschließlich die Formate JPEG sowie PNG unterstützt. Die ausgewählte Datei entspricht keinem dieser Formate.';
                    } else if (size > 500000) {
                        error =
                            'Die Größe des ausgewählten Bildes ist größer als 20MB. Bitte wählen Sie ein kleineres Bild.';
                    }
                    if (error) {
                        Modal.error({
                            title: 'Fehler',
                            content: error,
                        });
                    }
                }}
                onDropAccepted={(accepted) => {
                    this.props.onImageDrop(accepted[0]);
                }}>
                {children}
            </Dropzone>
        );
    }
}
