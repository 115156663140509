import React from 'react';
import { Tooltip } from 'antd';

export default function buttonWithTooltip(tooltip) {
    return (Button) => (
        <Tooltip key={tooltip} title={tooltip}>
            {Button}
        </Tooltip>
    );
}
