import { notification } from 'antd';

export default function setMessage(description, type) {
    let message;
    switch (type) {
        case 'success':
            message = 'Erfolg!';
            break;
        case 'warning':
            message = 'Warnung!';
            break;
        case 'error':
            message = 'Fehler!';
            break;
        default:
            message = 'Information!';
    }
    notification[type]({
        message,
        description,
    });
}
