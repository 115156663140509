export const CHANGE_FOCUS = 'CHANGE_FOCUS';
export const UPDATE_PREVIEW = 'UPDATE_PREVIEW';
export const RESET_PREVIEW = 'RESET_PREVIEW';
export const SET_PREVIEW_LANGUAGE = 'SET_PREVIEW_LANGUAGE';

export function changePreviewFocus(onFocus) {
    return {
        type: CHANGE_FOCUS,
        focus: onFocus,
    };
}

export function updatePreview(post) {
    return {
        type: UPDATE_PREVIEW,
        post,
    };
}

export function resetPreview() {
    return {
        type: RESET_PREVIEW,
    };
}

export function setPreviewLanguage(lang) {
    return {
        type: SET_PREVIEW_LANGUAGE,
        lang,
    };
}
