import React, { PureComponent } from 'react';
import { Layout, Menu, Icon, Dropdown, Switch, Modal, Row, Col, notification } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import gradient from '../../assets/images/gradient.svg';
import logo from '../../assets/images/uninow_logo_white.svg';

import buttonWithTooltip from '../../decorators/buttonWithTooltip';
import { setAttributeValue, setDemoMode } from '../../core/redux/actions';
import checkImageQuality from '../../core/helper/checkImageQuality';

import { ActivityModal } from '..';
import InstagramSwitch from '../InstagramSwitch';

const { Header } = Layout;
const { confirm } = Modal;

export const StyledHeader = styled(Header)`
    background-image: url(${gradient});
    background-position: 50% 50%;
    background-size: cover;
    background-color: #f21850;
`;

class PageHeader extends PureComponent {
    state = {
        mobile: window.innerWidth < 652,
        activityModalId: null,
    };

    onDimensionChange = () => {
        const width = window.innerWidth;
        if (this.state.mobile === false && width < 652) {
            this.setState({ mobile: true });
        } else if (this.state.mobile === true && width >= 652) {
            this.setState({ mobile: false });
        }
    };

    componentDidMount() {
        window.addEventListener('resize', this.onDimensionChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onDimensionChange);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.showFeed !== prevProps.showFeed ||
            this.props.showInstagramSync !== prevProps.showInstagramSync ||
            this.props.showTalentPool !== prevProps.showTalentPool
        ) {
            this.updateMenuItems();
        }
    }

    updateMenuItems() {
        this.menuItems = [];
        const { role } = this.context;
        const advertsOnly = role === 'CUSTOMER_ADVERTS';

        if (!advertsOnly) {
            this.menuItems.push({ title: 'Unternehmensprofil', link: '/profil' });
        }

        if (this.props.showTalentPool) {
            this.menuItems.push({
                title: 'Studierendenprofile',
                link: '/students',
            });
        }

        if (!advertsOnly) {
            this.menuItems.push({ title: 'Stellenanzeigen', link: '/stellenanzeigen' });
        }

        if (!['UNI_ADMIN', 'CUSTOMER_NO_ADVERTS'].includes(role)) {
            this.menuItems.push({ title: 'Werbeanzeigen', link: '/werbeanzeigen' });
            if (this.props.showFeed) {
                this.menuItems.push({ title: 'Beiträge', link: '/beitrage' });
            }
        }

        this.menuItems.push({
            title: 'Statistiken',
            link: advertsOnly ? '/statistiken/werbeanzeigen' : '/statistiken/stellenanzeigen',
        });
    }

    componentWillMount = () => {
        this.updateMenuItems();
    };

    showVisibilityErrorDialogue = (errors) => {
        const { history } = this.props;
        const { pathname } = window.location;
        const bulletPoints = errors.map((error) => <li key={error}>{error}</li>);

        let okText = 'OK';
        let forwarding = () => false;
        let forwardText = '';

        if (!(pathname.includes('profil') || pathname === '/')) {
            okText = 'Zum Profil wechseln';
            forwarding = () => {
                history.push('/profil');
            };
            forwardText = <p> Wollen Sie jetzt zum Profil wechseln und diese Angaben tätigen?</p>;
        }

        const content = (
            <span>
                <p>
                    Bevor Sie Ihr Unternehmen sichtbar schalten, füllen Sie in Ihrem
                    Unternehmensprofil bitte sämtliche Pflichtangaben aus.
                </p>
                <p>Folgende Angaben wurden nicht getätigt oder sind fehlerhaft:</p>
                <ul style={{ listStyleType: 'circle' }}>{bulletPoints}</ul>
                {forwardText}
            </span>
        );

        confirm({
            title: 'Achtung',
            content,
            okText,
            okType: 'primary',
            onOk: () => {
                forwarding();
            },
            cancelText: 'Abbrechen',
        });
    };

    showVisibilityWarningDialogue = (warnings) => {
        const bulletPoints = warnings.map((warning) => <li key={warning}>{warning}</li>);

        const content = (
            <span>
                <p>
                    Wir empfehlen Ihnen das Unternehmensprofil so vollständig wie möglich
                    auszufüllen.
                </p>
                <p>Folgende Angaben wurden nicht getätigt:</p>
                <ul>{bulletPoints}</ul>
                <p>Möchten Sie das Profil trotzdem online schalten?</p>
            </span>
        );

        confirm({
            title: 'Warning',
            content,
            okText: 'Trotzdem online nehmen',
            onOk: () => this.context.setCompanyVisibility(true, true),
            cancelText: 'Abbrechen',
        });
    };

    handleDemoModeChange = async (value) => {
        await this.props.setDemoMode(value);
    };

    handleCompanyVisibilityChange = async (value) => {
        let type = 'Sichtbar';
        let consequence =
            'Sobald Ihr Unternehmen sichtbar ist, wird es im Karriere-Portal angezeigt.';
        if (!value) {
            type = 'Unsichtbar';
            consequence =
                'Sobald Ihr Unternehmen unsichtbar ist, wird sowohl Ihr Profil als auch Ihre Stellenanzeigen nicht mehr in der App angezeigt.';
        }

        confirm({
            title: 'Achtung',
            content: `Sind Sie sicher, dass Sie dieses Unternehmen ${type.toLowerCase()} schalten möchten? ${consequence}`,
            okText: `${type} schalten`,
            okType: 'danger',
            cancelText: 'Abbrechen',
            onOk: () =>
                new Promise(async (resolve, error) => {
                    const { errors = [], warnings = [] } = await this.context.setCompanyVisibility(
                        value,
                    );

                    if (errors.length > 0) {
                        this.showVisibilityErrorDialogue(errors);
                        resolve();
                    } else if (warnings.length > 0) {
                        this.showVisibilityWarningDialogue(warnings);
                        resolve();
                    } else {
                        resolve();
                    }
                }),
        });
    };

    mapItem = (item) => {
        const { title, link } = item;
        const { selectedKey } = this.props;

        const selectedStyle = {
            float: 'left',
            paddingRight: 10,
            paddingLeft: 10,
            color: 'white',
        };

        if (title === selectedKey) {
            selectedStyle.backgroundColor = '#f39247';
        }
        return (
            <Link key={title} to={link} style={selectedStyle}>
                {title}
            </Link>
        );
    };

    showActivityModal = (companyId) => this.setState({ activityModalId: companyId });

    hideActivityModal = () => this.setState({ activityModalId: null });

    render() {
        const { logout, role } = this.context;
        const { isCompanyVisible, companyId, isDemoMode, showInstagramSync, showFeed } = this.props;
        const { mobile, activityModalId } = this.state;
        const menuItems = [
            { key: 'members', link: '/members', title: 'Mitglieder verwalten' },
            { key: 'passwortaendern', link: '/passwortaendern', title: 'Passwort ändern' },
            { key: 'logout', onClick: logout, title: 'Logout' },
            { render: <Menu.Divider key="divider" /> },
            {
                key: 'companies',
                link: '/companies',
                title: 'Unternehmensliste',
                allowedRoles: ['SUPERADMIN', 'ADMIN', 'CUSTOMER_SUPPORT', 'UNI_ADMIN'],
            },
            {
                key: 'memberlist',
                link: '/memberlist',
                title: 'Mitgliederliste',
                allowedRoles: ['SUPERADMIN', 'ADMIN'],
            },
            {
                key: 'applicantlist',
                link: '/applicantlist',
                title: 'Bewerberliste',
                allowedRoles: ['SUPERADMIN', 'ADMIN'],
            },
            {
                render: (
                    <Menu.Item key="visible">
                        <span>Unternehmen: </span>
                        <span style={{ marginLeft: '60px' }}>
                            {buttonWithTooltip(
                                <span>
                                    Schalten Sie Ihr Unternehmen sichtbar (
                                    <Icon type="eye" theme="filled" />) oder unsichtbar (
                                    <Icon type="eye" theme="outlined" />
                                    ).
                                </span>,
                            )(
                                <Switch
                                    style={{ position: 'absolute', right: '10px' }}
                                    checkedChildren={<Icon type="eye" theme="filled" />}
                                    unCheckedChildren={<Icon type="eye" theme="outlined" />}
                                    checked={isCompanyVisible}
                                    onChange={this.handleCompanyVisibilityChange}
                                />,
                            )}
                        </span>
                    </Menu.Item>
                ),
                allowedRoles: ['SUPERADMIN', 'ADMIN', 'UNI_ADMIN'],
            },
            {
                key: 'jobReasonsStatistics',
                link: '/statistiken/jobs',
                title: 'Kündigungsgrund Jobs',
                allowedRoles: ['SUPERADMIN', 'ADMIN', 'CUSTOMER_SUPPORT'],
            },
            showFeed && showInstagramSync
                ? {
                      render: (
                          <Menu.Item key="instagram">
                              <span>Instagram: </span>
                              <span style={{ marginLeft: '60px' }}>
                                  <InstagramSwitch />
                              </span>
                          </Menu.Item>
                      ),
                  }
                : null,
            {
                key: 'activities',
                onClick: () => this.showActivityModal(companyId),
                title: 'Aktivitäten',
                allowedRoles: ['SUPERADMIN', 'ADMIN', 'CUSTOMER_SUPPORT', 'UNI_ADMIN'],
            },
            {
                render: <Menu.Divider key="divider" />,
                allowedRoles: ['SUPERADMIN', 'ADMIN', 'CUSTOMER_SUPPORT'],
            },
            {
                render: (
                    <Menu.Item key="preview">
                        <span>Demo Modus: </span>
                        <span style={{ marginLeft: '60px' }}>
                            {buttonWithTooltip(
                                <span>Schalten Sie die Adminoptionen ein oder aus</span>,
                            )(
                                <Switch
                                    style={{ position: 'absolute', right: '10px' }}
                                    checked={isDemoMode}
                                    onChange={this.handleDemoModeChange}
                                />,
                            )}
                        </span>
                    </Menu.Item>
                ),
                allowedRoles: ['SUPERADMIN', 'ADMIN', 'CUSTOMER_SUPPORT'],
            },
        ];
        const NavigationDropDown = ({ extended }) => (
            <Dropdown
                overlay={
                    <Menu>
                        {extended &&
                            this.menuItems.map((item) => (
                                <Menu.Item key={item.title}>
                                    <Link to={item.link}>{item.title}</Link>
                                </Menu.Item>
                            ))}
                        {extended && <Menu.Divider />}
                        {menuItems
                            .filter((item) => item && typeof item === 'object') // Add this filter
                            .filter(
                                (item) => item.allowedRoles?.includes(role.toUpperCase()) ?? true,
                            )
                            .map((item) =>
                                item.render ? (
                                    item.render
                                ) : (
                                    <Menu.Item key={item.key}>
                                        {item.link && <Link to={item.link}>{item.title}</Link>}
                                        {item.onClick && (
                                            <div onClick={item.onClick}>{item.title}</div>
                                        )}
                                    </Menu.Item>
                                ),
                            )}
                    </Menu>
                }
                trigger={['click']}>
                <a className="ant-dropdown-link" style={{ color: 'white', paddingLeft: 10 }}>
                    <Icon
                        type="bars"
                        theme="outlined"
                        style={{ fontSize: 26, lineHeight: '54px' }}
                    />
                </a>
            </Dropdown>
        );

        return (
            <StyledHeader
                style={{
                    width: '100%',
                    position: 'fixed',
                    overflow: 'hidden',
                    zIndex: 40,
                }}>
                <ActivityModal
                    visible={!!activityModalId}
                    id={activityModalId}
                    type="company"
                    onClose={this.hideActivityModal}
                />
                <Row type="flex" align="middle" justify="space-between">
                    <Col style={{ marginTop: -6 }}>
                        <Link to="/">
                            <img src={logo} alt="logo" style={{ height: 37 }} />
                        </Link>
                    </Col>
                    <Col>
                        {!mobile && this.menuItems.map(this.mapItem)}
                        <NavigationDropDown extended={mobile} />
                    </Col>
                </Row>
            </StyledHeader>
        );
    }
}

PageHeader.contextTypes = {
    username: PropTypes.string.isRequired,
    role: PropTypes.string,
    logout: PropTypes.func.isRequired,
    setCompanyVisibility: PropTypes.func,
};

const mapStateToProps = (state) => ({
    isCompanyVisible: state.company.profile.visible,
    advertCount: state.company.advertCount,
    profile: state.company.profile,
    companyId: state.company.uuid,
    isDemoMode: state.user.isDemoMode,
    showFeed: state.company.showFeed,
    showInstagramSync: state.company.showInstagramSync,
    showTalentPool: state.company.showTalentPool,
});

const mapDispatchToProps = (dispatch) => ({
    setAttributeValue: (attributeName, value) => dispatch(setAttributeValue(attributeName, value)),
    setDemoMode: (value) => dispatch(setDemoMode(value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageHeader));
