import createAuthClient from '@uninow/auth-spa-js';

const accountServiceHost = window.REACT_APP_ACCOUNT_SERVICE_HOST || 'https://accounts.uninow.com';
export const authenticate = async (loginId, password) => {
    const authClient = await createAuthClient({
        domain: accountServiceHost,
        application: 'com.uninow.recruiting',
    });

    const response = await authClient.loginWithCredentials({
        loginId,
        password,
    });

    const token = response.getEncryptedToken();

    const user = await authClient.getUser();

    return { token, user: user.data };
};

export const getUser = async (token) => {
    try {
        const authClient = await createAuthClient({
            domain: accountServiceHost,
            application: 'com.uninow.recruiting',
        });

        authClient.addJWTAuthorization(token);

        const user = await authClient.getUser();

        return {
            status: 200,
            body: user.data,
            error: null,
        };
    } catch (error) {
        return {
            status: 500,
            body: null,
            error: error.message,
        };
    }
};
