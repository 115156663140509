import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Modal, Carousel, Icon, Row, Col } from 'antd';

import './style.css';

class GalleryModal extends Component {
    onCancel = () => {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    };

    next = () => {
        if (this.slider) {
            this.slider.next();
        }
    };

    prev = () => {
        if (this.slider) {
            this.slider.prev();
        }
    };

    render() {
        const { visible, images } = this.props;

        return (
            <div id="GalleryModal">
                <Modal
                    destroyOnClose
                    footer={null}
                    getContainer={() => document.getElementById('GalleryModal')}
                    onCancel={this.onCancel}
                    visible={visible}>
                    <Row type="flex" align="middle" justify="center">
                        <Col span={2} style={{ textAlign: 'left' }}>
                            <Icon type="left" className="slide-arrow" onClick={this.prev} />
                        </Col>
                        <Col span={20}>
                            <Carousel
                                ref={(slider) => {
                                    this.slider = slider;
                                }}>
                                {images.map((image) => (
                                    <div className="slide" key={image.large}>
                                        <img src={image.large} alt="" />
                                    </div>
                                ))}
                            </Carousel>
                        </Col>
                        <Col span={2} style={{ textAlign: 'right' }} onClick={this.next}>
                            <Icon type="right" className="slide-arrow" />
                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}

GalleryModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    images: PropTypes.array.isRequired,
    onCancel: PropTypes.func,
};

export default GalleryModal;
