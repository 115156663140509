import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import { Row, Col, Icon } from 'antd';

import parseMarkdown from '../../../core/helper/markdown';

class Ratings extends Component {
    renderRating = (el) => (
        <div className="rowContainer" key={el.key}>
            <Row>
                <Col span={4}>
                    {el.image ? (
                        <img
                            alt="Avatar"
                            key={el.image.raw}
                            src={el.image.edited || el.image.small}
                            className="avatar"
                        />
                    ) : (
                        <img
                            alt="Avatar"
                            key="placeholder-insights"
                            src="https://images.uninow.com/recruiting/default/person00.jpg"
                            className="avatar"
                        />
                    )}
                </Col>
                <Col offset={2} span={18}>
                    <div className="basicFont">{el.title}</div>
                    <div className="basicFontLight">{el.subtitle}</div>
                </Col>
            </Row>
            {el.video && (
                <Row style={{ padding: '10px 0px 0px 0px' }}>
                    <video
                        style={{ maxWidth: '100%' }}
                        controls
                        src={el.video.preview || el.video.raw}
                    />
                </Row>
            )}
            <Row className="textContainer">{parseMarkdown(el.text)}</Row>
        </div>
    );

    render() {
        return (
            <div
                style={{
                    height: '100%',
                    overflowY: 'scroll',
                }}>
                <div
                    className="navbar centerText"
                    style={{
                        borderBottom: '1px solid #edeff4',
                        backgroundColor: '#ffffff',
                    }}>
                    <Link to="/profil">
                        <Icon
                            type="left"
                            style={{
                                fontSize: 12,
                                margin: 6,
                                float: 'left',
                            }}
                            className="color-primary"
                        />
                    </Link>
                </div>

                <div className="navbarSpacer" />

                {this.props.ratings.length > 0 &&
                    this.props.ratings
                        .map(this.renderRating)
                        .reduce((prev, curr) => [
                            prev,
                            <div key={`${curr.key}_divider`} className="divider" />,
                            curr,
                        ])}
            </div>
        );
    }
}

const mapStateToProps = ({ company }) => ({
    ratings: company.profile.ratings,
});

export default connect(mapStateToProps)(Ratings);
