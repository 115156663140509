import styled from 'styled-components';

export const LoginFormLayout = styled.div`
    background-color: #fff;
    -webkit-box-shadow: 0 2px 41px 0 rgba(94, 94, 94, 0.5);
    box-shadow: 0 2px 41px 0 rgba(94, 94, 94, 0.5);
    text-align: center;
    margin: 50px auto 0px auto;
    padding: 20px 50px;
    width: 600px;
`;
