import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button, Form, List } from 'antd';
import { generateID } from '../../core/helper/redux';
import {
    addToArray,
    removeFromArray,
    changeArrayElement,
    setAttributeValue,
} from '../../core/redux/actions';
import { ProfileLayout, UniNowList } from '../../components';
import { defaultEvent } from '../../core/helper/defaultObjects';
import buttonWithTooltip from '../../decorators/buttonWithTooltip';
import EventModal from './EventModal';
import PlanFilter, { PLAN_PERMISSION } from '../../components/PlanFilter';

function buildLocationString(location) {
    const { name, label, addition } = location || {};

    if (!label) {
        return addition || 'Keine Standortangabe';
    }

    return `${name ? `${name}, ` : ''}${label}${addition ? ` (${addition})` : ''}`;
}

function buildTimeString(start, end) {
    if (!start) {
        return '';
    }

    const startDate = moment.utc(start);
    const endDate = moment.utc(end);

    const sameDay = !end || startDate.isSame(endDate, 'day');

    const dateString = sameDay
        ? startDate.locale('de').format('dd DD. MMM YYYY')
        : `${startDate.format('DD.MM.YYYY')} - ${endDate.format('DD.MM.YYYY')}`;

    const allDay = start
        ? startDate.format('HHmm') === '0000' && (!end || endDate.format('HHmm') === '0000')
        : false;

    // eslint-disable-next-line no-nested-ternary
    const timeString = allDay
        ? 'ganztägig'
        : end
        ? `von ${startDate.format('HH:mm')} - ${endDate.format('HH:mm')}`
        : `ab ${startDate.format('HH:mm')}`;

    return `${dateString} ${timeString}`;
}

class Events extends Component {
    state = {
        modalVisible: false,
    };

    componentDidMount() {
        if (this.props.history.location.search) {
            const searchParams = new URLSearchParams(this.props.history.location.search);
            const id = searchParams.get('eventId');
            const event = this.props.events.find((event) => id === event._id);
            if (event) {
                this.openModal(event);
            }
        }
    }

    add = () => {
        const newEvent = { ...defaultEvent, tempID: generateID().toString() };
        this.props.addToArray('profile.events', newEvent);
        this.openModal(newEvent);
    };

    remove = (evt) => {
        const id = (evt._id || evt.tempID || '').toString();

        const index = this.props.events.findIndex(
            (event) => id === (event._id || event.tempID || '').toString(),
        );

        this.props.removeFromArray('profile.events', index);
    };

    handleSubmit = () =>
        new Promise((resolve, reject) =>
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    resolve(true);
                }
                resolve(false);
            }),
        );

    openModal = (event) => {
        this.setState({
            activeEvent: event,
        });
    };

    hideModal = () => {
        this.setState({
            activeEvent: null,
        });
    };

    onSave = (updatedEvent) => {
        const id = (updatedEvent._id || updatedEvent.tempID || '').toString();

        const index = this.props.events.findIndex(
            (event) => id === (event._id || event.tempID || '').toString(),
        );

        this.props.changeArrayElement('profile.events', index, updatedEvent);

        this.hideModal();
    };

    render() {
        const { activeEvent } = this.state;
        const events = (this.props.events || [])
            .map((event) => {
                event.timestamp = event.start ? moment(event.start).unix() : 0;

                return event;
            })
            .sort((a, b) => a.timestamp - b.timestamp);

        const pastEvents = events.filter((event) =>
            moment(event.end ?? event.start).isBefore(moment()),
        );
        const currentEvents = events.filter(
            (event) =>
                moment(event.start).isAfter(moment()) ||
                (event.end && moment(event.end).isAfter(moment())),
        );
        return (
            <ProfileLayout
                subHeaderSelectedKey="Events"
                onSubmitMin={this.handleSubmit}
                infoText="Laden	Sie	die	Studierenden ein, Sie frühzeitig	auf Messen,	Firmenevents oder anderen Events kennenzulernen.">
                <PlanFilter permission={PLAN_PERMISSION.EVENTS}>
                    <Button icon="plus" type="primary" size="large" onClick={() => this.add()}>
                        Event hinzufügen
                    </Button>
                    <Form>
                        <EventModal
                            visible={!!activeEvent}
                            event={activeEvent}
                            onCancel={this.hideModal}
                            onOk={this.onSave}
                            role={this.context.role.toUpperCase()}
                            form={this.props.form}
                        />
                        {this.renderEvents(currentEvents)}
                        {pastEvents.length > 0 && [
                            <div
                                key="pastEvent"
                                style={{
                                    color: '#8f8f8f',
                                    paddingTop: 40,
                                }}>
                                Vergangene Events:
                            </div>,
                            this.renderEvents(pastEvents),
                        ]}
                    </Form>
                </PlanFilter>
            </ProfileLayout>
        );
    }

    renderEvents(events) {
        return (
            <UniNowList
                noItemsText="Es wurden noch keine Events hinzugefügt."
                itemLayout="horizontal"
                dataSource={events}
                renderItem={(event) => (
                    <List.Item
                        key={event._id || event.tempID}
                        actions={[
                            buttonWithTooltip('Editieren')(
                                <Button
                                    onClick={() => this.openModal(event)}
                                    shape="circle"
                                    icon="edit"
                                />,
                            ),
                            buttonWithTooltip('Löschen')(
                                <Button
                                    onClick={() => this.remove(event)}
                                    shape="circle"
                                    icon="delete"
                                />,
                            ),
                        ]}
                        style={{ width: '100%' }}>
                        <List.Item.Meta
                            title={event.title}
                            description={
                                event.start
                                    ? `${buildLocationString(event.location)}, ${buildTimeString(
                                          event.start,
                                          event.end,
                                      )}`
                                    : event.date || event.notes
                            }
                        />
                    </List.Item>
                )}
            />
        );
    }
}

Events.contextTypes = {
    role: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    events: state.company.profile.events,
});

const mapDispatchToProps = (dispatch) => ({
    addToArray: (attributeName, element) => dispatch(addToArray(attributeName, element)),
    removeFromArray: (attributeName, index) => dispatch(removeFromArray(attributeName, index)),
    changeArrayElement: (attributeName, index, updatedElement) =>
        dispatch(changeArrayElement(attributeName, index, updatedElement)),
    setAttributeValue: (attributeName, value) => dispatch(setAttributeValue(attributeName, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Events));
