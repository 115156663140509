import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import { Icon, message } from 'antd';

import './style.css';

const MAX_IMAGE_SIZE = 40000000; // 40MB

class AudioDropzone extends Component {
    onFilesAccept = (files) => {
        if (this.props.onFileSelected) {
            this.props.onFileSelected(files);
        }
    };

    onFilesRejected = (files) => {
        console.log('REJECT');
        files.forEach((file) => {
            if (file.size > MAX_IMAGE_SIZE) {
                message.error(
                    `Dateigröße von ${file.name} übersteigt das Limit von ${
                        MAX_IMAGE_SIZE / 1000000
                    }MB.`,
                );
            } else {
                message.error(`${file.name} konnte nicht hochgeladen werden.`);
            }
        });
    };

    render() {
        const { shape, icon, style = {} } = this.props;

        return (
            <Dropzone
                id="AudioDropzone"
                className={`overlay ${shape}`}
                accept="audio/*"
                maxSize={MAX_IMAGE_SIZE}
                multiple={this.props.multiple}
                onDropRejected={this.onFilesRejected}
                onDropAccepted={this.onFilesAccept}
                style={style}>
                <div style={{ position: 'relative', height: '100%' }}>
                    <Icon type={icon} className="upload-icon" />
                </div>
            </Dropzone>
        );
    }
}

AudioDropzone.propTypes = {
    onFileSelected: PropTypes.func,
    shape: PropTypes.string,
    multiple: PropTypes.bool,
    icon: PropTypes.string,
};

AudioDropzone.defaultProps = {
    multiple: false,
    icon: 'audio',
};

export default AudioDropzone;
