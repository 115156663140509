import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Icon } from 'antd';
import { Post } from '..';

import gradient from '../../assets/images/gradient.svg';
import AdvertLink from '../AdvertPreview/components/AdvertLink';

class AdvertCard extends Component {
    renderLink = () => {
        const { link, label } = this.props.data.action;
        return <AdvertLink link={link}>{label}</AdvertLink>;
    };

    renderAdvert = (data) => (
        <div>
            <Row
                type="flex"
                align="middle"
                style={{
                    padding: '40px 10px 10px 10px',
                }}>
                <Col span={4}>
                    <img
                        alt="logo"
                        src={
                            data.logo
                                ? data.logo.edited || data.logo.large
                                : 'https://images.uninow.com/campaigns/logo_placeholder_144x144.jpg'
                        }
                        style={{ height: '3em', width: '3em' }}
                    />
                </Col>

                <Col span={20}>
                    <div
                        style={{
                            fontWeight: '500',
                            fontSize: '1.2em',
                            color: '#4F4F4F',
                            paddingLeft: 10,
                            paddingRight: 10,
                        }}>
                        {data.title}
                    </div>
                    <div
                        style={{
                            fontSize: '1em',
                            color: '#8E8E8E',
                            fontWeight: '200',
                            paddingLeft: 10,
                            paddingRight: 10,
                        }}>
                        Gesponsert
                    </div>
                </Col>
            </Row>
            <div
                style={{
                    fontSize: '1.1em',
                    color: '#4F4F4F',
                    fontWeight: '200',
                    paddingLeft: 10,
                    paddingRight: 10,
                }}>
                {data.teaser
                    ? data.teaser.split('\n').map((text, key) => (
                          <span key={key}>
                              {text}
                              <br />
                          </span>
                      ))
                    : null}
            </div>
            {!data.video ? (
                <img
                    alt="header"
                    src={
                        data.image
                            ? data.image.edited || data.image.large
                            : 'https://images.uninow.com/campaigns/image_placeholder_1065x435.jpg'
                    }
                    style={{
                        width: '100%',
                        marginTop: 10,
                        marginBottom: 10,
                        objectFit: 'contain',
                    }}
                />
            ) : data.video && data.image ? (
                <div
                    style={{
                        height: '8.5em',
                        minWidth: '100%',
                        maxWidth: '100%',
                        marginTop: 10,
                        marginBottom: 10,
                        objectFit: 'cover',
                        backgroundImage: `url(${data.image.edited || data.image.large})`,
                        backgroundSize: 'cover',
                        textAlign: 'center',
                        color: '#fff',
                        position: 'relative',
                    }}>
                    <div
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                        <Icon type="play-circle" style={{ fontSize: '3em' }} />
                    </div>
                </div>
            ) : (
                <div style={{ position: 'relative' }}>
                    <video
                        src={data.video.preview || data.video.raw}
                        alt=""
                        autoPlay={false}
                        controls
                        style={{
                            height: '8.5em',
                            minWidth: '100%',
                            maxWidth: '100%',
                            marginTop: 10,
                            marginBottom: 10,
                            backgroundColor: '#000',
                        }}
                    />
                </div>
            )}

            <div
                style={{
                    fontSize: '1.1em',
                    color: '#4F4F4F',
                    fontWeight: '500',
                    paddingLeft: 10,
                    paddingRight: 10,
                }}>
                {data.headline}
            </div>
            <div
                style={{
                    fontSize: '1.1em',
                    color: '#4F4F4F',
                    fontWeight: '200',
                    paddingLeft: 10,
                    paddingRight: 10,
                }}>
                {data.subline
                    ? data.subline.split('\n').map((text, key) => (
                          <span key={key}>
                              {text}
                              <br />
                          </span>
                      ))
                    : null}
            </div>
            <div
                style={{
                    height: 1,
                    backgroundColor: '#EDEFF4',
                    marginLeft: 10,
                    marginTop: 10,
                }}
            />

            <div
                style={{
                    padding: 10,
                    color: '#f21852',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                }}>
                {this.renderLink()}
            </div>
        </div>
    );

    renderPost = (data) => (
        <div
            style={{
                fontSize: '1.1em',
                color: '#4F4F4F',
                fontWeight: '200',
                padding: '45px 0px 0px 0px',
            }}>
            <Post post={data} />
        </div>
    );

    render() {
        const { data } = this.props;

        const isFeedPost = !!data.post;

        return (
            <div style={{ backgroundColor: 'white' }}>
                <div
                    style={{
                        position: 'absolute',
                        zIndex: 30,
                        width: '100%',
                        backgroundImage: `url(${gradient}`,
                        backgroundColor: '#f21850',
                        backgroundSize: 'cover',
                        padding: '8px 0',
                    }}>
                    <div style={{ width: '15%', display: 'inline-block' }} />
                    <div
                        style={{
                            width: '70%',
                            textAlign: 'center',
                            display: 'inline-block',
                        }}>
                        <div
                            style={{
                                borderTopLeftRadius: 10,
                                borderBottomLeftRadius: 10,
                                backgroundColor: '#fff',
                                width: '50%',
                                textAlign: 'center',
                                display: 'inline-block',
                                padding: 1,
                            }}>
                            Mein Feed
                        </div>
                        <div
                            style={{
                                borderTopRightRadius: 10,
                                borderBottomRightRadius: 10,
                                backgroundColor: 'rgba(255,255,255,0.25)',
                                width: '50%',
                                textAlign: 'center',
                                display: 'inline-block',
                                color: '#fff',
                                padding: 1,
                            }}>
                            Entdecken
                        </div>
                    </div>
                    <div
                        style={{
                            width: '15%',
                            display: 'inline-block',
                            textAlign: 'center',
                            color: '#fff',
                        }}>
                        <Icon type="message" theme="outlined" />
                    </div>
                </div>
                {isFeedPost ? this.renderPost(data.post) : this.renderAdvert(data)}
            </div>
        );
    }
}

AdvertCard.propTypes = {
    data: PropTypes.object.isRequired,
};

export default AdvertCard;
