import React, { Component } from 'react';
import { Route, Redirect } from 'react-router';
import PropTypes from 'prop-types';

class PrivateRoute extends Component {
    render() {
        const { component: Component, accessRoles, ...rest } = this.props;
        const { authToken, role } = this.context;

        return (
            <Route
                {...rest}
                render={
                    (props) => {
                        if (authToken) {
                            if (accessRoles) {
                                if (accessRoles.includes(role.toUpperCase())) {
                                    return <Component {...props} />;
                                }
                                return (
                                    <Redirect
                                        to={{
                                            pathname: '/',
                                        }}
                                    />
                                );
                            }
                            return <Component {...props} />;
                        }
                        return (
                            <Redirect
                                to={{
                                    pathname: '/login',
                                    state: { from: props.location },
                                }}
                            />
                        );
                    }
                    //  (authToken ? (
                    //    <Component {...props} />
                    //  ) : (
                    //    <Redirect
                    //      to={{
                    //        pathname: '/login',
                    //        state: { from: props.location },
                    //      }}
                    //    />
                    //  ))
                }
            />
        );
    }
}

PrivateRoute.contextTypes = {
    authToken: PropTypes.string,
    role: PropTypes.string,
};

export default PrivateRoute;
