import React, { Component } from 'react';
import { Menu, Icon } from 'antd';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

class PageSubHeader extends Component {
    render() {
        const { items, selectedKey, messagesCount } = this.props;
        return (
            <Menu
                mode="horizontal"
                selectedKeys={[selectedKey]}
                style={{
                    backgroundColor: 'white',
                    position: 'fixed',
                    zIndex: '40',
                    top: 64,
                    left: '0',
                    right: '0',
                    width: '100%',
                }}>
                {items.map((item) => (
                    <Menu.Item key={item.title}>
                        {item.icon && <Icon type={item.icon} />}
                        {/* If we need messages implementation */}
                        {/* <Link to={item.link}> */}
                        {/*    {item.title === 'Benachrichtigungen' && messagesCount > 0 ? ( */}
                        {/*        <div> */}
                        {/*            <span>{item.title}</span> */}
                        {/*            <Badge */}
                        {/*                count={messagesCount} */}
                        {/*                style={{ */}
                        {/*                    backgroundColor: '#F42847', */}
                        {/*                    color: '#fff', */}
                        {/*                    marginLeft: '8px', */}
                        {/*                }} */}
                        {/*            /> */}
                        {/*        </div> */}
                        {/*    ) : ( */}
                        {/*        item.title */}
                        {/*    )} */}
                        {/* </Link> */}
                        <Link to={item.link}>{item.title}</Link>
                    </Menu.Item>
                ))}
            </Menu>
        );
    }
}

export default withRouter(PageSubHeader);
