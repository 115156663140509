import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ProfileLayout, ImageGalleryWithoutDD } from '../../components';
import { ErrorText } from '../../components/styles';
import imageConfig from '../../core/constants/imageConfig';

import {
    addToArray,
    removeFromArray,
    changeArrayElement,
    setAttributeValue,
} from '../../core/redux/actions';
import PlanAlert from '../../components/PlanAlert';

class Media extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
        };
    }

    validateImageList = (imageList) => {
        const { error } = this.state;
        if (imageList.length > 0) {
            if (error) {
                this.setState({ error: '' });
            }
            return true;
        }
        this.setState({ error: 'Bitte wählen Sie ein Titelbild.' });
        return false;
    };

    updateImageList = (updatedImageList) => {
        this.props.setAttributeValue('profile.gallery', updatedImageList);
        this.validateImageList(updatedImageList);
    };

    render() {
        const { error } = this.state;
        const { gallery, plan } = this.props;
        const exceededGallery = plan.numImages > 0 && gallery.length >= plan.numImages;
        const disabledGallery = plan.numImages > 0 && gallery.length > plan.numImages;
        return (
            <ProfileLayout
                subHeaderSelectedKey="Galerie"
                onSubmit={() => this.validateImageList(gallery)}
                infoText={`In der Galerie verwalten Sie die Bilder Ihres Unternehmens. Das erste Bild stellt dabei Ihr Titelbild dar und sollte deswegen immer vorhanden sein. Das optimale Seitenverhältnis eines Bildes beträgt 3:2 (~${imageConfig.galleryOptimalSize.width}x${imageConfig.galleryOptimalSize.height}px). Es werden ausschließlich die Datei-Formate JPEG/JPG sowie PNG mit einer Datei-Größe von maximal 20MB unterstützt.`}>
                {exceededGallery && (
                    <PlanAlert
                        message={
                            <span>
                                Ihr aktuelles Paket erlaubt nur{' '}
                                {plan.numImages === 1 ? 'ein Bild' : `${plan.numImages} Bilder`}.
                            </span>
                        }
                    />
                )}
                <ImageGalleryWithoutDD
                    optimalWidth={imageConfig.galleryOptimalSize.width}
                    optimalHeight={imageConfig.galleryOptimalSize.height}
                    imageList={[...this.props.gallery]}
                    disableAdd={exceededGallery}
                    disableEdit={disabledGallery}
                    onChange={this.updateImageList}
                />
                {error && <ErrorText style={{ marginLeft: '1%' }}>{error}</ErrorText>}
            </ProfileLayout>
        );
    }
}

const mapStateToProps = (state) => ({
    logo: state.company.profile.logo,
    gallery: state.company.profile.gallery,
    socialMedia: state.company.profile.socialMedia,
    plan: state.company.plan,
});

const mapDispatchToProps = (dispatch) => ({
    addToArray: (attributeName, element) => dispatch(addToArray(attributeName, element)),
    removeFromArray: (attributeName, index) => dispatch(removeFromArray(attributeName, index)),
    changeArrayElement: (attributeName, index, updatedElement) =>
        dispatch(changeArrayElement(attributeName, index, updatedElement)),
    setAttributeValue: (attributeName, value) => dispatch(setAttributeValue(attributeName, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Media);
