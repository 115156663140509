import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import gradient from '../../Preview/images/gradient.svg';
import parseMarkdown from '../../../core/helper/markdown';
import AdvertLink from './AdvertLink';

function MailInbox({ data, onBackClicked }) {
    const { title, subline, headline, action, teaser } = data;
    return (
        <div style={{ backgroundColor: 'white', paddingBottom: 10 }}>
            <div
                style={{
                    width: '100%',
                    backgroundImage: `url(${gradient}`,
                    backgroundColor: '#f21850',
                    backgroundSize: 'cover',
                    padding: 8,
                }}>
                <a
                    onClick={onBackClicked}
                    style={{ color: 'white', fontSize: '1.2em' }}
                    role="button">
                    <Icon type="left" />
                </a>
            </div>
            <div
                style={{
                    padding: 6,
                    borderBottom: '1px solid #efefef',
                    display: 'flex',
                }}>
                <img
                    alt="logo"
                    src={
                        data.logo
                            ? data.logo.edited || data.logo.large
                            : 'https://images.uninow.com/campaigns/logo_placeholder_144x144.jpg'
                    }
                    style={{ height: '3em', width: '3em' }}
                />
                <div style={{ width: '100%', paddingLeft: 6 }}>
                    <div style={{ color: '#4A494A', fontWeight: '500', fontSize: '1.2em' }}>
                        {title}
                    </div>
                    <div
                        style={{
                            padding: '0 2px',
                            marginRight: 2,
                            color: '#F21850',
                            border: '1px solid #F21850',
                            borderRadius: '2px',
                            display: 'inline-block',
                        }}>
                        Sponsored
                    </div>
                </div>
            </div>
            <div
                style={{
                    color: '#8E8E8E',
                    fontSize: '1em',
                    borderBottom: '1px solid #efefef',
                    padding: 6,
                }}>
                {headline}
            </div>
            <div
                style={{
                    color: '#4A494A',
                    fontSize: '1em',
                    padding: 6,
                    fontWeight: 300,
                }}>
                {parseMarkdown(teaser)}
            </div>

            {!data.video ? (
                <img
                    alt="header"
                    src={
                        data.image
                            ? data.image.edited || data.image.large
                            : 'https://images.uninow.com/campaigns/image_placeholder_1065x435.jpg'
                    }
                    style={{
                        width: '100%',
                        marginTop: 10,
                        marginBottom: 10,
                        objectFit: 'contain',
                    }}
                />
            ) : data.video && data.image ? (
                <div
                    style={{
                        height: '8.5em',
                        minWidth: '100%',
                        maxWidth: '100%',
                        marginTop: 10,
                        marginBottom: 10,
                        objectFit: 'cover',
                        backgroundImage: `url(${data.image.edited || data.image.large})`,
                        backgroundSize: 'cover',
                        paddingTop: 30,
                        textAlign: 'center',
                        color: '#fff',
                    }}
                />
            ) : (
                <div style={{ position: 'relative' }}>
                    <video
                        src={data.video.preview || data.video.raw}
                        alt=""
                        autoPlay={false}
                        controls
                        style={{
                            height: '8.5em',
                            minWidth: '100%',
                            maxWidth: '100%',
                            marginTop: 10,
                            marginBottom: 10,
                            backgroundColor: '#000',
                        }}
                    />
                </div>
            )}
            {action && (
                <AdvertLink link={action.link}>
                    <div
                        style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            minWidth: '80%',
                            maxWidth: '95%',
                            backgroundImage: `url(${gradient}`,
                            backgroundColor: '#f21850',
                            backgroundSize: 'cover',
                            padding: 5,
                            color: 'white',
                            textAlign: 'center',
                            borderRadius: 99,
                        }}>
                        {action?.label || headline}
                    </div>
                </AdvertLink>
            )}
        </div>
    );
}

MailInbox.propTypes = {};

export default MailInbox;
