import React from 'react';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import { PageSubHeader, BasicLayout } from '..';
import { PageBody } from './styles';
import DefaultErrorBoundary from '../DefaultErrorBoundary';

const studentsProfileItems = [
    { title: 'Suche', link: '/students' },
    { title: 'Kontaktierte Profile', link: '/students/kontaktiert' },
];

const profileItems = [
    { title: 'Allgemein', link: '/profil' },
    { title: 'Standorte', link: '/profil/standorte' },
    { title: 'Galerie', link: '/profil/galerie' },
    { title: 'Soziale Medien', link: '/profil/sozialemedien' },
    { title: 'Auszeichnungen', link: '/profil/auszeichnungen' },
    { title: 'Benefits', link: '/profil/benefits' },
    { title: 'Events', link: '/profil/events' },
    { title: 'Insights', link: '/profil/insights' },
];

const jobItems = [
    { title: 'Aktiv', link: '/stellenanzeigen' },
    { title: 'Archiviert', link: '/stellenanzeigen/archiviert' },
];

const adItems = [
    { title: 'Aktiv', link: '/werbeanzeigen' },
    { title: 'Archiviert', link: '/werbeanzeigen/archiviert' },
];

const statisticsItems = [{ title: 'Stellenanzeigen', link: '/statistiken/stellenanzeigen' }];
const adItem = { title: 'Werbeanzeigen', link: '/statistiken/werbeanzeigen' };
const companyStats = { title: 'Unternehmen', link: '/statistiken/unternehmen' };
function getOptions(path, role, { showCompanyStats } = { showCompanyStats: false }) {
    const options = {};
    if (/^\/werbeanzeigen/.test(path)) {
        options.selectedKey = 'Werbeanzeigen';
        options.items = adItems;
        const subItem = adItems.find((el) => el.link === path);
        if (subItem) {
            options.subKey = subItem.title;
        }
    } else if (/^\/stellenanzeigen/.test(path)) {
        options.selectedKey = 'Stellenanzeigen';
        options.items = jobItems;
        const subItem = jobItems.find((el) => el.link === path);
        if (subItem) {
            options.subKey = subItem.title;
        }
    } else if (/^\/beitrage/.test(path)) {
        options.selectedKey = 'Beiträge';
        options.items = jobItems;
        const subItem = jobItems.find((el) => el.link === path);
        if (subItem) {
            options.subKey = subItem.title;
        }
    } else if (/^\/profil/.test(path) || path === '/') {
        options.selectedKey = 'Unternehmensprofil';
        options.items = profileItems;

        const subItem = profileItems.find((el) => el.link === path);

        if (subItem) {
            options.subKey = subItem.title;
        } else {
            options.subKey = 'Allgemein';
        }
    } else if (/^\/companies/.test(path)) {
        options.selectedKey = 'Kunden';
        options.items = null;
        options.subKey = null;
    } else if (/^\/students/.test(path)) {
        options.selectedKey = 'Studierendenprofile';
        options.items = studentsProfileItems;

        const subItem = studentsProfileItems.find((el) => el.link === path);

        if (subItem) {
            options.subKey = subItem.title;
        } else {
            options.subKey = 'Suche';
        }
    } else if (/^\/statistiken/.test(path) && path !== '/statistiken/jobs') {
        if (role === 'CUSTOMER_ADVERTS') {
            options.selectedKey = 'werbeanzeigen';
            options.items = null;
            options.subKey = null;
        } else {
            options.selectedKey = 'Statistiken';
            options.items = statisticsItems;
            if (showCompanyStats) {
                options.items = [companyStats, ...statisticsItems];
            }
            if (role && !['UNI_ADMIN', 'CUSTOMER_NO_ADVERTS'].includes(role)) {
                options.items = [...options.items, adItem];
            }
            const subItem = options.items.find((el) => el.link === path);
            if (subItem) {
                options.subKey = subItem.title;
            }
        }
    }
    return options;
}

function SubHeaderLayout(props) {
    const { children, location, role } = props;
    const showCompanyStats = useSelector((state) => state.company?.showCompanyStats);
    const options = getOptions(location.pathname, role, { showCompanyStats });
    const { selectedKey, subKey, items, lateralPadding } = options;
    const randomMessageNumber = 2;

    return (
        <BasicLayout lateralPadding={lateralPadding} selectedKey={selectedKey}>
            {subKey && (
                <PageSubHeader
                    messagesCount={randomMessageNumber}
                    items={items}
                    selectedKey={subKey}
                />
            )}
            <PageBody padding={!!subKey}>
                <DefaultErrorBoundary>{children}</DefaultErrorBoundary>
            </PageBody>
        </BasicLayout>
    );
}

export default withRouter(SubHeaderLayout);
