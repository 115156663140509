import React from 'react';
import { Tag, Tooltip } from 'antd';
import Ionicon from '../Ionicon';

function Audience({ channels }) {
    const getIcon = (channel) => {
        switch (channel.targeting.roles?.[0]) {
            case 'student':
                return 'ios-school';
            case 'employee':
                return 'ios-briefcase';
            default:
                return 'ios-person';
        }
    };
    const getRoleText = (channel) => {
        switch (channel.targeting.roles?.[0]) {
            case 'student':
                return 'Studierende';
            case 'employee':
                return 'Angestellte';
            default:
                return 'Gäste';
        }
    };
    return (
        <div style={{ marginTop: 5, marginBottom: 5 }}>
            {channels.map((channel) => {
                const icon = getIcon(channel);
                return (
                    <Tooltip title={`sichtbar für ${getRoleText(channel)}`}>
                        <Tag>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Ionicon
                                    type={icon}
                                    fontStyle={{ fontSize: 16, color: '#85858f' }}
                                    style={{ marginRight: 2 }}
                                />
                                {channel.name}
                            </div>
                        </Tag>
                    </Tooltip>
                );
            })}
        </div>
    );
}

export default Audience;
