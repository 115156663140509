import React, { Component } from 'react';
import Geosuggest from 'react-geosuggest';
import 'antd/lib/timeline';
import './geosuggest.css';
import { Button } from 'antd';
import { withGoogleMaps } from '../Map';

class AsyncGeosuggest extends Component {
    render() {
        const { style, noButton, ...rest } = this.props;

        return (
            <form style={{ display: 'flex', borderRadius: 4, ...style }} autoComplete="off">
                <Geosuggest
                    ref={(instance) => (this.geosuggest = instance)}
                    autoComplete="off"
                    autoFill="off"
                    {...rest}
                />
                {!noButton && (
                    <Button
                        style={{ marginLeft: '5px' }}
                        icon="search"
                        size="large"
                        type="button"
                        onClick={() => this.geosuggest.selectSuggest()}>
                        Suchen
                    </Button>
                )}
            </form>
        );
    }
}

export default withGoogleMaps(AsyncGeosuggest);
