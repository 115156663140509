import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { onError } from 'apollo-link-error';

import ApolloClient from 'apollo-client';
import { setContext } from '@apollo/client/link/context';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink } from 'apollo-link';
import { ApolloProvider } from 'react-apollo';
import introspectionQueryResultData from './introspection.json';

const { REACT_APP_GRAPHQL_HOST } = window;

function FeedClient(props) {
    const feedUser = useSelector((store) => store.company.feedUser);
    const token = useSelector((store) => store.authentication.token);
    const client = useMemo(() => {
        function omitProperty(obj, property) {
            if (
                obj &&
                typeof obj === 'object' &&
                !(obj instanceof Function) &&
                !(obj instanceof File)
            ) {
                if (Array.isArray(obj)) {
                    return obj.map((element) => omitProperty(element, property));
                }
                return Object.keys(obj)
                    .filter((key) => key !== property)
                    .reduce((nextObj, key) => {
                        nextObj[key] = omitProperty(obj[key], property);
                        return nextObj;
                    }, {});
            }
            return obj;
        }

        const fragmentMatcher = new IntrospectionFragmentMatcher({
            introspectionQueryResultData,
        });
        const cache = new InMemoryCache({
            fragmentMatcher,
            dataIdFromObject: (object) => (object.id ? `${object.__typename}:${object.id}` : null),
        });

        const httpLink = createUploadLink({
            uri: `${REACT_APP_GRAPHQL_HOST}/graphql`,
            headers: { 'Apollo-Require-Preflight': 'true' },
        });

        const errorLink = onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                graphQLErrors.map((error) => {
                    const { message, locations, path } = error;
                    if (window.REACT_APP_ENV === 'development') {
                        console.log(
                            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                        );
                    }
                    return error;
                });
            }
            if (networkError && window.REACT_APP_ENV === 'development') {
                console.log(`[Network error]: ${networkError}`);
            }
        });

        const cleanTypenameMiddleware = new ApolloLink((operation, forward) => {
            if (operation.variables) {
                operation.variables = omitProperty(operation.variables, '__typename');
            }
            return forward(operation);
        });
        const authMiddleware = setContext((_, { headers }) => {
            // return the headers to the context so httpLink can read them
            return {
                headers: {
                    ...headers,
                    authorization: `Bearer ${token}`,
                    'X-Uninow-Feed-Selected-Profile': feedUser?._id,
                },
            };
        });

        const link = ApolloLink.from([
            cleanTypenameMiddleware,
            /* split(({ query }) => {
          const { kind, operation } = getMainDefinition(query);
          return kind === 'OperationDefinition' && operation === 'subscription';
        }, new WebSocketLink(subscriptionLink)), */
            authMiddleware,
            errorLink,
            httpLink,
        ]);

        cache.writeData({ data: { selectedMap: null, tmpLocation: null } });
        return new ApolloClient({
            cache,
            link,
            resolvers: {
                Query: {
                    selectedMap: () => null,
                    tmpLocation: () => null,
                },
            },
        });
    }, [feedUser?._id, token]);

    return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}
FeedClient.propTypes = { children: PropTypes.node.isRequired };

export default FeedClient;
