import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'antd';
import AdDropzone from '../AdDropzone';
import buttonWithTooltip from '../../decorators/buttonWithTooltip';
import buttonWithPopconfirm from '../../decorators/buttonWithPopconfirm';
import { ImageEditorModal } from './components';
import { readFromFile } from '../../core/helper/image';

const fileTypeToMimeTypes = {
    image: ['image/jpeg', 'image/png'],
    video: ['video/mpeg', 'video/mp4', 'video/quicktime'],
};

const defaultImage = {
    raw: '',
    payload: {
        scale: 1,
        posX: 0.5,
        posY: 0.5,
    },
};

class FileManager extends Component {
    constructor(props) {
        super(props);
        const { files } = this.props;
        this.state = {
            image: files.image,
            video: files.video,
            imageToEdit: undefined,
            renderImageEditorModal: false,
        };
    }

    componentWillReceiveProps = (nextProps) => {
        const { video: nextVideo, image: nextImage } = nextProps.files;
        const { video: curVideo, image: curImage } = this.props;
        if (nextVideo !== curVideo && nextImage !== curImage) {
            this.setState({ video: nextVideo, image: nextImage });
        }
        if (nextVideo !== curVideo) {
            this.setState({ video: nextVideo });
        }
        if (nextImage !== curImage) {
            this.setState({ image: nextImage });
        }
    };

    handleAddNewFile = async (file) => {
        const { onChange } = this.props;
        const isImage = fileTypeToMimeTypes.image.includes(file.type);
        if (isImage) {
            this.setState({
                renderImageEditorModal: true,
                image: file,
            });

            const raw = await readFromFile(file);

            this.handleEditImage({
                ...defaultImage,
                raw,
            });
        } else {
            this.setState(
                {
                    renderImageEditorModal: false,
                    video: file,
                    image: undefined,
                },
                () => onChange({ video: file, image: undefined }),
            );
        }
    };

    handleEditImage = (image) => {
        this.setState({
            renderImageEditorModal: true,
        });
        const { payload: imagePayload } = image;
        this.setState({
            imageToEdit: { ...image, payload: { ...defaultImage.payload, ...imagePayload } },
        });
    };

    handleDeleteFile = (type) => {
        const { onChange } = this.props;
        if (type === 'image') {
            this.setState(
                { image: undefined, imageToEdit: undefined, renderImageEditorModal: false },
                () => {
                    if (onChange) {
                        onChange({ video: this.state.video, image: undefined });
                    }
                },
            );
        } else {
            this.setState(
                {
                    image: undefined,
                    video: undefined,
                    imageToEdit: undefined,
                    renderImageEditorModal: false,
                },
                () => {
                    if (onChange) {
                        onChange({ video: undefined, image: undefined });
                    }
                },
            );
        }
    };

    handleSaveImage = (image) => {
        const { onChange } = this.props;
        const { _id } = image;
        const imageToSave = {
            ...image,
            payload: {
                ...image.payload,
            },
        };

        if (!_id) {
            imageToSave.tempID = 'uuid';
        }
        this.setState(
            { image: imageToSave, imageToEdit: undefined, renderImageEditorModal: false },
            () => onChange && onChange({ video: this.state.video, image: imageToSave }),
        );
    };

    renderImage = (image, imageWidth, imageHeight) => {
        const { edited, large, raw, _id, tempID } = image;
        const imageUrl = edited || large || raw;
        const imageID = tempID || _id;

        return (
            <div
                className="imgContainer"
                style={{ width: imageWidth, height: imageHeight, overflow: 'hidden' }}>
                <img className="image" src={imageUrl} alt={imageID} />
                <div className="imgToolbar top">
                    {buttonWithTooltip('Bild editieren')(
                        <Button
                            className="toolbarItem"
                            style={{ marginRight: '5px' }}
                            onClick={() => this.handleEditImage(image)}
                            shape="circle"
                            icon="edit"
                        />,
                    )}
                    {buttonWithPopconfirm({
                        title: 'Wollen Sie dieses Bild wirklich löschen?',
                        okText: 'Löschen',
                        onConfirm: () => this.handleDeleteFile('image'),
                    })(
                        buttonWithTooltip('Bild löschen')(
                            <Button
                                className="toolbarItem"
                                style={{ marginRight: '5px' }}
                                shape="circle"
                                icon="delete"
                            />,
                        ),
                    )}
                </div>
            </div>
        );
    };

    renderThumbnail = (image, imageWidth, imageHeight) => {
        const { edited, large, raw, _id, tempID } = image;
        const imageUrl = edited || large || raw;
        const imageID = tempID || _id;

        return (
            <div
                className="imgContainer"
                style={{
                    width: imageWidth,
                    height: imageHeight,
                    overflow: 'hidden',
                    display: 'inline-block',
                    float: 'left',
                }}>
                <img className="image" src={imageUrl} alt={imageID} />
                <div className="imgToolbar top">
                    {buttonWithTooltip('Vorschau editieren')(
                        <Button
                            className="toolbarItem"
                            style={{ marginRight: '5px' }}
                            onClick={() => this.handleEditImage(image)}
                            shape="circle"
                            icon="edit"
                        />,
                    )}
                    {buttonWithPopconfirm({
                        title: 'Wollen Sie diese Vorschau wirklich löschen?',
                        okText: 'Löschen',
                        onConfirm: () => {
                            this.handleDeleteFile('image');
                        },
                    })(
                        buttonWithTooltip('Vorschau löschen')(
                            <Button
                                className="toolbarItem"
                                style={{ marginRight: '5px' }}
                                shape="circle"
                                icon="delete"
                            />,
                        ),
                    )}
                </div>
            </div>
        );
    };

    renderVideo = (file, thumbnail, imageWidth, imageHeight) => (
        <div>
            <div
                className="imgContainer"
                style={{
                    width: 100,
                    height: 100,
                    overflow: 'hidden',
                    display: 'inline-block',
                    margin: 'auto 25px 25px auto',
                    float: 'left',
                }}>
                <div
                    style={{
                        height: 100,
                        width: 100,
                        backgroundColor: '#e6e6e6',
                        padding: '5px',
                        borderRadius: '10px',
                        border: '#1a1a1a solid black',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexFlow: 'column wrap',
                    }}>
                    <Icon type="video-camera" style={{ fontSize: '35px', color: '#f21850' }} />
                    <div
                        style={{
                            width: '100%',
                            textAlign: 'center',
                            fontWeight: 'bold',
                        }}
                    />
                </div>
                <div className="imgToolbar top">
                    {buttonWithPopconfirm({
                        title: 'Wollen Sie dieses Video wirklich löschen?',
                        okText: 'Löschen',
                        onConfirm: () => this.handleDeleteFile('video'),
                    })(
                        buttonWithTooltip('Video löschen')(
                            <Button
                                className="toolbarItem"
                                style={{ marginRight: '5px' }}
                                shape="circle"
                                icon="delete"
                            />,
                        ),
                    )}
                </div>
            </div>
            {!thumbnail ? (
                <AdDropzone
                    style={{
                        backgroundColor: '#e6e6e6',
                        height: imageHeight,
                        width: imageWidth,
                        padding: '5px',
                        borderRadius: '10px',
                        border: '#1a1a1a solid black',
                        cursor: 'pointer',
                        display: 'inline-block',
                    }}
                    onFileDrop={(newFile) => this.handleAddNewFile(newFile)}>
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            flexWrap: 'wrap',
                            color: 'white',
                        }}>
                        <Icon type="upload" style={{ fontSize: '35px', color: '#a0a0a0' }} />
                        <div
                            style={{
                                width: '100%',
                                textAlign: 'center',
                                fontWeight: 'bold',
                                paddingTop: 10,
                                color: '#a0a0a0',
                            }}>
                            Thumbnail hinzufügen (optional)
                        </div>
                    </div>
                </AdDropzone>
            ) : (
                this.renderThumbnail(thumbnail, imageWidth, imageHeight)
            )}
        </div>
    );

    render() {
        const { image, video, imageToEdit } = this.state;
        const {
            width,
            height,
            imageRatio,
            enableZoomOut,
            backgroundColor,
            fileTypes,
            maxFileSize,
            optimalWidth,
            optimalHeight,
        } = this.props;
        const { x, y } = imageRatio;

        let imageWidth;
        let imageHeight;
        if (height) {
            imageWidth = Math.round((height / y) * x);
            imageHeight = height;
        } else {
            imageHeight = Math.round((width / x) * y);
            imageWidth = width;
        }
        return (
            <div>
                {this.state.renderImageEditorModal ? (
                    <ImageEditorModal
                        imageToEdit={imageToEdit}
                        imageRatio={imageRatio}
                        onSave={this.handleSaveImage}
                        onDelete={() => this.handleDeleteFile('image')}
                        enableZoomOut={enableZoomOut || false}
                        backgroundColor={backgroundColor}
                        optimalWidth={optimalWidth}
                        optimalHeight={optimalHeight}
                        onCancel={() => {
                            this.setState({ imageToEdit: undefined });
                        }}
                    />
                ) : null}

                {!image && !video ? (
                    <AdDropzone
                        style={{
                            backgroundColor: '#e6e6e6',
                            height: imageHeight,
                            width: imageWidth,
                            padding: '5px',
                            borderRadius: '10px',
                            border: '#1a1a1a solid black',
                            cursor: 'pointer',
                        }}
                        onFileDrop={(newFile) => this.handleAddNewFile(newFile)}
                        fileTypes={fileTypes.reduce(
                            (prev, type) => [...prev, ...fileTypeToMimeTypes[type]],
                            [],
                        )}
                        maxFileSize={maxFileSize}>
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                flexWrap: 'wrap',
                                color: 'white',
                            }}>
                            <Icon type="upload" style={{ fontSize: '35px', color: '#a0a0a0' }} />
                            <div
                                style={{
                                    width: '100%',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    paddingTop: 10,
                                }}
                            />
                        </div>
                    </AdDropzone>
                ) : video ? (
                    this.renderVideo(video, image, imageWidth, imageHeight)
                ) : (
                    this.renderImage(image, imageWidth, imageHeight)
                )}
            </div>
        );
    }
}

FileManager.PropTypes = {
    fileTypes: PropTypes.array,
    maxFileSize: PropTypes.number, // in byte
};

FileManager.defaultProps = {
    fileTypes: ['image', 'video'],
    maxFileSize: 200000000,
};

export default FileManager;
