import React, { Component } from 'react';
import { Button, Icon } from 'antd';
import ImageDropzone from '../ImageDropzone';
import { defaultImage } from '../../core/helper/defaultObjects';
import buttonWithTooltip from '../../decorators/buttonWithTooltip/index';
import buttonWithPopconfirm from '../../decorators/buttonWithPopconfirm/index';
import { ImageEditorModal } from './components';
import { generateID } from '../../core/helper/redux';
import { readFromFile } from '../../core/helper/image';

class ImageManager extends Component {
    constructor(props) {
        super(props);
        const { image } = this.props;
        this.state = {
            image,
            // imageToEdit: undefined,
            showEditorModal: false,
        };
        this.imageToEdit = undefined;
    }

    componentWillReceiveProps = (nextProps) => {
        const { image: nextImage } = nextProps;
        const { image: curImage } = this.props;
        if (nextImage !== curImage) {
            this.setState({ image: nextImage });
        }
    };

    handleAddNewImage = async (file) => {
        const raw = await readFromFile(file);

        this.handleEditImage({
            ...defaultImage,
            raw,
        });
    };

    handleEditImage = (image = this.state.image) => {
        const { payload: imagePayload } = image;
        this.imageToEdit = { ...image, payload: { ...defaultImage.payload, ...imagePayload } };
        this.setState({ showEditorModal: true });
    };

    handleCancelEdit = () => {
        this.setState({ showEditorModal: false }, () => (this.imageToEdit = undefined));
    };

    handleDeleteImage = () => {
        const { onChange } = this.props;
        const { showEditorModal } = this.state;
        if (showEditorModal) {
            this.handleCancelEdit();
        }
        this.setState({ image: undefined }, () => {
            if (onChange) {
                onChange(undefined);
            }
        });
    };

    handleSaveImage = (image) => {
        const { onChange } = this.props;
        const { _id } = image;
        const imageToSave = {
            ...image,
            payload: {
                ...image.payload,
            },
        };

        if (!_id) {
            imageToSave.tempID = generateID();
        }
        this.setState({ image: imageToSave }, () => onChange && onChange(imageToSave));
        this.handleCancelEdit();
    };

    renderImage = (image, imageWidth, imageHeight) => {
        const { edited, large, raw, _id, tempID } = image;
        const imageUrl = edited || large || raw;
        const imageID = tempID || _id;

        return (
            <div
                className="imgContainer"
                style={{ width: imageWidth, height: imageHeight, overflow: 'hidden' }}>
                <img className="image" src={imageUrl} alt={imageID} />
                <div className="imgToolbar top">
                    {buttonWithTooltip('Bild editieren')(
                        <Button
                            className="toolbarItem"
                            style={{ marginRight: '5px' }}
                            onClick={() => this.handleEditImage()}
                            shape="circle"
                            icon="edit"
                        />,
                    )}
                    {buttonWithPopconfirm({
                        title: 'Wollen Sie dieses Bild wirklich löschen?',
                        okText: 'Löschen',
                        onConfirm: this.handleDeleteImage,
                    })(
                        buttonWithTooltip('Bild löschen')(
                            <Button
                                className="toolbarItem"
                                style={{ marginRight: '5px' }}
                                shape="circle"
                                icon="delete"
                            />,
                        ),
                    )}
                </div>
            </div>
        );
    };

    render() {
        const { image, showEditorModal } = this.state;
        const {
            width: imageWidth,
            imageRatio,
            enableZoomOut,
            optimalWidth,
            optimalHeight,
        } = this.props;
        const { x, y } = imageRatio;
        const imageHeight = Math.round((imageWidth / x) * y);

        return (
            <div>
                <ImageEditorModal
                    visible={showEditorModal}
                    imageToEdit={this.imageToEdit}
                    imageRatio={imageRatio}
                    optimalWidth={optimalWidth}
                    optimalHeight={optimalHeight}
                    onSave={this.handleSaveImage}
                    onDelete={this.handleDeleteImage}
                    enableZoomOut={enableZoomOut || false}
                    onCancel={this.handleCancelEdit}
                />

                {(!image && (
                    <ImageDropzone
                        style={{
                            backgroundColor: '#e6e6e6',
                            height: imageHeight,
                            width: imageWidth,
                            padding: '5px',
                            borderRadius: '10px',
                            border: '#1a1a1a solid black',
                        }}
                        onImageDrop={(newImage) => this.handleAddNewImage(newImage)}>
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                flexWrap: 'wrap',
                                color: 'white',
                            }}>
                            <Icon type="picture" style={{ fontSize: '35px', color: '#a0a0a0' }} />
                            <div
                                style={{
                                    width: '100%',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    paddingTop: 10,
                                    color: '#a0a0a0',
                                    whiteSpace: 'pre-wrap',
                                }}>
                                {`Bild hinzufügen\n(max 20MB, ~${optimalWidth} x ${optimalHeight} px )`}
                            </div>
                        </div>
                    </ImageDropzone>
                )) ||
                    this.renderImage(image, imageWidth, imageHeight)}
            </div>
        );
    }
}
export default ImageManager;
