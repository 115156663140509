import React from 'react';
import { connect } from 'react-redux';
import { Alert, Button } from 'antd';

function PlanAlert({ message }) {
    return (
        <div style={{ marginBottom: 20 }}>
            <Alert
                message={<span>{message} Kontaktieren Sie Ihren Ansprechpartner.</span>}
                type="warning"
                showIcon
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    plan: state.company.plan,
});
export default connect(mapStateToProps)(PlanAlert);
