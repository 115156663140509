import React, { Component } from 'react';
import { Button, Icon } from 'antd';
import AdDropzone from '../AdDropzone';
import buttonWithTooltip from '../../decorators/buttonWithTooltip';
import buttonWithPopconfirm from '../../decorators/buttonWithPopconfirm';

class VideoDropzone extends Component {
    constructor(props) {
        super(props);
        const { files } = this.props;
        this.state = {
            video: files.video || null,
        };
    }

    componentWillReceiveProps = (nextProps) => {
        const { video: nextVideo } = nextProps.files;
        const { video: curVideo } = this.props;

        if (nextVideo !== curVideo) {
            this.setState({ video: nextVideo });
        }
    };

    handleAddNewFile = async (file) => {
        const { onChange } = this.props;

        this.setState(
            {
                video: file,
            },
            () => onChange(file),
        );
    };

    handleDeleteFile = () => {
        const { onChange } = this.props;

        this.setState(
            {
                video: null,
            },
            () => {
                if (onChange) {
                    onChange(null);
                }
            },
        );
    };

    renderVideo = () => (
        <div>
            <div
                className="imgContainer"
                style={{
                    width: this.props.width || 100,
                    height: 100,
                    overflow: 'hidden',
                    display: 'inline-block',
                    margin: 'auto 25px 25px auto',
                    float: 'left',
                }}>
                <div
                    style={{
                        height: 100,
                        width: this.props.width || 100,
                        backgroundColor: '#e6e6e6',
                        padding: '5px',
                        borderRadius: '10px',
                        border: '#1a1a1a solid black',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexFlow: 'column wrap',
                    }}>
                    <Icon type="video-camera" style={{ fontSize: '35px', color: '#f21850' }} />
                    <div
                        style={{
                            width: '100%',
                            textAlign: 'center',
                            fontWeight: 'bold',
                        }}
                    />
                </div>
                <div className="imgToolbar top">
                    {buttonWithPopconfirm({
                        title: 'Wollen Sie dieses Video wirklich löschen?',
                        okText: 'Löschen',
                        onConfirm: () => this.handleDeleteFile('video'),
                    })(
                        buttonWithTooltip('Video löschen')(
                            <Button
                                className="toolbarItem"
                                style={{ marginRight: '5px' }}
                                shape="circle"
                                icon="delete"
                            />,
                        ),
                    )}
                </div>
            </div>
        </div>
    );

    render() {
        const { video } = this.state;
        const { width = '100%' } = this.props;

        return (
            <div>
                {video ? (
                    this.renderVideo()
                ) : (
                    <AdDropzone
                        style={{
                            backgroundColor: '#e6e6e6',
                            height: '100%',
                            width,
                            padding: '5px',
                            borderRadius: '10px',
                            border: '#1a1a1a solid black',
                            cursor: 'pointer',
                        }}
                        onFileDrop={(newFile) => this.handleAddNewFile(newFile)}
                        fileTypes={['video/mpeg', 'video/mp4', 'video/quicktime']}
                        maxFileSize={200000000}>
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                flexWrap: 'wrap',
                                color: 'white',
                            }}>
                            <Icon
                                type="video-camera"
                                style={{ fontSize: '35px', color: '#a0a0a0' }}
                            />
                            <div
                                style={{
                                    width: '100%',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    paddingTop: 10,
                                    color: '#a0a0a0',
                                }}>
                                Video hinzufügen (optional, max 200MB)
                            </div>
                        </div>
                    </AdDropzone>
                )}
            </div>
        );
    }
}

export default VideoDropzone;
