import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Notification, Row, Col, Spin, Alert } from 'antd';
import update from 'immutability-helper';
import {
    AdvertList,
    AdvertContingent,
    AdvertPreview,
    Phone,
    AdvertSelectionModal,
} from '../../components';

class Adverts extends Component {
    state = {
        adverts: [],
        info: {},
        loading: true,
        error: false,
        selectionVisible: false,
    };

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        const { request } = this.context;

        const responses = await Promise.all([request('vposts/list'), request('vposts/info')]);

        if (responses[0].error || responses[1].error) {
            this.setState({
                loading: false,
                error: !!responses[0].error || !!responses[1].error,
            });
        } else {
            this.setState({
                loading: false,
                adverts: responses[0].body.sort((a, b) =>
                    a.visible === b.visible ? 0 : a.visible ? -1 : 1,
                ),
                info: responses[1].body,
            });
        }
    };

    reloadInfo = async () => {
        const { request } = this.context;

        const { body } = await request('vposts/info');

        if (body) {
            this.setState({
                info: body,
            });
        }
    };

    toggleAdvert = async (
        uuid,
        { visible, archived, targeting = {}, startDate = null, endOfTerm },
    ) => {
        const { request, role } = this.context;

        const { body } =
            role !== 'READONLY'
                ? await request('vposts/toggle', {
                      uuid,
                      visible,
                      archived,
                      targeting,
                      startDate,
                      endOfTerm,
                  })
                : { error: true };

        if (body) {
            const { adverts } = this.state;

            const advertIndex = adverts.findIndex((ad) => ad.uuid === uuid);

            this.setState({
                adverts: update(adverts, {
                    [advertIndex]: {
                        $merge: {
                            visible: body.visible,
                            archived: body.archived,
                            targeting: body.targeting,
                            endOfTerm: body.endOfTerm,
                        },
                    },
                }),
            });

            this.reloadInfo();
        } else {
            Notification.error({
                message: 'Aktualisierung fehlgeschlagen',
                description:
                    'Ihre Anzeige konnte leider nicht aktualisiert werden. Bitte kontaktieren Sie support@uninow.de.',
            });
        }
    };

    addAdvertClick = () => {
        const { match, history } = this.props;

        history.push(`${match.url}/editieren/`);
    };

    duplicateAdvert = async (uuid, callback) => {
        const { adverts } = this.state;
        const { request, role } = this.context;

        const advert = adverts.find((ad) => ad.uuid === uuid);

        const { body } =
            role !== 'READONLY'
                ? await request('vposts/create', {
                      data: {
                          ...advert.data,
                          title: `${advert.data.title} (Kopie)`,
                      },
                      label: advert.label ? `${advert.label} (Duplikat)` : undefined,
                      type: advert.type,
                      targeting: advert.targeting,
                  })
                : { error: true };

        if (body) {
            this.setState({
                adverts: [...adverts, body],
            });
        } else {
            Notification.error({
                message: 'Anfrage fehlgeschlagen',
                description:
                    'Ihre Anzeige konnte leider nicht dupliziert werden. Bitte kontaktieren Sie support@uninow.de.',
            });
        }

        callback();
    };

    removeAdvert = async (adId) => {
        const { request } = this.context;

        this.setState({
            adverts: this.state.adverts.filter(({ uuid }) => uuid !== adId),
        });

        await request('adverts/remove', { uuid: adId });

        this.reloadInfo();
    };

    onAdvertClick = (advert) => {
        this.setState({ selectedAd: advert });
    };

    toggleSelectionModal = (visible) => {
        this.setState({ selectionVisible: visible });
    };

    render() {
        const { adverts, info, error, loading, selectionVisible, selectedAd } = this.state;
        const { archived, permissions } = this.props;
        const preview =
            selectedAd ||
            (loading || adverts.length === 0
                ? null
                : adverts.find((ad) => ad.visible) || adverts.find((ad) => !ad.archived));

        const canAdvertisePosts = permissions.includes('ADVERTISE_POST');
        const canCreateCareerAdverts = permissions.includes('CREATE_CAREER_ADVERT');
        const canCreateMailAdverts = permissions.includes('CREATE_MAIL_ADVERT');

        return (
            <Row>
                {(canAdvertisePosts || canCreateCareerAdverts || canCreateMailAdverts) && (
                    <AdvertSelectionModal
                        visible={selectionVisible}
                        postAdvert={canAdvertisePosts}
                        careerAdvert={canCreateCareerAdverts}
                        mailAdvert={canCreateMailAdverts}
                        onCancel={() => this.toggleSelectionModal(false)}
                    />
                )}
                <Col xxl={16} xl={18} span={16}>
                    <Alert
                        message="Wir haben die Budgetsteuerung für Werbeanzeigen verbessert. Ab sofort können Sie Ihr Budget flexibel einsetzen für Kampagnen, die sich an alle Studierenden richten („Academic Targeting“), als auch für solche, die auf bestimmte Hochschulen („Campus Targeting“) oder Fachrichtungen („Curriculum Targeting“) fokussiert sind. Die Abrechnung erfolgt in „AdCoins“. Bei Fragen melden Sie sich gerne: uninow@jobware.de oder 05251 5401-140."
                        type="info"
                    />
                    <AdvertContingent
                        adCoins={info.adCoins}
                        error={error}
                        loading={loading}
                        onUpdate={this.reloadInfo}
                    />
                    {!archived && (
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <Button
                                type="primary"
                                onClick={
                                    canAdvertisePosts ||
                                    canCreateCareerAdverts ||
                                    canCreateMailAdverts
                                        ? () => this.toggleSelectionModal(true)
                                        : this.addAdvertClick
                                }>
                                + Werbeanzeige hinzufügen
                            </Button>
                        </div>
                    )}
                    <AdvertList
                        info={info}
                        adverts={adverts}
                        onToggle={this.toggleAdvert}
                        onDublicate={this.duplicateAdvert}
                        onRemove={this.removeAdvert}
                        onClick={this.onAdvertClick}
                        selected={preview ? preview._id : null}
                        archived={!!archived}
                        error={error}
                        loading={loading}
                        {...this.props}
                    />
                </Col>
                <Col offset={2} xxl={6} xl={4} span={6}>
                    {loading ? (
                        <Phone backgroundColor="#EDEFF4">
                            <div style={{ width: '100%', padding: 30, textAlign: 'center' }}>
                                <Spin />
                            </div>
                        </Phone>
                    ) : preview ? (
                        <AdvertPreview
                            data={preview.data}
                            type={preview.type}
                            hideSecondPage={preview?.data?.action?.link?.indexOf('uninow://') >= 0}
                        />
                    ) : (
                        <Phone backgroundColor="#EDEFF4">
                            <div style={{ width: '100%', padding: 30, textAlign: 'center' }}>
                                Es wurde keine Werbeanzeige gefunden
                            </div>
                        </Phone>
                    )}
                </Col>
            </Row>
        );
    }
}

Adverts.contextTypes = {
    request: PropTypes.func,
    role: PropTypes.string,
};

const mapStateToProps = ({ company }) => ({
    permissions: company.permissions || [],
});

export default connect(mapStateToProps)(Adverts);
