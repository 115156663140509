import React from 'react';
import { connect } from 'react-redux';
import { Alert, Button } from 'antd';

import PlanAlert from './PlanAlert';

export const PLAN_PERMISSION = {
    TESTIMONIALS: 'TESTIMONIALS',
    BENEFITS: 'BENEFITS',
    AWARDS: 'AWARDS',
    EVENTS: 'EVENTS',
};
function PlanFilter({ children, permission, plan }) {
    const hasPermission = plan?.permissions?.includes(permission);
    return hasPermission ? (
        children
    ) : (
        <div style={{}}>
            <PlanAlert message="Um diese Funktion nutzen zu können, ist ein anderes Paket notwendig." />
            <div
                style={{
                    filter: 'blur(5px)',
                    pointerEvents: 'none',
                    userSelect: 'none',
                }}>
                {children}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    plan: state.company.plan,
});
export default connect(mapStateToProps)(PlanFilter);
