import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Popover, Input, Message } from 'antd';

class LoginLinkButton extends PureComponent {
    state = {
        loading: false,
        link: null,
    };

    fetchLink = async () => {
        this.setState({ loading: true });

        const { member } = this.props;
        const { request } = this.context;

        const { body } = await request('recruiting/administration/loginlink', {
            member: member._id,
        });

        if (body) {
            this.setState({ link: body, loading: false });
        }
    };

    handleVisibleChange = (visible) => {
        if (visible === false) {
            this.setState({ link: null });
        }
    };

    render() {
        const { loading, link } = this.state;

        return (
            <Popover
                placement="topRight"
                trigger={['click']}
                visible={!loading && link !== null}
                onVisibleChange={this.handleVisibleChange}
                content={
                    <div style={{ textAlign: 'right' }}>
                        <Input readOnly value={link} />
                        <CopyToClipboard
                            text={link}
                            onCopy={() => Message.info('Link in die Zwischenablage kopiert')}>
                            <a className="primary-link">Link kopieren</a>
                        </CopyToClipboard>
                    </div>
                }
                title="Loginlink">
                <Button loading={loading} shape="circle" icon="unlock" onClick={this.fetchLink} />
            </Popover>
        );
    }
}

LoginLinkButton.contextTypes = {
    request: PropTypes.func.isRequired,
};

export default LoginLinkButton;
