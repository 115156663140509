import React from 'react';

const LinkListing = ({ links = [] }) => {
    const listing = links
        .sort((a, b) => parseInt(b.value) - parseInt(a.value))
        .map(({ label, value }) => (
            <tr key={label}>
                <td className="overflow-ellipsis" style={{ maxWidth: 550, paddingRight: 10 }}>
                    <a href={label}>{label}</a>
                </td>
                <td style={{ paddingLeft: 10, textAlign: 'right' }}>{value}</td>
            </tr>
        ));

    return (
        <table cellSpacing="5px">
            <thead className="color-secondary size-tiny">
                <th style={{ fontWeight: 400 }}>Verlinkung</th>
                <th style={{ fontWeight: 400 }}>Klicks</th>
            </thead>
            <tbody>{listing}</tbody>
        </table>
    );
};

export default LinkListing;
