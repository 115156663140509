import React, { Component } from 'react';
import {
    Icon,
    Button,
    Form,
    Input,
    Switch,
    Select,
    Modal,
    Divider,
    Row,
    Col,
    Tabs,
    Checkbox,
} from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { Prompt } from 'react-router';
import { defaultJob } from '../../core/helper/defaultObjects';
import labelWithTooltip from '../../decorators/labelWithTooltip';
import {
    FormLayout,
    AdminArea,
    AddressManager,
    MemberSelect,
    EndOfTermForm,
    Spinner,
} from '../../components';
import { FormRow, FormCol } from '../../components/styles';
import { addToArray, setAttributeValue } from '../../core/redux/actions';
import MarkdownLink from '../MarkdownHelp/MarkdownLink';
import Targeting from './Targeting';
import Sections from './Sections';
import urlPattern from '../../core/helper/urlPattern';
import Preview from '../../components/Preview/Preview';
import { JobDetail } from '../../components/Preview/pages';
import Updates from './Updates';
import utmPattern from '../../core/helper/utmPattern';

const FormItem = Form.Item;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;
const BEWERBUNG2GO_BASEURL = 'www.bewerbung2go.de/erstellen/external/un/';
const LEAVE_WARNING_TEXT =
    'Sind Sie sich sicher, dass Sie diese Seite verlassen möchten? Ungespeicherte Daten werden verworfen.';
class EditJob extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isDirty: false,
            dataFetching: true,
        };
        this.types = [
            'Abschlussarbeiten',
            'Festanstellung',
            'Festanstellung (Vollzeit)',
            'Festanstellung (Teilzeit)',
            'Duales Studium',
            'Freelancer',
            'Nebenjob',
            'Praktikum',
            'Trainee',
            'Werkstudententätigkeit',
            'Ausbildung',
            'Volontariat',
            'Studentische Hilfskraft',
        ].sort();
        this.departments = [
            'Bau & Architektur',
            'Bildung & Soziales',
            'Design & Gestaltung',
            'Einkauf',
            'Elektronik & Technik',
            'Finanzen & Controlling',
            'Forschung & Entwicklung',
            'IT',
            'Logistik',
            'Management & Beratung',
            'Marketing & Kommunikation',
            'Medizin, Pflege & Gesundheit',
            'Personal',
            'Produktion',
            'Qualität',
            'Recht',
            'Service & Dienstleistung',
            'Vertrieb',
        ].sort();
    }

    initialLoad() {
        const { joblist, location = {}, match, duplicate, addToArray } = this.props;

        if (match.params.uuid && joblist.length === 0) {
            // joblist is empty but we query for job, invalid case, wait for reload
            return false;
        }
        // find job
        let job = joblist.find((el) => el.uuid === match.params.uuid);
        let isEdit = true;
        let initialDefaults = null;
        const hasPriorityLimit =
            joblist.filter((job) => !job.archived && job.priority >= 2).length >= 3;
        let isVisibleInitially = false;
        if (duplicate) {
            // create copy of job
            job = { ...job };
            job.uuid = undefined;
            if (job.priority >= 2 && hasPriorityLimit) {
                job.priority -= 1;
            }
            addToArray({ ...job });
            isEdit = false;
        } else if (!match.params.uuid || !job) {
            // no job found, must be new job
            const { state = {} } = location;
            const { defaults } = state;
            initialDefaults = defaults;
            job = defaults ? { ...defaults } : { ...defaultJob };
            addToArray(job);
            isEdit = false;
        } else {
            isVisibleInitially = job.visible;
        }
        this.setState({
            initialDefaults,
            isEdit,
            job,
            isVisibleInitially,
            initialJob: job,
            dataFetching: false,
        });
    }

    closeTabHandler = (e) => {
        e.preventDefault();
        e.returnValue = LEAVE_WARNING_TEXT;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.isDirty()) {
            window.addEventListener('beforeunload', this.closeTabHandler);
        } else {
            window.removeEventListener('beforeunload', this.closeTabHandler);
        }
        if (!prevProps.jobsLoading) {
            return;
        }
        this.initialLoad();
    }

    componentDidMount() {
        if (!this.props.jobsLoading) {
            this.initialLoad();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.closeTabHandler);
    }

    isDirty = () => {
        return !_.isEqual(this.state?.job, this.state?.initialJob);
    };

    getJob = () => {
        const { job } = this.state;
        return job;
    };

    addAddress = (address) => {
        if (address) {
            const job = this.getJob();
            const { setFieldsValue } = this.props.form;

            const updatedJob = {
                ...job,
                addresses: update(job.addresses ?? [], { $push: [address] }),
            };
            setFieldsValue({
                addresses: updatedJob.addresses,
            });
            this.setState({ job: updatedJob });
        }
    };

    addAddresses = (addresses = []) => {
        if (addresses.length > 0) {
            const job = this.getJob();
            const { setFieldsValue } = this.props.form;

            const updatedJob = {
                ...job,
                addresses: update(job.addresses, { $push: addresses }),
            };
            setFieldsValue({
                addresses: updatedJob.addresses,
            });
            this.setState({ job });
        }
    };

    replaceAddress = (index, address) => {
        const job = this.getJob();

        const updatedJob = {
            ...job,
            addresses: update(job.addresses, { [index]: { $set: address } }),
        };

        this.setState({ job: updatedJob });
    };

    removeAddress = (idOrLabel) => {
        const job = this.getJob();
        const { setFieldsValue } = this.props.form;

        const addressIndex = job.addresses.findIndex(
            ({ _id, label }) => _id === idOrLabel || label === idOrLabel,
        );

        if (addressIndex !== -1) {
            const updatedJob = {
                ...job,
                addresses: update(job.addresses, { $splice: [[addressIndex, 1]] }),
            };
            setFieldsValue({
                addresses: updatedJob.addresses,
            });
            this.setState({ job: updatedJob });
        }
    };

    removeAddresses = (idOrLabels = []) => {
        const job = this.getJob();
        const { setFieldsValue } = this.props.form;

        let nextAddresses = job.addresses;

        idOrLabels.forEach((idOrLabel) => {
            const addressIndex = nextAddresses.findIndex(
                ({ _id, label }) => _id === idOrLabel || label === idOrLabel,
            );

            if (addressIndex !== -1) {
                nextAddresses = update(nextAddresses, { $splice: [[addressIndex, 1]] });
            }
        });

        const updatedJob = {
            ...job,
            addresses: nextAddresses,
        };
        setFieldsValue({
            addresses: updatedJob.addresses,
        });
        this.setState({ job: updatedJob });
    };

    onDataChange = (attributeName, value, rawValue) => {
        const job = this.getJob();
        let updatedJob;

        switch (attributeName) {
            case 'address':
                const address = typeof value === 'string' ? JSON.parse(value) : value;
                updatedJob = { ...job, location: address.city, addresses: [address] };
                break;

            case 'endOfTerm':
                updatedJob = {
                    ...job,
                    [attributeName]: value ? value.format() : value,
                };
                break;

            default:
                updatedJob = { ...job, [attributeName]: value };
                break;
        }

        if (attributeName === 'visible' && value) {
            this.props.form.validateFieldsAndScroll((err, values) => {
                this.handleValidationError(err, () => this.setState({ job: updatedJob }), true);
            });
        } else {
            this.setState({ job: updatedJob });
        }
    };

    onContactSelect = (value) => {
        const { setFieldsValue } = this.props.form;
        const { job } = this.state;
        let jobUpdate = {};
        switch (value) {
            case 'mail':
                jobUpdate = { link: null, mailing: ['all'] };
                break;
            case 'bewerbung2go':
                {
                    const link = `https://${BEWERBUNG2GO_BASEURL}${job.uuid}`;
                    jobUpdate = { link, mailing: ['onlyme'] };
                }
                break;
            case 'link':
            default:
                jobUpdate = { link: 'https://', mailing: [] };
                break;
        }
        this.setState({ job: { ...job, ...jobUpdate } });
        setFieldsValue(jobUpdate);
    };

    handleCancel = () => {
        const { isEdit } = this.state;
        const { joblist } = this.props;
        if (!isEdit) {
            joblist.pop();
            this.props.setAttributeValue(joblist);
        }

        this.props.history.push('/stellenanzeigen');
    };

    save = async (job) => {
        let wasSuccessful = false;
        const { isEdit, isVisibleInitially } = this.state;

        const { history } = this.props;
        const { createJob, updateJob, setMessage } = this.context;
        const { uuid, title, visible } = job;

        this.setState({ isLoading: true });

        if (!uuid || !isEdit) {
            wasSuccessful = await createJob(job, this.props.joblist.length - 1);
        } else {
            const indexOfJob = this.props.joblist.findIndex(
                (joblistJob) => joblistJob.uuid === uuid,
            );
            if (!job.visible && isVisibleInitially) {
                job.priority = 0;
            }
            wasSuccessful = await updateJob(job, indexOfJob);
        }
        if (wasSuccessful) {
            setMessage(
                <span>
                    Die Stellenanzeige <b>{title}</b> wurde erfolgreich gespeichert
                    {visible && visible !== isVisibleInitially && ' und veröffentlicht'}.
                </span>,
                'success',
            );
            this.setState({ initialJob: job }, () => history.push('/stellenanzeigen'));
        } else {
            setMessage(
                <span>
                    Beim speichern der Stellenanzeige <b>{title}</b> ist ein Fehler aufgetreten.
                    Bitte versuchen Sie es erneut
                </span>,
                'error',
            );
            this.setState({ isLoading: false });
        }
    };

    handleValidationError = (err, callback, showMessage) => {
        if (!err) {
            callback();
            return;
        }
        const { role } = this.context;
        const isAdmin = ['SUPERADMIN', 'ADMIN', 'CUSTOMER_SUPPORT'].includes(role);
        if (isAdmin && err?.summary && Object.keys(err).length === 1) {
            Modal.confirm({
                title: 'Die Beschreibung der Stellenanzeige ist zu lang',
                content: 'Soll die Anzeige dennoch gespeichert werden?',
                onOk() {
                    callback();
                },
            });
            return;
        }
        if (showMessage) {
            Modal.warning({
                title: 'Warnung',
                content:
                    'Bitte füllen Sie sämtliche Felder korrekt aus, bevor Sie die Stellenanzeige speichern oder sichtbar schalten.',
            });
        }
    };

    handleSave = () => {
        const job = this.getJob();
        const { form } = this.props;
        const { validateFieldsAndScroll } = form;

        if (job.visible) {
            validateFieldsAndScroll((err, values) => {
                this.handleValidationError(
                    err,
                    () => {
                        this.save(job);
                    },
                    true,
                );
            });
        } else {
            validateFieldsAndScroll(['title', 'summary'], (err, values) => {
                this.handleValidationError(
                    err,
                    () => {
                        this.save(job);
                    },
                    false,
                );
            });
        }
    };

    validateAddresses = (rule, value, callback) => {
        const { addresses = [] } = this.getJob();
        callback(addresses.length === 0 ? rule.message : undefined);
    };

    handleSettingsUpdate = ({ endOfTerm, mailing }) => {
        if (endOfTerm !== undefined) {
            this.onDataChange('endOfTerm', endOfTerm);
        }

        if (mailing !== undefined) {
            this.onDataChange('notificationRecipients', mailing);
        }
    };

    render() {
        const { role } = this.context;
        const { form, jobContingent, numOfActiveJobs, jobsLoading } = this.props;
        if (jobsLoading || this.state.dataFetching) {
            return <Spinner />;
        }
        const { getFieldDecorator } = form;
        const { isLoading, isDirty, initialDefaults } = this.state;

        const job = this.getJob() || (initialDefaults ? { ...initialDefaults } : { ...defaultJob });
        const {
            title,
            visible,
            editable,
            type,
            summary,
            link,
            mailing,
            tags = [],
            addresses = [],
            department: jobDepartment,
            endOfTerm,
            notificationRecipients,
            remote,
            remoteOptional,
            utm,
        } = job;

        let visibleChangeDisabled = !visible && numOfActiveJobs >= jobContingent;
        if (jobContingent === -1) {
            visibleChangeDisabled = false;
        }

        const isAdmin = ['SUPERADMIN', 'ADMIN', 'CUSTOMER_SUPPORT'].includes(role);
        let contactType = 'mail';
        if (job.link !== null && typeof job.link !== 'undefined') {
            contactType = job.link.includes(BEWERBUNG2GO_BASEURL) ? 'bewerbung2go' : 'link';
        }
        return (
            <FormLayout
                formIsDirty={isDirty}
                buttons={[
                    <Button
                        key="save"
                        type="primary"
                        icon="save"
                        htmlType="submit"
                        loading={isLoading}
                        disabled={editable === false && role.toUpperCase() !== 'SUPERADMIN'}
                        onClick={this.handleSave}>
                        Speichern
                    </Button>,
                    <Button key="cancel" disabled={isLoading} onClick={() => this.handleCancel()}>
                        Abbrechen
                    </Button>,
                ]}>
                <Prompt
                    message={() => {
                        if (this.isDirty()) {
                            return LEAVE_WARNING_TEXT;
                        }
                    }}
                />
                <Col xxl={16} xl={18} span={16}>
                    <Form onSubmit={this.handleSubmit}>
                        <h2 style={{ paddingBottom: 20 }}>UniNow Stellenanzeige (Vorschau)</h2>
                        <AdminArea>
                            <FormItem label="Tags:">
                                {getFieldDecorator('tags', {
                                    initialValue: tags,
                                    onChange: (tags) => this.onDataChange('tags', tags),
                                })(
                                    <Select
                                        mode="tags"
                                        style={{ width: '100%' }}
                                        placeholder="Tags">
                                        <Select.Option key="IDW">IDW</Select.Option>
                                    </Select>,
                                )}
                            </FormItem>
                            <Updates jobID={job.uuid} />
                        </AdminArea>

                        <Tabs
                            defaultActiveKey="1"
                            animated={false}
                            type="card"
                            style={{ overflow: 'visible' }}>
                            <TabPane tab="Stellenanzeige" key="1" style={{ paddingTop: 10 }}>
                                <Divider orientation="left" style={{ marginTop: 0 }}>
                                    Allgemeine Angaben
                                </Divider>

                                <FormRow>
                                    <FormCol width="60%">
                                        <FormItem label="Bezeichnung:">
                                            {getFieldDecorator('title', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message:
                                                            'Bitte geben Sie Ihrer Stellenanzeige eine Bezeichnung.',
                                                    },
                                                ],
                                                onChange: (e) =>
                                                    this.onDataChange('title', e.target.value),
                                                initialValue: title,
                                            })(
                                                <Input placeholder="Geben Sie Ihrer Stellenanzeige eine Bezeichnung..." />,
                                            )}
                                        </FormItem>
                                    </FormCol>

                                    <FormCol width="20%">
                                        <span
                                            style={{
                                                display: 'inline-block',
                                                width: '20%',
                                                textAlign: 'center',
                                            }}
                                        />
                                    </FormCol>

                                    <FormCol width="20%">
                                        <FormItem
                                            colon={false}
                                            label={labelWithTooltip(
                                                'Sichtbarkeit',
                                                visibleChangeDisabled ? (
                                                    <span>
                                                        Sie haben Ihr Kontingent an aktiven
                                                        Stellenanzeigen erreicht. Um weitere
                                                        Stellenanzeigen hinzufügen zu können,
                                                        archivieren oder deaktivieren Sie eine
                                                        Stellenanzeige oder erhöhen Sie Ihr
                                                        Kontingent.
                                                    </span>
                                                ) : (
                                                    <span>
                                                        Schalten Sie Ihre Stellenanzeige sichtbar (
                                                        <Icon type="eye" />) oder unsichtbar (
                                                        <Icon type="eye-o" />
                                                        ). Unsichtbare Stellenanzeigen werden im
                                                        Karriereportal nicht angezeigt.
                                                    </span>
                                                ),
                                            )}>
                                            <Switch
                                                checkedChildren={<Icon type="eye" />}
                                                unCheckedChildren={<Icon type="eye-o" />}
                                                checked={visible}
                                                disabled={visibleChangeDisabled}
                                                onChange={(value) => {
                                                    this.onDataChange('visible', value);
                                                }}
                                            />
                                        </FormItem>
                                    </FormCol>
                                </FormRow>

                                <FormItem label="Typ:">
                                    {getFieldDecorator('type', {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    'Bitte wählen Sie den Typ ihrer Stellenanzeige',
                                            },
                                        ],
                                        initialValue: type || undefined,
                                        onChange: (value) => this.onDataChange('type', value),
                                    })(
                                        <Select placeholder="Wählen Sie einen Typen...">
                                            {this.types.map((value) => (
                                                <Option value={value} key={value}>
                                                    {value}
                                                </Option>
                                            ))}
                                        </Select>,
                                    )}
                                </FormItem>

                                <FormItem
                                    colon={false}
                                    label={labelWithTooltip(
                                        'Tätigkeitsbereich',
                                        'Auf welchen Tätigkeitsbereich bezieht sich die ausgeschriebene Stellenanzeige?',
                                    )}>
                                    {getFieldDecorator('department', {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    'Bitte wählen Sie den entsprechenden Tätigkeitsbereich der Stellenanzeige aus.',
                                            },
                                        ],
                                        onChange: (updatedStudies) =>
                                            this.onDataChange('department', updatedStudies),
                                        initialValue: jobDepartment,
                                    })(
                                        <Select
                                            notFoundContent="Kein Treffer"
                                            style={{ width: '100%' }}
                                            filterOption={(value, option) =>
                                                option.key
                                                    .toLowerCase()
                                                    .indexOf(value.toLowerCase()) !== -1
                                            }
                                            placeholder="Geben Sie einen Tätigkeitsbereich an...">
                                            {this.departments.map((department) => (
                                                <Option key={department}>{department}</Option>
                                            ))}
                                        </Select>,
                                    )}
                                </FormItem>

                                <FormItem label="Erfüllungsorte">
                                    {getFieldDecorator('addresses', {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    'Bitte wählen Sie mindestens einen Standort',
                                                validator: this.validateAddresses,
                                            },
                                        ],
                                    })(
                                        <AddressManager
                                            selectedAddresses={addresses}
                                            addressOptions={this.props.addresses}
                                            onRemove={this.removeAddress}
                                            onSelect={this.addAddress}
                                            onMultiSelect={this.addAddresses}
                                            onEdit={this.replaceAddress}
                                            onDeselect={this.removeAddress}
                                            onMultiDeselect={this.removeAddresses}
                                        />,
                                    )}
                                </FormItem>
                                <FormItem
                                    colon={false}
                                    label={labelWithTooltip(
                                        `Jobbeschreibung (${
                                            summary ? summary.length : 0
                                        }/1000 Zeichen)`,
                                        'Geben Sie den Studierenden und Absolventen einen kurzen Überblick. Wenn Sie mehr Details hinzufügen wollen, nutzen sie bitte das Feld "Abschnitte"',
                                    )}>
                                    {getFieldDecorator('summary', {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    'Bitte geben Sie zu Ihrer Stellenanzeige eine Beschreibung an.',
                                            },
                                            ...(!isAdmin
                                                ? [
                                                      {
                                                          max: 1000,
                                                          message:
                                                              'Bitte passen Sie die Beschreibung der Stellenanzeige auf maximal 1000 Zeichen an.',
                                                      },
                                                  ]
                                                : []),
                                        ],
                                        onChange: (e) =>
                                            this.onDataChange('summary', e.target.value),
                                        initialValue: summary,
                                    })(
                                        <TextArea
                                            placeholder="Beschreiben Sie Ihre Stellenanzeige..."
                                            autoSize={{ minRows: 4 }}
                                            maxLength={!isAdmin ? 1000 : undefined}
                                        />,
                                    )}
                                    <MarkdownLink />
                                </FormItem>
                                <FormItem
                                    colon={false}
                                    label={labelWithTooltip(
                                        'Kontakt',
                                        'Wollen Sie die Kontaktdaten der Interessenten per Mail erhalten oder soll eine Weiterleitung zu einer benutzerdefinierten Seite erfolgen?',
                                    )}>
                                    <Row gutter={20}>
                                        <Col xs={12} sm={9} md={7} lg={6} xl={4}>
                                            <Select
                                                onSelect={this.onContactSelect}
                                                value={contactType}>
                                                <Option value="mail">Mail</Option>
                                                <Option value="link">Verlinkung</Option>
                                                {process.env.REACT_APP_SHOW_B2GO && (
                                                    <Option value="bewerbung2go">
                                                        Bewerbung2go
                                                    </Option>
                                                )}
                                            </Select>
                                        </Col>
                                        <Col xs={12} sm={15} md={17} lg={18} xl={20}>
                                            {(contactType === 'link' ||
                                                contactType === 'bewerbung2go') &&
                                                getFieldDecorator('link', {
                                                    rules: [
                                                        {
                                                            pattern: urlPattern,
                                                            message:
                                                                'Bitte geben Sie eine gültige Adresse an (z.B. https://example.com)',
                                                            required: true,
                                                        },
                                                    ],
                                                    onChange: (e) =>
                                                        this.onDataChange('link', e.target.value),
                                                    initialValue: link,
                                                })(
                                                    <Input
                                                        placeholder="Wohin soll die Stellenanzeige verlinken?"
                                                        disabled={contactType === 'bewerbung2go'}
                                                    />,
                                                )}
                                            {(contactType === 'mail' ||
                                                contactType === 'bewerbung2go') &&
                                                getFieldDecorator('mailing', {
                                                    initialValue: mailing,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message:
                                                                'Wählen Sie bitte mindestens eine Kontaktperson aus.',
                                                        },
                                                    ],
                                                })(
                                                    <MemberSelect
                                                        initialMembers={mailing}
                                                        onChange={(mails) =>
                                                            this.onDataChange('mailing', mails)
                                                        }
                                                        limitToOne={contactType === 'bewerbung2go'}
                                                    />,
                                                )}
                                            {/* <FormItem> */}
                                            {/*    {(contactType === 'link' || */}
                                            {/*        contactType === 'bewerbung2go') && */}
                                            {/*        getFieldDecorator('utm', { */}
                                            {/*            rules: [ */}
                                            {/*                { */}
                                            {/*                    pattern: utmPattern, */}
                                            {/*                    message: */}
                                            {/*                        'Bitte geben Sie gültige UTM-Parameter an', */}
                                            {/*                    required: false, */}
                                            {/*                }, */}
                                            {/*            ], */}
                                            {/*            onChange: (e) => */}
                                            {/*                this.onDataChange( */}
                                            {/*                    'utm', */}
                                            {/*                    e.target.value, */}
                                            {/*                ), */}
                                            {/*            initialValue: utm, */}
                                            {/*        })( */}
                                            {/*            <Input */}
                                            {/*                placeholder="UTM-Parameter (optional)" */}
                                            {/*                disabled={ */}
                                            {/*                    contactType === 'bewerbung2go' */}
                                            {/*                } */}
                                            {/*            />, */}
                                            {/*        )} */}
                                            {/* </FormItem> */}
                                        </Col>
                                    </Row>
                                </FormItem>
                                <Sections form={form} job={job} onDataChange={this.onDataChange} />
                                <FormItem
                                    colon={false}
                                    label={labelWithTooltip(
                                        'Remote-Anteil',
                                        'Geben Sie an, wie hoch der Anteil dieser Stelle ist, der Remote ausgeführt werden kann.',
                                    )}>
                                    {getFieldDecorator('remote', {
                                        rules: [
                                            {
                                                required: false,
                                                message:
                                                    'Bitte geben Sie zu Ihrer Stellenanzeige eine Beschreibung an.',
                                            },
                                        ],
                                        onChange: (e) =>
                                            this.onDataChange('remote', e.target.value),
                                        initialValue: remote,
                                    })(
                                        <Input
                                            type="number"
                                            placeholder="Kein Remote-Anteil"
                                            style={{ width: 200 }}
                                            addonAfter="%"
                                            min={0}
                                            max={100}
                                        />,
                                    )}
                                    {getFieldDecorator('remoteOptional', {
                                        valuePropName: 'checked',
                                        onChange: (e) =>
                                            this.onDataChange('remoteOptional', e.target.checked),
                                        initialValue: remoteOptional,
                                    })(
                                        <Checkbox
                                            style={{ marginLeft: 20 }}
                                            disabled={!(remote > 0)}>
                                            Optional
                                        </Checkbox>,
                                    )}
                                </FormItem>
                            </TabPane>

                            <TabPane tab="Targeting" key="2" style={{ paddingTop: 10 }}>
                                <Targeting
                                    onDataChange={this.onDataChange}
                                    form={form}
                                    job={job}
                                    history={this.props.history}
                                />
                            </TabPane>

                            <TabPane
                                tab={<Icon type="setting" style={{ marginRight: 0 }} />}
                                key="3"
                                style={{ paddingTop: 10 }}>
                                <EndOfTermForm
                                    endOfTerm={endOfTerm}
                                    mailing={notificationRecipients || mailing}
                                    onChange={this.handleSettingsUpdate}
                                    productName="Stellenanzeige"
                                />
                            </TabPane>
                        </Tabs>
                    </Form>
                </Col>
                <Col
                    style={{
                        height: '100%',
                    }}
                    offset={2}
                    xxl={6}
                    xl={4}
                    span={6}>
                    <Preview>
                        <JobDetail job={job} />
                    </Preview>
                </Col>
            </FormLayout>
        );
    }
}

EditJob.contextTypes = {
    createJob: PropTypes.func.isRequired,
    updateJob: PropTypes.func.isRequired,
    setMessage: PropTypes.func.isRequired,
    username: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    companyIsVisible: state.company.profile.visible,
    joblist: state.jobs.joblist,
    addresses: state.company.profile.addresses,
    members: state.memberList.list,
    jobsLoading: state.jobs.jobsLoading,
    jobContingent: state.company.plan.numJobs ?? 0,

    numOfActiveJobs: state.jobs.joblist.reduce(
        (total, job) => total + (!job.archived && job.visible ? 1 : 0),
        0,
    ),
});

const mapDispatchToProps = (dispatch) => ({
    addToArray: (element) => dispatch(addToArray('joblist', element, 'jobs')),
    setAttributeValue: (value) => dispatch(setAttributeValue('joblist', value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(EditJob));
