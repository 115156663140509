import React, { useState } from 'react';
import { Button, Checkbox, Form, InputNumber, Modal } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setAttributeValue as reduxSetAttributeValue } from '../../core/redux/actions';

const FormItem = Form.Item;
function PlanAdjustModal({ form, originalPlan, setAttributeValue }) {
    const { getFieldDecorator } = form;
    const [visible, setVisible] = useState(false);
    const hideModal = () => {
        setVisible(false);
    };
    const formatNumber = (value) => (value === null || value === '' ? undefined : value);
    return (
        <>
            <Button onClick={() => setVisible(true)}>Paket Anpassen</Button>
            <Modal
                visible={visible}
                onOk={hideModal}
                onCancel={hideModal}
                title="Paket Anpassen"
                footer={
                    <Button onClick={hideModal} type="primary">
                        Ok
                    </Button>
                }>
                <FormItem label="Anzahl der Standorte">
                    {getFieldDecorator('planOverridesNumLocations', {
                        onChange: (value) =>
                            setAttributeValue(`planOverrides.numLocations`, formatNumber(value)),
                    })(
                        <InputNumber
                            placeholder={originalPlan.numLocations}
                            min={originalPlan.numLocations}
                            parser={(value) => value?.replaceAll(/[^0-9]/g, '')}
                        />,
                    )}
                </FormItem>
                <FormItem label="Anzahl der Galeriebilder">
                    {getFieldDecorator('planOverridesNumImages', {
                        onChange: (value) =>
                            setAttributeValue(`planOverrides.numImages`, formatNumber(value)),
                    })(
                        <InputNumber
                            placeholder={originalPlan.numImages}
                            min={originalPlan.numImages}
                            parser={(value) => value?.replaceAll(/[^0-9]/g, '')}
                        />,
                    )}
                </FormItem>
                <FormItem label="Anzahl der Jobs">
                    {getFieldDecorator('planOverridesNumJobs', {
                        onChange: (value) =>
                            setAttributeValue(`planOverrides.numJobs`, formatNumber(value)),
                    })(
                        <InputNumber
                            placeholder={originalPlan.numJobs}
                            min={originalPlan.numJobs}
                            parser={(value) => value?.replaceAll(/[^0-9]/g, '')}
                        />,
                    )}
                </FormItem>
                <FormItem label="Berechtigungen">
                    {getFieldDecorator('planOverridesPermissions', {
                        onChange: (value) =>
                            setAttributeValue(`planOverrides.permissions`, value || []),
                    })(
                        <Checkbox.Group
                            options={[
                                {
                                    value: 'TESTIMONIALS',
                                    label: 'Insights',
                                },
                                { value: 'BENEFITS', label: 'Benefits' },
                                { value: 'AWARDS', label: 'Auszeichnungen' },
                                { value: 'EVENTS', label: 'Events' },
                            ].map((option) => ({
                                ...option,
                                disabled: originalPlan.permissions.includes(option.value),
                            }))}
                        />,
                    )}
                </FormItem>
                <Button
                    onClick={() => {
                        setAttributeValue('planOverrides', {});
                    }}>
                    Zurücksetzen
                </Button>
            </Modal>
        </>
    );
}

PlanAdjustModal.propTypes = {
    form: PropTypes.shape({ getFieldDecorator: PropTypes.func.isRequired }).isRequired,
    plan: PropTypes.shape({
        numJobs: PropTypes.number,
        numImages: PropTypes.number,
        numLocations: PropTypes.number,
    }).isRequired,
    setAttributeValue: PropTypes.func.isRequired,
    originalPlan: PropTypes.shape({
        numJobs: PropTypes.number,
        numImages: PropTypes.number,
        numLocations: PropTypes.number,
    }).isRequired,
};

const mapStateToProps = (state) => ({
    planOverrides: state.company.planOverrides,
    plan: state.company.plan,
    originalPlan: state.company.originalPlan,
});

const mapDispatchToProps = (dispatch) => ({
    setAttributeValue: (attributeName, value) =>
        dispatch(reduxSetAttributeValue(attributeName, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanAdjustModal);
