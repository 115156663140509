import React, { useState, useMemo, useCallback } from 'react';
import moment from 'moment';

import { Timeline, Icon, Empty } from 'antd';

const actionPropMap = {
    CREATED: {
        icon: 'plus',
        color: 'green',
        text: 'Erstellt',
    },
    ENABLED: {
        icon: 'eye',
        color: 'green',
        text: 'Aktiviert',
    },
    DISABLED: {
        icon: 'eye-invisible',
        color: 'red',
        text: 'Deaktiviert',
    },
    ARCHIVED: {
        icon: 'folder-add',
        color: 'red',
        text: 'Archiviert',
    },
    RESTORED: {
        icon: 'rollback',
        color: 'green',
        text: 'Wiederhergestellt',
    },
    REMOVED: {
        icon: 'delete',
        color: 'red',
        text: 'Gelöscht',
    },
    UPDATED: {
        icon: 'edit',
        color: 'gray',
        text: 'Aktualisiert',
    },
};

const formatActivities = (activities) => {
    return activities.map((activity) => ({
        ...activity,
        ...(actionPropMap[activity.action] || actionPropMap.UPDATED),
    }));
};

const ActivityTimeline = ({ loading, activities }) => {
    const [limit, setLimit] = useState(5);

    const items = useMemo(() => formatActivities(activities), [activities]);

    const increaseLimit = useCallback(() => {
        setLimit((currentLimit) => currentLimit + 5);
    }, []);

    const renderItem = useCallback(
        ({ id, icon, color, text, createdAt, username, changes }) => (
            <Timeline.Item
                key={id}
                dot={<Icon type={icon} style={{ fontSize: '14px' }} />}
                color={color}>
                <div>
                    <b>{text}</b>
                    {moment(createdAt).format(' [am] DD.MM.YYYY [um] HH:mm [Uhr]')}
                </div>
                <div className="color-secondary" style={{ fontSize: '0.8rem' }}>
                    {username ? `von ${username}` : <i>von gelöschtem/r Nutzer/in</i>}
                </div>
                <ul style={{ listStyleType: 'disc', paddingLeft: '10px', fontSize: '0.9rem' }}>
                    {changes &&
                        Object.keys(changes).map((key) => <li key={key}>{changes[key]}</li>)}
                </ul>
            </Timeline.Item>
        ),
        [],
    );

    if (loading) {
        return <Timeline pending="Aktivitäten werden geladen..." />;
    }

    if (items.length === 0) {
        return <Empty description="Keine Aktivität" />;
    }

    return (
        <Timeline
            pending={
                limit < items.length ? (
                    <span className="primary-clickable" onClick={increaseLimit}>
                        mehr anzeigen
                    </span>
                ) : null
            }
            pendingDot={<Icon type="down" style={{ fontSize: '14px' }} />}>
            {items.slice(0, limit).map(renderItem)}
        </Timeline>
    );
};

export default ActivityTimeline;
