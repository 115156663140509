import { Modal } from 'antd';
import React from 'react';
// import { setHasSeenDisclaimer as setHasSeenDisclaimerAction } from '../../../core/redux/actions/user';
import { useDispatch, useSelector } from 'react-redux';

export default function useFeedDisclaimer() {
    const dispatch = useDispatch();
    const hasSeenDisclaimer = useSelector(({ user }) => user.hasSeenDisclaimer);
    // const setHasSeenDisclaimer = () => dispatch(setHasSeenDisclaimerAction(true));
    const showDisclaimer = () => {
        if (hasSeenDisclaimer) {
            Modal.info({
                width: 600,
                title: 'Liebe Nutzer*innen des UniNow-Newsfeeds,',
                content: (
                    <div>
                        wir möchten Sie darauf hinweisen, dass beim Posten im UniNow-Newsfeed eine
                        gewisse Netiquette zu beachten ist. Wir legen Wert auf respektvollen Umgang
                        miteinander und möchten sichergehen, dass sich alle Nutzer*innen des
                        UniNow-Newsfeeds wohlfühlen. <br />
                        <br />
                        Daraus ergeben sich diese allgemeinen Verhaltensregeln:
                        <br />
                        <br />
                        <ul
                            style={{
                                'list-style-type': 'circle',
                                marginLeft: '15px',
                            }}>
                            <li>keine externen Stellenanzeigen</li>
                            <li>keine sexuellen Inhalte</li>
                            <li>kein respektloses Verhalten </li>
                            <li>keine persönlichen Angriffe oder Verleumdungen</li>
                            <li>keine Hassrede oder Mobbing </li>
                            <li>kein Spam </li>
                            <li>kein Teilen persönlicher Daten (Dritter)</li>
                            <li>
                                keine sexistischen, rassistischen und diskriminierenden Beiträge
                            </li>
                        </ul>
                        Wenn es zu Verstößen gegen diese Spielregeln kommt, behalten wir uns vor,
                        den Account zu sperren oder entsprechende Posts zu löschen.
                    </div>
                ),
                onOk() {
                    // setHasSeenDisclaimer();
                },
            });
        }
    };
    return { showDisclaimer };
}
