import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { Link } from 'react-router-dom';
import { Row, Col, Icon } from 'antd';

import UNIcon from '../../UNIcon';

function buildEventTime(dateString) {
    const date = moment.utc(dateString);
    const time = date.format('HH:mm');

    return `${date.format('dd[,] DD. MMM YY[,]')} ${time === '00:00' ? 'ganztätig' : time}`;
}

class Events extends Component {
    renderEvent = (ev) => {
        const start = (ev.start ? buildEventTime(ev.start) : ev.date) || '';
        const city = ev.location ? ev.location.city : null;

        return (
            <Row
                key={ev._id || ev.tempID}
                style={{ padding: '4% 0%' }}
                type="flex"
                align="middle"
                gutter={8}>
                <Col span={4} style={{ textAlign: 'center' }}>
                    <UNIcon
                        className="color-primary"
                        style={{ fontSize: '1.5em' }}
                        icon="calendar-clock"
                    />
                </Col>
                <Col span={20}>
                    <div className="color-primary">{ev.title}</div>
                    <div style={{ color: '#8e8e8e', fontSize: '0.9em' }}>{start}</div>
                    {city && <div style={{ color: '#8e8e8e', fontSize: '0.9em' }}>{city}</div>}
                </Col>
            </Row>
        );
    };

    render() {
        const events = (this.props.events || [])
            .map((event) => {
                event.timestamp = event.start ? moment(event.start).unix() : 0;

                return event;
            })
            .sort((a, b) => a.timestamp - b.timestamp);
        const pastEvents = events.filter((event) =>
            moment(event.end ?? event.start).isBefore(moment()),
        );
        const currentEvents = events.filter(
            (event) =>
                moment(event.start).isAfter(moment()) ||
                (event.end && moment(event.end).isAfter(moment())),
        );
        return (
            <div
                style={{
                    height: '100%',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                }}>
                <div
                    className="navbar centerText"
                    style={{
                        borderBottom: '1px solid #edeff4',
                        backgroundColor: '#ffffff',
                    }}>
                    <Link to="/profil">
                        <Icon
                            type="left"
                            style={{
                                fontSize: 12,
                                margin: 6,
                                float: 'left',
                            }}
                            className="color-primary"
                        />
                    </Link>
                </div>

                <div className="navbarSpacer" />

                {currentEvents.length > 0 &&
                    currentEvents
                        .map(this.renderEvent)
                        .reduce((prev, curr) => [
                            prev,
                            <div key={`${curr.key}_divider`} className="divider" />,
                            curr,
                        ])}
                {pastEvents.length > 0 && (
                    <Row
                        key="pastEvent"
                        style={{ paddingTop: '8%', paddingBottom: '1%', color: '#8f8f8f' }}
                        type="flex"
                        gutter={8}>
                        <Col span={20} offset={4}>
                            Vergangene Events:
                        </Col>
                    </Row>
                )}
                {pastEvents.length > 0 &&
                    pastEvents
                        .map(this.renderEvent)
                        .reduce((prev, curr) => [
                            prev,
                            <div key={`${curr.key}_divider`} className="divider" />,
                            curr,
                        ])}
            </div>
        );
    }
}

const mapStateToProps = ({ company }) => ({
    events: company.profile.events,
});

export default connect(mapStateToProps)(Events);
