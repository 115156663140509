import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import { Button, Message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function PreviewQR({ path }) {
    const link = encodeURIComponent(`https://uninow.de/applink/${path}`);
    const url = `https://uninow.page.link/?link=${link}&apn=de.mocama.UniNow&ibi=de.mocama.uninow`;
    return (
        <div>
            <a href={url}>
                <QRCode value={url} size={180} />
            </a>
            <CopyToClipboard
                text={url}
                onCopy={() => Message.info('Link in die Zwischenablage kopiert')}>
                <Button style={{ width: '100%', marginTop: 20 }} type="primary">
                    Link Kopieren
                </Button>
            </CopyToClipboard>
        </div>
    );
}

PreviewQR.propTypes = {};

export default PreviewQR;
