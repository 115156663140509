import { Modal } from 'antd';

const { confirm } = Modal;

export function getDataUri(uri, callback) {
    const toDataURL = (url) =>
        fetch(url, {
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
        })
            .then((response) => response.blob())
            .then(
                (blob) =>
                    new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onloadend = () => resolve(reader.result);
                        reader.onerror = reject;
                        reader.readAsDataURL(blob);
                    }),
            );

    // timestamp for cache busting (cors problematic)
    toDataURL(`${uri}?timestamp=${Date.now()}`).then((dataUrl) => {
        callback(dataUrl);
    });
}

export const convertUrlToBase64 = (imageUrl) =>
    new Promise((resolve) => getDataUri(imageUrl, (uri) => resolve(uri)));

export const convertImageToBlob = (imageURL) =>
    new Promise((resolve) => {
        const image = new Image();
        const onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0);
            canvas.toBlob((blob) => {
                resolve(blob);
            });
        };

        image.onload = onload;
        image.src = imageURL;
    });

export const canvasToBlob = (canvas) =>
    new Promise((resolve) => {
        canvas.toBlob((blob) => resolve(blob));
    });

export const getUserConfirmation = (message, callback) => {
    confirm({
        title: 'Achtung',
        content: message,
        okText: 'Änderungen verwerfen',
        okType: 'danger',
        cancelText: 'Abbrechen',
        onOk() {
            callback(true);
        },
    });
};

// export const parseJwt = (token) => {
//   const base64Url = token.split('.')[1];
//   const base64 = base64Url.replace('-', '+').replace('_', '/');
//   return JSON.parse(window.atob(base64));
// };

export const isIE = () => {
    const ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones */
    const is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;

    return is_ie;
};
