import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { PostGrid } from '..';

class AdvertPostForm extends Component {
    onPostSelect = async (post) => {
        this.props.onChange({
            data: { post },
            changes: true,
        });
    };

    render() {
        const { data = {} } = this.props;
        const { post } = data;

        return (
            <div>
                {/* <PostList initialPost={post} onSelect={this.onPostSelect} /> */}
                <PostGrid initialPost={post} onSelect={this.onPostSelect} />
            </div>
        );
    }
}

AdvertPostForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    data: PropTypes.object,
};

export default AdvertPostForm;
