import { Col, Icon, Popconfirm, Row, Tooltip } from 'antd';
import React, { useState } from 'react';
import ChannelFilterInfo from './ChannelFilterInfo';
import AudienceChannelSelection from './AudienceChannelSelection';
import { emergencyGlobalChannelId, freshmanGlobalChannelId } from './constants';

export default function ChannelSelection({
    channelFilter,
    notificationChannels: inputNotificationChannels,
    selectedChannels,
    setSelectedChannels,
}) {
    const [emergencyPopconfirmVisible, setEmergencyPopconfirmVisible] = useState(false);
    const onEmergencyPopconfirmConfirm = (channels = []) => {
        const emergencyChannel = channels.find(
            (channel) => channel.globalChannel === emergencyGlobalChannelId,
        );

        toggleChannel(emergencyChannel, true);
        setEmergencyPopconfirmVisible(false);
    };

    const onEmergencyPopconfirmCancel = () => {
        setEmergencyPopconfirmVisible(false);
    };

    const toggleChannel = (channel, confirm = false) => {
        if (selectedChannels[channel.id]) {
            const { [channel.id]: deselect, ...selected } = selectedChannels;
            setSelectedChannels(selected);
        } else if (channel.globalChannel === emergencyGlobalChannelId && !confirm) {
            setEmergencyPopconfirmVisible(true);
        } else {
            setSelectedChannels({
                ...selectedChannels,
                [channel.id]: {
                    channel,
                    notification: true,
                },
            });
        }
    };
    const notificationChannels = inputNotificationChannels.filter(
        (channel) => !channel.targeting?.roles || channel.targeting?.roles?.length === 0,
    );
    const roleBasedChannels = inputNotificationChannels.filter(
        (channel) => !!channel.targeting?.roles && channel.targeting.roles.length > 0,
    );
    return (
        <>
            <Row className="verify-section">
                <div className="color-secondary weight-medium verify-section-headline">
                    Channels&nbsp;
                    <Tooltip
                        placement="right"
                        title="Hier kannst du einstellen, für welche Channel Push-Benachrichtigungen rausgehen.">
                        <Icon type="question-circle-o" />
                    </Tooltip>
                </div>
                {channelFilter ? (
                    <ChannelFilterInfo
                        notificationChannels={notificationChannels}
                        channelFilter={channelFilter}
                    />
                ) : (
                    <div className="channels">
                        {notificationChannels.map((channel) => (
                            <Row key={channel.id} className="channel">
                                <Col span={19} offset={1}>
                                    #{channel.name}
                                    {channel.globalChannel === freshmanGlobalChannelId && (
                                        <Tooltip
                                            placement="right"
                                            title="Damit eure Beiträge ausschließlich im Erstsemester-Modul angezeigt werden, darf kein anderer Channel ausgewählt sein.">
                                            <span className="color-secondary size-tiny">
                                                &nbsp;
                                                <Icon type="question-circle-o" />
                                            </span>
                                        </Tooltip>
                                    )}
                                    {channel.globalChannel === emergencyGlobalChannelId && (
                                        <Tooltip
                                            placement="right"
                                            title="Jeder Studierende eurer Hochschule erhält eine Push-Benachrichtigung für Beiträge in diesem Channel. Nur im Notfall zu benutzen.">
                                            <span className="color-secondary size-tiny">
                                                &nbsp;
                                                <Icon type="question-circle-o" />
                                            </span>
                                        </Tooltip>
                                    )}
                                </Col>
                                <Col span={4}>
                                    <Icon
                                        type="check"
                                        className={
                                            selectedChannels[channel.id] ? 'check active' : 'check'
                                        }
                                        onClick={() => toggleChannel(channel)}
                                    />
                                    {channel.globalChannel === emergencyGlobalChannelId && (
                                        <Popconfirm
                                            title="Alle Studierenden eurer Hochschule benachrichtigen?"
                                            okText="Ok"
                                            cancelText="Abbrechen"
                                            onConfirm={() =>
                                                onEmergencyPopconfirmConfirm(notificationChannels)
                                            }
                                            onCancel={onEmergencyPopconfirmCancel}
                                            visible={emergencyPopconfirmVisible}
                                        />
                                    )}
                                </Col>
                            </Row>
                        ))}
                    </div>
                )}
            </Row>
            <Row>
                <AudienceChannelSelection
                    toggleChannel={toggleChannel}
                    selectedChannels={selectedChannels}
                    channels={roleBasedChannels}
                />
            </Row>
        </>
    );
}
