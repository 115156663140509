import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Input, Row, Col } from 'antd';
import { ImageManager, ProfileLayout, UniNowList, VideoDropzone } from '../../components';
import {
    addToArray,
    removeFromArray,
    changeArrayElement,
    setAttributeValue,
} from '../../core/redux/actions';
import buttonWithPopconfirm from '../../decorators/buttonWithPopconfirm';
import { generateID } from '../../core/helper/redux';
import { defaultRating } from '../../core/helper/defaultObjects';
import buttonWithTooltip from '../../decorators/buttonWithTooltip/index';
import imageConfig from '../../core/constants/imageConfig';
import ListSorter from '../../components/ListSorter';
import MarkdownLink from '../MarkdownHelp/MarkdownLink';
import PlanFilter, { PLAN_PERMISSION } from '../../components/PlanFilter';

const FormItem = Form.Item;
const { TextArea } = Input;

const RatingForm = (props) => {
    const { getFieldDecorator, rating, onInputChange } = props;
    const { _id, tempID, title, subtitle, text } = rating;
    const key = _id || tempID;

    return (
        <div>
            <FormItem label="Titel (optional):">
                {getFieldDecorator(`name[${key}]`, {
                    rules: [
                        {
                            message: 'Bitte geben Sie den Namen ihres Mitarbeiters an.',
                        },
                    ],
                    onChange: (e) => onInputChange('title', e.target.value),
                    initialValue: title,
                })(<Input placeholder="Name Ihres Mitarbeiters..." />)}
            </FormItem>

            <FormItem label="Untertitel (optional):">
                {getFieldDecorator(`position[${key}]`, {
                    rules: [
                        {
                            message: 'Bitte geben Sie die Position ihres Mitarbeiters an.',
                        },
                    ],
                    onChange: (e) => onInputChange('subtitle', e.target.value),
                    initialValue: subtitle,
                })(<Input placeholder="Position Ihres Mitarbeiters..." />)}
            </FormItem>

            <FormItem label="Statement:">
                {getFieldDecorator(`statement[${key}]`, {
                    rules: [
                        {
                            required: true,
                            message: 'Bitte geben Sie das Statement Ihres Mitarbeiters an.',
                        },
                    ],
                    onChange: (e) => onInputChange('text', e.target.value),
                    initialValue: text,
                })(<TextArea autoSize placeholder="Das Statements Ihres Mitarbeiters..." />)}
                <MarkdownLink />
            </FormItem>
        </div>
    );
};

class Ratings extends Component {
    add = () => {
        const newRating = { ...defaultRating, tempID: generateID() };
        this.props.addToArray('profile.ratings', newRating, true);
    };

    remove = (targetKey) => {
        this.props.removeFromArray('profile.ratings', targetKey);
    };

    updateRating = (rating, index) => (attributeName, value) => {
        const updatedRating = {
            ...rating,
            [attributeName]: value,
        };
        this.props.changeArrayElement('profile.ratings', index, updatedRating);
    };

    handleSubmit = () =>
        new Promise((resolve, reject) =>
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    resolve(true);
                }
                resolve(false);
            }),
        );

    render() {
        const { ratings } = this.props;
        return (
            <ProfileLayout
                subHeaderSelectedKey="insights"
                onSubmitMin={this.handleSubmit}
                infoText="Insights	vermitteln	mehr
      Nähe	und
      Authentizität.
      Die	Studierenden	wollen	aus	erster
      Hand	erfahren,	wie	es	ist	bei	Ihnen
      zu	arbeiten.
      Gewähren	Sie	einen	echten	Einblick
      in	den	Arbeitsalltag. Ein	Bild	ist	natürlich	kein	Muss.
      Falls	Sie	dem	Statement	doch	ein
      Gesicht	hinzufügen	wollen,
      beachten	Sie,	dass	die	Datei (JPEG/JPG oder PNG)	max.	20MB groß sein darf und nach Möglichkeit im Seitenverhältnis 1:1 vorliegen sollte.">
                <PlanFilter permission={PLAN_PERMISSION.TESTIMONIALS}>
                    <Button icon="plus" type="primary" size="large" onClick={() => this.add()}>
                        Insight hinzufügen
                    </Button>
                    <Form>
                        <UniNowList
                            noItemsText="Es wurden noch keine Insights hinzugefügt."
                            itemLayout="vertical"
                            size="large"
                            dataSource={ratings}
                            renderItem={(rating, index) => (
                                <Row gutter={25} style={{ paddingBottom: 25 }}>
                                    <ListSorter
                                        index={index}
                                        maxLength={ratings.length}
                                        attributeName="profile.ratings"
                                    />
                                    <Col span={18} offset={1}>
                                        <RatingForm
                                            onInputChange={(attributeName, value) =>
                                                this.updateRating(rating, index)(
                                                    attributeName,
                                                    value,
                                                )
                                            }
                                            rating={{ ...rating }}
                                            getFieldDecorator={this.props.form.getFieldDecorator}
                                        />
                                        <br />
                                        {buttonWithPopconfirm({
                                            placement: 'top',
                                            title: 'Wollen Sie diese Insight wirklich löschen?',
                                            onConfirm: () => this.remove(index),
                                            okText: 'Löschen',
                                        })(
                                            buttonWithTooltip('Review löschen')(
                                                <Button shape="circle" icon="delete" />,
                                            ),
                                        )}
                                    </Col>
                                    <Col span={4}>
                                        <ImageManager
                                            image={rating.image}
                                            imageRatio={{ x: 1, y: 1 }}
                                            width={200}
                                            optimalWidth={imageConfig.testimonialsOptimalSize.width}
                                            optimalHeight={
                                                imageConfig.testimonialsOptimalSize.height
                                            }
                                            enableZoomOut
                                            onChange={(value) =>
                                                this.updateRating(rating, index)('image', value)
                                            }
                                        />
                                        <br />
                                        <VideoDropzone
                                            files={{ video: rating.video }}
                                            fileTypes={['video']}
                                            onChange={(value) =>
                                                this.updateRating(rating, index)('video', value)
                                            }
                                            width={200}
                                        />
                                    </Col>
                                </Row>
                            )}
                        />
                    </Form>
                </PlanFilter>
            </ProfileLayout>
        );
    }
}

const mapStateToProps = (state) => ({
    ratings: state.company.profile.ratings,
});

const mapDispatchToProps = (dispatch) => ({
    addToArray: (attributeName, element, prepend) =>
        dispatch(addToArray(attributeName, element, 'company', prepend)),
    removeFromArray: (attributeName, index) => dispatch(removeFromArray(attributeName, index)),
    changeArrayElement: (attributeName, index, updatedElement) =>
        dispatch(changeArrayElement(attributeName, index, updatedElement)),
    setAttributeValue: (attributeName, value) => dispatch(setAttributeValue(attributeName, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Ratings));
