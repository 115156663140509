import React from 'react';

export const ICONS = [
    'ios-pin-outline',
    'ios-map-outline',
    'ios-at-outline',
    'ios-information-circle-outline',
    'ios-home-outline',
    'ios-planet-outline',

    'ios-person-outline',
    'ios-bonfire',
    'ios-cut-outline',
    'ios-hammer-outline',
    'ios-basket-outline',
    'ios-flask-outline',
    'ios-briefcase',

    'ios-cart-outline',
    'ios-film-outline',
    'ios-football',
    'ios-musical-notes',
    'ios-laptop',
    'ios-game-controller-b-outline',

    'ios-beer-outline',
    'ios-pizza-outline',
    'ios-ice-cream-outline',
    'ios-cafe-outline',

    'ios-boat-outline',
    'ios-bicycle',
    'ios-bus-outline',
    'ios-train-outline',

    'ios-heart',
    'ios-heart-outline',

    'ios-thumbs-up-outline',
    'ios-thumbs-down-outline',
    'ios-trophy-outline',
    'ios-bookmark-outline',
];

const HEIGHT_OFFSET = 5;

const Ionicon = (props) => {
    const { type, strict = false, style = {}, fontStyle = {} } = props;

    const icon = !strict || ICONS.includes(type) ? type : 'ios-pin-outline';

    const fontSize = fontStyle.fontSize || 28;
    const dimension = `${parseInt(fontSize, 10) + HEIGHT_OFFSET}px`;

    return (
        <div
            style={{
                ...style,
                textAlign: 'center',
                width: dimension,
                height: dimension,
                lineHeight: dimension,
            }}>
            <i
                {...props}
                style={{ fontSize, opacity: 0.9, ...fontStyle }}
                className={`ion-${icon}`}
            />
        </div>
    );
};

export default Ionicon;
