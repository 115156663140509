import { PURGE } from 'redux-persist';
import { LOGIN_SUCCESS } from '../actions/user.js';

export default function authentication(state = { username: '', token: '', role: '' }, action) {
    const { type, username, token, role } = action;
    switch (type) {
        case LOGIN_SUCCESS:
            return {
                username,
                token,
                role,
            };
        case PURGE:
            return {};
        default:
            return state;
    }
}
