import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Tooltip, Button } from 'antd';

import { Recruiting, Blog, Podcast, Video, Gallery, Event, Poll } from './types';
import { ATTACHMENT_TYPES } from '../constants';

class Attachment extends Component {
    render() {
        const { form, onClose, type, data, language = 'de', activeLanguage = 'de' } = this.props;

        const attachmentType = ATTACHMENT_TYPES.find(
            (attachmentType) => attachmentType.type === type,
        );

        if (!attachmentType) {
            return null;
        }

        const editMode = !!data;

        let attachment = null;

        switch (attachmentType.type) {
            case 'recruiting':
                attachment = <Recruiting form={form} data={data} language={language} />;
                break;

            case 'blog':
                attachment = <Blog form={form} data={data} language={language} />;
                break;

            case 'podcast':
                attachment = <Podcast form={form} data={data} language={language} />;
                break;

            case 'video':
                attachment = <Video form={form} data={data} language={language} />;
                break;

            case 'gallery':
                attachment = <Gallery form={form} data={data} language={language} />;
                break;

            case 'event':
                attachment = <Event form={form} data={data} language={language} />;
                break;

            case 'poll':
                attachment = (
                    <Poll
                        form={form}
                        data={data}
                        language={language}
                        activeLanguage={activeLanguage}
                    />
                );
                break;

            default:
                break;
        }

        return (
            <div id="AttachmentContainer">
                <Row style={{ padding: '5px 20px' }}>
                    <Col span={12} style={{ textAlign: 'left' }}>
                        <p style={{ fontWeight: 'bold', margin: '5px 0px' }}>
                            {attachmentType.title}
                        </p>
                    </Col>
                    <Col span={12} className="remove-option" style={{ textAlign: 'right' }}>
                        {!editMode && (
                            <Tooltip title="Abbrechen">
                                <Button
                                    shape="circle"
                                    icon="close"
                                    style={{ fontWeight: 'bold', fontSize: 24 }}
                                    onClick={onClose}
                                />
                            </Tooltip>
                        )}
                    </Col>
                </Row>
                {attachment}
            </div>
        );
    }
}

Attachment.propTypes = {
    form: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    type: PropTypes.string,
    data: PropTypes.object,
    language: PropTypes.string,
};

export default Attachment;
