import styled from 'styled-components';

export const FormBody = styled.div`
    margin-bottom: 80px;
    // margin-top: 10px;
`;

export const FormFooter = styled.div`
    display: flex;
    align-items: center;
    z-index: 30;
    padding-left: 5%;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 60px;
    width: 100px;
`;

export const LeftMargin = styled.div`
    margin-left: 10px;
`;
