import React, { Component } from 'react';
import { uniqBy, debounce } from 'lodash';
import PropTypes from 'prop-types';

import { withApollo } from 'react-apollo';

import { Row, Col, Icon, Form, AutoComplete, Select, Input } from 'antd';

import { loadFromFile } from '../image';
import PostImageDropzone from '../../../../PostImageDropzone';

class Recruiting extends Component {
    state = {
        image: null,
        searchingCompany: null,
        selectedCompany: null,
        companies: [],
        searchingJob: null,
        selectedJobs: [],
        jobs: [],
    };

    onImageFileSelected = async (files) => {
        å;
        const { setFieldsValue } = this.props.form;

        const selectedImage = await loadFromFile(files[0]);

        setFieldsValue({
            'attachment.image': selectedImage,
        });

        this.setState({
            image: selectedImage,
        });
    };

    removeImage = () => {
        const { setFieldsValue } = this.props.form;

        setFieldsValue({
            'attachment.image': null,
        });

        this.setState({
            image: null,
        });
    };

    onCompanySelect = async (value) => {
        const { companies = [] } = this.state;
        const { setFieldsValue } = this.props.form;

        const company = companies.find((company) => company.id === value);

        setFieldsValue({ 'attachment.company': company, 'attachment.jobs': [] });

        const { data } = await this.props.client.query({
            query: SEARCH_JOB,
            variables: {
                pagination: { first: 10 },
                filterBy: {
                    companies: [value],
                },
            },
        });

        const jobs = data.jobs.edges.map((edge) => edge.node);

        this.setState({
            jobs,
            selectedJobs: [],
            selectedCompany: value,
        });
    };

    onCompanySearch = (value) => {
        this.setState({
            jobs: [],
            searchingCompany: 'validating',
        });

        this.searchCompany(value);
    };

    searchCompany = debounce(async (value) => {
        const { data } = await this.props.client.query({
            query: SEARCH_COMPANY,
            variables: {
                pagination: { first: 10 },
                text: value,
            },
        });

        const companies = data.companies.edges.map((edge) => edge.node);

        this.setState({
            companies,
            searchingCompany: null,
        });
    }, 250);

    onJobChange = (values) => {
        const { jobs = [] } = this.state;
        const { getFieldValue, setFieldsValue } = this.props.form;
        const fieldJobs = getFieldValue('attachment.jobs');

        values = values.slice(-2);

        const selectedJobs = uniqBy(
            fieldJobs.concat(jobs).filter((job) => values.includes(job.id)),
            'id',
        );

        setFieldsValue({ 'attachment.jobs': selectedJobs });

        this.setState({
            selectedJobs: values,
        });
    };

    onJobSearch = (value) => {
        this.setState({
            searchingJob: 'validating',
        });

        this.searchJob(value);
    };

    searchJob = debounce(async (value) => {
        const { selectedCompany } = this.state;

        const { data } = await this.props.client.query({
            query: SEARCH_JOB,
            variables: {
                pagination: { first: 10 },
                text: value,
                filterBy: {
                    companies: [selectedCompany],
                },
            },
        });

        const jobs = data.jobs.edges.map((edge) => edge.node);

        this.setState({
            jobs,
            searchingJob: null,
        });
    }, 250);

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            image,
            searchingCompany,
            selectedCompany,
            companies = [],
            searchingJob,
            selectedJobs = [],
            jobs = [],
        } = this.state;

        return (
            <div style={{ padding: '0px 20px 10px 20px' }}>
                <Row>
                    <Form.Item hasFeedback validateStatus={searchingCompany}>
                        {getFieldDecorator('attachment.company')(
                            <div style={{ display: 'none' }} />,
                        )}
                        <AutoComplete
                            placeholder="Unternehmen suchen"
                            onChange={this.onCompanySearch}
                            onSelect={this.onCompanySelect}
                            filterOption={(value, { props }) =>
                                props.children.toLowerCase().includes(value.toLowerCase())
                            }>
                            {companies.map((company) => (
                                <AutoComplete.Option key={company.id}>
                                    {company.title}
                                </AutoComplete.Option>
                            ))}
                        </AutoComplete>
                    </Form.Item>
                </Row>

                <Row>
                    {getFieldDecorator(`attachment.jobs`)(<div style={{ display: 'none' }} />)}
                    <Form.Item hasFeedback validateStatus={searchingJob}>
                        <Select
                            disabled={!selectedCompany}
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Jobs auswählen (optional; max. 2)"
                            value={selectedJobs}
                            onSearch={this.onJobSearch}
                            onChange={this.onJobChange}
                            filterOption={(value, { props }) =>
                                props.children.toLowerCase().includes(value.toLowerCase())
                            }>
                            {jobs.map((job) => (
                                <Select.Option key={job.id}>{job.title}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Row>

                <br />
                <div className="color-secondary" style={{ textAlign: 'left' }}>
                    Zusätzliche Angaben (nur für Company of the day relevant)
                </div>
                <br />

                <Row gutter={20}>
                    <Col span={12} style={{ height: 100 }}>
                        {getFieldDecorator('attachment.image')(
                            image ? (
                                <div>
                                    <img className="gallery-image" src={image.preview} alt="" />
                                    <div className="gallery-overlay">
                                        <div className="inner-overlay-two">
                                            <div
                                                className="inner-overlay-three"
                                                onClick={() => this.removeImage()}>
                                                <Icon type="close" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <PostImageDropzone
                                    icon="plus"
                                    multiple={false}
                                    onFileSelected={this.onImageFileSelected}
                                    style={{ position: 'relative' }}
                                />
                            ),
                        )}
                    </Col>
                    <Col span={12}>
                        <Form.Item hasFeedback>
                            {getFieldDecorator(`attachment.badgeTitle`)(
                                <Input placeholder="Bildtitel (optional)" />,
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        );
    }
}

Recruiting.propTypes = {
    form: PropTypes.object.isRequired,
};

export default withApollo(Recruiting);
