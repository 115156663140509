import React, { Component } from 'react';
import { Form, Input, Icon, Button, Tooltip } from 'antd';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import logo from '../../assets/images/uninow_logo_vertical_white.svg';
import background from '../../assets/images/background.jpg';

import { LoginFormLayout } from './styles';

const FormItem = Form.Item;

const Error = styled.div`
    color: #f5222d;
    margin-top: 10px;
`;

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            alert: '',
            username: '',
            password: '',
        };
    }

    componentDidMount = async () => {
        const { search = '' } = this.props.location;

        if (search) {
            const hit = search.match(/t=([^&]+)/);

            if (hit) {
                this.setState({ isLoading: true });

                const success = await this.context.loginWithToken(hit[1]);

                if (!success) {
                    this.setState({ isLoading: false });
                }
            }
        }
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const { username, password } = this.state;
        this.setState({ isLoading: true });
        try {
            await this.context.login(username, password);
        } catch (error) {
            const message =
                error.response?.status === 401
                    ? 'Die angegebene Kombination aus Nutzername und Passwort existiert nicht.'
                    : 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut';
            if (error.response?.status !== 401) {
                Sentry.captureException(error);
            }
            this.setState({
                isLoading: false,
                alert: (
                    <div>
                        {message}{' '}
                        <Tooltip title={error.message}>
                            <Icon type="info-circle" />
                        </Tooltip>
                    </div>
                ),
            });
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { isLoading, alert } = this.state;
        const { from } = this.props.location.state || { from: { pathname: '/' } };
        const { authToken } = this.context;
        return (
            <div
                style={{
                    backgroundImage: `url(${background})`,
                    backgroundSize: 'cover',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    overflow: 'scroll',
                }}>
                {authToken ? (
                    <Redirect to={from} />
                ) : (
                    <div>
                        <img
                            src={logo}
                            alt="logo"
                            width="30%"
                            style={{ marginLeft: 'auto', marginRight: 'auto' }}
                        />
                        <LoginFormLayout>
                            <Form
                                onSubmit={this.handleSubmit}
                                className="login-form"
                                hideRequiredMark>
                                <FormItem>
                                    <h1>Login</h1>
                                </FormItem>
                                <FormItem className="entry">
                                    {getFieldDecorator('userName', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Bitte geben Sie Ihren Nutzernamen an.',
                                            },
                                        ],
                                    })(
                                        <Input
                                            disabled={isLoading}
                                            onChange={(e) =>
                                                this.setState({ username: e.target.value })
                                            }
                                            prefix={
                                                <Icon
                                                    type="user"
                                                    style={{ color: 'rgba(0,0,0,.25)' }}
                                                />
                                            }
                                            placeholder="Geben Sie hier Ihren Nutzernamen an..."
                                        />,
                                    )}
                                </FormItem>
                                <FormItem className="entry">
                                    {getFieldDecorator('password', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Bitte geben Sie ihr Passwort an.',
                                            },
                                        ],
                                    })(
                                        <Input
                                            disabled={isLoading}
                                            onChange={(e) =>
                                                this.setState({ password: e.target.value })
                                            }
                                            prefix={
                                                <Icon
                                                    type="lock"
                                                    style={{ color: 'rgba(0,0,0,.25)' }}
                                                />
                                            }
                                            type="password"
                                            placeholder="Geben Sie hier ihr Passwort ein..."
                                        />,
                                    )}
                                </FormItem>
                                <FormItem validateStatus="error" help={<Error>{alert}</Error>}>
                                    <Button
                                        type="primary"
                                        style={{
                                            fontWeight: 700,
                                            padding: '0px 30px',
                                            borderWidth: 0,
                                            borderRadius: '100px',
                                            width: '100%',
                                        }}
                                        size="large"
                                        loading={isLoading}
                                        disabled={isLoading}
                                        htmlType="submit">
                                        Log in
                                    </Button>
                                </FormItem>
                                <FormItem>
                                    <a
                                        href="https://accounts.uninow.com/auth/forgot-password"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        Passwort vergessen?
                                    </a>
                                </FormItem>
                            </Form>
                        </LoginFormLayout>
                    </div>
                )}
            </div>
        );
    }
}

Login.contextTypes = {
    login: PropTypes.func.isRequired,
    loginWithToken: PropTypes.func.isRequired,
    authToken: PropTypes.string.isRequired,
};

export default Form.create()(Login);
