import React from 'react';
import { Button, Divider, Form, Select } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { AffiliationSelection } from '../../components';

const FormItem = Form.Item;

function Targeting({ job, history, onDataChange, form }) {
    const hasPriorityLimit = useSelector((state) => {
        const highPriorityJobs = state.jobs.joblist.filter(
            (filterJob) => !filterJob.archived && filterJob.priority >= 2,
        );
        return highPriorityJobs.length >= 3;
    });
    const { getFieldDecorator } = form;
    return (
        <div>
            {job.visible && (
                <>
                    <Divider orientation="left">Werbeanzeigen</Divider>
                    <p>
                        Sie möchten Ihre Stellenanzeige ganz zielgerichtet an weiteren Standorten in
                        Deutschland ausliefern? Das ermöglichen Ihnen unsere Display Ads.
                    </p>
                    <Button
                        style={{ marginBottom: 10 }}
                        type="primary"
                        onClick={() => {
                            history.push(`/werbeanzeigen/editieren/?jobId=${job.uuid}`);
                        }}>
                        Jetzt erstellen!
                    </Button>
                </>
            )}
            <Divider orientation="left">Fachrichtungen</Divider>

            <AffiliationSelection
                description={
                    <span>
                        Bitte wählen Sie Fachrichtungen aus, für die Ihre Stellenanzeige attraktiv
                        ist.
                        <br />
                        Ist keine Fachrichtung ausgewählt, erscheint die Anzeige für alle
                        Studierenden unabhängig ihrer Fachrichtung.
                    </span>
                }
                onChange={(updatedStudies) => onDataChange('studies', updatedStudies)}
                initialAffiliations={job.studies}
            />
            <Divider orientation="left">Priorität</Divider>

            <FormItem label="Priorität">
                {getFieldDecorator('priority', {
                    initialValue: job.priority,
                    onChange: (value) => onDataChange('priority', value),
                })(
                    <Select style={{ width: '100%' }} placeholder="Priorität">
                        <Select.Option value={2} disabled={hasPriorityLimit}>
                            Hoch
                        </Select.Option>
                        <Select.Option value={1}>Mittel</Select.Option>
                        <Select.Option value={0}>Niedrig</Select.Option>
                    </Select>,
                )}
            </FormItem>
            {hasPriorityLimit && (
                <span>Sie können nur bei maximal drei Jobs eine hohe Priorität festlegen.</span>
            )}
        </div>
    );
}

Targeting.propTypes = {
    job: PropTypes.shape().isRequired,
    history: PropTypes.shape().isRequired,
    onDataChange: PropTypes.func.isRequired,
    form: PropTypes.shape().isRequired,
};
export default Targeting;
