import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { connect } from 'react-redux';
import { DatePicker, Button, Message } from 'antd';
import { setAttributeValue } from '../../core/redux/actions';

class EndOfTermInput extends Component {
    constructor(props) {
        super(props);

        const { endOfTerm } = props;

        this.state = {
            date: endOfTerm ? moment(endOfTerm) : null,
        };
    }

    onDateChange = (date) => {
        this.setState({ date });
    };

    onSave = async () => {
        const { setAttributeValue } = this.props;
        const { request } = this.context;
        const { date } = this.state;

        const { error } = await request('recruiting/company/update', {
            company: { endOfTerm: date },
        });

        if (error) {
            Message.error('Laufzeitende konnte nicht gespeichert werden.');
        } else {
            Message.success('Neues Laufzeitende gespeichert.');
            this.setState({ initialDate: date });
            setAttributeValue('endOfTerm', date.format());
        }
    };

    render() {
        const { endOfTerm } = this.props;
        const { date } = this.state;

        const sameDay = endOfTerm && date && moment(endOfTerm).isSame(date, 'day');
        const sameValue = endOfTerm === date;

        const now = moment().endOf('day');

        return (
            <div>
                <DatePicker
                    disabledDate={(currentDate) => currentDate.isBefore(now)}
                    value={date}
                    onChange={this.onDateChange}
                    format="DD.MM.YYYY"
                />
                <Button
                    onClick={this.onSave}
                    type="link"
                    icon="save"
                    disabled={sameDay || sameValue}
                />
            </div>
        );
    }
}

EndOfTermInput.contextTypes = {
    request: PropTypes.func.isRequired,
};

const mapStateToProps = ({ company }) => ({
    endOfTerm: company.endOfTerm || null,
});

const mapDispatchToProps = (dispatch) => ({
    setAttributeValue: (attributeName, value) => dispatch(setAttributeValue(attributeName, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EndOfTermInput);
