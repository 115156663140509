import React from 'react';

import { reactReplaceControlCharacters, reactStringReplace } from './react-replace';

const parseData = (data) => {
    // Match links [caption](link)
    let replacedText = reactStringReplace(data, /(\[.+?\]\(.+?\))/gim, (match, i, s, key) => {
        const test = /\[(.+?)\]\((.+?)\)/.exec(match);
        return (
            <a target="_blank" key={`caption_${key}`} href={test[2]} rel="noreferrer">
                {parseData(test[1])}
            </a>
        );
    });

    // link
    replacedText = reactStringReplace(
        replacedText,
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/g,
        (match, i, s, key) => {
            if (match.startsWith('www')) {
                match = `https://${match}`;
            } else if (match.startsWith('http://')) {
                match = `https://${match.replace('http://', '')}`;
            }

            return (
                <a target="_blank" key={`link_${key}`} href={match} rel="noreferrer">
                    {match}
                </a>
            );
        },
    );

    replacedText = reactReplaceControlCharacters(replacedText);

    // Match bold+italic text ***bold+italic text here*** or ___bold+italic text here___
    replacedText = reactStringReplace(replacedText, /<ib>(.*?)<ib>/gim, (match, i, s, key) => (
        <b key={`bolditalic_${key}`}>
            <i>{match}</i>
        </b>
    ));

    // Match bold text **bold text here** or __bold text here__
    replacedText = reactStringReplace(replacedText, /<b>(.*?)<b>/gim, (match, i, s, key) => (
        <b key={`bold_${key}`} style={{ fontWeight: 700 }}>
            {match}
        </b>
    ));

    // Match italics text *italic text here* or _italic text here_
    replacedText = reactStringReplace(replacedText, /<i>(.*?)<i>/gim, (match, i, s, key) => (
        <i key={`italic_${key}`}>{match}</i>
    ));

    // new line
    replacedText = reactStringReplace(replacedText, '\n', (_, i, s, key) => (
        <br key={`br_${key}`} />
    ));

    return replacedText;
};

export default parseData;
