import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    Spin,
    Row,
    Col,
    Button,
    Notification,
    Modal,
    Icon,
    Input,
    Message,
    Tabs,
    Collapse,
    Alert,
    Form,
    Divider,
} from 'antd';
import { pickBy } from 'lodash';

import { connect } from 'react-redux';
import imageToBlob from '../../core/helper/imageToBlob';
import {
    FormLayout,
    AdvertForm,
    AdvertPostForm,
    AdvertUniversitiesForm,
    AffiliationSelection,
    AdvertDevicesForm,
    AdvertSemesterForm,
    AdvertLocaleForm,
    EndOfTermForm,
    AdvertPreview,
    ProgressBar,
    InlineEdit,
} from '../../components';

import defaultAdvert from './defaultAdvert.json';
import defaultJobAdvert from './defaultJobAdvert.json';

import './style.css';
import AdvertLimitsForm from '../../components/AdvertLimitsForm';
import EditWebhooksForm from '../../components/EditWebhooksForm';
import TargetSection from './components/TargetSection';

const { TabPane } = Tabs;
const { Panel } = Collapse;

class EditAdvert extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isNew: false,
            loading: true,
            error: null,
            formError: false,
            saving: false,
            data: {},
            label: null,
            unsavedChanges: false,
            modalVisible: false,
            locations: null,
            affiliations: null,
            devices: null,
            locales: null,
            semesters: null,
            deltaTimeInMin: null,
            userLimit: null,
            endOfTerm: null,
            notificationRecipients: ['all'],
            defaultActiveTab: '1',
            defaultActivePanel: '1',
            type: 0,
            disabledUniversities: [],
        };
    }

    componentWillMount() {
        const { search = '' } = this.props.location;
        const params = new URLSearchParams(search);

        const postId = params.get('post');
        const type = params.get('type') ?? '0';
        const jobId = params.get('jobId');

        this.setState({
            defaultActiveTab: params.get('activeTab') ?? '1',
            defaultActivePanel: params.get('activePanel') ?? '1',
            type: postId ? '1' : type,
            jobId,
        });

        this.fetchAdvertOrPost(postId, jobId);
    }

    showModal = () => {
        this.setState({
            modalVisible: true,
        });
    };

    hideModal = () => {
        this.setState({
            modalVisible: false,
        });
    };

    fetchAdvertOrPost = async (postId, jobId) => {
        const { shortid } = this.props.match.params;
        const { request } = this.context;

        if (shortid) {
            const { body, error } = await request('vposts/fetch', {
                shortid,
            });

            if (body) {
                const { positions = [] } = body.targeting;

                const advertisesPost = !!body.data.post;
                const isCareerAdvert =
                    positions.length > 0 &&
                    positions.some((position) => position.startsWith('CAREER'));
                const isMailAdvert =
                    positions.length > 0 &&
                    positions.some((position) => position.startsWith('MAIL'));

                let type = '0';

                if (advertisesPost) {
                    type = '1';
                } else if (isCareerAdvert) {
                    type = '2';
                } else if (isMailAdvert) {
                    type = '3';
                }

                this.setState({
                    loading: false,
                    data: body.data,
                    uuid: body.uuid,
                    label: body.label,
                    targeting: body.targeting,
                    visible: body.visible,
                    endOfTerm: body.endOfTerm,
                    notificationRecipients: body.notificationRecipients,
                    webhooks: body.webhooks,
                    disabledUniversities: body.targeting.disabledUniversities,
                    universities: body.targeting.universities,
                    type,
                });
            } else {
                this.setState({
                    loading: false,
                    error,
                });
            }
        } else if (postId) {
            const { body, error } = await request('recruiting/post/fetch', {
                id: postId,
            });

            if (body) {
                this.setState({
                    ...defaultAdvert,
                    data: {
                        ...defaultAdvert.data,
                        post: body.post,
                    },
                    loading: false,
                    isNew: true,
                    visible: false,
                });
            } else {
                this.setState({
                    loading: false,
                    error,
                });
            }
        } else if (jobId) {
            const { joblist, companyUuid } = this.props;
            const job = joblist.find((el) => el.uuid === jobId);
            this.setState({
                ...defaultJobAdvert,
                data: {
                    ...defaultJobAdvert.data,
                    action: {
                        label: 'Jetzt Bewerben',
                        link: `uninow://feed/job?id=${job.uuid}&companyID=${companyUuid}`,
                    },
                },
                loading: false,
                isNew: true,
                visible: false,
            });
        } else {
            this.setState({
                ...defaultAdvert,
                loading: false,
                isNew: true,
                visible: false,
            });
        }
    };

    deactivateAdvert = async () => {
        const { shortid } = this.props.match.params;
        const { request, role } = this.context;

        const { body } =
            role !== 'READONLY'
                ? await request('vposts/toggle', {
                      shortid,
                      visible: false,
                  })
                : { error: true };

        if (body) {
            this.setState({
                loading: false,
                visible: false,
            });
        } else {
            Notification.error({
                message: 'Aktualisierung fehlgeschlagen',
                description:
                    'Ihre Anzeige konnte leider nicht aktualisiert werden. Bitte kontaktieren Sie support@uninow.de.',
            });
        }
    };

    handleChange = ({ errors, changes, data }) => {
        this.setState({
            data: data || this.state.data,
            unsavedChanges: changes,
            formError: !!errors,
        });
    };

    handleUniversitiesChange = (universities, locations, disabledUniversities) => {
        this.setState({
            universities,
            locations,
            disabledUniversities,
            unsavedChanges: !!universities || !!locations,
        });
    };

    handleAffiliationChange = (affiliations) => {
        this.setState({
            affiliations,
            unsavedChanges: true,
        });
    };

    handleSemesterChange = (semesters) => {
        this.setState({
            semesters,
            unsavedChanges: true,
        });
    };

    handleLocaleChange = (locales) => {
        this.setState({
            locales,
            unsavedChanges: true,
        });
    };

    handleDeviceChange = (devices) => {
        this.setState({
            devices,
            unsavedChanges: !!devices,
        });
    };

    handleUserLimitChange = (userLimit) => {
        this.setState({
            userLimit,
            unsavedChanges: !!userLimit,
        });
    };

    handleDeltaTimeInMinChange = (deltaTimeInMin) => {
        this.setState({
            deltaTimeInMin,
            unsavedChanges: !!deltaTimeInMin,
        });
    };

    handleChange = (nextData = {}) => {
        this.setState({
            ...nextData,
            unsavedChanges: true,
        });
    };

    handleSave = async () => {
        const { request, upload, role } = this.context;

        if (role === 'READONLY') {
            return;
        }

        this.setState({
            saving: true,
        });

        const { match } = this.props;
        const { shortid } = match.params;
        const {
            isNew,
            data: inputData,
            type,
            universities,
            locations,
            affiliations,
            devices,
            locales,
            semesters,
            label,
            endOfTerm,
            notificationRecipients,
            userLimit,
            deltaTimeInMin,
            disabledUniversities,
            webhooks,
        } = this.state;
        let data = { ...inputData };
        const uploads = [];

        // Upload assets if necessary
        let assetSize = 0;

        if (data.logo && data.logo.edited) {
            const files = {
                raw: await imageToBlob(data.logo.raw),
                edited: await imageToBlob(data.logo.edited),
            };

            assetSize += files.raw.size + files.edited.size;

            uploads.push({
                key: 'logo',
                files,
                body: {
                    payload: data.logo.payload,
                },
            });
        }

        if (data.image && data.image.edited) {
            const files = {
                raw: await imageToBlob(data.image.raw),
                edited: await imageToBlob(data.image.edited),
            };

            assetSize += files.raw.size + files.edited.size;

            uploads.push({
                key: 'image',
                files,
                body: {
                    payload: data.image.payload,
                },
            });
        }

        if (data.video && data.video.preview) {
            const files = {
                video: data.video,
            };

            assetSize += files.video.size;

            uploads.push({
                key: 'video',
                files,
            });
        }

        if (uploads.length > 0) {
            data = await this.handleUploadWithProgress(uploads, upload, data, assetSize);
        }
        const { body } = await request(isNew ? 'vposts/create' : 'vposts/update', {
            shortid,
            data: data.post ? { post: data.post._id } : data,
            type,
            universities,
            locations,
            affiliations,
            devices,
            locales,
            semesters,
            label,
            endOfTerm,
            notificationRecipients,
            deltaTimeInMin,
            userLimit,
            webhooks,
            disabledUniversities,
        });

        if (this.uploadProgressBar) {
            this.uploadProgressBar.updatePercent(100);
        }

        if (uploads.length > 0) {
            Notification.close('UPLOAD');
        }

        this.setState({
            saving: false,
        });

        if (body) {
            this.setState(
                {
                    unsavedChanges: false,
                    saving: false,
                    formError: false,
                },
                () => this.props.history.push('/werbeanzeigen'),
            );
        } else {
            Notification.error({
                message: 'Aktualisierung fehlgeschlagen',
                description:
                    'Ihre Anzeige konnte leider nicht gespeichert werden. Bitte kontaktieren Sie support@uninow.de.',
            });
        }
    };

    async handleUploadWithProgress(uploads, upload, inputData, assetSize) {
        const data = { ...inputData };
        Notification.info({
            key: 'UPLOAD',
            message: 'Dateien werden hochgeladen',
            duration: null,
            description: (
                <div>
                    Bitte warten Sie einen Moment. Anschließend werden Sie automatisch auf die
                    Übersichtsseite weitergeleitet.
                    <ProgressBar
                        ref={(bar) => {
                            this.uploadProgressBar = bar;
                        }}
                    />
                </div>
            ),
        });

        const maxProgressForUpload = 0.9;

        const percentages = uploads.map(() => 0);
        await Promise.all(
            uploads.map(async (up, index) => {
                const onUploadProgress = ({ loaded, total }) => {
                    if (this.uploadProgressBar) {
                        return;
                    }
                    percentages[index] = Math.round((loaded * 100) / total);

                    const overallProgress = percentages.reduce(
                        (prevProgress, percent) => prevProgress + percent,
                        0,
                    );
                    const progress = maxProgressForUpload * (overallProgress / uploads.length);

                    this.uploadProgressBar.updatePercent(progress);
                };
                const { body } = await upload(
                    'vposts/asset/upload',
                    up.files,
                    up.body,
                    onUploadProgress,
                );

                if (body) {
                    data[up.key] = body;
                }
            }),
        );

        if (!data.image) {
            data.image = null;
        }
        if (!data.video) {
            data.video = null;
        }

        // continue updating progress bar until create/update request finishes
        const delaySeconds = Math.ceil(assetSize / 16666666);
        const fakeOffset = 10 / delaySeconds;

        const fakeUpdate = (progress, ms = 1000) => {
            if (this.uploadProgressBar && progress <= 100) {
                this.uploadProgressBar.updatePercent(progress);
                setTimeout(() => fakeUpdate(progress + fakeOffset, ms), ms);
            }
        };
        fakeUpdate(maxProgressForUpload * 100);
        return data;
    }

    handleLabelChange = async (value) => {
        const { isNew } = this.state;
        const { shortid } = this.props.match.params;
        const { request, role } = this.context;

        if (!isNew && role !== 'READONLY') {
            await request('vposts/update', {
                shortid,
                label: value,
            });
        }

        this.setState({ label: value });
    };

    handleFormError = (isError) => {
        this.setState({ formError: isError });
    };

    handleCancel = async () => {
        this.props.history.push('/werbeanzeigen');
    };

    handleWebhooksChange = (newHook) => {
        this.setState({ webhooks: newHook, unsavedChanges: true });
    };

    render() {
        const { role = '' } = this.context;
        const {
            isNew,
            loading,
            error,
            saving,
            data,
            unsavedChanges,
            formError,
            targeting = {},
            defaultActiveTab,
            defaultActivePanel,
            visible,
            uuid,
            endOfTerm,
            notificationRecipients,
            label,
            type,
            modalVisible,
            webhooks,
            hideSecondPage,
        } = this.state;
        const { match, isDemoMode } = this.props;
        const { shortid } = match.params;

        const shareableLink = `${window.location.origin}/preview/${shortid}`;

        if (loading) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <Spin size="large" />
                </div>
            );
        }

        if (error) {
            return (
                <div style={{ textAlign: 'center' }}>
                    Ihre Werbeanzeige konnte leider nicht geladen werden. Bitte kontaktieren sie{' '}
                    <a href="mailto:support@uninow.de">support@uninow.de</a>.
                </div>
            );
        }

        const { devices = [], semesters = [], locales = [], userLimit, deltaTimeInMin } = targeting;

        const isAdmin = !isDemoMode && role && ['SUPERADMIN', 'ADMIN'].includes(role.toUpperCase());

        const advertFormProps =
            type === '2'
                ? {
                      disableVideo: true,
                      imageWidth: 1200,
                      imageHeight: 600,
                      type,
                  }
                : { type };

        return (
            <FormLayout
                formIsDirty={unsavedChanges}
                buttons={[
                    <Button
                        key="save"
                        type="primary"
                        icon="save"
                        htmlType="submit"
                        loading={saving}
                        disabled={(!unsavedChanges && !isNew) || formError}
                        onClick={this.handleSave}>
                        Speichern
                    </Button>,
                    <Button key="cancel" disabled={saving} onClick={this.handleCancel}>
                        Abbrechen
                    </Button>,
                ]}>
                <Row id="EditAdvert">
                    <Col span={16}>
                        <Row
                            type="flex"
                            justify="space-between"
                            align="top"
                            style={{ marginBottom: 25 }}>
                            <div style={{ marginBottom: '0.5em' }}>
                                <h2 style={{ marginBottom: 0 }}>UniNow Werbeanzeige (Vorschau)</h2>
                                <InlineEdit
                                    initialValue={label || uuid || 'Label (optional)'}
                                    onSave={this.handleLabelChange}
                                />
                            </div>
                            <Button onClick={this.showModal} type="primary">
                                <Icon type="share-alt" /> Teilen
                            </Button>
                            <Modal
                                maskClosable
                                footer={null}
                                onCancel={this.hideModal}
                                visible={modalVisible}
                                title="Öffentlicher Link">
                                <Row type="flex" justify="space-between" align="middle">
                                    <Col span={19} style={{ paddingRight: 20 }}>
                                        <Input readOnly value={shareableLink} />
                                    </Col>
                                    <Col span={5}>
                                        <CopyToClipboard
                                            text={shareableLink}
                                            onCopy={() =>
                                                Message.info('Link in die Zwischenablage kopiert')
                                            }>
                                            <Button type="primary">Kopieren</Button>
                                        </CopyToClipboard>
                                    </Col>
                                </Row>
                            </Modal>
                        </Row>
                        <Tabs
                            defaultActiveKey={defaultActiveTab}
                            animated={false}
                            type="card"
                            style={{ overflow: 'visible' }}>
                            <TabPane tab="Werbeanzeige" key="1" style={{ paddingTop: 10 }}>
                                {type === '1' ? (
                                    <AdvertPostForm data={data} onChange={this.handleChange} />
                                ) : (
                                    <AdvertForm
                                        setFormError={this.handleFormError}
                                        data={data}
                                        onChange={this.handleChange}
                                        {...advertFormProps}
                                        onHideSecondPage={(value) =>
                                            this.setState({ hideSecondPage: value })
                                        }
                                    />
                                )}
                            </TabPane>

                            {isAdmin && (
                                <TabPane
                                    tab="Targeting (Adminbereich)"
                                    key="2"
                                    style={{ paddingTop: 10 }}>
                                    {visible && (
                                        <Alert
                                            message={
                                                <div>
                                                    Änderungen beim Targeting können nur vorgenommen
                                                    werden, wenn die Werbeanzeige offline geschaltet
                                                    ist.{' '}
                                                    <a
                                                        style={{ color: '#f21850' }}
                                                        onClick={this.deactivateAdvert}>
                                                        Jetzt offline nehmen.
                                                    </a>
                                                </div>
                                            }
                                            type="info"
                                            style={{ marginBottom: '20px' }}
                                            showIcon
                                        />
                                    )}
                                    <Collapse
                                        accordion
                                        defaultActiveKey={defaultActivePanel}
                                        expandIconPosition="right">
                                        <Panel
                                            header={
                                                <span style={{ fontSize: '1.1em' }}>
                                                    <Icon
                                                        type="user"
                                                        style={{ paddingRight: 10 }}
                                                    />
                                                    Devices (Adminbereich)
                                                </span>
                                            }
                                            key="3">
                                            <div style={{ paddingLeft: 25, paddingRight: 25 }}>
                                                <AdvertDevicesForm
                                                    disabled={visible}
                                                    onChange={this.handleDeviceChange}
                                                    devices={devices}
                                                />
                                            </div>
                                        </Panel>
                                        <Panel
                                            header={
                                                <span style={{ fontSize: '1.1em' }}>
                                                    <Icon
                                                        type="sliders"
                                                        style={{ paddingRight: 10 }}
                                                    />
                                                    Limits (Adminbereich)
                                                </span>
                                            }
                                            key="4">
                                            <div style={{ paddingLeft: 25, paddingRight: 25 }}>
                                                <AdvertLimitsForm
                                                    disabled={visible}
                                                    onChangeUserLimit={this.handleUserLimitChange}
                                                    onChangeDeltaTime={
                                                        this.handleDeltaTimeInMinChange
                                                    }
                                                    userLimit={userLimit}
                                                    deltaTimeInMin={deltaTimeInMin}
                                                />
                                            </div>
                                        </Panel>

                                        <Panel
                                            header={
                                                <span style={{ fontSize: '1.1em' }}>
                                                    <Icon
                                                        type="number"
                                                        style={{ paddingRight: 10 }}
                                                    />
                                                    Semester (Adminbereich)
                                                </span>
                                            }
                                            key="5">
                                            <div style={{ paddingLeft: 25, paddingRight: 25 }}>
                                                <AdvertSemesterForm
                                                    disabled={visible}
                                                    onChange={this.handleSemesterChange}
                                                    initialSemesters={semesters}
                                                />
                                            </div>
                                        </Panel>
                                        <Panel
                                            header={
                                                <span style={{ fontSize: '1.1em' }}>
                                                    <Icon
                                                        type="global"
                                                        style={{ paddingRight: 10 }}
                                                    />
                                                    Lokalisierung (Adminbereich)
                                                </span>
                                            }
                                            key="6">
                                            <div style={{ paddingLeft: 25, paddingRight: 25 }}>
                                                <AdvertLocaleForm
                                                    disabled={visible}
                                                    onChange={this.handleLocaleChange}
                                                    initialLocales={locales}
                                                />
                                            </div>
                                        </Panel>
                                    </Collapse>
                                </TabPane>
                            )}

                            <TabPane
                                tab={<Icon type="setting" style={{ marginRight: 0 }} />}
                                key="3"
                                style={{ paddingTop: 10 }}>
                                <EndOfTermForm
                                    endOfTerm={endOfTerm}
                                    mailing={notificationRecipients}
                                    onChange={({ endOfTerm: newEndOfTerm, mailing }) =>
                                        this.handleChange(
                                            pickBy(
                                                {
                                                    endOfTerm: newEndOfTerm,
                                                    notificationRecipients: mailing,
                                                },
                                                (value) => value !== undefined,
                                            ),
                                        )
                                    }
                                    productName="Werbeanzeige"
                                />
                                <TargetSection targeting={targeting} visible={visible} />
                                {isAdmin && (
                                    <>
                                        <Divider />
                                        <EditWebhooksForm
                                            onChange={this.handleWebhooksChange}
                                            initialValues={webhooks}
                                        />
                                    </>
                                )}
                            </TabPane>
                        </Tabs>
                    </Col>
                    <Col offset={2} xxl={6} xl={4} span={6}>
                        <AdvertPreview data={data} type={type} hideSecondPage={hideSecondPage} />
                    </Col>
                </Row>
            </FormLayout>
        );
    }
}

EditAdvert.contextTypes = {
    role: PropTypes.string,
    request: PropTypes.func,
    upload: PropTypes.func,
};
EditAdvert.propTypes = {
    joblist: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    companyUuid: PropTypes.string.isRequired,
    isDemoMode: PropTypes.bool.isRequired,
    match: PropTypes.shape({ params: PropTypes.shape() }).isRequired,
    history: PropTypes.shape({}).isRequired,
};
const mapStateToProps = (state) => ({
    joblist: state.jobs.joblist,
    companyUuid: state.company.uuid,
    isDemoMode: state.user.isDemoMode,
});

export default connect(mapStateToProps)(EditAdvert);
