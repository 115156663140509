import React, { PureComponent } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormLayout } from '..';
import { saveSessionData } from '../../core/redux/actions';

class ProfileLayout extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            formIsDirty: false,
        };
    }

    componentWillMount = () => {
        this.company = JSON.stringify(this.props.company);
    };

    componentDidMount = () => {
        this.unsubscribe = this.context.subscribeToStore(() => {
            this.setState({ formIsDirty: true });
        });
    };

    componentWillUnmount = () => {
        this.resetData();
        this.unsubscribe();
    };

    resetData = () => {
        if (this.state.formIsDirty) {
            const company = JSON.parse(this.company);

            const prevVisibility = company.profile.visible;
            const curVisibility = this.props.company.profile.visible;

            if (curVisibility !== prevVisibility) {
                company.profile.visible = curVisibility;
            }

            this.props.saveSessionData({ company });
            this.setState({ formIsDirty: false });
        }
    };

    handleClickSave = async () => {
        const { onSubmit, onSubmitMin } = this.props;
        if (onSubmit && !(await onSubmit())) {
            return;
        }
        if (onSubmitMin && !(await onSubmitMin())) {
            return;
        }
        this.setState({ isLoading: true });
        const { updateCompanyData } = this.context;
        const wasSuccessful = await updateCompanyData();
        if (wasSuccessful) {
            this.company = JSON.stringify(this.props.company);
            this.setState({ formIsDirty: false });
        }
        this.setState({ isLoading: false });
    };

    render() {
        const { isLoading, formIsDirty } = this.state;
        const { infoText, children } = this.props;
        const { role } = this.context;

        const readonly = role === 'READONLY';

        return (
            <FormLayout
                formIsDirty={readonly ? false : formIsDirty}
                infoText={infoText}
                buttons={
                    readonly
                        ? []
                        : [
                              <Button
                                  key="save-profile"
                                  type="primary"
                                  icon="save"
                                  onClick={this.handleClickSave}
                                  loading={isLoading}
                                  disabled={!formIsDirty}>
                                  Speichern
                              </Button>,
                          ]
                }>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '100%', marginRight: '100px' }}>{children}</div>
                </div>
            </FormLayout>
        );
    }
}

ProfileLayout.contextTypes = {
    updateCompanyData: PropTypes.func.isRequired,
    setMessage: PropTypes.func.isRequired,
    subscribeToStore: PropTypes.func,
    role: PropTypes.string,
};

const mapStateToProps = (state) => ({
    company: state.company,
});

const mapDispatchToProps = (dispatch) => ({
    saveSessionData: (data) => dispatch(saveSessionData(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileLayout));
