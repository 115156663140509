import React, { Component } from 'react';
import { Form, Input, Icon } from 'antd';
import PropTypes from 'prop-types';

const FormItem = Form.Item;

class SettingsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFields(async (err, values) => {
            const { newPassword, currentPassword } = values;
            if (!err) {
                this.setState({ isLoading: true });
                await this.context.changePassword(currentPassword, newPassword);
                this.setState({ isLoading: false });
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        // const { isLoading } = this.state;

        return (
            <div>
                <Form onSubmit={this.handleSubmit} className="login-form">
                    <FormItem label="Aktuelles Passwort:">
                        {getFieldDecorator('currentPassword', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Bitte geben Sie Ihr aktuelles Passwort an.',
                                },
                            ],
                        })(
                            <Input
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder="Geben Sie hier ihr aktuelles Passwort ein..."
                            />,
                        )}
                    </FormItem>
                    <FormItem label="Neues Passwort:">
                        {getFieldDecorator('newPassword', {
                            rules: [
                                {
                                    validator: (rule, value, callback) => {
                                        const { getFieldValue } = this.props.form;
                                        if (value && value === getFieldValue('currentPassword')) {
                                            callback(
                                                'Das neue Passwort darf nicht dem aktuellen entsprechen.',
                                            );
                                        }
                                        callback();
                                    },
                                },
                                {
                                    required: true,
                                    message: 'Bitte geben Sie Ihr neues Passwort an.',
                                },
                            ],
                        })(
                            <Input
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder="Geben Sie hier ihr neues Passwort ein..."
                            />,
                        )}
                    </FormItem>
                    <FormItem label="Neues Passwort (Wiederholung):">
                        {getFieldDecorator('newPasswordRep', {
                            rules: [
                                {
                                    validator: (rule, value, callback) => {
                                        const { getFieldValue } = this.props.form;
                                        if (value && value !== getFieldValue('newPassword')) {
                                            callback(
                                                'Neues Passwort und Wiederholung müssen übereinstimmen.',
                                            );
                                        }
                                        callback();
                                    },
                                },
                                {
                                    required: true,
                                    message: 'Bitte wiederholen Sie Ihr neues Passwort.',
                                },
                            ],
                        })(
                            <Input
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder="Wiederholen Sie ihr neues Passwort..."
                            />,
                        )}
                    </FormItem>
                </Form>
            </div>
        );
    }
}

SettingsForm.contextTypes = {
    changePassword: PropTypes.func.isRequired,
};

export default Form.create()(SettingsForm);
