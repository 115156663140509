import React, { Component } from 'react';

import { Row, Col } from 'antd';

class ItemGrid extends Component {
    shouldComponentUpdate = (nextProps) => {
        const { gridItems: nextGridItems } = nextProps;
        const { gridItems: curGridItems } = this.props;

        const shouldUpdate =
            nextGridItems.length !== curGridItems.length || nextGridItems !== curGridItems;

        return shouldUpdate;
    };

    render() {
        const { gridItems, dropZone, renderItem } = this.props;

        return (
            <Row type="flex">
                {gridItems
                    .filter((item) => item)
                    .map((item, index) => (
                        <Col span={8} key={item.tempID || item._id}>
                            {renderItem(item, index)}
                        </Col>
                    ))}

                <Col span={8} key="dropzone">
                    {dropZone}
                </Col>
            </Row>
        );
    }
}

export default ItemGrid;
