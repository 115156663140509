import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Table, Input, Modal, Button, Badge } from 'antd';

import ROLES from '../../core/constants/roles';

const { TextArea } = Input;

const HEIGHT_OFFSET = 280;

const ROLE_MAP = ROLES.reduce((map, role) => {
    map[role.key] = role.name;
    return map;
}, {});

const columns = [
    {
        title: 'Unternehmen',
        dataIndex: 'companyName',
        key: 'companyName',
        width: '30%',
    },
    {
        title: 'Status',
        dataIndex: 'companyVisible',
        key: 'companyVisible',
        width: 150,
        filters: [
            { text: 'Aktiv', value: 'true' },
            { text: 'Inaktiv', value: 'false' },
        ],
        onFilter: (value, record) => value === (record.companyVisible || 'false').toString(),
        render: (value, record) => (
            <div style={{ textAlign: 'center' }}>
                {value ? (
                    <span>
                        <Badge status="success" /> aktiv
                    </span>
                ) : (
                    <span>
                        <Badge status="error" /> inaktiv
                    </span>
                )}
            </div>
        ),
    },
    {
        title: 'Name',
        dataIndex: 'username',
        key: 'username',
        width: '50%',
    },
    {
        title: 'Rolle',
        dataIndex: 'role',
        key: 'role',
        width: 150,
        render: (role) => ROLE_MAP[role] || 'INVALID ROLE',
        filters: ROLES.map((role) => ({ text: role.name, value: role.key })),
        onFilter: (value, record) => value === record.role,
    },
];

class MemberList extends Component {
    state = {
        members: [],
        currentDataSource: [],
        selectedRowKeys: [],
        emailString: '',
        loading: true,
        modalVisible: false,
        scrollHeight: window.innerHeight - HEIGHT_OFFSET,
    };

    onDimensionChange = () => {
        this.setState({
            scrollHeight: window.innerHeight - HEIGHT_OFFSET,
        });
    };

    componentDidMount() {
        this.loadMembers();
        window.addEventListener('resize', this.onDimensionChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onDimensionChange);
    }

    loadMembers = async () => {
        const { request } = this.context;

        const { body } = await request('recruiting/administration/members');

        this.setState({
            loading: false,
            members: body,
            currentDataSource: body,
        });
    };

    onFilterChange = (a) => {
        this.setState({
            selectedRowKeys: [],
            emailString: '',
        });
    };

    onChange = (pagination, filters, sorter, { currentDataSource }) => {
        this.setState({
            currentDataSource,
        });
    };

    onRowSelection = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys,
            emailString: selectedRows.map((member) => member.username).join('; '),
        });
    };

    render() {
        const {
            members,
            currentDataSource,
            selectedRowKeys,
            scrollHeight,
            emailString,
            loading,
            modalVisible,
        } = this.state;

        return (
            <div>
                <Button
                    disabled={!emailString}
                    type="primary"
                    onClick={() => this.setState({ modalVisible: true })}>
                    E-Mail-Liste {emailString ? ` (${selectedRowKeys.length} Nutzer)` : null}
                </Button>
                <Modal
                    bodyStyle={{ textAlign: 'right ' }}
                    title="E-Mail-Liste"
                    onCancel={() => this.setState({ modalVisible: false })}
                    footer={null}
                    visible={modalVisible}>
                    <TextArea value={emailString} rows={4} />
                    <a
                        className="primary-link"
                        href={`data:application/octet-stream,${emailString}`}
                        download="E-Mail-Liste.txt">
                        Download
                    </a>
                </Modal>
                <Table
                    loading={loading}
                    pagination={{ pageSize: 10 }}
                    scroll={{ y: scrollHeight }}
                    onFilter={this.onFilterChange}
                    onChange={this.onChange}
                    columns={columns}
                    dataSource={members}
                    rowSelection={{
                        selectedRowKeys,
                        onChange: this.onRowSelection,
                        hideDefaultSelections: true,
                        selections: [
                            {
                                key: 'allData',
                                text: 'Alle auswählen',
                                onSelect: () => {
                                    this.setState({
                                        selectedRowKeys: currentDataSource.map(
                                            (member) => member._id,
                                        ),
                                        emailString: currentDataSource
                                            .map((member) => member.username)
                                            .join('; '),
                                    });
                                },
                            },
                            {
                                key: 'noData',
                                text: 'Keinen auswählen',
                                onSelect: () => {
                                    this.setState({
                                        selectedRowKeys: [],
                                        emailString: '',
                                    });
                                },
                            },
                        ],
                    }}
                    rowKey="_id"
                />
            </div>
        );
    }
}

MemberList.contextTypes = {
    request: PropTypes.func.isRequired,
};

export default MemberList;
