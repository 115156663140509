import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Phone from '../Phone';
import Overview from './pages/overview';
import Benefits from './pages/benefits';
import SocialMedia from './pages/socialMedia';
import Events from './pages/events';
import Awards from './pages/awards';
import Testimonials from './pages/testimonials';
import JobList from './pages/jobList';
import JobDetail from './pages/jobDetail';
import Feed from './pages/FeedPreviewPhone';

import './preview.css';

class Preview extends Component {
    render() {
        return (
            <div className="preview">
                <Phone backgroundColor="white">
                    <Switch>
                        <Route exact path="/profil/benefits" component={Benefits} />
                        <Route exact path="/profil/sozialemedien" component={SocialMedia} />
                        <Route exact path="/profil/events" component={Events} />
                        <Route exact path="/profil/auszeichnungen" component={Awards} />
                        <Route exact path="/profil/insights" component={Testimonials} />
                        <Route path="/beitrage" component={Feed} />
                        <Route path="*" component={Overview} />
                    </Switch>
                </Phone>
            </div>
        );
    }
}

export default Preview;
