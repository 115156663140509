import { SESSIONDATA_LOAD_SUCCESS } from '../actions/index';

const companyList = (
    state = {
        list: [],
    },
    action,
) => {
    const { type, companyList } = action;
    if (type === SESSIONDATA_LOAD_SUCCESS && companyList) {
        return { list: [...companyList] };
    }

    return state;
};

export default companyList;
