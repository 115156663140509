import React, { PureComponent } from 'react';
import { EditorModal } from '../../..';

export default class ImageEditorModal extends PureComponent {
    handleSaveEdit = () => {
        const { onSave } = this.props;
        const imageToSave = this.imageEditorRef.getImage();

        if (onSave) {
            onSave(imageToSave);
        }
    };

    handleCancelEdit = () => {
        const { onCancel } = this.props;
        if (onCancel) {
            onCancel();
        }
    };

    handleDeleteImage = () => {
        const { onDelete } = this.props;
        if (onDelete) {
            onDelete();
        }
    };

    getEditorRef = (imageEditorRef) => {
        this.imageEditorRef = imageEditorRef;
    };

    render() {
        const { imageToEdit, enableZoomOut, visible, optimalWidth, optimalHeight } = this.props;
        let isEdit = false;

        if (imageToEdit) {
            const { tempID, _id } = imageToEdit;
            isEdit = !!(_id || tempID);
        }
        return (
            <EditorModal
                visible={visible}
                editMode={isEdit}
                onRef={this.getEditorRef}
                imageToEdit={imageToEdit}
                optimalWidth={optimalWidth}
                optimalHeight={optimalHeight}
                enableZoomOut={enableZoomOut}
                onCancel={this.handleCancelEdit}
                onSave={this.handleSaveEdit}
                onDelete={this.handleDeleteImage}
            />
        );
    }
}
