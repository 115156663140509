import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Icon, Form, Input } from 'antd';
import { mapValues } from 'lodash';
import { loadFromFile } from '../image';
import PostImageDropzone from '../../../../PostImageDropzone';

const URL_REGEX = new RegExp(
    '^(?!mailto:)(?:(?:http|https|ftp)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$',
    'i',
);

class Blog extends Component {
    constructor(props) {
        super(props);
        let { image = null } = props.data || {};
        if (props.data?.translations?.image) {
            image = props.data?.translations?.image?.[props.language] || null;
        }

        this.state = {
            image,
            primaryLanguage: 'de',
        };
    }

    onImageFileSelected = async (files) => {
        const { setFieldsValue } = this.props.form;

        const selectedImage = await loadFromFile(files[0]);

        setFieldsValue({
            [`attachment.translations.image.${this.props.language}`]: selectedImage,
        });

        this.setState({
            image: selectedImage,
        });
    };

    removeImage = () => {
        const { setFieldsValue } = this.props.form;

        setFieldsValue({
            [`attachment.translations.image.${this.props.language}`]: null,
        });

        this.setState({
            image: null,
        });
    };

    addProtocolToLink = () => {
        const { getFieldValue, getFieldError, setFieldsValue, validateFields } = this.props.form;

        const link = getFieldValue(`attachment.translations.url.${this.props.language}`);
        const error = getFieldError(`attachment.translations.url.${this.props.language}`);

        if (link && error && !/^(https?:|uninow:)/gim.test(link)) {
            setFieldsValue(
                {
                    [`attachment.translations.url.${this.props.language}`]: `https://${link}`,
                },
                () => validateFields([`attachment.translations.url.${this.props.language}`]),
            );
        }
    };

    render() {
        const { form, data } = this.props;
        const { getFieldDecorator, getFieldValue } = form;
        const { image } = this.state;

        const { title, url, subtitle, description, translations } = data || {};

        const formTranslations = getFieldValue(`attachment.translations`);
        const primaryValues = formTranslations
            ? mapValues(formTranslations, (el) => el?.[this.state.primaryLanguage])
            : null;

        return (
            <div style={{ padding: '0px 20px 10px 20px' }}>
                <Row type="flex" align="top" gutter={25}>
                    <Col span={12} style={{ height: 100 }}>
                        {getFieldDecorator(`attachment.translations.image.${this.props.language}`, {
                            initialValue: image,
                        })(
                            image ? (
                                <div>
                                    <img
                                        className="gallery-image"
                                        src={image.preview || image.large}
                                        alt=""
                                    />
                                    <div className="gallery-overlay">
                                        <div className="inner-overlay-two">
                                            <div
                                                className="inner-overlay-three"
                                                onClick={() => this.removeImage()}>
                                                <Icon type="close" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <PostImageDropzone
                                    icon="plus"
                                    multiple={false}
                                    onFileSelected={this.onImageFileSelected}
                                    style={{ position: 'relative' }}
                                />
                            ),
                        )}
                    </Col>
                    <Col span={12}>
                        <Form.Item className="padded-input">
                            {getFieldDecorator(
                                `attachment.translations.title.${this.props.language}`,
                                {
                                    initialValue: translations?.title
                                        ? translations?.title?.[this.props.language]
                                        : title,
                                    rules: [
                                        {
                                            required:
                                                this.props.language === this.state.primaryLanguage,
                                        },
                                    ],
                                },
                            )(<Input placeholder={primaryValues?.title || 'Titel'} />)}
                        </Form.Item>
                        <Form.Item className="padded-input">
                            {getFieldDecorator(
                                `attachment.translations.url.${this.props.language}`,
                                {
                                    initialValue: translations?.url
                                        ? translations?.url?.[this.props.language]
                                        : url,
                                    rules: [
                                        {
                                            required:
                                                this.props.language === this.state.primaryLanguage,
                                        },
                                        {
                                            validator: (rule, value, callback) => {
                                                if (
                                                    !value &&
                                                    this.props.language !==
                                                        this.state.primaryLanguage
                                                ) {
                                                    return;
                                                }
                                                if (URL_REGEX.test(value)) {
                                                    callback();
                                                } else if (/^uninow:\/\/\S/.test(value)) {
                                                    callback();
                                                } else {
                                                    callback('Bitte gib einen validen Link an.');
                                                }
                                            },
                                        },
                                    ],
                                },
                            )(
                                <Input
                                    placeholder={primaryValues?.url || 'Link'}
                                    onBlur={this.addProtocolToLink}
                                />,
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ paddingTop: 10 }}>
                    <Form.Item className="padded-input">
                        {getFieldDecorator(
                            `attachment.translations.subtitle.${this.props.language}`,
                            {
                                initialValue: translations?.subtitle
                                    ? translations?.subtitle?.[this.props.language]
                                    : subtitle,
                            },
                        )(<Input placeholder={primaryValues?.subtitle || 'Untertitel'} />)}
                    </Form.Item>
                    <Form.Item className="padded-input">
                        {getFieldDecorator(
                            `attachment.translations.description.${this.props.language}`,
                            {
                                initialValue: translations?.description
                                    ? translations?.description?.[this.props.language]
                                    : description,
                            },
                        )(
                            <Input.TextArea
                                autoSize={{ minRows: 2, maxRows: 2 }}
                                placeholder={primaryValues?.description || 'Beschreibung'}
                            />,
                        )}
                    </Form.Item>
                </Row>
            </div>
        );
    }
}

Blog.propTypes = {
    form: PropTypes.object.isRequired,
    language: PropTypes.string,
};

export default Blog;
