import { PURGE } from 'redux-persist';
import { SWITCH_PROFILE } from '../actions/user.js';
import { SET_DEMOMODE } from '../actions';

const defaultState = {
    profileId: null,
};

export default function authentication(state = defaultState, action) {
    const { type, profileId } = action;

    switch (type) {
        case SWITCH_PROFILE:
            return {
                ...state,
                profileId,
            };
        case PURGE:
            return defaultState;
        case SET_DEMOMODE:
            return { ...state, isDemoMode: action.value };
        default:
            return state;
    }
}
