import React from 'react';
import StudentsProfilesLayout from '../layout/StudensProfilesLayout';
import { InstantSearch } from 'react-instantsearch';
import useMeiliSearch from '../../../../core/api/MeileSearchClient';

const StudentsProfileSearch = () => {
    const { client } = useMeiliSearch();

    return (
        <InstantSearch
            indexName="talent_profiles:createdAt:desc"
            searchClient={client.searchClient}
            routing={true}>
            <StudentsProfilesLayout title="Suche" />
        </InstantSearch>
    );
};

export default StudentsProfileSearch;
