import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AutoComplete, Select } from 'antd';
import { RequestContext } from '../../components/Session/RequestProvider';

function UniversityAutocomplete({ onChange, value }) {
    const [options, setOptions] = useState([]);
    const { request } = useContext(RequestContext);
    const find = async () => {
        const { body } = await request('recruiting/universities/listAll');
        setOptions(body ?? []);
    };
    useEffect(() => {
        find().catch((e) => console.error(e));
    }, []);
    return (
        <Select
            value={value}
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Tags Mode"
            onChange={onChange}
            options={options}>
            {options.map(({ id }) => (
                <Select.Option key={id}>{id}</Select.Option>
            ))}
        </Select>
    );
}

UniversityAutocomplete.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default UniversityAutocomplete;
