import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    Input,
    InputNumber,
    Form,
    Select,
    AutoComplete,
    Row,
    Col,
    DatePicker,
    Message,
    Icon,
    Divider,
    Button,
    Modal,
    Alert,
    Checkbox,
} from 'antd';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { setAttributeValue } from '../../core/redux/actions';
import { ProfileLayout, ImageManager, AdminArea } from '../../components';
import { FormRow, FormCol } from '../../components/styles';
import labelWithTooltip from '../../decorators/labelWithTooltip';

import imageConfig from '../../core/constants/imageConfig';
import LocationUniFinder from './LocationUniFinder';
import PreviewQR from '../Statistics/components/PreviewQR';
import MarkdownLink from '../MarkdownHelp/MarkdownLink';
import UniversityAutocomplete from './UniversityAutocomplete';
import PlanSelect from './PlanSelect';
import PlanAdjustModal from './PlanAdjustModal';
import urlPattern from '../../core/helper/urlPattern';

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

class General extends Component {
    employeesOpts = [
        '1-10',
        '11-50',
        '51-200',
        '201-500',
        '501-1.000',
        '1.001-5.000',
        '5.001-10.000',
        'mehr als 10.000',
    ];

    attribute = 'profile';

    constructor() {
        super();
        this.state = { loading: true, industries: {} };
    }

    fetchIndustries = async () => {
        const { request } = this.context;

        const { body } = await request('recruiting/config/industries');

        if (body) {
            const mappedIndustries = {};
            body.forEach((industry) => {
                mappedIndustries[industry.title] = industry.specializations.sort();
            });
            this.setState({
                industries: mappedIndustries,
            });
        }

        this.setState({ loading: false });
    };

    componentDidMount() {
        this.fetchIndustries();
    }

    handleSubmit = async () =>
        new Promise((resolve) =>
            this.props.form.validateFieldsAndScroll((err) => {
                if (!err) {
                    resolve(true);
                }
                resolve(false);
            }),
        );

    render() {
        const {
            form,
            industry,
            setAttributeValue: setAttrVal,
            aboutUs,
            logo,
            addresses,
            tags = [],
            visible,
            administratingCompany,
            administratingAccounts,
            plan,
        } = this.props;
        const { getFieldDecorator } = form;

        const now = moment();
        const isAdmin = ['ADMIN', 'SUPERADMIN'].includes(this.context.role.toUpperCase());

        return (
            <ProfileLayout
                subHeaderSelectedKey="Allgemein"
                onSubmit={this.handleSubmit}
                infoText="Geben Sie den Studierenden grundlegende Informationen zu Ihrem Unternehmen. Bitte füllen Sie dabei sämtliche Felder aus, die mit einem * markiert sind.">
                <Form onSubmit={this.handleSubmit} layout="vertical">
                    {isAdmin && administratingCompany && (
                        <Alert
                            message={`Dieses Unternehmen wird von Unternehmen/Hochschule "${administratingCompany}" verwaltet`}
                            type="warning"
                            style={{ marginBottom: '20px' }}
                            showIcon
                        />
                    )}
                    {isAdmin && administratingAccounts?.length > 0 && (
                        <Alert
                            message={`Dieses Unternehmen wird von "${administratingAccounts
                                .map(({ email }) => email)
                                .join(', ')}" verwaltet`}
                            type="warning"
                            style={{ marginBottom: '20px' }}
                            showIcon
                        />
                    )}
                    <AdminArea>
                        <Row>
                            <Col span={12}>
                                <FormItem label="Laufzeitende:">
                                    {getFieldDecorator('endOfTerm', {
                                        onChange: (date) => setAttrVal('endOfTerm', date),
                                    })(
                                        <DatePicker
                                            disabledDate={(currentDate) =>
                                                currentDate.isBefore(now)
                                            }
                                            format="DD.MM.YYYY"
                                        />,
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <FormItem label="Paket auswählen">
                                {getFieldDecorator('plan', {
                                    onChange: (value) => {
                                        setAttrVal('plan', value);
                                        setAttrVal('originalPlan', value);
                                    },
                                })(<PlanSelect />)}
                            </FormItem>
                        </Row>
                        <PlanAdjustModal form={form} />
                        <Divider />
                        <Row>
                            <FormItem label="Tags:">
                                {getFieldDecorator('tags', {
                                    initialValue: tags,
                                    onChange: (tags) => setAttrVal(`${this.attribute}.tags`, tags),
                                })(
                                    <Select
                                        mode="tags"
                                        style={{ width: '100%' }}
                                        placeholder="Tags">
                                        <Select.Option key="IDW">IDW</Select.Option>
                                    </Select>,
                                )}
                            </FormItem>
                        </Row>
                        <Row>
                            <LocationUniFinder addresses={addresses} />
                        </Row>
                        <Row>
                            <Col span={12}>
                                <FormItem label="Statistik Start:">
                                    {getFieldDecorator('startOfStatistics', {
                                        onChange: (date) => setAttrVal('startOfStatistics', date),
                                    })(<DatePicker format="DD.MM.YYYY" />)}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <FormItem label="Limitiere Targeting Universitäten">
                                {getFieldDecorator('limitTargetingUniversities', {
                                    onChange: (value) =>
                                        setAttrVal('limitTargetingUniversities', value),
                                })(<UniversityAutocomplete />)}
                            </FormItem>
                        </Row>
                        <Row>
                            <FormItem>
                                {getFieldDecorator('isTemplate', {
                                    onChange: (e) => setAttrVal('isTemplate', e.target.checked),
                                    valuePropName: 'checked',
                                })(<Checkbox>Als Template verwenden</Checkbox>)}
                            </FormItem>
                            <FormItem>
                                {getFieldDecorator('showCompanyStats', {
                                    onChange: (e) =>
                                        setAttrVal('showCompanyStats', e.target.checked),
                                    valuePropName: 'checked',
                                })(<Checkbox>Unternehmensstatistiken anzeigen</Checkbox>)}
                            </FormItem>
                            <FormItem>
                                {getFieldDecorator('showFeed', {
                                    onChange: (e) => setAttrVal('showFeed', e.target.checked),
                                    valuePropName: 'checked',
                                })(<Checkbox>Beiträge anzeigen</Checkbox>)}
                            </FormItem>
                            <FormItem>
                                {getFieldDecorator('showInstagramSync', {
                                    onChange: (e) =>
                                        setAttrVal('showInstagramSync', e.target.checked),
                                    valuePropName: 'checked',
                                })(<Checkbox>Instagram Schnittstelle anzeigen</Checkbox>)}
                            </FormItem>
                            <FormItem>
                                {getFieldDecorator('showTalentPool', {
                                    onChange: (e) => setAttrVal('showTalentPool', e.target.checked),
                                    valuePropName: 'checked',
                                })(<Checkbox>Studierendenprofile anzeigen</Checkbox>)}
                            </FormItem>
                        </Row>
                    </AdminArea>
                    <FormItem
                        label={
                            <>
                                Name Ihres Unternehmens
                                {isAdmin && (
                                    <span
                                        style={{
                                            color: '#8E8E8E',
                                            cursor: 'pointer',
                                        }}>
                                        (
                                        <CopyToClipboard
                                            text={this.props.id}
                                            onCopy={() =>
                                                Message.info('ID in die Zwischenablage kopiert')
                                            }>
                                            <span>
                                                ID: {this.props.id} <Icon type="copy" />
                                            </span>
                                        </CopyToClipboard>
                                        {this.props.feedUser && (
                                            <>
                                                {', '}
                                                <CopyToClipboard
                                                    text={this.props.feedUser.username}
                                                    onCopy={() =>
                                                        Message.info(
                                                            'Feed User in die Zwischenablage kopiert',
                                                        )
                                                    }>
                                                    <span>
                                                        Feed User: @{this.props.feedUser.username}{' '}
                                                        <Icon type="copy" />
                                                    </span>
                                                </CopyToClipboard>
                                            </>
                                        )}
                                        )
                                    </span>
                                )}
                                :
                            </>
                        }>
                        {getFieldDecorator('name', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Bitte tragen Sie den Namen des Unternehmens ein.',
                                },
                            ],

                            onChange: (e) => setAttrVal(`${this.attribute}.name`, e.target.value),
                        })(<Input placeholder="z.B. UniNow GmbH" />)}
                    </FormItem>
                    <FormItem label="Untertitel für Ihr Unternehmen:">
                        {getFieldDecorator('subtitle', {
                            rules: [
                                {
                                    required: false,
                                    message:
                                        'Bitte tragen Sie den Untertitel des Unternehmens ein.',
                                },
                            ],

                            onChange: (e) =>
                                setAttrVal(`${this.attribute}.subtitle`, e.target.value),
                        })(<Input placeholder="optional" />)}
                    </FormItem>
                    <FormItem
                        required
                        label={labelWithTooltip(
                            'Logo',
                            `Das optimale Seitenverhältnis für ein Logo beträgt 3:2 (~${imageConfig.logoOptimalSize.width}x${imageConfig.logoOptimalSize.height}px), bei einer maximalen Datei-Größe von 20MB. Unterstützt werden die Datei-Formate JPEG/JPG sowie PNG.`,
                        )}>
                        {getFieldDecorator('logo', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Bitte wählen Sie ein Logo.',
                                },
                            ],
                            initialValue: logo,
                            onChange: (value) => {
                                setAttrVal('profile.logo', value);
                            },
                        })(
                            <ImageManager
                                image={logo}
                                width={300}
                                optimalWidth={imageConfig.logoOptimalSize.width}
                                optimalHeight={imageConfig.logoOptimalSize.height}
                                enableZoomOut
                                imageRatio={{ x: 3, y: 2 }}
                            />,
                        )}
                    </FormItem>

                    <FormRow>
                        <FormCol width="45%">
                            <FormItem
                                label={labelWithTooltip(
                                    'Branche',
                                    'Tragen Sie in dieses Feld ein, in welcher Branche Ihr Unternehmen tätig ist.',
                                )}>
                                {getFieldDecorator('industry', {
                                    rules: [
                                        {
                                            required: true,
                                            message:
                                                'Bitte wählen Sie die Branche Ihres Unternehmens',
                                        },
                                    ],
                                    onChange: (value) => {
                                        if (value !== industry) {
                                            setAttrVal(`${this.attribute}.industry`, value);
                                            setAttrVal(
                                                `${this.attribute}.specialization`,
                                                undefined,
                                            );
                                        }
                                    },
                                })(
                                    <Select
                                        showSearch
                                        placeholder="z.B. Technik & Telekommunikation"
                                        notFoundContent="Keine passende Branche gefunden"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                        loading={this.state.loading}>
                                        {!this.state.loading &&
                                            Object.keys(this.state.industries)
                                                .sort()
                                                .map((elem, index) => (
                                                    <Option key={index} value={elem}>
                                                        {elem}
                                                    </Option>
                                                ))}
                                    </Select>,
                                )}
                            </FormItem>
                        </FormCol>
                        <FormCol width="10%">
                            <span
                                style={{
                                    display: 'inline-block',
                                    width: '100%',
                                    textAlign: 'center',
                                }}
                            />
                        </FormCol>
                        <FormCol width="45%">
                            <FormItem
                                label={labelWithTooltip(
                                    'Spezialisierung',
                                    'Ist Ihr Unternehmen innerhalb seiner Branche spezialisiert? (Wird nur zur Darstellung genutzt, keine Filterung)',
                                )}>
                                {getFieldDecorator('specialization', {
                                    onChange: (value) =>
                                        setAttrVal(`${this.attribute}.specialization`, value),
                                })(
                                    <AutoComplete
                                        style={{ overflow: 'hidden' }}
                                        placeholder="z.B. Software"
                                        disabled={!industry || this.state.loading}
                                        dataSource={
                                            !this.state.loading && this.state.industries[industry]
                                        }
                                        filterOption={(inputValue, option) =>
                                            option.props.children
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                    />,
                                )}
                            </FormItem>
                        </FormCol>
                    </FormRow>

                    <FormRow>
                        <FormCol width="45%">
                            <FormItem label="Anzahl Ihrer Mitarbeiter:">
                                {getFieldDecorator('employees', {
                                    rules: [
                                        {
                                            required: true,
                                            message:
                                                'Bitte wählen Sie die zutreffene Anzahl an Mitarbeitern aus.',
                                        },
                                    ],
                                    onChange: (value) =>
                                        setAttrVal(`${this.attribute}.employees`, value),
                                })(
                                    <Select placeholder="Wählen Sie die Anzahl ihrer Mitarbeiter...">
                                        {this.employeesOpts.map((value) => (
                                            <Option value={value} key={value}>
                                                {value}
                                            </Option>
                                        ))}
                                    </Select>,
                                )}
                            </FormItem>
                        </FormCol>
                        <FormCol width="10%">
                            <span
                                style={{
                                    display: 'inline-block',
                                    width: '100%',
                                    textAlign: 'center',
                                }}
                            />
                        </FormCol>
                        <FormCol width="45%">
                            <FormItem label="Gründungsjahr:">
                                {getFieldDecorator('yearOfFoundation', {
                                    rules: [
                                        {
                                            pattern:
                                                /^(8|9|10|11|12|13|14|15|16|17|18|19|20)[0-9][0-9]/,
                                            message: 'Bitte geben Sie eine gültige Jahreszahl an.',
                                        },
                                    ],
                                    onChange: (value) =>
                                        setAttrVal(
                                            `${this.attribute}.yearOfFoundation`,
                                            value || null,
                                        ),
                                })(<InputNumber placeholder="JJJJ" />)}
                            </FormItem>
                        </FormCol>
                    </FormRow>

                    <FormItem
                        label={labelWithTooltip(
                            'Statement',
                            `Das	Werteversprechen,	der	Claim	oder
              die	Vision	Ihres	Unternehmens,	mit
              dem	Sie	Ihre	Mitarbeiter,	Kunden	und
              Bewerber	Tag	für	Tag	begeistern.`,
                        )}>
                        {getFieldDecorator('statement', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Bitte geben Sie ein Statement an',
                                },
                            ],
                            onChange: (e) =>
                                setAttrVal(`${this.attribute}.statement`, e.target.value),
                        })(
                            <Input placeholder="z.B. Der bequemste Weg dein Studium und deine Karriere zu organisieren." />,
                        )}
                    </FormItem>

                    <FormItem
                        label={labelWithTooltip(
                            'Beschreibung',
                            'Beschreiben Sie Ihr Unternehmen in einem prägnanten Text, welcher nicht länger als 1000 Zeichen ist (Leerzeichen inbegriffen).',
                        )}>
                        {getFieldDecorator('aboutUs', {
                            rules: [
                                {
                                    required: true,
                                    message:
                                        'Bitte geben Sie eine Beschreibung zu ihrem Unternehmen an.',
                                },
                                {
                                    max: 1000,
                                    message:
                                        'Bitte verwenden Sie nicht mehr als 1000 Zeichen (Leerzeichen inbegriffen).',
                                },
                            ],
                            onChange: (e) =>
                                setAttrVal(`${this.attribute}.aboutUs`, e.target.value),
                        })(
                            <TextArea
                                autoSize
                                maxLength="1000"
                                placeholder="Beschreiben Sie Ihr Unternehmen..."
                            />,
                        )}
                        <div>
                            {`${aboutUs ? aboutUs.length : 0}/1000 Zeichen `}
                            <MarkdownLink />
                        </div>
                    </FormItem>
                    <FormItem
                        label={labelWithTooltip(
                            'Impressum',
                            `Optionaler Link zum Impressum Ihres Unternehmens`,
                        )}>
                        {getFieldDecorator('imprint', {
                            rules: [
                                {
                                    pattern: urlPattern,
                                    message:
                                        'Bitte geben Sie eine gültige Adresse an (z.B. https://example.com )',
                                },
                            ],
                            onChange: (e) =>
                                setAttrVal(`${this.attribute}.imprint`, e.target.value),
                        })(<Input placeholder="http://" />)}
                    </FormItem>
                </Form>
                <Divider />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <Button
                        onClick={() =>
                            Modal.info({
                                title: `App ${visible ? 'Link' : 'Vorschau'}`,
                                content: (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            marginTop: 15,
                                        }}>
                                        <PreviewQR path={`career/company?id=${this.props.id}`} />
                                        <div style={{ marginLeft: 15 }}>
                                            Scannen Sie diesen QR-Code mit ihrem Mobilgerät, um ihr
                                            Unternehmensprofil in der UniNow App anzuzeigen
                                        </div>
                                    </div>
                                ),
                                onOk() {},
                            })
                        }
                        style={{ alignItems: 'center', display: 'flex' }}
                        icon="mobile"
                        type="primary">
                        App {visible ? 'Link' : 'Vorschau'}
                    </Button>
                    {this.props.endOfTerm && (
                        <div>
                            Vertragslaufzeit: {moment(this.props.endOfTerm).format('DD.MM.YYYY')}
                        </div>
                    )}
                </div>
            </ProfileLayout>
        );
    }
}

const mapStateToProps = (state) => ({
    id: state.company.uuid,
    name: state.company.profile.name,
    subtitle: state.company.profile.subtitle,
    logo: state.company.profile.logo,
    addresses: state.company.profile.addresses,
    industry: state.company.profile.industry,
    specialization: state.company.profile.specialization,
    yearOfFoundation: state.company.profile.yearOfFoundation,
    statement: state.company.profile.statement,
    employees: state.company.profile.employees,
    aboutUs: state.company.profile.aboutUs,
    tags: state.company.profile.tags,
    endOfTerm: state.company.endOfTerm || null,
    startOfStatistics: state.company.startOfStatistics || null,
    limitTargetingUniversities: state.company.limitTargetingUniversities,
    administratingCompany: state.company.administratingCompany,
    administratingAccounts: state.company.administratingAccounts,
    feedUser: state.company.feedUser,
    plan: state.company.plan,
    isTemplate: state.company.isTemplate,
    visible: state.company.profile.visible,
    showCompanyStats: state.company.showCompanyStats,
    planOverrides: state.company.planOverrides,
    imprint: state.company.profile.imprint,
    showFeed: state.company.showFeed,
    showInstagramSync: state.company.showInstagramSync,
    showTalentPool: state.company.showTalentPool,
});

const mapDispatchToProps = (dispatch) => ({
    setAttributeValue: (attributeName, value) => dispatch(setAttributeValue(attributeName, value)),
});

General.contextTypes = {
    role: PropTypes.string,
    request: PropTypes.func,
};

const WrappedGeneral = Form.create({
    mapPropsToFields(props) {
        const {
            name,
            subtitle,
            logo,
            industry,
            specialization,
            aboutUs,
            statement,
            yearOfFoundation,
            employees,
            endOfTerm,
            startOfStatistics,
            limitTargetingUniversities,
            plan,
            isTemplate,
            showCompanyStats,
            showFeed,
            planOverrides,
            imprint,
            showInstagramSync,
            showTalentPool,
        } = props;

        return {
            endOfTerm: Form.createFormField({ value: endOfTerm ? moment(endOfTerm) : null }),
            name: Form.createFormField({ value: name }),
            subtitle: Form.createFormField({ value: subtitle }),
            logo: Form.createFormField({ value: logo }),
            industry: Form.createFormField({ value: industry }),
            specialization: Form.createFormField({ value: industry && specialization }),
            statement: Form.createFormField({ value: statement }),
            aboutUs: Form.createFormField({ value: aboutUs }),
            yearOfFoundation: Form.createFormField({ value: yearOfFoundation }),
            employees: Form.createFormField({ value: employees || undefined }),
            startOfStatistics: Form.createFormField({
                value: startOfStatistics ? moment(startOfStatistics) : null,
            }),
            limitTargetingUniversities: Form.createFormField({ value: limitTargetingUniversities }),
            plan: Form.createFormField({ value: plan }),
            planOverridesNumLocations: Form.createFormField({ value: planOverrides.numLocations }),
            planOverridesNumImages: Form.createFormField({ value: planOverrides.numImages }),
            planOverridesNumJobs: Form.createFormField({ value: planOverrides.numJobs }),
            planOverridesPermissions: Form.createFormField({
                value: planOverrides.permissions || [],
            }),
            isTemplate: Form.createFormField({ value: isTemplate }),
            showCompanyStats: Form.createFormField({ value: showCompanyStats }),
            showFeed: Form.createFormField({ value: showFeed }),
            imprint: Form.createFormField({ value: imprint }),
            showInstagramSync: Form.createFormField({ value: showInstagramSync }),
            showTalentPool: Form.createFormField({ value: showTalentPool }),
        };
    },
})(General);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedGeneral);
