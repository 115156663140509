import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Modal } from 'antd';

class AdDropzone extends Component {
    render() {
        const { style, children, fileTypes, maxFileSize } = this.props;
        return (
            <Dropzone
                accept={fileTypes.join(', ')}
                maxSize={maxFileSize} // 200MB
                style={{
                    cursor: 'pointer',
                    backgroundColor: '#404040',
                    ...style,
                }}
                rejectStyle={{ boxShadow: 'inset 0 0 20px red' }}
                acceptStyle={{ boxShadow: 'inset 0 0 20px green' }}
                multiple={false}
                onDropRejected={(rejected) => {
                    let error = '';
                    const { type, size } = rejected[0];
                    if (!fileTypes.includes(type)) {
                        error =
                            'Es werden ausschließlich die Formate JPEG, PNG, MPEG, MP4, Quicktime unterstützt. Die ausgewählte Datei entspricht keinem dieser Formate.';
                    } else if (size > maxFileSize) {
                        error = `Die Größe der ausgewählten Datei ist größer als ${
                            maxFileSize / 1000000
                        }MB. Bitte wählen Sie ein kleineres Dokument.`;
                    }
                    if (error) {
                        Modal.error({
                            title: 'Fehler',
                            content: error,
                        });
                    }
                }}
                onDropAccepted={(accepted) => {
                    this.props.onFileDrop(accepted[0]);
                }}>
                {children}
            </Dropzone>
        );
    }
}

AdDropzone.PropTypes = {
    fileTypes: PropTypes.array,
    maxFileSize: PropTypes.number, // in byte
};

AdDropzone.defaultProps = {
    fileTypes: ['image/jpeg', 'image/png', 'video/mpeg', 'video/mp4', 'video/quicktime'],
    maxFileSize: 200000000,
};

export default AdDropzone;
