import React, { PureComponent } from 'react';
import { debounce, findIndex } from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import { Icon, Button, Input, Form, Table, Tag, Tooltip, Popconfirm, Radio } from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormRow, FormCol } from '../../components/styles';
import { ToggleModalButton, ActivityModal } from '../../components';
import TemplateSelect from './TemplateSelect';

const CompanyCreationButton = Form.create()((props) => {
    const { onSave, form } = props;
    const { getFieldDecorator, validateFields, resetFields } = form;

    return (
        <ToggleModalButton
            modalProps={{
                title: 'Erstellen Sie ein neues Unternehmen',
                onOk: async (toggleModal, setLoading) => {
                    await validateFields(async (err, values) => {
                        if (!err) {
                            const { companyName, template } = values;
                            setLoading(true);
                            await onSave(companyName, template?._id);
                            setLoading(false);
                            toggleModal();
                            resetFields();
                        }
                    });
                },
                okText: 'Erstellen + Wechseln',
                cancelText: 'Abbrechen',
                onCancel: resetFields,
                children: (
                    <>
                        <Form.Item label="Geben Sie dem neuen Unternehmen einen Namen:">
                            {getFieldDecorator('companyName', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Bitte geben Sie dem Unternehmen einen Namen.',
                                    },
                                ],
                            })(<Input placeholder="Name des Unternehmens..." />)}
                        </Form.Item>

                        <Form.Item label="Wählen Sie ein Template aus">
                            {getFieldDecorator('template')(<TemplateSelect />)}
                        </Form.Item>
                    </>
                ),
            }}
            buttonProps={{
                size: 'large',
                style: { width: '100%' },
                icon: 'plus',
                type: 'primary',
                children: 'Unternehmen erstellen',
            }}
        />
    );
});

class Companies extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            isSwitchingCompany: false,
            loadingCompanyID: '',
            activityShow: false,
            activityModalId: null,

            companies: [],
            pagination: {
                current: 1,
                pageSize: 8,
            },
            search: null,
            filterBy: [],
            sortBy: null,
        };
    }

    componentDidMount = () => {
        this.fetchCompanies();
    };

    fetchCompanies = async () => {
        const { search, filterBy, sortBy, pagination, startsWith } = this.state;
        const { request } = this.context;

        const { body } = await request('recruiting/administration/companies', {
            limit: pagination.pageSize,
            skip: (pagination.current - 1) * pagination.pageSize,
            search,
            visibility: filterBy,
            sortBy,
            startsWith,
        });

        if (body) {
            const { companies, count } = body;

            this.setState({
                companies,
                pagination: {
                    ...pagination,
                    total: count,
                },
            });
        }

        this.setState({ loading: false });
    };

    fetchCompaniesDebounced = debounce(this.fetchCompanies, 250);

    handleCompanySwitch = async (uuid, companyName) => {
        this.setState({ loadingCompanyID: uuid });
        const { switchCompany, setMessage } = this.context;
        const { history } = this.props;
        const wasSuccessful = await switchCompany(uuid);

        if (wasSuccessful) {
            setMessage(
                <span>
                    Sie sind erfolgreich zum Unternehmen <b>{companyName}</b> gewechselt.
                </span>,
                'success',
            );
            history.push('/profil');
        } else {
            setMessage(
                <span>
                    Beim wechseln zum Unternehmen <b>{companyName}</b> ist ein Fehler aufgetreten.
                    Bitte versuchen Sie es erneut.
                </span>,
                'error',
            );
            this.setState({ loadingCompanyID: '' });
        }
    };

    handleCompanyCreation = async (companyName, templateId) => {
        const { createCompany, setMessage } = this.context;
        const wasSuccessful = await createCompany(companyName, templateId);

        if (wasSuccessful) {
            setMessage(`Sie haben erfolgreich das Unternehmen ${companyName} erstellt.`, 'success');
        } else {
            setMessage(
                `Beim erstellen des Unternehmens ${companyName} ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.`,
                'error',
            );
        }
    };

    handleCompanyArchive = async (companyID, archive) => {
        const { request, setMessage } = this.context;
        const { error } = await request('recruiting/company/archive', { companyID, archive });
        if (!error) {
            setMessage(`Unternehmen ${archive ? 'archiviert' : 'reaktiviert'}`, 'success');
            const companies = this.state.companies.slice();
            const index = findIndex(companies, { _id: companyID });
            if (index >= 0) {
                companies.splice(index, 1, {
                    ...companies[index],
                    archived: archive,
                });
                this.setState({ companies });
            }
        } else {
            setMessage(
                `Beim ${
                    archive ? 'archivieren' : 'reaktivieren'
                } ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.`,
                'error',
            );
        }
    };

    handleSearch = ({ target }) => {
        if (target.value !== this.state.search) {
            const { pagination } = this.state;

            this.setState(
                {
                    loading: true,
                    search: target.value,
                    pagination: {
                        ...pagination,
                        current: 1,
                    },
                },
                this.fetchCompaniesDebounced,
            );
        }
    };

    showActivityModal = (companyId) => this.setState({ activityModalId: companyId });

    hideActivityModal = () => this.setState({ activityModalId: null });

    handleTableChange = (pagination = {}, filters = {}, sorter = {}) => {
        const sortBy = sorter.field ? { [sorter.field]: sorter.order === 'ascend' ? 1 : -1 } : null;

        this.setState(
            {
                loading: true,
                pagination,
                sortBy,
                filterBy: filters.visible || [],
            },
            this.fetchCompanies,
        );
    };

    getColumns = () => {
        const { role } = this.context;
        const isADMIN = ['SUPERADMIN', 'ADMIN', 'CUSTOMER_SUPPORT'].includes(role.toUpperCase());
        return [
            {
                title: 'Name',
                dataIndex: 'name',
                render: (text, { _id: companyId, profile }) => (
                    <div>
                        {profile.name}

                        {isADMIN && (
                            <div
                                style={{
                                    color: '#8f8f8f',
                                }}>
                                {companyId}
                            </div>
                        )}

                        <div>
                            {(profile.tags || []).map((tag) => (
                                <Tag key={tag}>{tag}</Tag>
                            ))}
                        </div>
                    </div>
                ),
            },
            {
                title: 'Aktualisiert am',
                dataIndex: 'updatedAt',
                render: (text, { updatedAt }) =>
                    updatedAt ? moment(updatedAt).format('DD.MM.YYYY') : '-',
                sorter: true,
            },
            {
                title: 'Laufzeitende',
                dataIndex: 'endOfTerm',
                render: (text, { endOfTerm }) =>
                    endOfTerm ? moment(endOfTerm).format('DD.MM.YYYY') : '-',
                sorter: true,
            },
            {
                title: 'Stellen- / Werbeanzeigen',
                dataIndex: 'jobs',
                render: (text, { jobs, adverts }) => `${jobs || 0} / ${adverts || 0}`,
            },
            {
                title: 'Sichtbar',
                dataIndex: 'visible',
                filters: [
                    {
                        text: 'Sichtbar',
                        value: 'visible',
                    },
                    {
                        text: 'Unsichtbar',
                        value: 'invisible',
                    },
                    ...(['SUPERADMIN', 'ADMIN', 'CUSTOMER_SUPPORT', 'UNI_ADMIN'].includes(
                        role.toUpperCase(),
                    )
                        ? [
                              {
                                  text: 'Archiviert',
                                  value: 'archived',
                              },
                          ]
                        : []),
                ],
                render: (text, { _id, profile, archived }) => (
                    <Tooltip title={profile.visible ? 'sichtbar' : 'unsichtbar'}>
                        <Icon
                            type={archived ? 'eye-invisible' : 'eye'}
                            theme={profile.visible ? 'filled' : 'outlined'}
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.showActivityModal(_id)}
                        />
                    </Tooltip>
                ),
            },
            ...(['SUPERADMIN', 'ADMIN', 'UNI_ADMIN'].includes(role.toUpperCase())
                ? [
                      {
                          title: '',
                          dataIndex: 'archiveCompany',
                          align: 'right',
                          render: (text, { _id, profile, archived }) => {
                              const { loadingCompanyID } = this.state;
                              const { companyUUID } = this.props;
                              return archived ? (
                                  <Popconfirm
                                      title="Wollen Sie dieses Profil wirklich reaktivieren?"
                                      onConfirm={() => this.handleCompanyArchive(_id, false)}
                                      okText="Ja"
                                      cancelText="Nein">
                                      {' '}
                                      <Button loading={_id === loadingCompanyID} icon="delete">
                                          Reaktivieren
                                      </Button>
                                  </Popconfirm>
                              ) : (
                                  <Popconfirm
                                      title="Wollen Sie dieses Profil wirklich archivieren?"
                                      disabled={_id === companyUUID || profile.visible}
                                      onConfirm={() => this.handleCompanyArchive(_id, true)}
                                      okText="Ja"
                                      cancelText="Nein">
                                      <Tooltip
                                          title={
                                              _id === companyUUID
                                                  ? 'Das aktuell ausgewählte Profil kann nicht archiviert werden'
                                                  : profile.visible &&
                                                    'Sichtbare Profile können nicht archiviert werden'
                                          }>
                                          <Button
                                              disabled={_id === companyUUID || profile.visible}
                                              loading={_id === loadingCompanyID}
                                              icon="delete">
                                              Archivieren
                                          </Button>
                                      </Tooltip>
                                  </Popconfirm>
                              );
                          },
                      },
                  ]
                : []),
            {
                title: '',
                dataIndex: 'switchCompany',
                align: 'right',
                render: (text, { _id, profile }) => {
                    const { loadingCompanyID } = this.state;
                    const { companyUUID } = this.props;
                    return (
                        <Button
                            disabled={_id === companyUUID}
                            loading={_id === loadingCompanyID}
                            icon="arrow-left"
                            onClick={() => this.handleCompanySwitch(_id, profile.name)}>
                            Wechseln
                        </Button>
                    );
                },
            },
        ];
    };

    handleStartsWithChange = (value) => {
        this.setState(
            ({ pagination }) => ({
                startsWith: value === 'all' || value === this.state.startsWith ? undefined : value,
                pagination: { ...pagination, current: 1 },
            }),
            this.fetchCompanies,
        );
    };

    render() {
        const { role } = this.context;
        const { activityModalId, loading, companies, pagination = {} } = this.state;

        const canCreateCompany = ['SUPERADMIN', 'ADMIN', 'UNI_ADMIN'].includes(role.toUpperCase());

        return (
            <div>
                <ActivityModal
                    visible={!!activityModalId}
                    id={activityModalId}
                    type="company"
                    onClose={this.hideActivityModal}
                />
                <FormRow style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <FormCol width={canCreateCompany ? '65%' : '100%'}>
                        <Input
                            size="large"
                            onKeyUp={this.handleSearch}
                            placeholder="Suchen Sie hier nach Unternehmen..."
                        />
                    </FormCol>
                    {canCreateCompany && (
                        <FormCol width="30%">
                            <CompanyCreationButton onSave={this.handleCompanyCreation} />
                        </FormCol>
                    )}
                </FormRow>
                <Radio.Group value={this.state.startsWith} style={{ marginBottom: 10 }}>
                    {['all', ...'ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÜ#'].map((letter) => (
                        <Radio.Button
                            key={letter}
                            value={letter}
                            onClick={() => this.handleStartsWithChange(letter)}>
                            {letter}
                        </Radio.Button>
                    ))}
                </Radio.Group>
                <Table
                    loading={loading}
                    onRow={({ _id }) => {
                        const { companyUUID } = this.props;

                        if (_id === companyUUID) {
                            return {
                                style: { backgroundColor: '#f2f2f2' },
                            };
                        }
                    }}
                    rowKey={({ _id }) => _id}
                    pagination={pagination}
                    dataSource={companies}
                    onChange={this.handleTableChange}
                    columns={this.getColumns()}
                />
            </div>
        );
    }
}

Companies.contextTypes = {
    switchCompany: PropTypes.func.isRequired,
    setMessage: PropTypes.func.isRequired,
    createCompany: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired,
    request: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    companyUUID: state.company.uuid,
});

export default connect(mapStateToProps)(withRouter(Companies));
