import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import { Row, Col, Icon, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import gradient from '../images/gradient.svg';

import parseMarkdown from '../../../core/helper/markdown';
import { UNIcon } from '../../index';

class JobList extends Component {
    renderSection = (el) => {
        const bullets = el.content?.split('\n') ?? [];

        return (
            <div key={el._id} className="rowContainer">
                {el.title && (
                    <>
                        <div style={{ fontWeight: '600' }}>{el.title}</div>
                        <br />
                    </>
                )}

                {bullets.length > 1 ? (
                    bullets.map((text, key) => (
                        <span key={key}>
                            <Row>
                                <Col span={1}>
                                    {text.length > 0 ? (
                                        <div
                                            style={{
                                                display: 'inline-block',
                                                width: 6,
                                                height: 6,
                                                borderRadius: 10,
                                                backgroundColor: '#f21850',
                                            }}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </Col>
                                <Col offset={1} span={22}>
                                    {parseMarkdown(text)}
                                </Col>
                            </Row>
                        </span>
                    ))
                ) : (
                    <span>
                        <Row>{parseMarkdown(bullets[0])}</Row>
                    </span>
                )}
            </div>
        );
    };

    getDisplayLogoURL = () => {
        const { job, company } = this.props;
        let addressGeoPoint;
        let { logo } = company.profile;

        if (job.addresses) {
            addressGeoPoint = job.addresses[0]?.geoPoint;
        }
        if (addressGeoPoint) {
            const matchedAddress = company.profile.addresses.find(
                ({ geoPoint }) =>
                    geoPoint.lat === addressGeoPoint.lat && geoPoint.lng === addressGeoPoint.lng,
            );

            if (matchedAddress && matchedAddress.logo) {
                logo = matchedAddress.logo;
            }
        }
        const { edited, large } = logo || {};
        const displayLogoURL = edited || large;

        return displayLogoURL;
    };

    renderContactButton(children) {
        if (this.props.job.link) {
            return <a href={this.props.job.link}>{children}</a>;
        }
        return <Tooltip title="Öffnet Bewerbungsformular in der App">{children}</Tooltip>;
    }

    render() {
        const { job, companyName, industry } = this.props;
        const { addresses = [] } = job;

        const addressString = addresses[0]
            ? `${addresses[0].city}${addresses.length > 1 ? ` + ${addresses.length - 1}` : ''}`
            : null;

        return (
            <div
                style={{
                    paddingTop: '10%',
                    height: '100%',
                    overflowY: 'scroll',
                }}>
                <div
                    style={{ minHeight: '100%', borderTopRightRadius: 15, borderTopLeftRadius: 15 }}
                    className="box-shadow">
                    <div
                        style={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            paddingTop: 5,
                            paddingBottom: 5,
                            borderBottom: '1px solid #efefef',
                        }}>
                        <Link to="/profil">
                            <Icon
                                type="cross"
                                style={{
                                    fontSize: 12,
                                    margin: 6,
                                    float: 'left',
                                    color: '#8e8e8e',
                                    overflow: 'hidden',
                                    backgroundColor: '#fff',
                                }}
                            />
                        </Link>
                        <span style={{ fontWeight: 'bold', textAlign: 'center', width: '100%' }}>
                            Stellenbeschreibung
                        </span>
                        <div style={{ padding: 12 }} />
                    </div>

                    <div
                        style={{
                            paddingLeft: '5%',
                            paddingRight: '5%',
                            borderBottom: '1px solid #efefef',
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                paddingTop: 10,
                                paddingBottom: 5,
                            }}>
                            <div>
                                <div
                                    style={{
                                        color: '#f21850',
                                        fontSize: 13,
                                        fontWeight: 'lighter',
                                    }}>
                                    {companyName}
                                </div>
                                <div>{industry}</div>
                            </div>
                            <Icon
                                type="heart"
                                style={{
                                    fontSize: 12,
                                    margin: 6,
                                    color: '#8e8e8e',
                                    float: 'right',
                                }}
                            />
                        </div>
                        <div className="title">{job.title}</div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                paddingBottom: 5,
                                paddingTop: 10,
                                width: '100%',
                                borderBottom: '1px solid #efefef',
                            }}>
                            <div>
                                <div style={{ fontSize: 8, color: 'rgba(0, 0, 0, 0.45)' }}>
                                    <UNIcon icon="briefcase" />
                                    {job.type}
                                </div>
                                <div style={{ fontSize: 8, color: 'rgba(0, 0, 0, 0.45)' }}>
                                    <UNIcon icon="pin" />
                                    {addressString}
                                    {addresses.length > 1
                                        ? ` + ${addresses.length - 1} weitere`
                                        : ''}
                                </div>
                            </div>
                            <img
                                src={this.getDisplayLogoURL()}
                                alt="Logo"
                                style={{
                                    width: '20%',
                                    height: 'auto',
                                    borderBottom: '1px solid #edeff4',
                                }}
                            />
                        </div>
                        <div
                            style={{
                                wordWrap: 'break-word',
                                borderBottom: '1px solid #edeff4',
                                paddingTop: 10,
                                paddingBottom: 10,
                            }}>
                            {job.summary
                                ? parseMarkdown(job.summary)
                                : 'Beschreibung Ihrer Stellenanzeige...'}
                        </div>

                        {job.sections &&
                            job.sections.length > 0 &&
                            job.sections.map(this.renderSection)}
                    </div>
                </div>

                <div
                    style={{
                        position: 'absolute',
                        zIndex: 40,
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: '10%',
                        padding: 5,
                    }}>
                    {this.renderContactButton(
                        <div
                            style={{
                                width: '60%',
                                margin: 'auto',
                                backgroundImage: `url(${gradient}`,
                                color: '#fff',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                borderRadius: 50,
                                fontSize: 12,
                            }}>
                            {job.link ? 'Jetzt bewerben' : 'Ich bin interessiert'}
                        </div>,
                    )}
                </div>
            </div>
        );
    }
}

JobList.defaultProps = {
    job: {},
    company: {},
    companyName: '',
    industry: '',
};

JobList.propTypes = {
    job: PropTypes.shape(),
    company: PropTypes.shape(),
    companyName: PropTypes.string,
    industry: PropTypes.string,
};

const mapStateToProps = ({ company }) => {
    return {
        company,
        companyName: company.profile.name,
        industry: company.profile.industry,
    };
};

export default connect(mapStateToProps)(JobList);
