import React from 'react';
import { Button, Col, Divider, Form, Input, List, Row } from 'antd';
import PropTypes from 'prop-types';
import { UniNowList } from '../../components';
import buttonWithPopconfirm from '../../decorators/buttonWithPopconfirm';
import buttonWithTooltip from '../../decorators/buttonWithTooltip';
import { ListSorter } from '../../components/ListSorter';
import MarkdownLink from '../MarkdownHelp/MarkdownLink';
import { defaultSection } from '../../core/helper/defaultObjects';
import { generateID } from '../../core/helper/redux';

const { TextArea } = Input;
const FormItem = Form.Item;
function Sections({ onDataChange, job, form }) {
    const { getFieldDecorator } = form;
    const { sections } = job;
    const handleSectionAdd = () => {
        const newSections = [...(job.sections ?? []), { ...defaultSection, tempID: generateID() }];
        const { setFieldsValue } = form;
        setFieldsValue({
            sections: newSections,
        });
        onDataChange('sections', newSections);
    };

    const handleSectionDelete = (indexOfSection) => {
        const newSections = job.sections.filter((section, index) => index !== indexOfSection);
        const { setFieldsValue } = form;
        setFieldsValue({
            sections: newSections,
        });
        onDataChange('sections', newSections);
    };

    const handleUpdateSectionOrder = (index, positionChange) => {
        const newSections = [...job.sections];
        const newIndex = index + positionChange;
        const element = newSections[index];
        newSections[index] = newSections[newIndex];
        newSections[newIndex] = element;
        const { setFieldsValue } = form;
        setFieldsValue({
            sections: newSections,
        });

        onDataChange('sections', newSections);
    };

    const handleSectionChange = (attributeName, indexOfSection, value) => {
        const sectionsClone = [...job.sections];
        const sectionToUpdate = { ...sectionsClone[indexOfSection] };
        sectionToUpdate[attributeName] = value;
        sectionsClone[indexOfSection] = sectionToUpdate;
        onDataChange('sections', sectionsClone);
    };

    return (
        <>
            <Divider orientation="left" style={{ marginBottom: 0 }}>
                Abschnitte
            </Divider>
            <FormItem>
                <UniNowList
                    noItemsText="Es wurden noch keine Abschnitte hinzugefügt."
                    itemLayout="vertical"
                    size="large"
                    dataSource={sections}
                    renderItem={(section, index) => (
                        <List.Item
                            key={section._id || section.tempID}
                            actions={[
                                buttonWithPopconfirm({
                                    placement: 'top',
                                    title: 'Wollen Sie diesen Abschnitt wirklich löschen?',
                                    okText: 'Löschen',
                                    onConfirm: () => handleSectionDelete(index),
                                })(
                                    buttonWithTooltip('Abschnitt löschen')(
                                        <Button shape="circle" icon="delete" />,
                                    ),
                                ),
                            ]}>
                            <Row gutter={24}>
                                <ListSorter
                                    onUp={() => handleUpdateSectionOrder(index, -1)}
                                    onDown={() => handleUpdateSectionOrder(index, 1)}
                                    index={index}
                                    maxLength={sections.length}
                                />
                                <Col span={22} offset={1}>
                                    <FormItem label="Titel" style={{ marginBottom: '15px' }}>
                                        {getFieldDecorator(
                                            `sectionTitle[${section._id || section.tempID}]`,
                                            {
                                                onChange: (e) => {
                                                    handleSectionChange(
                                                        'title',
                                                        index,
                                                        e.target.value,
                                                    );
                                                },
                                                initialValue: section.title,
                                            },
                                        )(<Input placeholder="Titel (optional)" />)}
                                    </FormItem>
                                    <FormItem label="Beschreibung">
                                        {getFieldDecorator(
                                            `sectionContent[${section._id || section.tempID}]`,
                                            {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message:
                                                            'Bitte geben Sie diesem Abschnitt eine Beschreibung.',
                                                    },
                                                ],
                                                onChange: (e) => {
                                                    handleSectionChange(
                                                        'content',
                                                        index,
                                                        e.target.value,
                                                    );
                                                },
                                                initialValue: section.content,
                                            },
                                        )(
                                            <TextArea
                                                autoSize={{ minRows: 4 }}
                                                placeholder="Beschreibung..."
                                            />,
                                        )}
                                        <MarkdownLink />
                                    </FormItem>
                                </Col>
                            </Row>
                        </List.Item>
                    )}
                />
            </FormItem>
            <FormItem>
                <Button icon="plus" type="secondary" onClick={handleSectionAdd}>
                    Abschnitt hinzufügen
                </Button>
            </FormItem>
        </>
    );
}

Sections.propTypes = {
    job: PropTypes.shape().isRequired,
    onDataChange: PropTypes.func.isRequired,
    form: PropTypes.shape().isRequired,
};
export default Sections;
