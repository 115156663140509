import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

const SpinnerContainer = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

export default function Spinner(props) {
    return (
        <SpinnerContainer>
            <Spin size="large" {...props} />
        </SpinnerContainer>
    );
}
