import _ from 'lodash';

if (!Array.prototype.find) {
  Array.prototype.find = function (callback) { // eslint-disable-line
        return _.find(this, callback);
    };
}

if (!Array.prototype.findIndex) {
  Array.prototype.findIndex = function (callback) { // eslint-disable-line
        return _.findIndex(this, callback);
    };
}

if (!Object.keys) {
    Object.keys = function (obj) {
        const keys = [];

        for (const i in obj) {
            if (obj.hasOwnProperty(i)) {
                keys.push(i);
            }
        }

        return keys;
    };
}

if (!Object.entries) {
    Object.entries = function (obj) {
        const entries = [];

        for (const i in obj) {
            if (obj.hasOwnProperty(i)) {
                entries.push([i, obj[i]]);
            }
        }

        return entries;
    };
}
