import React, { Component } from 'react';
import PageHeader from '../PageHeader';
import { Body } from './style';

export default class BasicLayout extends Component {
    render() {
        const { selectedKey, lateralPadding, children } = this.props;

        return (
            <div className="basic" style={{ height: '100%' }}>
                <PageHeader selectedKey={selectedKey} />
                <Body lateralPadding={lateralPadding}>{children}</Body>
            </div>
        );
    }
}
