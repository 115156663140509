import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Spin, Statistic } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AdvertCard, Phone } from '../../../../components';
import { RequestContext } from '../../../../components/Session/RequestProvider';
import { dotFormattedNumber, formatAsPercentage } from '../../../../core/helper/numberFormatter';

function StatisticsModal({ data, loading }) {
    const { request } = useContext(RequestContext);
    const { search, pathname } = useLocation();
    const [advertDetail, setAdvertDetail] = useState(null);
    const query = new URLSearchParams(search);
    const advertId = query.get('advertId');
    const history = useHistory();
    const [advertStats, setAdvertStats] = useState(null);

    const handleOk = () => {
        history.replace(pathname);
    };

    const clipTitle = (title, maxLength = 50) =>
        title && title.length > maxLength ? `${title.slice(0, maxLength)}...` : title;

    const advert =
        (data && data.find((advertData) => advertId === advertData['AdvertStats.shortId'])) ?? {};

    useEffect(() => {
        setAdvertDetail(null);
        if (advertId) {
            (async () => {
                const response = await request('vposts/fetch', { shortid: advertId });
                let ad;
                if (!response.error) {
                    ad = response.body;
                    const advertItem =
                        data &&
                        data.find((advertData) => ad.uuid === advertData['AdvertStats.shortId']);
                    setAdvertStats(advertItem);

                    setAdvertDetail(ad);
                }
                if (!ad) {
                    Modal.error({
                        title: 'Fehler',
                        content: 'Daten konnten nicht geladen werden',
                    });
                }
            })();
        }
    }, [advertId]);

    const loadingIndicator = (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                height: '100%',
            }}>
            <Spin />
        </div>
    );

    return (
        <Modal
            visible={!!advertId}
            title="Werbeanzeige"
            width={800}
            closable
            onOk={handleOk}
            onCancel={handleOk}
            footer={[
                <Link key={advertId} to={`/werbeanzeigen/editieren/${advertId}`}>
                    <Button key="edit" type="link" onClick={handleOk}>
                        Bearbeiten
                    </Button>
                </Link>,
                <Button key="submit" type="primary" onClick={handleOk}>
                    OK
                </Button>,
            ]}>
            <div style={{ flexDirection: 'row', display: 'flex' }}>
                <div
                    style={{
                        paddingRight: 20,
                        borderRight: '1px solid #e8e8e8',
                    }}>
                    <Phone position="absolute">
                        {loading && loadingIndicator}
                        {!loading && advertDetail && <AdvertCard data={advertDetail.data} />}
                    </Phone>
                </div>
                <div style={{ flex: 1, paddingRight: 10, paddingLeft: 10 }}>
                    {loading && loadingIndicator}
                    {!loading && advert && (
                        <>
                            <Row gutter={[16, 24]}>
                                <Col md={24}>
                                    <Statistic
                                        title="Titel"
                                        value={
                                            clipTitle(
                                                advertStats && advertStats['AdvertStats.title'],
                                            ) || ''
                                        }
                                    />
                                </Col>
                                <Col sm={12}>
                                    <Statistic
                                        title="Gesehen"
                                        value={
                                            (advertStats &&
                                                dotFormattedNumber(
                                                    advertStats['AdvertStats.seen'],
                                                )) ||
                                            0
                                        }
                                    />
                                </Col>
                                <Col sm={12}>
                                    <Statistic
                                        title="Geklickt"
                                        value={
                                            (advertStats &&
                                                dotFormattedNumber(
                                                    advertStats['AdvertStats.clicked'],
                                                )) ||
                                            0
                                        }
                                    />
                                </Col>
                                <Col sm={12}>
                                    <Statistic
                                        title="Klick-Rate"
                                        value={
                                            advertStats &&
                                            formatAsPercentage(
                                                advertStats['AdvertStats.clicked'],
                                                advertStats['AdvertStats.seen'],
                                            )
                                        }
                                        precision={2}
                                    />
                                </Col>
                                <Col sm={12}>
                                    <Statistic
                                        title="Geschlossen"
                                        value={
                                            (advertStats && advertStats['AdvertStats.closed']) || 0
                                        }
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                </div>
            </div>
        </Modal>
    );
}
StatisticsModal.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    loading: PropTypes.bool.isRequired,
};

export default StatisticsModal;
