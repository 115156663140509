import React, { PureComponent } from 'react';
import { Modal, Button } from 'antd';

export default class ToggleModalButton extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            isLoading: false,
        };
    }

    setLoadingStatus = (isLoading) => {
        this.setState({ isLoading });
    };

    toggleModal = () => {
        this.setState({ showModal: !this.state.showModal });
    };

    render() {
        const { modalProps, buttonProps, customButton } = this.props;
        const { children: modalChildren, onOk, onCancel, ...restModalProps } = modalProps;
        const { showModal, isLoading } = this.state;

        return (
            <div>
                <Modal
                    confirmLoading={isLoading}
                    visible={showModal}
                    onOk={() => {
                        if (onOk) {
                            onOk(this.toggleModal, this.setLoadingStatus);
                        }
                    }}
                    onCancel={() => {
                        if (onCancel) {
                            onCancel();
                        }
                        this.toggleModal();
                    }}
                    {...restModalProps}>
                    {modalChildren}
                </Modal>
                {customButton ? (
                    customButton(this.toggleModal)
                ) : (
                    <Button {...buttonProps} onClick={this.toggleModal} />
                )}
            </div>
        );
    }
}
