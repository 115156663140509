import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Col, Input } from 'antd';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import { JobList, AdminArea } from '../../components';
import { internshipJob } from '../../core/helper/defaultObjects';
import JobListPreview from '../../components/Preview/pages/jobList';
import Preview from '../../components/Preview/Preview';
import { SessionContext } from '../../components/Session';

const Container = styled.div`
    // margin-top: 10px;
`;

export default function Jobs(props) {
    const [applicationId, setApplicationId] = useState(null);
    const { archived } = props;

    const onInputChange = ({ target }) => {
        setApplicationId(target.value);
    };

    const addInternship = () => {
        const { match, history } = props;

        history.push(`${match.url}/editieren/`, {
            defaults: { ...internshipJob, payload: { applicationId } },
        });
    };

    const { loadSessionData } = useContext(SessionContext);
    const hasArchivedJobs = useSelector((store) => store.jobs.includesArchived);

    useEffect(() => {
        if (archived && !hasArchivedJobs) {
            loadSessionData(true);
        }
    }, [archived, hasArchivedJobs]);
    return (
        <Container>
            <Col xxl={16} xl={18} span={16}>
                <Alert
                    message={`In dieser Ansicht können Sie die ${
                        archived ? 'archivierten' : 'aktiven'
                    } Stellenanzeigen Ihres Unternehmens verwalten.`}
                    type="info"
                    showIcon
                    style={{ marginBottom: '20px' }}
                />
                <AdminArea>
                    <Input
                        placeholder="Bewerber ID, e.g. 12345"
                        onChange={onInputChange}
                        suffix={<Button onClick={addInternship}>Tagespraktikum hinzufügen</Button>}
                    />
                </AdminArea>
                <JobList archived={!!archived} {...props} />
            </Col>
            <Col
                style={{
                    height: '100%',
                }}
                offset={2}
                xxl={6}
                xl={4}
                span={6}>
                <Preview>
                    <JobListPreview />
                </Preview>
            </Col>
        </Container>
    );
}
