import { Input } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import useFeedDisclaimer from './useFeedDisclaimer';
import translate from '../../../core/helper/translate';

function PostTextArea({
    lang,
    primaryValue,
    disabled,
    className,
    value,
    onChange,
    primaryLanguage,
}) {
    const { showDisclaimer } = useFeedDisclaimer();

    return (
        <Input.TextArea
            value={value}
            onChange={onChange}
            onFocus={showDisclaimer}
            disabled={disabled}
            className={className}
            autoSize={{ minRows: 4, maxRows: 10 }}
            style={{ resize: 'none' }}
            rows={4}
            placeholder={
                lang !== primaryLanguage && !!primaryValue
                    ? primaryValue
                    : translate('NEW_POST', lang)
            }
        />
    );
}
const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = ({ company, preview }) => {
    return {
        name: company.profile.name,
        logo: company.profile.logo.small,
        username: company.contact.name,
        post: preview.post,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostTextArea);
