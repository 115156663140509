import React, { useState } from 'react';

import { Icon, Collapse } from 'antd';

import './style.css';
import ContactSection from '../ContactSection';

const { Panel } = Collapse;

export default function ContactOverlay() {
    const [expanded, setExpanded] = useState(false);

    const onCollapseChange = (activeKeys = []) => {
        const nextExpanded = activeKeys.length > 0;

        if (nextExpanded !== expanded) {
            setExpanded(nextExpanded);
        }
    };

    const panelHeader = (
        <div className="cross-fade-container">
            <div className={expanded ? 'cross-fade-top' : 'cross-fade-bottom'}>
                <div style={{ fontWeight: 200 }}>Zusammen sind wir</div>
                <div style={{ fontWeight: 700 }}>ein starkes Team im Studium!</div>
            </div>
            <div className={expanded ? 'cross-fade-bottom' : 'cross-fade-top'}>
                <div style={{ fontWeight: 600 }}>Hast du Fragen?</div>
                <div>Wir helfen dir gerne!</div>
            </div>
        </div>
    );

    const expandIcon = () =>
        expanded ? (
            <Icon style={{ color: '#f21850' }} type="down" />
        ) : (
            <Icon style={{ color: '#f21850', fontSize: '1.5em' }} type="message" />
        );

    return (
        <div id="ContactOverlay" className={expanded ? 'expanded-collapse' : ''}>
            <Collapse
                expandIcon={expandIcon}
                expandIconPosition="right"
                onChange={onCollapseChange}
                style={{ backgroundColor: '#FFFFFF' }}>
                <Panel key="1" header={panelHeader}>
                    <div
                        style={{
                            width: 350,
                            overflow: 'hidden',
                        }}>
                        <ContactSection />
                    </div>
                </Panel>
            </Collapse>
        </div>
    );
}
