import React, { PureComponent } from 'react';
import { Button, Icon } from 'antd';
import { generateID } from '../../core/helper/redux';
import { EditorModal, ItemGrid, ImageDropzone } from '..';
import buttonWithTooltip from '../../decorators/buttonWithTooltip';
import buttonWithPopconfirm from '../../decorators/buttonWithPopconfirm';
import { defaultImage } from '../../core/helper/defaultObjects';
import { convertUrlToBase64, canvasToBlob } from '../../core/helper';
import { readFromFile } from '../../core/helper/image';

class ImageGalleryWithoutDD extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            imageList: [],
            imageToEdit: undefined,
        };
    }

    componentWillMount = () => {
        const { imageList } = this.props;

        if (imageList) {
            this.setState({ imageList: [...imageList] });
        }
    };

    componentWillReceiveProps = (nextProps) => {
        const { imageList: curImageList } = this.props;
        const { imageList: nextImageList } = nextProps;

        if (nextImageList && nextImageList !== curImageList) {
            this.setState({ imageList: nextImageList });
        }
    };

    handleEditImage = async (imageToEdit) => {
        const { raw: imageUrl, payload: imagePayload, widthLarge, heightLarge } = imageToEdit;

        if (!imageUrl.includes('base64')) {
            imageToEdit.raw = await convertUrlToBase64(imageUrl);
        }
        if (widthLarge == null || heightLarge == null) {
            const image = new Image();
            image.src = imageToEdit.raw;
            const { width, height } = await new Promise((resolve, reject) => {
                image.onload = function () {
                    const { height } = this;
                    const { width } = this;
                    resolve({ width, height });
                };
            });
            imageToEdit.heightLarge = height;
            imageToEdit.widthLarge = width;
        }

        this.setState({
            imageToEdit: { ...imageToEdit, payload: { ...defaultImage.payload, ...imagePayload } },
        });
    };

    handleAddNewImage = async (file) => {
        const raw = await readFromFile(file);

        this.handleEditImage({
            ...defaultImage,
            raw,
        });
    };

    handleTitleChange = (image) => {
        const { imageList } = this.state;
        const { onChange } = this.props;

        const updatedImageList = imageList.filter((imageX) => {
            const uuIDX = imageX._id || imageX.tempID;
            const imageUuID = image._id || image.tempID;
            return uuIDX !== imageUuID;
        });

        this.setState({ imageList: [image, ...updatedImageList] }, () =>
            onChange(this.state.imageList),
        );
    };

    handleCancelEdit = () => {
        this.setState({ imageToEdit: undefined });
    };

    handleSaveEdit = async () => {
        const { imageList } = this.state;
        const imageToEdit = this.editor.getImage();
        let updatedImageList = [...imageList];
        const { onChange } = this.props;

        const editedImage = {
            ...imageToEdit,
            editedBlob: canvasToBlob(this.editor.getCanvasImage()),
            preview: await this.editor.getCanvasScaledImage().toDataURL(),
        };

        const { _id, tempID } = editedImage;
        const uuID = _id || tempID;

        if (uuID) {
            const indexInImageList = imageList.findIndex(
                (image) => image._id === uuID || image.tempID === uuID,
            );
            updatedImageList[indexInImageList] = editedImage;
        } else {
            editedImage.tempID = generateID();
            updatedImageList = [...imageList, editedImage];
        }

        this.setState({ imageList: updatedImageList, imageToEdit: undefined }, () => {
            if (onChange) {
                onChange([...updatedImageList]);
            }
        });
    };

    handleImageDelete = (uuID) => {
        const { imageList } = this.state;
        const { onChange } = this.props;
        const newImageList = imageList.filter((image) => {
            const imageUuID = image._id || image.tempID;
            return imageUuID !== uuID;
        });

        this.setState(
            {
                imageList: newImageList,
                imageToEdit: undefined,
            },
            () => {
                if (onChange) {
                    onChange(newImageList);
                }
            },
        );
    };

    handleImageMove = (fromIndex, toIndex) => {
        const { imageList } = this.state;
        const { onChange } = this.props;

        if (toIndex >= imageList.length) {
            let k = toIndex - imageList.length + 1;
            while (k--) {
                imageList.push(undefined);
            }
        }
        imageList.splice(toIndex, 0, imageList.splice(fromIndex, 1)[0]);

        this.setState({ imageList: [...imageList] }, () => {
            if (onChange) {
                onChange(imageList);
            }
        });
    };

    renderEditor = (imageToEdit) => {
        const { optimalWidth, optimalHeight } = this.props;
        const { _id, tempID } = imageToEdit;
        const uuID = _id || tempID;

        return (
            <EditorModal
                editMode={uuID}
                onCancel={this.handleCancelEdit}
                onSave={this.handleSaveEdit}
                onDelete={() => this.handleImageDelete(uuID)}
                width={700}
                height={466}
                onRef={(refeditor) => {
                    this.editor = refeditor;
                }}
                optimalHeight={optimalHeight}
                optimalWidth={optimalWidth}
                imageToEdit={imageToEdit}
                enableZoomOut
                visible
            />
        );
    };

    render() {
        const { imageToEdit } = this.state;
        const { imageList } = this.props;

        return (
            <div>
                {imageToEdit && this.renderEditor(imageToEdit)}
                <ItemGrid
                    dropZone={
                        !this.props.disableAdd && (
                            <ImageDropzone
                                style={{
                                    margin: '2%',
                                    height: '100%',
                                    width: '96%',
                                    position: 'relative',
                                    paddingBottom: '60%',
                                }}
                                onImageDrop={(image) => this.handleAddNewImage(image)}>
                                <div
                                    style={{
                                        padding: '5px',
                                        position: 'absolute',
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-around',
                                        color: 'white',
                                    }}>
                                    <Icon type="plus-square" style={{ fontSize: '40px' }} />
                                    <div
                                        style={{
                                            width: '75%',
                                        }}>
                                        {' '}
                                        Hier klicken oder ein Bild hineinziehen
                                    </div>
                                </div>
                            </ImageDropzone>
                        )
                    }
                    gridItems={imageList}
                    renderItem={(image, index) => {
                        const { tempID, _id, preview, edited, large, raw } = image;
                        const uuID = _id || tempID;
                        const imageUrl = preview || edited || large || raw || undefined;

                        return (
                            <div className="imgContainer">
                                <img className="image" src={imageUrl} alt="Bild" />
                                <div className="imgToolbar top">
                                    {index !== 0 &&
                                        buttonWithPopconfirm({
                                            title: 'Wollen Sie dieses Bild wirklich zu Ihrem neuen Titlebild machen?',
                                            okText: 'Als Titlebild festlegen',
                                            onConfirm: () => this.handleTitleChange(image),
                                        })(
                                            buttonWithTooltip('Als Titlebild festlegen')(
                                                <Button
                                                    style={{ marginRight: '5px' }}
                                                    shape="circle"
                                                    icon="star"
                                                    disabled={this.props.disableEdit}
                                                />,
                                            ),
                                        )}

                                    {buttonWithTooltip('Bild editieren')(
                                        <Button
                                            style={{ marginRight: '5px' }}
                                            onClick={() =>
                                                this.handleEditImage({
                                                    ...image,
                                                    payload: { ...image.payload },
                                                })
                                            }
                                            disabled={this.props.disableEdit}
                                            shape="circle"
                                            icon="edit"
                                        />,
                                    )}

                                    {buttonWithPopconfirm({
                                        title: 'Wollen Sie dieses Bild wirklich löschen?',
                                        placement: 'bottom',
                                        onConfirm: () => this.handleImageDelete(uuID),
                                        okText: 'Löschen',
                                    })(
                                        buttonWithTooltip('Bild löschen')(
                                            <Button
                                                shape="circle"
                                                icon="delete"
                                                style={{ marginRight: '5px' }}
                                            />,
                                        ),
                                        index,
                                    )}
                                </div>

                                <div className="imgToolbar bottom">
                                    {index !== 0 &&
                                        buttonWithTooltip('Bild nach links verschieben')(
                                            <Button
                                                shape="circle"
                                                icon="left"
                                                disabled={this.props.disableEdit}
                                                style={{ marginRight: '5px', fontWeight: 'bold' }}
                                                onClick={() =>
                                                    this.handleImageMove(index, index - 1)
                                                }
                                            />,
                                        )}
                                    {index !== imageList.length - 1 &&
                                        buttonWithTooltip('Bild nach rechts verschieben')(
                                            <Button
                                                shape="circle"
                                                icon="right"
                                                disabled={this.props.disableEdit}
                                                style={{ marginRight: '5px', fontWeight: 'bold' }}
                                                onClick={() =>
                                                    this.handleImageMove(index, index + 1)
                                                }
                                            />,
                                        )}
                                </div>
                            </div>
                        );
                    }}
                />
            </div>
        );
    }
}

export default ImageGalleryWithoutDD;
