import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Popconfirm, Modal, Row, Col, Input, Icon, Alert } from 'antd';
import PropTypes from 'prop-types';
import { Map, LocationManager, ProfileLayout, UniNowList } from '../../components';
import { defaultLocation } from '../../core/helper/defaultObjects';
import {
    addToArray,
    removeFromArray,
    changeArrayElement as changeArrayElementAction,
    changeArrayElementPos,
} from '../../core/redux/actions';
import buttonWithPopconfirm from '../../decorators/buttonWithPopconfirm';
import { generateID } from '../../core/helper/redux';
import buttonWithTooltip from '../../decorators/buttonWithTooltip';
import PlanAlert from '../../components/PlanAlert';

const PAGE_SIZE = 10;

class Locations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locationToEdit: { data: null, indexInLocationList: null, error: null },
            filter: null,
        };
    }

    handleUpdateLocation = (updatedLocation) => {
        this.setState({
            locationToEdit: {
                ...this.state.locationToEdit,
                data: { ...updatedLocation },
                error: this.validateLocation(
                    updatedLocation,
                    this.state.locationToEdit.indexInLocationList,
                ),
            },
        });
    };

    validateLocation = (location, indexInLocationList) => {
        if (!location.street || !location.city || !location.country) {
            return 'Bitte geben Sie eine vollständige Addresse (Straße, Hausnr., PLZ, Ort, Land) an.';
        }
        const index = this.props.locations.findIndex(
            (elem) =>
                elem.geoPoint.lat === location.geoPoint.lat &&
                elem.geoPoint.lng === location.geoPoint.lng,
        );
        if (index !== -1 && index !== indexInLocationList) {
            return 'Sie haben bereits die Adresse als Standort angegeben.';
        }

        return null;
    };

    handleLocationDelete = (location, index) => {
        this.setState({
            locationToEdit: { data: null, indexInLocationList: null },
        });

        this.props.removeFromArray('profile.addresses', index);
    };

    handleLocationPin = (location, index) => {
        const { locations, changeArrayElement, moveToTop } = this.props;
        locations.forEach((otherLocation, idx) => {
            if (idx !== index) {
                changeArrayElement('profile.addresses', idx, {
                    ...otherLocation,
                    pinned: false,
                });
            }
        });
        changeArrayElement('profile.addresses', index, {
            ...location,
            pinned: !location.pinned,
        });
        if (!location.pinned) {
            moveToTop('profile.addresses', index);
        }
    };

    handleEditLocation = (location, index) => {
        if (location) {
            this.setState({
                locationToEdit: {
                    data: { ...this.props.locations[index] },
                    indexInLocationList: index,
                },
            });
        } else {
            this.setState({
                locationToEdit: {
                    data: { ...defaultLocation },
                    indexInLocationList: null,
                },
            });
        }
    };

    getLocationIndex = (location) => {
        const {
            _id,
            geoPoint: { lat, lng },
        } = location;
        const { locations } = this.props;
        return locations.findIndex((loc) => {
            if (_id && _id === loc._id) {
                return true;
            }

            const { lat: locLat, lng: locLng } = loc.geoPoint || {};

            return lat && lat === locLat && lng === locLng;
        });
    };

    handleSaveEdit = () => {
        const editedLocation = { ...this.state.locationToEdit };
        const { indexInLocationList, data, error: existingError } = editedLocation;
        const error = existingError || this.validateLocation(data, indexInLocationList) || null;
        if (!error) {
            if (indexInLocationList || indexInLocationList === 0) {
                this.props.changeArrayElement(
                    'profile.addresses',
                    indexInLocationList,
                    editedLocation.data,
                );
            } else {
                // check if default location exists
                const defaultIndex = this.props.locations.findIndex(
                    (location) =>
                        location.city === 'Musterstadt' && location.country === 'Musterland',
                );
                if (defaultIndex !== -1) {
                    this.props.removeFromArray('profile.addresses', defaultIndex);
                }
                // real adding
                editedLocation.data.tempID = generateID();
                this.props.addToArray('profile.addresses', editedLocation.data);
            }
            this.setState({ locationToEdit: { data: null, indexInLocationList: null } });
        } else {
            this.setState({ locationToEdit: { ...editedLocation, error } });
        }
    };

    handleCancelEdit = () => {
        this.setState({ locationToEdit: { data: null, indexInLocationList: null } });
    };

    handleFilterChange = ({ target }) => {
        this.setState({ filter: target.value });
    };

    showEditor = () => {
        const { locationToEdit } = this.state;
        const { data, indexInLocationList, error } = locationToEdit;

        let buttons = [
            <Button key="back" size="large" onClick={this.handleCancelEdit}>
                Abbrechen
            </Button>,
            <Button
                key="submit"
                type="primary"
                size="large"
                onClick={() => {
                    this.handleSaveEdit();
                }}
                disabled={
                    (locationToEdit.error && locationToEdit.error.length > 0) ||
                    !locationToEdit.data.street
                }>
                {indexInLocationList || indexInLocationList === 0
                    ? 'Änderungen hinzufügen'
                    : 'Hinzufügen'}
            </Button>,
        ];

        if (indexInLocationList || indexInLocationList === 0) {
            buttons = [
                <Popconfirm
                    key="delete"
                    placement="top"
                    title="Wollen Sie diesen Standort wirklich löschen?"
                    onConfirm={() => this.handleLocationDelete(locationToEdit, indexInLocationList)}
                    okText="löschen"
                    cancelText="abbrechen">
                    <Button type="danger" size="large">
                        Löschen
                    </Button>
                </Popconfirm>,
                ...buttons,
            ];
        }

        return (
            <Modal
                title="Bearbeiten Sie ihren Standort"
                visible
                maskClosable={false}
                onOk={this.handleOk}
                onCancel={this.handleCancelEdit}
                footer={buttons}
                style={{ top: '10%' }}
                destroyOnClose>
                <LocationManager
                    editLogo
                    allowManualEdit
                    error={error}
                    location={data}
                    onSuggestSelect={this.handleUpdateLocation}
                />
            </Modal>
        );
    };

    render() {
        const { filter } = this.state;
        const locationToEdit = { ...this.state.locationToEdit };
        const { locations, plan } = this.props;

        const pagination =
            locations.length > PAGE_SIZE
                ? {
                      pageSize: PAGE_SIZE,
                  }
                : null;

        const filterPhrases = filter
            ? filter.split(/[\s+]+/gim).map((phrase) => phrase.toLowerCase())
            : null;

        const filteredLocations =
            pagination && filterPhrases
                ? locations.filter((location) => {
                      const locationString = JSON.stringify(location).toLowerCase();

                      return filterPhrases.every((phrase) => locationString.includes(phrase));
                  })
                : locations;

        const exceededLocations = plan.numLocations > 0 && locations?.length >= plan.numLocations;
        return (
            <ProfileLayout
                subHeaderSelectedKey="Standorte"
                infoText="Verwalten Sie hier die Standorte Ihres Unternehmens. Achten Sie dabei bitte darauf, dass Sie mindestens einen vollständigen Standort (Straße, Hausnr., PLZ, Ort, Land) angeben.">
                {locationToEdit.data && this.showEditor()}
                {exceededLocations && (
                    <PlanAlert
                        message={
                            <span>
                                Ihr aktuelles Paket erlaubt nur{' '}
                                {plan.numLocations === 1
                                    ? 'einen Standort'
                                    : `${plan.numLocations} Standorte`}
                                .
                            </span>
                        }
                    />
                )}
                <Row
                    type="flex"
                    justify="space-between"
                    align="middle"
                    style={{ paddingBottom: 15 }}>
                    <Col span={14}>
                        <Button
                            size="large"
                            type="primary"
                            icon="plus"
                            disabled={exceededLocations}
                            onClick={() => this.handleEditLocation()}>
                            Standort hinzufügen
                        </Button>
                    </Col>
                    <Col span={pagination ? 10 : 0}>
                        <Input
                            value={filter}
                            onChange={this.handleFilterChange}
                            prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="Standorte filtern"
                        />
                    </Col>
                </Row>

                <UniNowList
                    noItemsText="Es wurden noch keine Standorte hinzugefügt."
                    itemLayout="vertical"
                    size="large"
                    pagination={pagination}
                    dataSource={filteredLocations}
                    renderItem={(location) => {
                        const listItemActions = [
                            buttonWithTooltip('Standort editieren')(
                                <Button
                                    disabled={
                                        plan?.numLocations > 0 &&
                                        locations?.length > plan?.numLocations
                                    }
                                    style={{ marginRight: 5 }}
                                    onClick={() =>
                                        this.handleEditLocation(
                                            location,
                                            this.getLocationIndex(location),
                                        )
                                    }
                                    shape="circle"
                                    icon="edit"
                                />,
                            ),
                            buttonWithTooltip('Standort löschen')(
                                buttonWithPopconfirm({
                                    placement: 'top',
                                    title: 'Wollen Sie diesen Standort wirklich löschen?',
                                    onConfirm: () =>
                                        this.handleLocationDelete(
                                            location,
                                            this.getLocationIndex(location),
                                        ),
                                    okText: 'Löschen',
                                })(
                                    <Button
                                        style={{ marginRight: 5 }}
                                        shape="circle"
                                        icon="delete"
                                    />,
                                ),
                            ),
                            buttonWithTooltip(
                                `Standort ${location.pinned ? 'nicht mehr' : ''} anpinnen`,
                            )(
                                <Button
                                    shape="circle"
                                    className="ant-btn-icon-only"
                                    onClick={() =>
                                        this.handleLocationPin(
                                            location,
                                            this.getLocationIndex(location),
                                        )
                                    }>
                                    <Icon type="pushpin" theme={location.pinned && 'filled'} />
                                </Button>,
                            ),
                        ];

                        if (locations.length === 1) {
                            listItemActions.pop();
                        }

                        const {
                            name,
                            geoPoint,
                            street,
                            postalCode,
                            city,
                            country,
                            addition,
                            logo,
                            manualChange,
                        } = location;
                        const { lat, lng } = geoPoint;

                        return (
                            <Row style={{ paddingBottom: 15 }}>
                                <Col span={12}>
                                    {name && (
                                        <b>
                                            {name}
                                            <br />
                                        </b>
                                    )}
                                    {street}
                                    {addition ? <div>{addition}</div> : <br />}
                                    {postalCode}
                                    {postalCode && ' '}
                                    {city}
                                    <br />
                                    <br />
                                    {country}
                                    {logo ? (
                                        <img
                                            alt="logo"
                                            src={logo?.edited ?? logo?.large}
                                            style={{ width: 70, marginBottom: 3 }}
                                        />
                                    ) : (
                                        <br />
                                    )}
                                    <br />
                                    {manualChange && (
                                        <p style={{ color: '#8f8f8f' }}>
                                            manuell geändert am{' '}
                                            {new Date(manualChange).toLocaleDateString()}
                                        </p>
                                    )}
                                    {listItemActions}
                                </Col>
                                <Col span={10} style={{ textAlign: 'right' }}>
                                    <Map
                                        zoom={15}
                                        showMarker
                                        lat={lat}
                                        lng={lng}
                                        containerElement={
                                            <div
                                                style={{ height: '15em', backgroundColor: 'gray' }}
                                            />
                                        }
                                        mapElement={<div style={{ height: '100%' }} />}
                                    />
                                </Col>
                            </Row>
                        );
                    }}
                />
            </ProfileLayout>
        );
    }
}

Locations.propTypes = {
    locations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    plan: PropTypes.shape().isRequired,
    addToArray: PropTypes.func.isRequired,
    removeFromArray: PropTypes.func.isRequired,
    changeArrayElement: PropTypes.func.isRequired,
    moveToTop: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    locations: state.company.profile.addresses,
    plan: state.company.plan,
});

const mapDispatchToProps = (dispatch) => ({
    addToArray: (attributeName, element) => dispatch(addToArray(attributeName, element)),
    moveToTop: (attributeName, index) =>
        dispatch(changeArrayElementPos(attributeName, index, -index)),
    removeFromArray: (attributeName, index) => dispatch(removeFromArray(attributeName, index)),
    changeArrayElement: (attributeName, index, updatedElement) =>
        dispatch(changeArrayElementAction(attributeName, index, updatedElement)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
