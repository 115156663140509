import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { switchProfile } from '../../core/redux/actions/user';
import { changeArrayElement, saveSessionData, setAttributeValue } from '../../core/redux/actions';
import { RequestContext } from './RequestProvider';
import showMessage from '../../core/helper/showMessage';
import { SessionContext } from './index';
import checkImageQuality from '../../core/helper/checkImageQuality';

export const CompanyContext = createContext();
function CompanyProvider(props) {
    const { request, upload, uploadAsset } = useContext(RequestContext);
    const { loadSessionData, role } = useContext(SessionContext);
    const companyId = props.company.uuid;
    const updateCompanyData = async () => {
        const { company } = props;
        const { gallery, logo, ratings, awards, addresses } = company.profile;
        const successMessage = {
            data: 'Das Speichern Ihrer Daten war erfolgreich!',
            type: 'success',
        };
        const errorMessage = {
            data: 'Beim Speichern Ihrer Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
            type: 'error',
        };
        let userMessage;
        let wasSuccessful = false;

        const sequential = async (files, func) => {
            const result = [];
            for (const file of files) {
                result.push(await func(file));
            }
            return result;
        };
        try {
            company.profile.logo = await uploadAsset(logo, 'logo');
            company.profile.gallery = await sequential(
                gallery,
                async (image) => await uploadAsset(image, 'gallery'),
            );
            company.profile.ratings = await sequential(ratings, async (rating) => {
                const { image, video } = rating;

                if (image) {
                    rating.image = await uploadAsset(image, 'rating');
                }
                if (video instanceof File || video instanceof Blob) {
                    const { body } = await upload('vposts/asset/upload', { video });
                    rating.video = body;
                }
                return rating;
            });
            company.profile.awards = await sequential(awards, async (award) => {
                const { image } = award;
                if (image) {
                    award.image = await uploadAsset(image, 'rating');
                }

                return award;
            });
            company.profile.addresses = await sequential(addresses, async (address) => {
                const { logo } = address;
                if (logo) {
                    address.logo = await uploadAsset(logo, 'rating');
                }

                return address;
            });
            const serverData = await request('recruiting/company/update', {
                company,
                companyID: company.uuid,
            });

            const { jobs: serverJobs, ...serverCompany } = serverData.body;

            if (serverCompany) {
                props.saveSessionData({ company: serverCompany });
                userMessage = successMessage;
                wasSuccessful = true;
            } else {
                userMessage = errorMessage;
            }
        } catch (e) {
            userMessage = errorMessage;
        }
        showMessage(userMessage.data, userMessage.type);
        return wasSuccessful;
    };

    const setCompanyVisibility = async (isVisible, ignoreWarnings = false) => {
        const { company } = props;
        const { profile, plan } = company;
        const errors = [];
        const warnings = [];

        const arrayIsEmpty = (array) => !array || array.length === 0;
        const valueNotSet = (value) => !value;
        const maxElements = (max) => (array) => max > 0 && (!array || array.length > max);

        const requiredAttributes = [
            {
                name: 'addresses',
                validator: [arrayIsEmpty, maxElements(plan.numLocations)],
                error:
                    plan.numLocations > 1
                        ? `Mindestens ein, maximal ${plan.numLocations} Standorte`
                        : `${plan.numLocations === 1 ? 'Genau ein' : 'Ein'} Standort`,
            },
            {
                name: 'gallery',
                validator: [arrayIsEmpty, maxElements(plan.numImages)],
                error:
                    plan.numImages > 0
                        ? `Mindestens ein, maximal ${plan.numImages} Galeriebilder`
                        : 'Ein Titelbild',
            },
            {
                name: 'name',
                validator: valueNotSet,
                error: 'Name Ihres Unternehmens',
            },
            {
                name: 'statement',
                validator: valueNotSet,
                error: 'Ein Statement',
            },
            {
                name: 'industry',
                validator: valueNotSet,
                error: 'Die Branche Ihres Unternehmens',
            },
            { name: 'logo', validator: valueNotSet, error: 'Logo' },
            {
                name: 'employees',
                validator: valueNotSet,
                error: 'Anzahl Ihrer Mitarbeiter',
            },
            { name: 'aboutUs', validator: valueNotSet, error: 'Beschreibung' },
        ];

        if (isVisible) {
            requiredAttributes.forEach(({ name, validator, error, warning, onCompany = false }) => {
                const validate = (currentValidator) => {
                    if (currentValidator(onCompany ? company[name] : profile[name])) {
                        if (error) {
                            errors.push(error);
                        } else if (!ignoreWarnings) {
                            warnings.push(warning);
                        }
                    }
                };
                if (Array.isArray(validator)) {
                    validator.forEach((currentValidator) => validate(currentValidator));
                } else {
                    validate(validator);
                }
            });
            const imageError = await checkImageQuality(profile);
            if (imageError) {
                errors.push(imageError);
            }
        }
        if (errors.length === 0 && warnings.length === 0) {
            company.profile.visible = isVisible;
            const { body: serverCompany } = await request('recruiting/company/update', {
                company: { profile: { visible: isVisible } },
                companyID: company.uuid,
            });

            if (serverCompany) {
                showMessage(
                    `Ihr Unternehmen wurde erfolgreich ${
                        isVisible ? 'sichtbar' : 'unsichtbar'
                    } geschaltet.`,
                    'success',
                );

                props.setAttributeValue('profile.visible', isVisible);

                if (serverCompany.endOfTerm) {
                    props.setAttributeValue('endOfTerm', serverCompany.endOfTerm);
                }
            } else {
                showMessage(
                    'Beim Speichern Ihrer Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
                    'error',
                );
            }
        }

        return { errors, warnings };
    };

    const switchCompany = async (companyID) => {
        const { switchProfile } = props;

        await switchProfile(companyID);

        await loadSessionData();

        return true;
    };

    const createCompany = async (companyName, templateId) => {
        const company = {
            profile: {
                name: companyName,
                addresses: [
                    {
                        geoPoint: {
                            lat: 52.1079294,
                            lng: 11.637266400000044,
                        },
                        street: 'Musterstr. 2',
                        postalCode: 12345,
                        city: 'Musterstadt',
                        country: 'Musterland',
                    },
                ],
            },
        };
        const { body } = await request('recruiting/company/create', {
            company,
            templateId,
        });

        if (body) {
            return switchCompany(body.companyID);
        }
        return false;
    };

    const saveJob =
        (url, saveToReducer) =>
        async (job, indexOfJob = null) => {
            const { body: newJob } = await request(url, {
                job,
                jobID: job.uuid,
            });

            if (newJob) {
                saveToReducer(newJob, indexOfJob);
                return true;
            }

            return false;
        };

    const createJob = saveJob('recruiting/job/create', (job, index) => {
        props.changeArrayElement('joblist', index, job.job, 'jobs');
    });

    const updateJob = saveJob('recruiting/job/update/data', (updatedJob, indexOfJob) => {
        props.changeArrayElement('joblist', indexOfJob, updatedJob, 'jobs');
    });

    const getChildContext = () => {
        return {
            companyId,
            updateCompanyData,
            switchCompany,
            createCompany,
            setCompanyVisibility,
            createJob,
            updateJob,
        };
    };

    return (
        <CompanyContext.Provider value={getChildContext()}>
            {props.children}
        </CompanyContext.Provider>
    );
}

CompanyProvider.propTypes = { children: PropTypes.node.isRequired };

const mapStateToProps = ({ company }) => ({
    company,
});

const mapDispatchToProps = (dispatch) => ({
    switchProfile: (profileId) => dispatch(switchProfile(profileId)),
    saveSessionData: (data) => dispatch(saveSessionData(data)),
    setAttributeValue: (attributeName, value, reducer) =>
        dispatch(setAttributeValue(attributeName, value, reducer)),
    changeArrayElement: (attributeName, indexOfElement, updatedElement, reducer) =>
        dispatch(changeArrayElement(attributeName, indexOfElement, updatedElement, reducer)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CompanyProvider);
