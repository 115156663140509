/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Form, Input, Modal, Row, Select, Spin } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { RequestContext } from '../../components/Session/RequestProvider';
import { UNIcon } from '../../components';
import { ICONS } from '../../components/UNIcon/icons';
import EditGroupModal from './EditGroupModal';
import EditBenefitModal from './EditBenefitModal';
import DraggableBenefit from './DraggableBenefit';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

function EditBenefits(props) {
    const [currentBenefit, setCurrentBenefit] = useState(null);
    const [currentGroup, setCurrentGroup] = useState(null);
    const [changedBenefits, setChangedBenefits] = useState([]);
    const [deletedBenefits, setDeletedBenefits] = useState([]);
    const [benefits, setBenefits] = useState([]);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const { request } = useContext(RequestContext);
    const fetchBenefits = async () => {
        setLoading(true);
        const { body } = await request('recruiting/config/benefits');

        if (body) {
            setBenefits(body);
            setChangedBenefits([]);
            setDeletedBenefits([]);
        }
        setLoading(false);
    };
    const saveBenefits = async () => {
        setSaving(true);
        const { body } = await request('recruiting/administration/benefits', {
            benefits,
            changedBenefits,
            deletedBenefits,
        });

        if (body) {
            setBenefits(body);
            setChangedBenefits([]);
            setDeletedBenefits([]);
        }
        setSaving(false);
    };
    useEffect(fetchBenefits, []);
    if (loading) {
        return <Spin />;
    }

    const getListStyle = (isDraggingOver) => ({
        background: isDraggingOver ? 'lightblue' : 'transparent',
        paddingBottom: 20,
    });

    const onDragEnd = (result) => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }
        const sourceIndex = +source.droppableId;
        const destinationIndex = +destination.droppableId;
        if (sourceIndex === destinationIndex) {
            const items = reorder(benefits[sourceIndex].benefits, source.index, destination.index);
            const newState = [...benefits];
            newState[sourceIndex].benefits = items;
            setBenefits(newState);
        } else {
            const newList = move(
                benefits[sourceIndex].benefits,
                benefits[destinationIndex].benefits,
                source,
                destination,
            );
            const newState = [...benefits];
            newState[sourceIndex].benefits = newList[sourceIndex];
            newState[destinationIndex].benefits = newList[destinationIndex];

            setBenefits(newState);
        }
    };

    const renderBenefit = (benefit, index, groupIndex) => (
        <DraggableBenefit
            benefit={benefit}
            index={index}
            groupIndex={groupIndex}
            onEditClick={() =>
                setCurrentBenefit({
                    groupIndex,
                    index,
                })
            }
        />
    );
    return (
        <>
            <Row>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Row gutter={[25, 25]}>
                        {benefits.map((benefitGroup, groupIndex) => (
                            <Droppable key={groupIndex} droppableId={`${groupIndex}`}>
                                {(provided, snapshot) => (
                                    <Col md={6}>
                                        <Card
                                            title={benefitGroup.group}
                                            extra={
                                                <>
                                                    <Button
                                                        type="link"
                                                        icon="plus"
                                                        size="small"
                                                        onClick={() => {
                                                            const nextBenefits = [...benefits];
                                                            nextBenefits[groupIndex].benefits = [
                                                                ...nextBenefits[groupIndex]
                                                                    .benefits,
                                                                {
                                                                    text: 'unbenannt',
                                                                    icon: 'heart-outline',
                                                                },
                                                            ];
                                                            setBenefits(nextBenefits);
                                                        }}
                                                    />
                                                    <Button
                                                        type="link"
                                                        icon="edit"
                                                        size="small"
                                                        onClick={() =>
                                                            setCurrentGroup({
                                                                groupIndex,
                                                            })
                                                        }
                                                    />
                                                </>
                                            }>
                                            <div
                                                ref={provided.innerRef}
                                                style={getListStyle(snapshot.isDraggingOver)}
                                                {...provided.droppableProps}>
                                                {benefitGroup.benefits?.map((benefit, index) =>
                                                    renderBenefit(benefit, index, groupIndex),
                                                )}
                                            </div>
                                        </Card>
                                    </Col>
                                )}
                            </Droppable>
                        ))}
                    </Row>
                </DragDropContext>
            </Row>
            <Row>
                <div style={{ display: 'flex' }}>
                    <Button
                        disabled={saving}
                        onClick={() =>
                            setBenefits([...benefits, { group: 'Unbenannt', benefits: [] }])
                        }>
                        Gruppe Hinzufügen
                    </Button>
                    <Button disabled={saving} onClick={() => fetchBenefits()}>
                        Zurücksetzen
                    </Button>
                    <Button
                        disabled={saving || !benefits || benefits.length === 0}
                        onClick={() => saveBenefits()}
                        type="primary">
                        Speichern
                    </Button>
                </div>
            </Row>
            <EditGroupModal
                group={currentGroup && benefits[currentGroup.groupIndex]}
                onCancel={() => setCurrentGroup(null)}
                onOk={(group) => {
                    const nextBenefits = [...benefits];
                    nextBenefits[currentGroup.groupIndex] = group;
                    setBenefits(nextBenefits);
                    setCurrentGroup(null);
                }}
                onDelete={() => {
                    const group = benefits[currentGroup.groupIndex];
                    setDeletedBenefits([...deletedBenefits, ...group.benefits]);
                    const nextBenefits = [...benefits];
                    nextBenefits.splice(currentGroup.groupIndex, 1);
                    setBenefits(nextBenefits);
                    setCurrentGroup(null);
                }}
            />
            <EditBenefitModal
                benefit={
                    currentBenefit &&
                    benefits[currentBenefit.groupIndex].benefits[currentBenefit.index]
                }
                onCancel={() => setCurrentBenefit(null)}
                onOk={(benefit) => {
                    const nextBenefits = [...benefits];
                    nextBenefits[currentBenefit.groupIndex].benefits[currentBenefit.index] =
                        benefit;
                    setBenefits(nextBenefits);
                    setChangedBenefits([...changedBenefits, benefit]);
                    setCurrentBenefit(null);
                }}
                onDelete={() => {
                    setDeletedBenefits([
                        ...deletedBenefits,
                        benefits[currentBenefit.groupIndex].benefits[currentBenefit.index],
                    ]);
                    const nextBenefits = [...benefits];
                    nextBenefits[currentBenefit.groupIndex].benefits.splice(
                        currentBenefit.index,
                        1,
                    );
                    setBenefits(nextBenefits);

                    setCurrentBenefit(null);
                }}
            />
        </>
    );
}

EditBenefits.propTypes = {};

export default EditBenefits;
