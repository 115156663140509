import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Card, Table, notification, Spin } from 'antd';
import _ from 'lodash';
import moment from 'moment';

const subTable = ({ jobs }) => {
    const columns = [
        { title: 'Unternehmen', dataIndex: 'company', key: 'company' },
        { title: 'Job', dataIndex: 'title', key: 'natitleme' },
        {
            title: 'Datum',
            dataIndex: 'date',
            key: 'date',
            render: (date) => moment(date).format('DD.MM.YYYY hh:mm'),
        },
    ];
    return <Table columns={columns} dataSource={jobs} pagination={false} />;
};

class JobReasonsStatistics extends Component {
    state = {
        loading: true,
        stats: [],
    };

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        const { request } = this.context;

        const { body, error } = await request('recruiting/administration/reasons');

        if (error) {
            this.setState(
                {
                    loading: false,
                },
                () =>
                    notification.error({
                        message: 'Statistiken konnten leider nicht geladen werden',
                        description:
                            'Bitte versuchen Sie es später noch einmal oder kontaktieren Sie Ihren Ansprechpartner.',
                    }),
            );
        } else {
            const stats = [
                { reason: 'EMPLOYED_UNINOW', jobs: [] },
                { reason: 'EMPLOYED_OTHER', jobs: [] },
                { reason: 'CANCELED', jobs: [] },
            ];

            body.forEach((elem) => {
                let index;
                switch (elem.reason) {
                    case 'EMPLOYED_UNINOW':
                        index = 0;
                        break;
                    case 'EMPLOYED_OTHER':
                        index = 1;
                        break;
                    case 'CANCELED':
                        index = 2;
                        break;
                    default:
                        index = stats.findIndex((reason) => reason === elem.reason);
                        if (index === -1 || !index) {
                            index = stats.length;
                            stats.push({ reason: elem.reason, jobs: [] });
                        }
                        break;
                }
                stats[index].jobs.push({
                    title: elem.title,
                    company: elem.company,
                    date: elem.date,
                });
            });

            this.setState({
                loading: false,
                stats,
            });
        }
    };

    render() {
        const { loading, stats = [] } = this.state;

        const [employedUninow, employedOther, canceled, ...other] = stats;

        const columns = [
            {
                title: 'Grund',
                dataIndex: 'reason',
                key: 'reason',
            },
            {
                title: 'Anzahl der Stellenanzeigen',
                dataIndex: 'jobs',
                key: 'count',
                defaultSortOrder: 'descend',
                render: (jobs) => jobs.length,
                sorter: (a, b) => a.jobs.length - b.jobs.length,
            },
            {
                title: 'Anzahl Unternehmen',
                dataIndex: 'jobs',
                key: 'countCompanys',
                render: (jobs) => _.uniqBy(jobs, 'company').length,
                sorter: (a, b) =>
                    _.uniqBy(a.jobs, 'company').length - _.uniqBy(b.jobs, 'company').length,
            },
        ];

        return (
            <Row id="CompanyTotal">
                <Col xs={12} sm={6}>
                    <Card
                        headStyle={{ backgroundColor: '#fafafa' }}
                        title={<div className="title">durch UniNow besetzt</div>}
                        className="card"
                        loading={loading}>
                        <div className="number">
                            {loading ? <Spin /> : employedUninow.jobs.length}
                        </div>
                    </Card>
                </Col>
                <Col xs={12} sm={6}>
                    <Card
                        headStyle={{ backgroundColor: '#fafafa' }}
                        title={<div className="title">durch anderen Kanal besetzt</div>}
                        className="card"
                        loading={loading}>
                        <div className="number">
                            {loading ? <Spin /> : employedOther.jobs.length}
                        </div>
                    </Card>
                </Col>
                <Col xs={12} sm={6}>
                    <Card
                        headStyle={{ backgroundColor: '#fafafa' }}
                        title={<div className="title">nicht mehr ausgeschrieben</div>}
                        className="card"
                        loading={loading}>
                        <div className="number">{loading ? <Spin /> : canceled.jobs.length}</div>
                    </Card>
                </Col>
                <Col xs={12} sm={6}>
                    <Card
                        headStyle={{ backgroundColor: '#fafafa' }}
                        title={<div className="title">Andere Gründe</div>}
                        className="card"
                        loading={loading}>
                        <div className="number">
                            {loading ? (
                                <Spin />
                            ) : (
                                other.reduce((prev, cur) => prev + cur.jobs.length, 0)
                            )}
                        </div>
                    </Card>
                </Col>
                <Col span={24}>
                    <Table
                        columns={columns}
                        dataSource={other}
                        loading={loading}
                        locale={{ emptyText: 'Es wurden keine Daten gefunden' }}
                        pagination={{ defaultPageSize: 10, position: 'bottom' }}
                        expandedRowRender={subTable}
                    />
                </Col>
            </Row>
        );
    }
}

JobReasonsStatistics.contextTypes = {
    request: PropTypes.func,
};

export default JobReasonsStatistics;
