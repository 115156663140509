import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import gradient from '../../Preview/images/gradient.svg';

const demoData = [
    {
        sender: 'Martina Musterfrau',
        subject: 'Übungsteilnahme',
        text: 'Lorem Ipsum dolor sit amet',
        date: 'Heute',
    },
    {
        sender: 'Max Mustermann',
        subject: 'Seminar',
        text: 'Lorem Ipsum dolor sit amet',
        date: 'Gestern',
    },
    {
        sender: 'Prof. Dr. Müller',
        subject: 'Vorlesung',
        text: 'Lorem Ipsum dolor sit amet',
        date: 'Vorgestern',
    },
    {
        sender: 'noreply',
        subject: 'Anmeldung',
        text: 'Lorem Ipsum dolor sit amet',
        date: '13.01.22',
    },
];
function MailInbox({ data, onDetailClick }) {
    const { title, subline, headline } = data;
    return (
        <>
            <div
                style={{
                    width: '100%',
                    backgroundImage: `url(${gradient}`,
                    backgroundColor: '#f21850',
                    backgroundSize: 'cover',
                    padding: '8px 0',
                    color: 'white',
                    textAlign: 'center',
                }}>
                Mail
            </div>
            <div
                style={{
                    backgroundColor: 'white',
                    padding: 6,
                    paddingLeft: 0,
                    borderBottom: '1px solid #efefef',
                    display: 'flex',
                    cursor: 'pointer',
                }}
                role="button"
                onClick={onDetailClick}>
                <div
                    style={{
                        backgroundColor: 'rgb(0,122,255)',
                        height: 5,
                        width: 5,
                        margin: 4,
                        borderRadius: 99,
                        flexShrink: 0,
                        border: '1px solid rgb(255,149,0)',
                    }}
                />
                <div style={{ flexGrow: 1, flexBasis: 0, minWidth: 0 }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}>
                        <div
                            style={{
                                color: '#4A494A',
                                fontWeight: '500',
                                fontSize: '1.2em',
                                flexGrow: 1,
                                flexBasis: 0,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {title}
                        </div>
                        <div style={{ color: '#8E8E8E', fontWeight: '400', fontSize: '1em' }}>
                            <Icon type="ellipsis" />
                        </div>
                    </div>

                    <div
                        style={{
                            color: '#4A494A',
                            fontSize: '1em',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            flexGrow: 1,
                            flexBasis: 0,
                        }}>
                        <div
                            style={{
                                padding: '0 2px',
                                marginRight: 2,
                                color: '#F21850',
                                border: '1px solid #F21850',
                                borderRadius: '2px',
                                display: 'inline-block',
                            }}>
                            Sponsored
                        </div>
                        {headline}
                    </div>
                    <div
                        style={{
                            color: '#8E8E8E',
                            fontSize: '1em',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxHeight: '2.6em',
                            lineHeight: '1.3em',
                        }}>
                        {subline}
                    </div>
                </div>
            </div>
            {demoData.map((mail) => (
                <div
                    style={{
                        backgroundColor: 'white',
                        padding: 6,
                        paddingLeft: 0,
                        borderBottom: '1px solid #efefef',
                        display: 'flex',
                    }}>
                    <div
                        style={{
                            backgroundColor: 'rgb(0,122,255)',
                            height: 5,
                            width: 5,
                            margin: 4,
                            borderRadius: 99,
                            float: 'left',
                        }}
                    />
                    <div style={{ width: '100%' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}>
                            <div style={{ color: '#4A494A', fontWeight: '500', fontSize: '1.2em' }}>
                                {mail.sender}
                            </div>
                            <div style={{ color: '#8E8E8E', fontWeight: '400', fontSize: '1em' }}>
                                {mail.date}
                            </div>
                        </div>

                        <div style={{ color: '#4A494A', fontSize: '1em' }}>{mail.subject}</div>
                        <div style={{ color: '#8E8E8E', fontSize: '1em' }}>{mail.text}</div>
                    </div>
                </div>
            ))}
        </>
    );
}

MailInbox.propTypes = {};

export default MailInbox;
