import React, { useEffect, useState } from 'react';
import { Row, Col, Tabs } from 'antd';
import '../style.css';
import moment from 'moment';
import 'moment/locale/de';
import {
    Chart as ChartJS,
    Title as ChartTitle,
    Tooltip as ChartTooltip,
    ArcElement,
    Legend,
} from 'chart.js';
import CompanyCards from './CompanyCards';
import TopUniversitiesTable from './TopUniversitiesTable';
import TopStudiesTable from './TopStudiesTable';
import TopUniversitiesChart from './TopUniversitiesChart';
import TopStudiesChart from './TopStudiesChart';
import useCubeWithQueryErrorHandling from '../../hooks/useCubeWithQueryErrorHandling';

const { TabPane } = Tabs;

ChartJS.register(ArcElement, ChartTitle, ChartTooltip, Legend);

moment.locale('de');

const chartColors = [
    '#DF3754',
    '#DF434C',
    '#A5142D',
    '#EE8F37',
    '#E25345',
    '#E8A14E',
    '#DF3754',
    '#E46040',
    '#DB5C72',
    '#EE8F37',
    '#DCDCDC',
];

const chartOptions = {
    padding: '0px',
    responsive: true,
    cutout: '70%',
    animation: false,
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: false,
        },
        tooltip: {
            enabled: true,
        },
    },
};

function Company() {
    const [companyFilterValue, setCompanyFilterValue] = useState('Seen');
    const [data, setData] = useState([]);
    const query = {
        measures: ['CompanyStats.lastUpdate'],
    };

    const { resultSet } = useCubeWithQueryErrorHandling(query);

    useEffect(() => {
        setCompanyFilterValue('Follows');
    }, []);

    useEffect(() => {
        if (resultSet) {
            const companyData = resultSet.tablePivot().map((record, index) => ({
                ...record,
                key: index,
            }));
            setData(companyData);
        }
    }, [resultSet]);

    if (!resultSet) {
        return null;
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <CompanyCards
                        companyFilterValue={companyFilterValue}
                        setCompanyFilterValue={setCompanyFilterValue}
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={24} lg={24} xs={24} xxl={24} md={24} sm={24}>
                    <Tabs
                        defaultActiveKey="1"
                        tabBarGutter={8}
                        size="large"
                        style={{ width: '100%' }}>
                        <TabPane tab="Top 10 Hochschulen" key="1">
                            <Row gutter={16} style={{ padding: '5px' }}>
                                <Col
                                    xs={24}
                                    lg={10}
                                    style={{
                                        padding: '60px',
                                    }}>
                                    <TopUniversitiesChart
                                        chartColors={chartColors}
                                        companyFilterValue={companyFilterValue}
                                        chartOptions={chartOptions}
                                    />
                                </Col>
                                <Col xs={24} lg={14}>
                                    <TopUniversitiesTable
                                        chartColors={chartColors}
                                        companyFilterValue={companyFilterValue}
                                    />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab="Top 10 Studiengänge" key="2">
                            <Row gutter={16} style={{ padding: '5px' }}>
                                <Col
                                    xs={24}
                                    lg={10}
                                    style={{
                                        padding: '60px',
                                    }}>
                                    <TopStudiesChart
                                        chartColors={chartColors}
                                        companyFilterValue={companyFilterValue}
                                        chartOptions={chartOptions}
                                    />
                                </Col>
                                <Col xs={24} lg={14}>
                                    <TopStudiesTable
                                        chartColors={chartColors}
                                        companyFilterValue={companyFilterValue}
                                    />
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                    <div
                        style={{
                            fontSize: '0.8rem',
                            opacity: 0.5,
                            color: '#000000',
                            padding: '5px',
                        }}>
                        Letzte Aktualisierung am{' '}
                        {moment(data['CompanyStats.lastUpdate']).format('DD.MM.YYYY')}
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default Company;
