import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { sortBy } from 'lodash';

import { connect } from 'react-redux';

import { Table, Select, Tooltip, Empty, Result } from 'antd';

import { Link } from 'react-router-dom';
import { updateApplicantStatus } from '../../core/redux/actions';
import { Spinner } from '../../components';
import { RequestContext } from '../../components/Session/RequestProvider';
import { SessionContext } from '../../components/Session';

const STATE_MAP = {
    OPEN: 1,
    PROCESSING: 2,
    CLOSED: 3,
    REVOKED: 4,
};

function Applicants({ joblist, jobsLoading, match, updateStatus }) {
    const { request } = useContext(RequestContext);
    const { role } = useContext(SessionContext);

    if (jobsLoading) {
        return <Spinner />;
    }

    const job = joblist.find((el) => el.uuid === match.params.uuid);
    const applicants = job
        ? sortBy(job.applicants || [], [
              ({ status }) => STATE_MAP[status] || Infinity,
              ({ createdAt }) => -moment.utc(createdAt).unix(),
          ])
        : [];

    console.log(applicants);
    const columns = [
        {
            title: 'Eingegangen am',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => moment(a.createdAt).diff(moment(b.createdAt)),
            render: (text) => moment(text).format('DD.MM.YYYY HH:mm'),
        },
        {
            title: 'Standort',
            key: 'location',
            render: (record) => (
                <div>
                    {record.city}
                    <br />
                    <span style={{ color: '#82828c' }}>{record.street}</span>
                </div>
            ),
        },
        {
            title: 'Name',
            key: 'name',
            render: (record) =>
                !record.firstName ? (
                    '-'
                ) : (
                    <div>
                        {record.firstName}
                        <br />
                        <span style={{ color: '#82828c' }}>{record.lastName}</span>
                    </div>
                ),
        },
        {
            title: 'Kontakt',
            key: 'contact',
            render: (record) =>
                !record.email ? (
                    '-'
                ) : (
                    <div>
                        {record.email}
                        <br />
                        <span style={{ color: '#82828c' }}>{record.tel}</span>
                    </div>
                ),
        },
        {
            title: 'Studium',
            key: 'studies',
            render: (record) => {
                const { major, semester } = record;

                if (!major && !semester) {
                    return '-';
                }

                let formattedSemester = semester;
                if (semester && /^\d+$/.test(semester)) {
                    formattedSemester = `Semester ${semester}`;
                }

                return (
                    <div>
                        {major || formattedSemester}
                        <br />
                        <span style={{ color: '#82828c' }}>{major ? formattedSemester : ''}</span>
                    </div>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, item) => (
                <Select
                    defaultValue={item.status}
                    style={{ width: 145 }}
                    disabled={item.status === 'REVOKED'}
                    onSelect={(value) => onStatusChange(item.jobID, item.uuid, value)}>
                    <Select.Option value="OPEN">Offen</Select.Option>
                    <Select.Option value="PROCESSING">In Bearbeitung</Select.Option>
                    <Select.Option value="CLOSED">Abgeschlossen</Select.Option>
                    <Select.Option style={{ display: 'none' }} value="REVOKED">
                        <Tooltip title="Der/Die Bewerber/in hat die Bewerbung zurückgezogen.">
                            Widerrufen
                        </Tooltip>
                    </Select.Option>
                </Select>
            ),
            onFilter: (value, record) => record.status === value,
            filters: [
                {
                    text: 'Offen',
                    value: 'OPEN',
                },
                {
                    text: 'In Bearbeitung',
                    value: 'PROCESSING',
                },
                {
                    text: 'Abgeschlossen',
                    value: 'CLOSED',
                },
                {
                    text: 'Widerrufen',
                    value: 'REVOKED',
                },
            ],
        },
    ];

    const onStatusChange = async (jobID, uuid, value) => {
        updateStatus(job.uuid, uuid, value);

        if (role !== 'READONLY') {
            await request('recruiting/job/update/applicant', {
                jobID,
                applicantID: uuid,
                status: value,
            });
        }
    };

    return (
        <div>
            {job ? (
                <>
                    <h2 style={{ marginBottom: 0 }}>Bewerbungen für {job.title}</h2>
                    <br />
                    <Table
                        rowKey="uuid"
                        dataSource={applicants}
                        columns={columns}
                        locale={{
                            emptyText: (
                                <div>
                                    <Empty description="Keine Bewerber*" />
                                    <br />
                                    <br />
                                    *verfügbar seit 05.07.2019 bzw. App Version 3.23.0
                                </div>
                            ),
                        }}
                    />
                </>
            ) : (
                <Result
                    status="info"
                    title="Dieser Job konnte in Ihrem Unternehmensprofil nicht gefunden werden."
                    extra={<Link to="/stellenanzeigen">Zurück zur Übersicht</Link>}
                />
            )}
        </div>
    );
}

Applicants.contextTypes = {
    request: PropTypes.func.isRequired,
    role: PropTypes.string,
};

const mapStateToProps = (state) => ({
    joblist: state.jobs.joblist,

    jobsLoading: state.jobs.jobsLoading,
});

const mapDispatchToProps = (dispatch) => ({
    updateStatus: (jobID, applicantID, status) =>
        dispatch(updateApplicantStatus(jobID, applicantID, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Applicants);
