import React, { Component } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Slider, Icon, Progress } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { convertUrlToBase64 } from '../../core/helper';
import imageConfig from '../../core/constants/imageConfig';

class ImageEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageToEdit: undefined,
            dominantColor: '',
            isReady: false,
            quality: 100,
        };
    }

    componentWillMount = () => {
        const { imageToEdit } = this.props;
        if (imageToEdit) {
            this.initialize(imageToEdit);
        }
    };

    componentDidMount = () => {
        const { onRef } = this.props;
        onRef(this);
    };

    componentWillReceiveProps = (nextProps) => {
        const { imageToEdit: nextImageToEdit } = nextProps;
        const { imageToEdit: curImageToEdit } = this.props;

        // if(nextImageToEdit && nextImageToEdit !== curImageToEdit){
        //     this.initialize(nextImageToEdit);
        // }else{
        //     this.setState({imageToEdit: undefined, dominantColor: '', isReady: false});
        // }

        if (nextImageToEdit) {
            if (nextImageToEdit !== curImageToEdit) {
                this.initialize(nextImageToEdit);
            }
        } else {
            this.setState({ imageToEdit: undefined, dominantColor: '', isReady: false });
        }
    };

    componentToHex = (c) => {
        const hex = c.toString(16);
        return hex.length === 1 ? `0${hex}` : hex;
    };

    rgbToHex = (r, g, b) =>
        `#${this.componentToHex(r)}${this.componentToHex(g)}${this.componentToHex(b)}`;

    getImageBorderColor = (imageUrl) =>
        new Promise((resolve) => {
            const image = new Image();
            const canvas = document.createElement('canvas');
            const canvasContext = canvas.getContext('2d');

            image.onload = () => {
                const { width: imageWidth, height: imageHeight } = image;

                canvas.width = imageWidth;
                canvas.height = imageHeight;
                canvasContext.drawImage(image, 0, 0);

                const pixelCoordinates = [
                    { x: 0, y: 0 },
                    { x: 0, y: imageHeight - 1 },
                    { x: imageWidth - 1, y: 0 },
                    { x: imageWidth - 1, y: imageHeight - 1 },
                ];

                const pixelColors = pixelCoordinates.map((coordinate) => {
                    const { x, y } = coordinate;
                    const pixel = canvasContext.getImageData(x, y, 1, 1).data;
                    const r = pixel[0];
                    const g = pixel[1];
                    const b = pixel[2];
                    const a = pixel[3];
                    let color = '#ffffff';
                    if (a > 0) {
                        color = this.rgbToHex(r, g, b);
                    }
                    return color;
                });

                const colorCounts = Object.entries(_.countBy(pixelColors)).sort((a, b) => {
                    const colorCountA = a[1];
                    const colorCountB = b[1];
                    if (colorCountA > colorCountB) {
                        return -1;
                    }
                    return 1;
                });

                const borderColor = colorCounts[0][0];
                resolve(borderColor);
            };

            image.src = imageUrl;
        });

    initialize = async (imageToEdit) => {
        const { isReady } = this.state;
        const { raw: imageUrl } = imageToEdit;
        const { onLoadBegin, onLoadEnd, optimalHeight, optimalWidth } = this.props;

        if (onLoadBegin) {
            onLoadBegin();
        }
        if (isReady) {
            this.setState({ isReady: false });
        }
        try {
            if (!imageUrl.includes('base64')) {
                imageToEdit.raw = await convertUrlToBase64(imageUrl);
            }
            const image = new Image();
            image.src = imageToEdit.raw;
            const { originalWidth, originalHeight } = await new Promise((resolve, reject) => {
                image.onload = function () {
                    const originalHeight = this.height;
                    const originalWidth = this.width;
                    resolve({ originalWidth, originalHeight });
                };
            });

            const role = this.context.role || this.props.role || '';
            const isAdmin = false; // ['SUPERADMIN', 'ADMIN', 'CUSTOMER_SUPPORT', ].includes(role.toUpperCase());
            const minQuality = this.calculateMinQuality(isAdmin);
            const maxScale = this.calculateMaximumScale(
                originalHeight,
                minQuality,
                optimalHeight,
                originalWidth,
                optimalWidth,
                imageToEdit.payload.scale,
            );

            const dominantColor = '#ffffff'; // await this.getImageBorderColor(imageToEdit.raw);
            this.setState({
                imageToEdit,
                dominantColor,
                isReady: true,
                originalHeight,
                originalWidth,
                maxScale,
            });
            if (onLoadEnd) {
                onLoadEnd();
            }
        } catch (e) {}
    };

    getImage = () => {
        const { imageToEdit, dominantColor } = this.state;
        const imageCanvas = this.avatarEditorRef.getImage();
        const imageCanvasCopy = this.avatarEditorRef.getImage();

        const context = imageCanvas.getContext('2d');
        context.fillStyle = dominantColor;
        context.fillRect(0, 0, imageCanvasCopy.width, imageCanvasCopy.height);
        context.drawImage(imageCanvasCopy, 0, 0);
        return {
            ...imageToEdit,
            edited: imageCanvas.toDataURL(),
            width: imageCanvas.width,
            height: imageCanvas.height,
            payload: {
                ...imageToEdit.payload,
            },
        };
    };

    getCanvasImage = () => this.avatarEditorRef.getImage();

    getCanvasScaledImage = () => this.avatarEditorRef.getImageScaledToCanvas();

    handleImagePositionChange = (position) => {
        const { imageToEdit } = this.state;
        const { onChange } = this.props;
        const { x, y } = position;
        const updatedImage = {
            ...imageToEdit,
            payload: {
                ...imageToEdit.payload,
                posX: x,
                posY: y,
                ...this.avatarEditorRef.getCroppingRect(),
            },
        };

        this.setState({ imageToEdit: updatedImage }, () => {
            if (onChange) {
                onChange(updatedImage);
            }
        });
    };

    handleImageScaleChange = (value) => {
        const { imageToEdit } = this.state;
        const { onChange, onQualityChange } = this.props;
        const updatedImage = {
            ...imageToEdit,
            payload: {
                ...imageToEdit.payload,
                scale: value,
            },
        };

        const { width, height } = this.avatarEditorRef.getImage();

        const { quality, lowQuality } = this.calculateQuality();
        if (onQualityChange) {
            onQualityChange(lowQuality);
        }
        this.setState(
            {
                editWidth: width,
                editHeight: height,
                imageToEdit: updatedImage,
                quality,
            },
            () => {
                if (onChange) {
                    onChange(updatedImage);
                }
            },
        );
    };

    onImageReady = () => {
        const { imageToEdit } = this.state;
        const { onLoadEnd, onQualityChange } = this.props;

        const { width, height } = this.avatarEditorRef.getImage();

        const { lowQuality } = this.calculateQuality();

        if (onQualityChange) {
            onQualityChange(lowQuality);
        }

        this.setState({
            editWidth: width,
            editHeight: height,
            imageToEdit: {
                ...imageToEdit,
                payload: {
                    ...imageToEdit.payload,
                    ...this.avatarEditorRef.getCroppingRect(),
                },
            },
        });

        if (onLoadEnd) {
            onLoadEnd();
        }
    };

    tipFormatter = (value) => `${Math.round(value * 100)}%`;

    renderEditor = () => {
        const { imageToEdit, dominantColor, originalWidth, originalHeight, maxScale } = this.state;
        const { payload: imagePayload, raw: imageUrl } = imageToEdit;
        const { scale, posX, posY } = imagePayload;
        const { width, height, enableZoomOut, optimalWidth, optimalHeight } = this.props;

        const { quality, strokeColor } = this.calculateQuality();
        const marks = { [maxScale]: 'max' };
        if (maxScale > 1) {
            marks[1] = '100%';
        }
        let min = 1;
        if (enableZoomOut) {
            marks[0.1] = '10%';
            min = 0.1;
        }
        return (
            <div>
                <span style={{ display: 'flex', justifyContent: 'center' }}>
                    <AvatarEditor
                        crossOrigin="anonymous"
                        image={imageUrl}
                        ref={(refeditor) => {
                            this.avatarEditorRef = refeditor;
                        }}
                        style={{ backgroundColor: dominantColor }}
                        border={1}
                        width={width}
                        height={height}
                        scale={scale}
                        position={{ x: posX, y: posY }}
                        onPositionChange={this.handleImagePositionChange}
                        onImageReady={this.onImageReady}
                        disableDrop
                    />

                    <Slider
                        disabled={maxScale < 1 && !this.props.enableZoomOut}
                        style={{ height, margin: '0 0 0 20px' }}
                        marks={marks}
                        tipFormatter={this.tipFormatter}
                        vertical
                        defaultValue={1}
                        value={scale}
                        min={min}
                        max={maxScale}
                        step={0.02}
                        onChange={this.handleImageScaleChange}
                    />
                </span>
                <div style={{ paddingTop: 10, paddingRight: 80 }}>
                    <Progress
                        strokeColor={strokeColor}
                        percent={quality}
                        format={() => 'Bildqualität'}
                    />
                </div>
            </div>
        );
    };

    calculateMaximumScale(
        originalHeight,
        minQuality,
        optimalHeight,
        originalWidth,
        optimalWidth,
        currentScale,
    ) {
        const maxScale =
            Math.floor(
                Math.min(
                    originalHeight / (minQuality * optimalHeight),
                    originalWidth / (minQuality * optimalWidth),
                ) * 10000,
            ) * 0.01;
        return Math.max(maxScale, currentScale);
    }

    calculateQuality() {
        const { optimalWidth, optimalHeight } = this.props;
        const {
            imageToEdit: {
                payload: { scale = 1 },
            },
            originalWidth,
            originalHeight,
        } = this.state;
        const role = this.context.role || this.props.role || '';
        const isAdmin = false; // ['SUPERADMIN', 'ADMIN', 'CUSTOMER_SUPPORT', ].includes(role.toUpperCase());

        let quality = 100;
        let lowQuality = false;
        const minQuality = this.calculateMinQuality(isAdmin);
        let strokeColor = '#52c41a';
        if (optimalWidth && optimalHeight) {
            const widthQuality = (originalWidth * 100) / (optimalWidth * scale);
            const heightQuality = (originalHeight * 100) / (optimalHeight * scale);

            quality = Math.min(100, widthQuality, heightQuality);

            if (quality < minQuality) {
                strokeColor = '#f5222d';
                lowQuality = true;
            } else if (
                isAdmin &&
                quality > imageConfig.minimumAdminImageQuality &&
                quality < imageConfig.minimumImageQuality
            ) {
                strokeColor = '#faad14';
            }
        }
        return {
            quality,
            minQuality,
            strokeColor,
            lowQuality,
        };
    }

    calculateMinQuality(isAdmin) {
        const minQuality = isAdmin
            ? imageConfig.minimumAdminImageQuality
            : imageConfig.minimumImageQuality;
        return minQuality;
    }

    render() {
        const { isReady } = this.state;

        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {isReady ? (
                    this.renderEditor()
                ) : (
                    <Icon type="loading" style={{ fontSize: '100px', color: '#f21850' }} />
                )}
            </div>
        );
    }
}

ImageEditor.contextTypes = {
    role: PropTypes.string.isRequired,
};

export default ImageEditor;
