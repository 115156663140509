import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import { decode } from 'jsonwebtoken';
import * as Sentry from '@sentry/react';
import { connect } from 'react-redux';

import * as sizeof from 'object-sizeof';
import { authenticate, getUser } from '../../core/api/auth';
import { saveUserData, clearUserData, switchProfile } from '../../core/redux/actions/user';
import showMessage from '../../core/helper/showMessage';
import {
    saveSessionData,
    changeArrayElement,
    addToArray,
    setAttributeValue,
} from '../../core/redux/actions';
import { RequestContext } from './RequestProvider';
import { Spinner } from '../index';

export const SessionContext = createContext();

class Session extends Component {
    constructor() {
        super();
        this.state = { ready: false };
    }

    componentDidMount = async () => {
        const { username, authToken } = this.props;

        if (username) {
            Sentry.setUser({ username });
        }
        await this.loginWithToken(authToken);
    };

    getContext = () => {
        const { authToken, username, role, store } = this.props;

        return {
            login: this.login,
            loginWithToken: this.loginWithToken,
            logout: this.logout,
            createUser: this.createUser,
            updateUserRole: this.updateUserRole,
            deleteUser: this.deleteUser,
            resetPassword: this.resetPassword,
            authToken,
            username,
            accountId: authToken ? decode(authToken).sub : null,
            role,
            loadSessionData: this.loadSessionData,
            setMessage: this.setMessage,
            changePassword: this.changePassword,
            sendPasswordEmail: this.sendPasswordEmail,
            subscribeToStore: store.subscribe,
        };
    };

    shouldComponentUpdate = (nextProps, nextState) =>
        nextProps.authToken !== this.props.authToken || nextState.ready !== this.state.ready;

    setMessage = (description, type) => {
        showMessage(description, type);
    };

    login = async (usernameRaw, passwordRaw) => {
        const username = usernameRaw.trim();
        const password = passwordRaw.trim();

        const { token, user } = await authenticate(username, password);

        const { body, error } = await this.context.request('recruiting/member/info', {}, token);
        if (error || !body) {
            throw new Error(error);
        }
        if (body) {
            const { role } = body;

            Sentry.setUser({ name: user.email });

            this.props.saveUserData(user.email, token, role);
        }
    };

    loginWithToken = async (token) => {
        if (!token) {
            this.setState({ ready: true });
            return false;
        }
        const decodedToken = decode(token);
        const isDeprecatedToken = !!decodedToken.member && !decodedToken.acr;

        const handleLoginError = () => {
            this.setMessage('Anmeldung fehlgeschlagen.', 'error');
            this.logout();
            this.setState({ ready: true });
            return false;
        };
        if (isDeprecatedToken) {
            const { body } = await this.context.request('translate', {
                token,
                application: 'com.uninow.recruiting',
            });

            if (body) {
                token = body.token;
            } else {
                return handleLoginError();
            }
        }

        const { body: user } = await getUser(token);
        if (!user) {
            return handleLoginError();
        }
        const infoResponse = await this.context.request('recruiting/member/info', {}, token);

        if (infoResponse.body && user) {
            const { role } = infoResponse.body;

            Sentry.setUser({ name: user.email });

            this.props.saveUserData(user.email, token, role);
            this.setState({ ready: true });
        } else {
            return handleLoginError();
        }
    };

    logout = () => {
        this.props.clearUserData();
    };

    loadSessionData = async (loadArchivedJobs) => {
        const { saveSessionData } = this.props;
        const companyResponse = await this.context.request('recruiting/company/fetch');

        if (companyResponse.error) {
            this.setMessage(
                'Beim Laden Ihrer Daten ist ein Fehler unterlaufen. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.',
                'error',
            );
            return false;
        }

        const sessionData = {
            company: companyResponse.body,
            companyList: [],
            jobs: [],
            jobsLoading: true,
        };

        await saveSessionData(sessionData);
        this.context.request('recruiting/company/members').then((memberResponse) => {
            if (memberResponse.error) {
                this.setMessage(
                    'Beim Laden Ihrer Mitarbeiter ist ein Fehler unterlaufen. Bitte versuchen Sie es später erneut',
                    'error',
                );
            }
            saveSessionData({
                memberList: memberResponse.body || [],
            });
        });
        this.context
            .request('recruiting/job/list', { loadArchived: loadArchivedJobs })
            .then((jobResponse) => {
                if (jobResponse.error) {
                    this.setMessage(
                        'Beim Laden Ihrer Stellenanzeigen ist ein Fehler unterlaufen. Bitte versuchen Sie es später erneut',
                        'error',
                    );
                }
                saveSessionData({
                    jobs: jobResponse.body || [],
                    jobsLoading: false,
                    includesArchived: loadArchivedJobs,
                });
            });

        return true;
    };

    createUser = async (username, role, password) => {
        const { company } = this.props;
        const { uuid: companyID } = company;
        const member = { username, role: role.toUpperCase(), password };
        const body = {
            member,
            companyID,
        };

        const { body: result } = await this.context.request('recruiting/member/create', body);

        if (result) {
            const { member: newMember } = result;
            this.props.addToArray('list', newMember, 'memberList');
            return newMember.password;
        }

        return false;
    };

    deleteUser = async (memberID) => {
        const { body: result } = await this.context.request('recruiting/member/remove', {
            memberID,
        });

        if (result) {
            const updatedMemberList = this.props.memberList.filter(
                (member) => member._id !== memberID,
            );
            this.props.setAttributeValue('list', updatedMemberList, 'memberList');
            return true;
        }
        return false;
    };

    resetPassword = async (memberID) => {
        const { body: result } = await this.context.request('recruiting/member/resetPassword', {
            memberID,
        });

        if (result && result.member) {
            return result.member.password;
        }
        return false;
    };

    sendPasswordEmail = async (email) => {
        const { body: newPw } = await this.context.request('recruiting/member/recover', {
            username: email,
        });

        return newPw;
    };

    changePassword = async (oldPassword, newPassword) => {
        const { body, error } = await this.context.request('recruiting/member/update', {
            password: oldPassword,
            member: {
                password: newPassword,
            },
        });

        if (body) {
            this.setMessage('Das ändern Ihres Passworts war erfolgreich.', 'success');

            return true;
        }

        this.setMessage(
            `Beim speichern Ihrer Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.\n\n(Reason: ${error})`,
            'error',
        );

        return false;
    };

    updateUserRole = async (memberID, newRole) => {
        const { memberList, changeArrayElement } = this.props;

        const { body: result } = await this.context.request('recruiting/member/updateRole', {
            memberID,
            newRole,
        });

        if (result) {
            const index = memberList.findIndex((member) => member._id === memberID);
            changeArrayElement(
                'list',
                index,
                { ...memberList[index], role: newRole.toUpperCase() },
                'memberList',
            );

            return true;
        }

        return false;
    };

    render() {
        return (
            <SessionContext.Provider value={this.getContext()}>
                {!this.state.ready && <Spinner tip="Anmeldung..." />}
                {this.state.ready && this.props.children}
            </SessionContext.Provider>
        );
    }
}
Session.contextType = RequestContext;

const mapStateToProps = ({ company, memberList, authentication, user }) => ({
    company,
    memberList: memberList.list,
    authToken: authentication.token,
    username: authentication.username,
    role: authentication.role,
    profileId: user.profileId,
});

const mapDispatchToProps = (dispatch) => ({
    clearUserData: () => dispatch(clearUserData()),
    switchProfile: (profileId) => dispatch(switchProfile(profileId)),
    saveUserData: (username, token, role) => dispatch(saveUserData(username, token, role)),
    saveSessionData: (data) => dispatch(saveSessionData(data)),
    changeArrayElement: (attributeName, indexOfElement, updatedElement, reducer) =>
        dispatch(changeArrayElement(attributeName, indexOfElement, updatedElement, reducer)),
    addToArray: (attributeName, element, reducer) =>
        dispatch(addToArray(attributeName, element, reducer)),
    setAttributeValue: (attributeName, value, reducer) =>
        dispatch(setAttributeValue(attributeName, value, reducer)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Session);
