import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { Row, Col, Icon, Badge } from 'antd';

import parseData from '../../core/helper/markdown';
import { Map } from '..';

const PRIMARY_COLOR = '#4A4A4A';
const SECONDARY_COLOR = '#8E8E8E';
const HIGHLIGHT_COLOR = '#F21850';

const BACKGROUND_COLOR = '#EFF0F2';

class Post extends Component {
    state = {
        dimensions: null,
    };

    componentDidMount() {
        this.setState({
            dimensions: {
                width: this.container.offsetWidth,
                height: this.container.offsetHeight,
            },
        });
    }

    renderHeader = () => {
        const { publishDate, author = {} } = this.props.post;
        const { displayName, username, avatar = {} } = author;

        return (
            <Row gutter={10} style={{ padding: '5px 10px' }}>
                <Col span={4}>
                    <img alt="logo" src={avatar.small} style={{ width: '100%' }} />
                </Col>
                <Col span={20}>
                    <Row gutter={5} style={{ fontWeight: 600, fontSize: '1em' }}>
                        <Col span={22}>{displayName}</Col>
                        <Col span={2} style={{ textAlign: 'right' }}>
                            <Icon type="ellipsis" />
                        </Col>
                    </Row>
                    <Row
                        gutter={5}
                        type="flex"
                        justify="space-between"
                        style={{
                            color: SECONDARY_COLOR,
                            fontSize: '0.9em',
                        }}>
                        <Col
                            span={14}
                            style={{
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                            }}>
                            @{username}
                        </Col>
                        <Col
                            span={10}
                            style={{
                                textAlign: 'right',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                            }}>
                            {moment.duration(moment(publishDate).diff(moment())).humanize(true)}
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    };

    renderContent = () => {
        const { content } = this.props.post;

        return (
            <div style={{ fontSize: '1em', fontWeight: 400, padding: '10px 10px' }}>
                {parseData(content)}
            </div>
        );
    };

    renderAttachment = () => {
        const { attachments = [] } = this.props.post;

        if (attachments.length === 0 || !attachments[0]) {
            return null;
        }

        const { __typename, translations } = attachments[0];

        switch (__typename) {
            case 'Blog':
                const { title, subtitle, description, url, image = {} } = attachments[0];

                return (
                    <div style={{ background: BACKGROUND_COLOR, cursor: 'pointer' }}>
                        <a href={url} target="_blank" rel="noreferrer">
                            {image && image.large && (
                                <img
                                    alt=""
                                    src={image.large}
                                    style={{ width: '100%', height: '100px', objectFit: 'cover' }}
                                />
                            )}
                        </a>
                        <div style={{ padding: '5px 10px' }}>
                            {title && (
                                <div
                                    style={{
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        fontWeight: 700,
                                    }}>
                                    {title.toUpperCase()}
                                </div>
                            )}
                            {subtitle && (
                                <div
                                    style={{
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        fontWeight: 700,
                                        color: '#000000',
                                    }}>
                                    {subtitle}
                                </div>
                            )}
                            {description && (
                                <div
                                    style={{
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                    }}>
                                    {description}
                                </div>
                            )}
                        </div>
                    </div>
                );

            case 'Video':
                const { video } = attachments[0];

                return video && video.raw ? (
                    <div style={{ padding: '10px 0px' }}>
                        <video
                            preload="metadata"
                            src={video.raw}
                            autoPlay={false}
                            muted
                            controls
                            style={{
                                width: '100%',
                            }}
                        />
                    </div>
                ) : null;

            case 'Gallery':
                let { images = [] } = attachments[0];
                if (translations) {
                    images = translations.images?.[0]?.value ?? [];
                }
                return (
                    <div style={{ textAlign: 'center' }}>
                        <img
                            alt=""
                            src={images[0] && images[0].large}
                            style={{ width: '100%', objectFit: 'contain', maxHeight: '450px' }}
                        />
                        {images.length > 1 && (
                            <div style={{ padding: 5 }}>
                                {images.map((image, index) => (
                                    <Badge
                                        key={image.raw}
                                        status={index === 0 ? 'error' : 'default'}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                );

            case 'Event':
                const {
                    start: starting,
                    end: ending,
                    title: eventTitle,
                    location = {},
                } = attachments[0];

                let locationString = ['city', 'street', 'addition']
                    .map((prop) => (location || {})[prop])
                    .filter((prop) => prop)
                    .join(', ');

                if (!locationString) {
                    locationString = 'Kein Ort angegeben';
                }

                const eventStart = moment(starting);
                const eventEnd = ending ? moment(ending) : null;

                const sameDay = eventStart.isSame(eventEnd, 'day');

                let timeString = null;
                if (!eventStart.isValid()) {
                    timeString = 'Keine Startzeit angegeben';
                } else if (!eventEnd) {
                    timeString = `${eventStart.format('D. MMMM YY')}, ${eventStart.format(
                        'HH:mm',
                    )}`;
                } else if (sameDay) {
                    timeString = `${eventStart.format('D. MMMM YY')}, ${eventStart.format(
                        'HH:mm',
                    )} - ${eventEnd.format('HH:mm')}`;
                } else {
                    timeString = (
                        <span>
                            {`${eventStart.format('D. MMMM YY')}, ${eventStart.format('HH:mm')}`} -
                            <br />
                            {`${eventEnd.format('D. MMMM YY')}, ${eventEnd.format('HH:mm')}`}
                        </span>
                    );
                }

                const { geoPoint } = location || {};
                const { lat, lng } = geoPoint || {};

                return (
                    <div style={{ backgroundColor: BACKGROUND_COLOR }}>
                        {lat && lng && (
                            <Map
                                zoom={15}
                                showMarker
                                lat={lat}
                                lng={lng}
                                containerElement={<div style={{ height: '10em' }} />}
                                mapElement={<div style={{ height: '100%' }} />}
                            />
                        )}
                        <div style={{ padding: '5px 10px' }}>
                            <div style={{ fontWeight: 700, padding: '10px 0px' }}>{eventTitle}</div>
                            <Row type="flex" align="middle" justify="space-between">
                                <Col span={22}>
                                    <div
                                        style={{
                                            color: SECONDARY_COLOR,
                                            fontSize: '0.9em',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                        }}>
                                        <Icon
                                            type="clock-circle"
                                            style={{ paddingRight: 5, color: HIGHLIGHT_COLOR }}
                                        />
                                        <span>{timeString}</span>
                                    </div>
                                    <div
                                        style={{
                                            color: SECONDARY_COLOR,
                                            fontSize: '0.9em',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                        }}>
                                        <Icon
                                            type="environment"
                                            style={{ paddingRight: 5, color: HIGHLIGHT_COLOR }}
                                        />
                                        <span>{locationString}</span>
                                    </div>
                                </Col>
                                <Col span={2}>
                                    <Icon
                                        type="calendar"
                                        style={{ color: HIGHLIGHT_COLOR, fontSize: '1.2em' }}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                );

            case 'Poll':
                const { options = [], end } = attachments[0];

                const now = moment();
                const endDate = moment(end);

                const until = now.isAfter(endDate)
                    ? 'Diese Umfrage ist abgelaufen'
                    : `endet ${moment.duration(endDate.diff(now)).humanize(true)}`;

                return (
                    <div style={{ padding: '5px 10px', color: SECONDARY_COLOR, fontSize: '0.9em' }}>
                        {options.map((option) => (
                            <Row
                                type="flex"
                                align="middle"
                                key={option.option}
                                style={{
                                    borderRadius: 20,
                                    margin: '5px 0px',
                                    padding: '8px 10px',
                                    backgroundColor: '#EDEFF4',
                                }}>
                                <Col span={20}>{option.option}</Col>
                                <Col span={4} style={{ textAlign: 'right' }}>
                                    <Icon type="right" />
                                </Col>
                            </Row>
                        ))}
                        <Row
                            style={{
                                color: SECONDARY_COLOR,
                                fontSize: '0.9em',
                                padding: '10px 5px',
                            }}>
                            <Col span={8}>0 Stimmen</Col>
                            <Col span={16} style={{ textAlign: 'right' }}>
                                {until}
                            </Col>
                        </Row>
                    </div>
                );

            case 'CompanyPromotion':
                const { badgeTitle, company = {} } = attachments[0];
                const { header } = company;
                const { large } = header || {};

                return (
                    <div>
                        <div style={{ backgroundColor: '#eef0f2' }}>
                            {large && (
                                <div style={{ position: 'relative', width: '100%' }}>
                                    <img style={{ width: '100%' }} src={large} alt="" />
                                    {badgeTitle && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '5%',
                                                left: 0,
                                                color: '#ffffff',
                                                backgroundColor: '#f21850',
                                                fontWeight: 600,
                                                padding: '5px 15px',
                                            }}>
                                            {badgeTitle}
                                        </div>
                                    )}
                                </div>
                            )}
                            <Row
                                type="flex"
                                justify="space-between"
                                style={{ padding: '10px 20px' }}>
                                <Col>
                                    <span
                                        style={{
                                            fontWeight: 600,
                                            fontSize: '0.9em',
                                            color: SECONDARY_COLOR,
                                        }}>
                                        Erfahre mehr
                                    </span>
                                </Col>
                                <Col>
                                    <Icon type="right" style={{ color: SECONDARY_COLOR }} />
                                </Col>
                            </Row>
                        </div>
                    </div>
                );

            case 'JobsListing':
                const { jobs = [] } = attachments[0];

                return (
                    <div>
                        {jobs.slice(0, 2).map(({ _id, title, type, company, address }) => (
                            <Row
                                key={_id}
                                type="flex"
                                align="top"
                                justify="space-between"
                                style={{
                                    padding: '10px 10px',
                                    borderTop: '1px solid #eef0f2',
                                    borderBottom: '1px solid #eef0f2',
                                }}>
                                <Col span={22}>
                                    <div style={{ color: SECONDARY_COLOR, fontSize: '0.9em' }}>
                                        {type}
                                    </div>
                                    <div>
                                        {title.length > 60 ? `${title.substring(0, 60)}...` : title}
                                    </div>
                                    <div style={{ color: SECONDARY_COLOR, fontSize: '0.9em' }}>
                                        {company.title}
                                    </div>
                                    <div style={{ color: SECONDARY_COLOR, fontSize: '0.9em' }}>
                                        <Icon
                                            type="environment"
                                            theme="filled"
                                            style={{ paddingRight: 5 }}
                                        />
                                        {address?.city}
                                    </div>
                                </Col>
                                <Col span={2}>
                                    <Icon style={{ color: HIGHLIGHT_COLOR }} type="book" />
                                </Col>
                            </Row>
                        ))}
                        {jobs.length > 2 && (
                            <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                                style={{
                                    padding: '5px 10px',
                                    backgroundColor: 'rgb(237, 239, 244)',
                                }}>
                                <Col span={20}>
                                    <div
                                        style={{
                                            color: SECONDARY_COLOR,
                                            fontSize: '0.8em',
                                            fontWeight: 600,
                                        }}>
                                        Alle Jobs ansehen
                                    </div>
                                </Col>
                                <Col span={4} style={{ textAlign: 'right' }}>
                                    <Icon
                                        type="right"
                                        style={{ color: SECONDARY_COLOR, fontSize: '0.8em' }}
                                    />
                                </Col>
                            </Row>
                        )}
                    </div>
                );

            default:
                return null;
        }
    };

    renderFooter = () => {
        const { likesCount = 0, channels = [] } = this.props.post;

        return (
            <div style={{ color: SECONDARY_COLOR, fontSize: '0.9em', padding: '5px 10px' }}>
                {channels.length > 0 && (
                    <div style={{ padding: '10px 0px' }}>
                        {channels.map((channel) => `#${channel}`).join(' ')}
                    </div>
                )}
                <div style={{ padding: '5px 0px' }}>
                    <Icon type="heart" style={{ paddingRight: 10, fontSize: '1.1em' }} />
                    {likesCount > 0 && (
                        <div
                            style={{
                                fontWeight: 700,
                                fontSize: '0.9em',
                                display: 'inline-block',
                            }}>
                            Gefällt {likesCount} mal
                        </div>
                    )}
                </div>
            </div>
        );
    };

    render() {
        const { dimensions } = this.state;

        const fontSize = dimensions ? Math.round(dimensions.width / 22) : 12;

        return (
            <div
                id="Post"
                style={{
                    fontSize,
                    color: PRIMARY_COLOR,
                    fontWeight: 400,
                    paddingBottom: 5,
                }}
                ref={(container) => (this.container = container)}>
                {dimensions && (
                    <div>
                        {this.renderHeader()}
                        {this.renderContent()}
                        {this.renderAttachment()}
                        {this.renderFooter()}
                    </div>
                )}
            </div>
        );
    }
}

Post.propTypes = {
    post: PropTypes.object.isRequired,
};

export default Post;
