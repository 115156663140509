import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, InputNumber, Icon, Button } from 'antd';
import { differenceWith, cloneDeep } from 'lodash';

import { AsyncGeosuggest, UniversityPopover } from '..';

import './style.css';

const DEFAULT_PERIMETER = 100;

class AdvertUniversitiesForm extends Component {
    constructor(props) {
        super(props);

        const { locations = [] } = props;

        this.state = {
            loading: true,
            universities: [],
            disabledUniversities: [],
            locations: cloneDeep(locations),
        };
    }

    componentDidMount() {
        (async () => {
            const { request } = this.context;
            const { disabledUniversities = [], universities = [] } = this.props;
            const { body: allUniversities } = await request('recruiting/universities/listAll');
            const mapIdsToUniversities = (ids) =>
                ids.map((id) => allUniversities?.find((uni) => uni.id === id) ?? { id });
            this.setState({
                universities: mapIdsToUniversities(universities ?? []),
                loading: false,
                disabledUniversities: mapIdsToUniversities(disabledUniversities ?? []),
            });
        })();
    }

    addLocation = () => {
        const { locations } = this.state;

        this.setState({
            locations: [
                ...locations,
                {
                    label: '',
                    geoPoint: {
                        perimeter: DEFAULT_PERIMETER,
                    },
                },
            ],
        });
    };

    removeLocation = (index) => {
        const { locations } = this.state;

        this.setState(
            {
                locations: [...locations.slice(0, index), ...locations.slice(index + 1)],
            },
            this.updateUniversities,
        );
    };

    onGeoLocationSelect = (index, suggestion) => {
        if (!suggestion) {
            const { locations } = this.state;
            if (!locations[index]) {
                locations[index] = {};
            }
            locations[index].label = '';
            locations[index].geoPoint = {
                perimeter:
                    locations[index] &&
                    locations[index].geoPoint &&
                    locations[index].geoPoint.perimeter
                        ? locations[index].geoPoint.perimeter
                        : DEFAULT_PERIMETER,
            };

            this.setState({ locations }, this.updateUniversities);
        } else {
            const { label, location } = suggestion;
            const { locations } = this.state;

            if (locations && locations.length > 0) {
                if (!locations[index]) {
                    locations[index] = {};
                }
                locations[index].label = label;
                locations[index].geoPoint = {
                    ...locations[index].geoPoint,
                    ...location,
                };

                this.setState({ locations }, this.updateUniversities);
            } else {
                this.setState(
                    {
                        locations: [
                            {
                                label,
                                geoPoint: {
                                    ...location,
                                    perimeter: DEFAULT_PERIMETER,
                                },
                            },
                        ],
                    },
                    this.updateUniversities,
                );
            }
        }
    };

    onPerimeterChange = (index, perimeter) => {
        const { locations } = this.state;

        if (locations?.[index]?.geoPoint) {
            locations[index].geoPoint.perimeter = perimeter;
            this.setState({ locations }, this.updateUniversities);
        } else {
            this.setState(
                {
                    locations: [
                        {
                            label: '',
                            geoPoint: {
                                perimeter,
                            },
                        },
                    ],
                },
                this.updateUniversities,
            );
        }
    };

    updateUniversities = async () => {
        const { locations = [] } = this.state;
        const { request } = this.context;
        const { limitTargetingUniversities } = this.props;

        const { body } = await request('recruiting/universities/list', {
            locations,
        });
        const nextUniversities = body.filter(
            ({ id }) =>
                !this.state.disabledUniversities.find((disabledUni) => id === disabledUni.id),
        );
        if (body) {
            this.setState(
                {
                    universities:
                        nextUniversities.length > 0 ? nextUniversities : limitTargetingUniversities,
                },
                this.handleChange,
            );
        }
    };

    handleChange = () => {
        const { locations: initialLocations = [], disabledUniversities: initialDisabled = [] } =
            this.props;
        const { universities = [], locations = [], disabledUniversities = [] } = this.state;

        const changes =
            initialLocations.length !== locations.length ||
            differenceWith(
                initialLocations,
                locations,
                (a, b) => a.label === b.label && a.geoPoint.perimeter === b.geoPoint.perimeter,
            ).length > 0 ||
            disabledUniversities.length !== initialDisabled.length;

        this.props.onChange(
            changes ? universities.map((uni) => uni.id) : null,
            changes ? locations : null,
            changes ? disabledUniversities.map((uni) => uni.id) : null,
        );
    };

    removeUni = (uni) => {
        this.setState(
            (state) => ({
                disabledUniversities: [...state.disabledUniversities, uni],
            }),
            this.updateUniversities,
        );
    };

    reAddUni = (uni) => {
        this.setState(
            (state) => ({
                disabledUniversities: state.disabledUniversities.filter((id) => id !== uni),
            }),
            this.updateUniversities,
        );
    };

    render() {
        const { disabled = false } = this.props;
        const { locations, universities, disabledUniversities } = this.state;

        return (
            <div id="AdvertUniversitiesForm">
                Wählen Sie die Standorte aus, an denen Ihre Werbeanzeige ausgeliefert werden soll.
                <br />
                <br />
                <Row>
                    {!this.state.loading && (
                        <span>
                            Aktuell wird Ihre Werbeanzeige an{' '}
                            {universities?.length === 0 ? (
                                <span className="color-primary">allen Hochschulen</span>
                            ) : (
                                <UniversityPopover
                                    universities={universities}
                                    removeUni={this.removeUni}>
                                    <span className="primary-clickable">{`${
                                        universities?.length > 0
                                            ? `${universities.length} Hochschule(n)`
                                            : 'allen Hochschulen'
                                    }`}</span>
                                </UniversityPopover>
                            )}{' '}
                            ausgeliefert
                            {disabledUniversities.length > 0 && (
                                <span>
                                    {' '}
                                    (
                                    {disabledUniversities.length > 1
                                        ? `${disabledUniversities.length} wurden`
                                        : 'eine wurde'}{' '}
                                    manuell{' '}
                                    <UniversityPopover
                                        universities={disabledUniversities}
                                        removeUni={this.reAddUni}>
                                        <span className="primary-clickable">ausgeschlossen</span>
                                    </UniversityPopover>
                                    )
                                </span>
                            )}
                            .
                        </span>
                    )}
                </Row>
                <br />
                <Row gutter={20} type="flex" justify="space-between" align="top">
                    <Col span={18}>
                        <AsyncGeosuggest
                            initialValue={locations[0] ? locations[0].label : ''}
                            placeholder="Standort suchen"
                            onSuggestSelect={(suggestion) =>
                                this.onGeoLocationSelect(0, suggestion)
                            }
                            noButton
                            style={{ boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.1)' }}
                            disabled={disabled}
                        />
                        <span style={{ opacity: 0.45 }}>Standort</span>
                    </Col>
                    <Col span={6}>
                        <Row style={{ whiteSpace: 'nowrap' }}>
                            <InputNumber
                                min={0}
                                step={10}
                                value={
                                    locations[0]
                                        ? locations[0].geoPoint.perimeter || DEFAULT_PERIMETER
                                        : DEFAULT_PERIMETER
                                }
                                onChange={(value) => this.onPerimeterChange(0, value)}
                                style={{
                                    width: 'auto',
                                    marginRight: 15,
                                    boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.1)',
                                }}
                                disabled={disabled}
                            />
                            {!disabled && locations[0] && locations[0].label && (
                                <Icon
                                    type="close"
                                    theme="outlined"
                                    style={{ cursor: 'pointer', color: '#f21850', paddingTop: 10 }}
                                    onClick={() => this.removeLocation(0)}
                                />
                            )}
                        </Row>
                        <Row>
                            <span style={{ opacity: 0.45 }}>Umkreis (km)</span>
                        </Row>
                    </Col>
                </Row>
                <br />
                {locations.length > 1 && <h4>Weitere Standorte</h4>}
                {locations.map((location, index) => {
                    if (index === 0) {
                        return null;
                    }

                    return (
                        <Row
                            key={location.label + index}
                            gutter={20}
                            type="flex"
                            justify="space-between"
                            align="middle"
                            style={{ padding: '5px 0px' }}>
                            <Col span={18}>
                                <AsyncGeosuggest
                                    initialValue={location.label ? location.label : ''}
                                    placeholder="Standort suchen"
                                    onSuggestSelect={(suggestion) =>
                                        this.onGeoLocationSelect(index, suggestion)
                                    }
                                    noButton
                                    style={{ boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.1)' }}
                                    disabled={disabled}
                                />
                            </Col>
                            <Col span={6}>
                                <Row style={{ whiteSpace: 'nowrap' }}>
                                    <InputNumber
                                        min={0}
                                        step={10}
                                        value={
                                            location && location.geoPoint
                                                ? location.geoPoint.perimeter || DEFAULT_PERIMETER
                                                : DEFAULT_PERIMETER
                                        }
                                        onChange={(value) => this.onPerimeterChange(index, value)}
                                        style={{
                                            width: 'auto',
                                            marginRight: 15,
                                            boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.1)',
                                        }}
                                        disabled={disabled}
                                    />
                                    {!disabled && (
                                        <Icon
                                            type="close"
                                            theme="outlined"
                                            style={{ cursor: 'pointer', color: '#f21850' }}
                                            onClick={() => this.removeLocation(index)}
                                        />
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    );
                })}
                <div style={{ paddingTop: 15 }}>
                    <Button type="primary" disabled={disabled} onClick={this.addLocation}>
                        {' '}
                        + Standort hinzufügen
                    </Button>
                </div>
                <br />
            </div>
        );
    }
}

AdvertUniversitiesForm.defaultProps = { disabled: false, disabledUniversities: [], locations: [] };
AdvertUniversitiesForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    universities: PropTypes.arrayOf(PropTypes.string).isRequired,
    disabledUniversities: PropTypes.arrayOf(PropTypes.string),
    locations: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            geoPoint: {
                perimeter: PropTypes.number,
            },
        }),
    ),
};

AdvertUniversitiesForm.contextTypes = {
    request: PropTypes.func,
};

export default AdvertUniversitiesForm;
