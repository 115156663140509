import React, { Component } from 'react';

import PropTypes from 'prop-types';
import phoneCase from './phone-case.png';

class Phone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowHeight: null,
            windowWidth: null,
        };
    }

    updateDimensions() {
        this.setState({ windowWidth: window.outerWidth, windowHeight: window.outerHeight });
    }

    componentWillMount() {
        this.updateDimensions();
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions.bind(this));
    }

    render() {
        let maxWidth = 0;
        if (this.state.windowWidth >= 1600 || this.state.windowWidth < 1200) {
            maxWidth = this.state.windowWidth * (6 / 24);
        } else if (this.state.windowWidth >= 1200) {
            maxWidth = this.state.windowWidth * (4 / 24);
        }

        const SCALE = Math.min(maxWidth / 420, this.state.windowHeight / (875 + 500));
        const top = this.props.position === 'fixed' ? 150 : 0;
        return (
            <div
                style={{
                    position: 'relative',
                    fontSize: 16 * SCALE,
                    height: this.props.position === 'absolute' ? 875 * SCALE : undefined,
                    width: this.props.position === 'absolute' ? 420 * SCALE : undefined,
                }}>
                {this.props.topContent && (
                    <div
                        style={{
                            top: top - 40,
                            zIndex: 10,
                            width: 420 * SCALE,
                            position: this.props.position,
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                        {this.props.topContent}
                    </div>
                )}
                <img
                    src={phoneCase}
                    alt="phone"
                    style={{
                        height: 875 * SCALE,
                        width: 420 * SCALE,
                        position: this.props.position,
                        top,
                    }}
                />
                <div
                    style={{
                        background: this.props.backgroundColor
                            ? this.props.backgroundColor
                            : 'white',
                        height: 667 * SCALE,
                        width: 375 * SCALE,
                        top: top + 95 * SCALE,
                        marginLeft: 23 * SCALE,
                        position: this.props.position,
                        overflowX: 'hidden',
                        overflowY: 'auto',
                    }}>
                    {this.props.children}
                </div>
                {this.props.bottomContent && (
                    <div
                        style={{
                            top: top + 875 * SCALE,
                            width: 420 * SCALE,
                            position: this.props.position,
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                        {this.props.bottomContent}
                    </div>
                )}
            </div>
        );
    }
}

Phone.defaultProps = {
    position: 'fixed',
    bottomContent: null,
};

Phone.propTypes = {
    position: PropTypes.oneOf(['absolute', 'fixed']),
    bottomContent: PropTypes.node,
};

export default Phone;
