import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

function EditWebhooksForm({ onChange, form, initialValues }) {
    const { validateFields, getFieldDecorator } = form;
    const handleChange = ({ target }) => {
        validateFields((errors, values) => {
            console.log(errors, values);
            if (!errors) {
                onChange(values);
            }
        });
    };
    return (
        <Form id="AdvertForm" onChange={handleChange} initial>
            <Form.Item
                label="Webhook Seen-Url"
                extra="Diese URL wird aufgerufen, wenn die Werbeanzeige angezeigt wird">
                {getFieldDecorator('seenUrl', {
                    rules: [{ type: 'url' }],
                    initialValue: initialValues?.seenUrl,
                })(<Input type="url" />)}
            </Form.Item>
            <Form.Item
                label="Webhook Click-Url"
                extra="Diese URL wird aufgerufen, wenn die Werbeanzeige angeklickt wird">
                {getFieldDecorator('clickUrl', {
                    rules: [{ type: 'url' }],
                    initialValue: initialValues?.clickUrl,
                })(<Input type="url" />)}
            </Form.Item>
        </Form>
    );
}

EditWebhooksForm.propTypes = {};

export default Form.create()(EditWebhooksForm);
