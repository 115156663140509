import { useContext } from 'react';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';

import { RequestContext } from '../../components/Session/RequestProvider';

const { REACT_APP_ENV, REACT_APP_API_HOST, REACT_APP_API_HOST_DEV } = window;
const HOST = REACT_APP_ENV === 'development' ? REACT_APP_API_HOST_DEV : REACT_APP_API_HOST;

function useMeilisearch() {
    const { request } = useContext(RequestContext);

    const client = instantMeiliSearch(HOST, '', {
        async httpClient(url, options) {
            let route = url.replace(HOST, '');
            if (route === "/indexes/talent_profiles/facet-search" ) {
                route = "/talent_profiles/facet-search"
            }
            const response = await request(`recruiting/query${route}`, options.body, );

            return response.body;
        },
    });

    return { client };
}

export default useMeilisearch;
