import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Query, Mutation } from 'react-apollo';

import * as loadImage from 'blueimp-load-image';
import { Row, Col, Icon, Form, Input, Modal } from 'antd';
import defaultHeader from '../../core/images/header_placeholder.jpg';
import defaultAvatar from '../../core/images/avatar_placeholder.png';
import { PROFILE } from '../../core/gql/queries';
import { UPDATE_USER } from '../../core/gql/mutations';
import { ImageDropzone, ImageEditor, Geosearch } from '../index';
import FollowerModal from '../FollowerModal';

import ErrorTooltip from '../ErrorTooltip';

import './style.css';

const emptyLocation = {
    geoPoint: null,
    addition: null,
    country: null,
    city: null,
    postalCode: null,
    street: null,
    state: null,
    label: null,
};

const Card = () => (
    <div id="ProfileOverview">
        <div className="header-container">
            <img src={defaultHeader} alt="" className="header empty" />
        </div>
        <div className="logo-container" style={{ textAlign: 'center' }}>
            <div className="logo-subcontainer">
                <img src={defaultAvatar} alt="" className="logo" />
            </div>
        </div>
        <div className="card-content" />
    </div>
);

class ProfileOverview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editMode: props.editMode || false,
            showEdit: false,
            modalVisible: false,
            file: null,
            dataURL: null,
            selectedImageProp: null,
            avatar: null,
            header: null,
            followerModalVisible: false,
        };
    }

    toggleFollowerModal = (value) => {
        this.setState((followerModalVisible) => ({
            followerModalVisible: value === undefined ? !followerModalVisible : value,
        }));
    };

    onBlurCompletionWebsite = (fieldName) => {
        const { getFieldValue, setFields, validateFields } = this.props.form;
        const value = getFieldValue(fieldName);
        if (
            value &&
            value.length > 0 &&
            !value.startsWith('https://') &&
            !value.startsWith('http://')
        ) {
            const newValue = {};
            newValue[fieldName] = { value: `https://${value}`, errors: null };
            setFields(newValue);
        }
        validateFields([fieldName]);
    };

    showEditIcon = () => {
        this.setState({ showEdit: true });
    };

    hideEditIcon = () => {
        this.setState({ showEdit: false });
    };

    enableEditMode = () => {
        this.setState({ editMode: true });
    };

    disableEditMode = () => {
        this.setState({ editMode: false });
    };

    resetState = () => {
        this.setState({
            editMode: false,
            file: null,
            dataURL: null,
            selectedImageProp: null,
            avatar: null,
            header: null,
        });
    };

    showModal = () => {
        this.setState({ modalVisible: true });
    };

    hideModal = () => {
        this.setState({ modalVisible: false });
    };

    saveProfile = async (callback) => {
        const { validateFields } = this.props.form;

        validateFields((errors, values) => {
            if (!errors) {
                const location =
                    values.addition || values.contact.location
                        ? {
                              ...(values.contact.location || emptyLocation),
                              addition: values.addition,
                          }
                        : null;

                const variables = {
                    ...values,
                    contact: {
                        ...values.contact,
                        location,
                    },
                };

                callback({ variables });

                this.disableEditMode();
            }
        });
    };

    updateLocation = (location) => {
        const { setFieldsValue } = this.props.form;

        setFieldsValue({ 'contact.location': location });
    };

    onImageAccepts = async () => {
        if (this.editor) {
            const { setFieldsValue } = this.props.form;
            const { file, selectedImageProp } = this.state;
            const imageUrl = this.editor.getDataURL();
            const payload = await this.editor.getPayload();

            const image = {
                data: file,
                type: 'image',
                payload,
            };

            this.setState({
                [selectedImageProp]: imageUrl,
            });
            setFieldsValue({ [selectedImageProp]: image });
        }
        this.hideModal();
    };

    onFileSelected = async (file, imageProp) => {
        const fileReader = new FileReader();
        if (/(?:jpg|jpeg)/gi.test(file.name)) {
            const dataURL = await new Promise((resolve) => {
                loadImage(
                    file.preview,
                    (editedImage) => {
                        resolve(editedImage.toDataURL());
                    },
                    { orientation: true, maxWidth: 600, canvas: true },
                );
            });
            this.setState({
                file,
                dataURL,
                selectedImageProp: imageProp,
            });
        } else {
            fileReader.onloadend = () => {
                this.setState({
                    file,
                    dataURL: fileReader.result,
                    selectedImageProp: imageProp,
                });
            };
            fileReader.readAsDataURL(file);
        }
        this.showModal();
    };

    UNSAFE_componentWillReceiveProps({ editMode = false }) {
        if (editMode && editMode !== this.state.editMode) {
            this.setState({ editMode });
        }
    }

    render() {
        const { getFieldDecorator, getFieldError } = this.props.form;
        const {
            editMode,
            showEdit,
            modalVisible,
            dataURL,
            selectedImageProp,
            followerModalVisible,
        } = this.state;
        const { minimized } = this.props;

        const hasModifyPermission = this.context.accountRole !== 'DRAFT';

        return (
            <Query query={PROFILE}>
                {({ loading, error, data }) => {
                    if (loading || error || !data.me) {
                        return <Card />;
                    }

                    const {
                        header,
                        avatar,
                        username = '',
                        displayName = '',
                        contact = {},
                        autoFollow,
                        followerCount,
                        postsCount,
                        permissions = [],
                    } = data.me;

                    const {
                        email = '',
                        website = '',
                        imprint = '',
                        location = {},
                        telephoneNumber = '',
                    } = contact || {};

                    const { label = null, addition = null } = location || {};

                    let actions = [
                        {
                            onClick: this.enableEditMode,
                            type: 'edit',
                        },
                    ];

                    if (editMode) {
                        actions = [
                            {
                                onClick: () => {
                                    this.disableEditMode();
                                    this.resetState();
                                },
                                type: 'close',
                            },
                            {
                                onClick: this.saveProfile,
                                type: 'save',
                                callback: true,
                            },
                        ];
                    }

                    const headerImg =
                        this.state.header || (header ? header.large : null) || defaultHeader;
                    const avatarImg =
                        this.state.avatar || (avatar ? avatar.large : null) || defaultAvatar;

                    const canViewFollowerTrend =
                        followerCount > 0 && (permissions || []).includes('VIEW_FOLLOWER_TREND');

                    return (
                        <div
                            id="ProfileOverview"
                            onMouseEnter={this.showEditIcon}
                            onMouseLeave={this.hideEditIcon}>
                            <Modal
                                title="Bild anpassen"
                                width={700}
                                maskClosable={false}
                                onOk={this.onImageAccepts}
                                onCancel={this.hideModal}
                                visible={modalVisible}>
                                <ImageEditor
                                    ref={(editor) => {
                                        this.editor = editor;
                                    }}
                                    width={selectedImageProp === 'avatar' ? 300 : 600}
                                    height={selectedImageProp === 'avatar' ? 300 : 300}
                                    image={dataURL}
                                />
                            </Modal>

                            {canViewFollowerTrend && (
                                <FollowerModal
                                    visible={followerModalVisible}
                                    onCancel={() => this.toggleFollowerModal(false)}
                                />
                            )}

                            <div className="header-container">
                                <img src={headerImg} alt="" className="header empty" />
                                {editMode &&
                                    getFieldDecorator('header')(
                                        <ImageDropzone
                                            onFileSelected={([file]) =>
                                                this.onFileSelected(file, 'header')
                                            }
                                        />,
                                    )}
                            </div>
                            {minimized === false && hasModifyPermission && (
                                <div
                                    className={`action-list ${
                                        editMode || showEdit ? 'slide' : ''
                                    }`}>
                                    <Mutation
                                        mutation={UPDATE_USER}
                                        update={(cache, { data: { updateUser } }) => {
                                            const { me } = cache.readQuery({ query: PROFILE });

                                            cache.writeQuery({
                                                query: PROFILE,
                                                data: { me: { ...me, ...updateUser } },
                                            });
                                        }}>
                                        {(send, { error }) => (
                                            <div>
                                                {actions.map((action) => (
                                                    <div
                                                        key={action.type}
                                                        onClick={
                                                            action.callback
                                                                ? () => action.onClick(send)
                                                                : action.onClick
                                                        }
                                                        className="action-button">
                                                        <Icon
                                                            className="color-primary action-icon"
                                                            type={action.type}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </Mutation>
                                </div>
                            )}

                            <div className="logo-container" style={{ textAlign: 'center' }}>
                                <div className="logo-subcontainer">
                                    <img src={avatarImg} alt="" className="logo" />
                                    {editMode &&
                                        getFieldDecorator('avatar')(
                                            <ImageDropzone
                                                shape="circle"
                                                onFileSelected={([file]) =>
                                                    this.onFileSelected(file, 'avatar')
                                                }
                                            />,
                                        )}
                                </div>
                            </div>
                            <div className="card-content">
                                <Form>
                                    {editMode ? (
                                        <Form.Item
                                            help={
                                                <ErrorTooltip
                                                    errors={getFieldError('displayName')}
                                                />
                                            }>
                                            {getFieldDecorator('displayName', {
                                                validateTrigger: 'onBlur',
                                                initialValue: displayName,
                                                rules: [
                                                    {
                                                        min: 1,
                                                        max: 50,
                                                        message:
                                                            'Anzeigname muss aus 1-50 Zeichen bestehen',
                                                    },
                                                    {
                                                        pattern: /^[\w\süöäß\-\.]+$/i, // eslint-disable-line
                                                        message:
                                                            'Bitte benutze für deinen Anzeigenamen nur Buchstaben, Zahlen, Leerzeichen sowie folgende Zeichen: . und -',
                                                    },
                                                    {
                                                        required: true,
                                                        message: 'Bitte gib einen Anzeignamen ein',
                                                    },
                                                ],
                                            })(<Input placeholder="Anzeigename" />)}
                                        </Form.Item>
                                    ) : (
                                        <div
                                            className="weight-bold size-large"
                                            style={{
                                                whiteSpace: 'normal',
                                                wordWrap: 'break-word',
                                            }}>
                                            {displayName || '---'}
                                        </div>
                                    )}
                                    <div
                                        className="weight-regular size-small color-secondary"
                                        style={{
                                            whiteSpace: 'normal',
                                            wordWrap: 'break-word',
                                        }}>
                                        @{username}
                                    </div>
                                    {minimized === false && (
                                        <div>
                                            <br />
                                            <Row type="flex" align="middle">
                                                <Col span={3}>
                                                    <Icon type="mail" className="color-secondary" />
                                                </Col>
                                                <Col span={21}>
                                                    {editMode ? (
                                                        <Form.Item
                                                            help={
                                                                <ErrorTooltip
                                                                    errors={getFieldError(
                                                                        'contact.email',
                                                                    )}
                                                                />
                                                            }>
                                                            {getFieldDecorator('contact.email', {
                                                                validateTrigger: 'onBlur',
                                                                initialValue: email,
                                                                rules: [
                                                                    {
                                                                        type: 'email',
                                                                        message:
                                                                            'Keine valide E-Mail-Adresse',
                                                                    },
                                                                ],
                                                            })(
                                                                <Input placeholder="E-Mail-Adresse" />,
                                                            )}
                                                        </Form.Item>
                                                    ) : (
                                                        <div
                                                            className="color-secondary overflow-ellipsis"
                                                            style={{ fontSize: 14 }}>
                                                            {email ? (
                                                                <a href={`mailto:${email}`}>
                                                                    {email}
                                                                </a>
                                                            ) : (
                                                                <div
                                                                    style={{ fontStyle: 'italic' }}>
                                                                    E-Mail-Adresse
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row type="flex" align="middle">
                                                <Col span={3}>
                                                    <Icon
                                                        type="phone"
                                                        className="color-secondary"
                                                    />
                                                </Col>
                                                <Col span={21}>
                                                    {editMode ? (
                                                        <Form.Item
                                                            help={
                                                                <ErrorTooltip
                                                                    errors={getFieldError(
                                                                        'contact.telephoneNumber',
                                                                    )}
                                                                />
                                                            }>
                                                            {getFieldDecorator(
                                                                'contact.telephoneNumber',
                                                                {
                                                                    validateTrigger: 'onBlur',
                                                                    initialValue: telephoneNumber,
                                                                    rules: [
                                                                        {
                                                                            pattern: new RegExp(
                                                                                '^[+]?[0-9()/ -]*$',
                                                                            ),
                                                                            message:
                                                                                'Keine valide Telefonnummer',
                                                                        },
                                                                    ],
                                                                },
                                                            )(
                                                                <Input placeholder="Telefonnummer" />,
                                                            )}
                                                        </Form.Item>
                                                    ) : (
                                                        <div
                                                            className="color-secondary overflow-ellipsis"
                                                            style={{ fontSize: 14 }}>
                                                            {telephoneNumber ? (
                                                                <a href={`tel:${telephoneNumber}`}>
                                                                    {telephoneNumber}
                                                                </a>
                                                            ) : (
                                                                <div
                                                                    style={{ fontStyle: 'italic' }}>
                                                                    Telefonnummer
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row type="flex" align="middle">
                                                <Col span={3}>
                                                    <Icon type="link" className="color-secondary" />
                                                </Col>
                                                <Col span={21}>
                                                    {editMode ? (
                                                        <Form.Item
                                                            help={
                                                                <ErrorTooltip
                                                                    errors={getFieldError(
                                                                        'contact.website',
                                                                    )}
                                                                />
                                                            }>
                                                            {getFieldDecorator('contact.website', {
                                                                validateTrigger: null,
                                                                initialValue: website,
                                                                rules: [
                                                                    {
                                                                        type: 'url',
                                                                        message: 'Keine valide URL',
                                                                    },
                                                                ],
                                                            })(
                                                                <Input
                                                                    placeholder="Website"
                                                                    onBlur={() =>
                                                                        this.onBlurCompletionWebsite(
                                                                            'website',
                                                                        )
                                                                    }
                                                                />,
                                                            )}
                                                        </Form.Item>
                                                    ) : (
                                                        <div
                                                            className="color-secondary overflow-ellipsis"
                                                            style={{ fontSize: 14 }}>
                                                            {website ? (
                                                                <a
                                                                    href={
                                                                        /https?:\/\//gim.test(
                                                                            website,
                                                                        )
                                                                            ? website
                                                                            : `https://${website}`
                                                                    }
                                                                    target="_blank"
                                                                    rel="noopener noreferrer">
                                                                    {website}
                                                                </a>
                                                            ) : (
                                                                <div
                                                                    style={{ fontStyle: 'italic' }}>
                                                                    Website
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row type="flex" align="middle">
                                                <Col span={3}>
                                                    <Icon
                                                        type="audit"
                                                        className="color-secondary"
                                                    />
                                                </Col>
                                                <Col span={21}>
                                                    {editMode ? (
                                                        <Form.Item
                                                            help={
                                                                <ErrorTooltip
                                                                    errors={getFieldError(
                                                                        'contact.imprint',
                                                                    )}
                                                                />
                                                            }>
                                                            {getFieldDecorator('contact.imprint', {
                                                                validateTrigger: null,
                                                                initialValue: imprint,
                                                                rules: [
                                                                    {
                                                                        type: 'url',
                                                                        message: 'Keine valide URL',
                                                                    },
                                                                ],
                                                            })(
                                                                <Input
                                                                    placeholder="Impressum"
                                                                    onBlur={() =>
                                                                        this.onBlurCompletionWebsite(
                                                                            'imprint',
                                                                        )
                                                                    }
                                                                />,
                                                            )}
                                                        </Form.Item>
                                                    ) : (
                                                        <div
                                                            className="color-secondary overflow-ellipsis"
                                                            style={{ fontSize: 14 }}>
                                                            {imprint ? (
                                                                <a
                                                                    href={
                                                                        /https?:\/\//gim.test(
                                                                            imprint,
                                                                        )
                                                                            ? imprint
                                                                            : `https://${imprint}`
                                                                    }
                                                                    target="_blank"
                                                                    rel="noopener noreferrer">
                                                                    {imprint}
                                                                </a>
                                                            ) : (
                                                                <div
                                                                    style={{ fontStyle: 'italic' }}>
                                                                    Impressum
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row type="flex" align="middle">
                                                <Col span={3}>
                                                    <Icon
                                                        type="environment"
                                                        className="color-secondary"
                                                    />
                                                </Col>
                                                <Col span={21}>
                                                    {editMode ? (
                                                        getFieldDecorator('contact.location', {
                                                            initialValue: location,
                                                        })(
                                                            <Geosearch
                                                                initialValue={label}
                                                                placeholder="Standort"
                                                                onSelect={(value) =>
                                                                    this.updateLocation(value)
                                                                }
                                                                onChange={(value) =>
                                                                    value
                                                                        ? null
                                                                        : this.updateLocation(null)
                                                                }
                                                            />,
                                                        )
                                                    ) : (
                                                        <div
                                                            className="color-secondary"
                                                            style={{ fontSize: 14 }}>
                                                            {label || addition ? (
                                                                <a
                                                                    href={`https://maps.google.com?q=${label}`}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer">
                                                                    {label}
                                                                    {addition && (
                                                                        <div>{addition}</div>
                                                                    )}
                                                                </a>
                                                            ) : (
                                                                <div
                                                                    style={{ fontStyle: 'italic' }}>
                                                                    Standort
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </Col>
                                                <Col span={21} offset={3}>
                                                    {editMode && (
                                                        <Form.Item
                                                            help={
                                                                <ErrorTooltip
                                                                    errors={getFieldError(
                                                                        'addition',
                                                                    )}
                                                                />
                                                            }>
                                                            {getFieldDecorator('addition', {
                                                                validateTrigger: null,
                                                                initialValue: addition,
                                                            })(
                                                                <Input placeholder="Adresszusatz" />,
                                                            )}
                                                        </Form.Item>
                                                    )}
                                                </Col>
                                            </Row>
                                            <div className="divider" />
                                            <Row type="flex" align="middle" justify="center">
                                                <Col span={12} style={{ textAlign: 'center' }}>
                                                    <div className="weight-bold color-primary">
                                                        {postsCount || 0}
                                                    </div>
                                                    <div className="weight-regular color-secondary">
                                                        {postsCount === 1 ? 'Beitrag' : 'Beiträge'}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                                </Form>
                            </div>
                        </div>
                    );
                }}
            </Query>
        );
    }
}

ProfileOverview.propTypes = {
    minimized: PropTypes.bool,
};

ProfileOverview.defaultProps = {
    minimized: false,
};

ProfileOverview.contextTypes = {
    accountRole: PropTypes.string,
};

export default Form.create()(ProfileOverview);
