import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { Button, Result } from 'antd';
import { useLocation } from 'react-router-dom';

function FallbackComponent({ eventId, resetError }) {
    const location = useLocation();
    const [initialLocation] = useState(location);
    useEffect(() => {
        if (location !== initialLocation) {
            resetError();
        }
    }, [location]);
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            <Result
                status="warning"
                title="Ein Fehler ist aufgetreten. Das tut uns leid."
                extra={
                    <>
                        <Button
                            type="primary"
                            onClick={() => {
                                window.location.reload();
                            }}>
                            Seite neu laden
                        </Button>
                        <Button
                            type="secondary"
                            onClick={() => {
                                Sentry.showReportDialog({
                                    eventId,
                                    title: 'Helfen Sie uns, den Fehler zu beheben.',
                                    subtitle:
                                        'Unser Team wurde über das Problem benachrichtigt. Wenn Sie uns helfen möchten, teilen Sie uns bitte unten mit, was geschehen ist.',
                                });
                            }}>
                            Feedback geben
                        </Button>
                    </>
                }
            />
        </div>
    );
}
function DefaultErrorBoundary({ children }) {
    return (
        <Sentry.ErrorBoundary
            fallback={({ eventId, resetError }) => (
                <FallbackComponent eventId={eventId} resetError={resetError} />
            )}>
            {children}
        </Sentry.ErrorBoundary>
    );
}

DefaultErrorBoundary.propTypes = { children: PropTypes.node.isRequired };

export default DefaultErrorBoundary;
