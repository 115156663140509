export const ICONS = [
    'arrow-back-small',
    'list',
    'tour',
    'searchsavefull',
    'searchsave',
    'radio',
    'link',
    'hall',
    'instagram',
    'kununu-box',
    'kununu',
    'linkedin',
    'pinterest-box',
    'pinterest',
    'snapchat',
    'twitter-box',
    'twitter-circle',
    'twitter',
    'vimeo',
    'whatsapp',
    'xing-box',
    'xing-circle',
    'xing',
    'youtube',
    'briefcase',
    'school',
    'game',
    'google-plus',
    'google-plus-box',
    'github-circle',
    'facebook',
    'facebook-box',
    'blogger',
    'blogger-outline',
    'wheelchair-accessibility',
    'trending-up',
    'tram',
    'timer',
    'Team-Events',
    'swim',
    'sofa',
    'silverware',
    'seat-legroom-normal',
    'arrow-forward-small',
    'bag-outline',
    'book-outline',
    'bookmarks-outline',
    'bookmarks',
    'briefcase-outline-thin',
    'tag',
    'account-switch',
    'food',
    'gamepad-variant',
    'gas-station',
    'globe',
    'google-maps',
    'heart-pulse',
    'highway',
    'home-modern',
    'home',
    'local-play',
    'nature-people',
    'parking',
    'linkedin-box',
    'seat-flat',
    'presentation-play',
    'paw',
    'certificate',
    'clock-fast',
    'credit-card-plus',
    'database-plus',
    'domain',
    'drinks',
    'duck',
    'food-apple',
    'zoom-outline-thin',
    'zoom-outline',
    'airballoon',
    'airplane',
    'attach-money',
    'baby-buggy',
    'beach',
    'bow-tie',
    'calendar-clock',
    'car',
    'cash-multiple',
    'tag-outline',
    'tag-outline-thin',
    'settings',
    'settings-thin',
    'school-outline',
    'school-outline-thin',
    'pin',
    'timelapse',
    'briefcase-outline',
    'calender-outline',
    'chat-outline-thin',
    'chat-outline',
    'feed-outline-thin',
    'feed-outline',
    'feed',
    'food-outline',
    'heart-outline-thin',
    'heart-outline',
    'heart',
    'mail-outline',
    'pin-outline',
    'scooter',
    'tiktok',
    'studyid-outline',
    'premium',
    'scan-qr_01',
    'authenticator-outline',
    'scan-qr_03',
    'scan-qr_02',
    'premium-outline',
    'check-outline',
    'icon_shortcut',
    'twitch',
    'indeed',
    'glassdoor',
    'dice-swap',
    'dice-swap-thin',
    'watchado',
    'watchado-outline',
    'spotify',
    'spotify-outline',
    'abroad-outline',
    'abroad',
];
