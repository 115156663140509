import React, { Component } from 'react';

import { Progress } from 'antd';

class ProgressBar extends Component {
    state = {
        percent: 0,
    };

    updatePercent = (percent) => {
        this.setState({
            percent: Math.round(percent),
        });
    };

    render() {
        const { percent } = this.state;

        return (
            <div>
                <Progress percent={percent} />
            </div>
        );
    }
}

export default ProgressBar;
