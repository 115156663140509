import React from 'react';
import PropTypes from 'prop-types';

import { Progress } from 'antd';

import './style.css';

const FeedProgressBar = (props) => (
    <Progress id="ProgressBar" percent={props.value} showInfo={false} />
);

FeedProgressBar.propTypes = {
    value: PropTypes.number.isRequired,
};

export default FeedProgressBar;
