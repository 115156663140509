import React, { Component } from 'react';
import { Row, Col, Icon } from 'antd';

import DefaultListItem from './DefaultListItem';
import calender from '../../../core/images/feed-calender.svg';
import FeedPost from '../../FeedPost/index';

export default class ReviewPostList extends Component {
    state = { showReleaseablePosts: false };

    renderPost = (edge) => {
        return (
            <FeedPost
                key={edge.cursor}
                post={{ ...edge.node }}
                {...(this.props.postProps || {})}
                reviewMode
            />
        );
    };

    render() {
        const { posts = [] } = this.props;
        return (
            <div id="PostList">
                <DefaultListItem
                    style={{
                        borderBottom: '1px solid rgb(230, 236, 240)',
                        fontSize: 14,
                    }}
                    content={
                        <Row>
                            <Col span={3}>
                                <img src={calender} alt="calender" />
                            </Col>
                            <Col
                                span={1}
                                className="weight-bold"
                                style={{ color: '#000', fontSize: 22 }}>
                                {posts.length}
                            </Col>
                            <Col span={13} style={{ textAlign: 'left', marginTop: 5 }}>
                                <div className="weight-bold" style={{ color: '#000' }}>
                                    {posts.length === 1
                                        ? `freizugebende Veröffentlichung`
                                        : `freizugebende Veröffentlichungen`}{' '}
                                </div>
                                {/* <div className="color-secondary">
                  Der nächste Post ist geplant am{' '}
                  {moment(posts[0].node.publishDate).format('DD.MM.YYYY')}
                  {' '}um{' '}
                  {moment(posts[0].node.publishDate).format('HH:mm')}
                  {' '}Uhr
                </div> */}
                            </Col>
                            <Col span={7} style={{ textAlign: 'right', marginTop: 5 }}>
                                {this.state.showReleaseablePosts ? (
                                    <span
                                        className="clickable-primary"
                                        onClick={() =>
                                            this.setState({
                                                showReleaseablePosts: false,
                                            })
                                        }>
                                        {'  '}
                                        Alle verbergen <Icon type="down" />
                                    </span>
                                ) : (
                                    <span
                                        className="clickable-primary"
                                        onClick={() =>
                                            this.setState({
                                                showReleaseablePosts: true,
                                            })
                                        }>
                                        Alle anzeigen{'  '}
                                        <Icon type="right" />
                                    </span>
                                )}
                            </Col>
                        </Row>
                    }
                />
                {this.state.showReleaseablePosts && posts.map(this.renderPost)}
            </div>
        );
    }
}
