import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Icon, Input } from 'antd';

class InlineEdit extends PureComponent {
    state = {
        editMode: false,
        value: '',
    };

    toggleEdit = () => {
        this.setState((state) => ({ editMode: !state.editMode }));
    };

    handleInputChange = ({ target }) => {
        this.setState({ value: target.value });
    };

    handleSave = () => {
        const { value } = this.state;
        const { onSave } = this.props;

        if (onSave) {
            onSave(value);
        }

        this.setState({ editMode: false });
    };

    render() {
        const { value, editMode } = this.state;
        const { initialValue } = this.props;

        return editMode ? (
            <Input
                placeholder={value || initialValue}
                onChange={this.handleInputChange}
                suffix={
                    <Icon className="primary-clickable" onClick={this.handleSave} type="save" />
                }
            />
        ) : (
            <span>
                {value || initialValue}
                <Icon
                    className="primary-clickable"
                    style={{ paddingLeft: 10 }}
                    onClick={this.toggleEdit}
                    type="edit"
                />
            </span>
        );
    }
}

InlineEdit.propTypes = {
    initialValue: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default InlineEdit;
