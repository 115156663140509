import React, { Component } from 'react';
import moment from 'moment';
import {
    Button,
    Switch,
    Icon,
    List,
    Spin,
    notification,
    Row,
    Col,
    Menu,
    Dropdown,
    Popconfirm,
    Modal,
} from 'antd';
import PropTypes from 'prop-types';
import buttonWithTooltip from '../../decorators/buttonWithTooltip';
import { UniNowList, AdvertImpressionsModal, ActivityModal } from '..';

import './style.css';
import { SortSettings } from './components';
import { setAdCurrentPage, sortAdverts } from '../../core/redux/actions';
import { connect } from 'react-redux';
import PreviewQR from '../../screens/Statistics/components/PreviewQR';

const SORT_OPTIONS = {
    visibility: {
        sortFunc: (a, b) => {
            if (a.visible && !b.visible) {
                return -1;
            }
            if (!a.visible && b.visible) {
                return 1;
            }
            return 0;
        },
        title: 'Sichtbarkeit',
    },
    creationDate: {
        sortFunc: (a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix(),
        title: 'Erstellungsdatum',
    },
    changedDate: {
        sortFunc: (a, b) => {
            const lastChangedA = a.activities
                ? moment.max(a.activities.map(({ date }) => moment(date)))
                : moment.unix(0);
            const lastChangedB = b.activities
                ? moment.max(b.activities.map(({ date }) => moment(date)))
                : moment.unix(0);
            return moment(lastChangedB).unix() - moment(lastChangedA).unix();
        },
        title: 'Änderungsdatum',
    },
    alphabetically: {
        sortFunc: (a, b) => {
            const { data: { title: titleA = '' } = {} } = a;
            const { data: { title: titleB = '' } = {} } = b;
            return titleA.toUpperCase().localeCompare(titleB.toUpperCase());
        },
        title: 'Alphabetisch',
    },
};

class AdvertList extends Component {
    state = {
        currentAdvert: null,
        targetingModal: false,
        activityModalId: null,
    };

    handleVisibilityChange = async (uuid, status) => {
        const { adverts = [] } = this.props;
        const advert = adverts.find((ad) => ad.uuid === uuid);

        if (status === true) {
            this.setState({
                currentAdvert: advert,
                targetingModal: true,
            });
        } else {
            this.setVisibility(uuid, false);
        }
    };

    hideTargetingModal = () => {
        this.setState({
            currentAdvert: null,
            targetingModal: false,
        });
    };

    activateAdvert = async (targeting, startDate = null, endOfTerm) => {
        const { currentAdvert } = this.state;

        await this.props.onToggle(currentAdvert.uuid, {
            visible: true,
            targeting,
            startDate,
            endOfTerm,
        });

        this.hideTargetingModal();
    };

    setVisibility = async (uuid, status) => {
        this.setState({
            [`pending_visibility_${uuid}`]: true,
        });

        await this.props.onToggle(uuid, {
            visible: status,
        });

        this.setState({
            [`pending_visibility_${uuid}`]: false,
        });
    };

    setArchived = async (uuid, status) => {
        this.setState({
            [`pending_archive_${uuid}`]: true,
        });

        await this.props.onToggle(uuid, {
            archived: status,
        });

        this.setState({
            [`pending_archive_${uuid}`]: false,
        });
    };

    dublicate = (uuid) => {
        this.setState({
            [`pending_dublicate_${uuid}`]: true,
        });

        if (this.props.onDublicate) {
            this.props.onDublicate(uuid, () => {
                this.setState({
                    [`pending_dublicate_${uuid}`]: false,
                });
            });
        }
    };

    editAd = (adId, tab = null, panel = null) => {
        const { history, match } = this.props;

        if (tab && panel) {
            history.push(`${match.url}/editieren/${adId}?activeTab=${tab}&activePanel=${panel}`);
        } else {
            history.push(`${match.url}/editieren/${adId}`);
        }
    };

    showStatistics = (detail) => {
        this.props.history.push(`statistiken/werbeanzeigen${detail ? `?advertId=${detail}` : ''}`);
    };

    removeAdvert = (adId) => {
        if (this.props.onRemove) {
            this.props.onRemove(adId);
        }
    };

    onListItemClick = (advert) => {
        if (this.props.onClick) {
            this.props.onClick(advert);
        }
    };

    showActivityModal = (advertId) => this.setState({ activityModalId: advertId });

    hideActivityModal = () => this.setState({ activityModalId: null });

    render() {
        const { role } = this.context;
        const { targetingModal, currentAdvert, activityModalId } = this.state;
        const {
            history,
            match,
            archived,
            adverts,
            loading,
            error,
            selected,
            info = {},
        } = this.props;

        const currentTargeting = currentAdvert ? currentAdvert.targeting : {};

        if (loading) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <Spin size="large" />
                </div>
            );
        }

        if (error) {
            return (
                <div style={{ textAlign: 'center' }}>
                    Ihre Werbeanzeigen konnten leider nicht geladen werden. Bitte kontaktieren sie{' '}
                    <a href="mailto:support@uninow.de">support@uninow.de</a>.
                </div>
            );
        }

        const dataSource = adverts
            .filter((ad) => (archived ? ad.archived === true : ad.archived !== true))
            .sort((a, b) => {
                for (const [key, sortOption] of Object.entries(SORT_OPTIONS)) {
                    const sortSetting = this.props.sortOptions[key];
                    if (sortSetting) {
                        const res = sortOption.sortFunc(a, b);
                        if (res !== 0) {
                            return sortSetting === 'desc' ? res : res * -1;
                        }
                    }
                }
                return 0;
            });

        const isADMIN = ['SUPERADMIN', 'ADMIN', 'CUSTOMER_SUPPORT'].includes(role.toUpperCase());

        const sortSettings = (
            <SortSettings
                activeOptions={this.props.sortOptions}
                sortOptions={SORT_OPTIONS}
                setActiveOptions={(sortOptions) => this.props.setSortOptions(sortOptions)}
            />
        );

        return (
            <div>
                <ActivityModal
                    visible={!!activityModalId}
                    id={activityModalId}
                    type="advert"
                    onClose={this.hideActivityModal}
                />
                <AdvertImpressionsModal
                    adId={currentAdvert ? currentAdvert.shortid : null}
                    targeting={currentTargeting}
                    info={info}
                    onOk={this.activateAdvert}
                    onCancel={this.hideTargetingModal}
                    lastStartDate={currentAdvert?.startDate}
                    visible={targetingModal}
                    history={history}
                    match={match}
                />
                <UniNowList
                    noItemsText="Es wurden noch keine Werbeanzeigen hinzugefügt."
                    itemLayout="horizontal"
                    dataSource={dataSource}
                    pagination={{
                        current: this.props.currentPage,
                        pageSize: 10,
                        onChange: (page) => {
                            this.props.setAdCurrentPage(page);
                        },
                    }}
                    optionsElement={sortSettings}
                    searchFilter={(advert, search) => {
                        const postTitle =
                            advert.data.post && advert.data.post.content
                                ? `${advert.data.post.content.substring(0, 70)}${
                                      advert.data.post.content.length > 70 ? '...' : ''
                                  }`
                                : null;
                        const title = `${advert.label || advert.uuid} - ${
                            advert.data.title || postTitle
                        }`;
                        return (
                            title.toLowerCase().includes(search) ||
                            advert.shortid?.toLowerCase().includes(search) ||
                            advert.uuid?.includes(search) ||
                            advert._id?.includes(search)
                        );
                    }}
                    renderItem={(advert) => {
                        const {
                            universities,
                            affiliations,
                            impressionsLeft,
                            impressionsLeftToday,
                        } = advert.targeting;

                        const description = [
                            <span
                                key="id"
                                className="advert-targeting-link"
                                onClick={() => this.editAd(advert.shortid)}>
                                {advert.label || advert.uuid}
                            </span>,
                            ' | ',
                            <span
                                key="locations"
                                className="advert-targeting-link"
                                onClick={() => this.editAd(advert.shortid, '2', '1')}>
                                {universities.length > 0
                                    ? `${universities.length} Hochschule(n)`
                                    : 'Alle Standorte'}
                            </span>,
                            ' | ',
                            <span
                                key="affiliations"
                                className="advert-targeting-link"
                                onClick={() => this.editAd(advert.shortid, '2', '2')}>
                                {affiliations.length > 0
                                    ? `${affiliations.length} Fachrichtung(en)`
                                    : 'Alle Fachrichtungen'}
                            </span>,
                        ];

                        if (advert.visible) {
                            description.push(
                                ` | Verbleibende Impressions: ${Math.max(impressionsLeft, 0)}${
                                    typeof impressionsLeftToday === 'number'
                                        ? ` (${impressionsLeftToday} heute)`
                                        : ''
                                }`,
                            );

                            if (advert.endOfTerm) {
                                description.push(
                                    ` | Endet am: ${moment(advert.endOfTerm).format('DD.MM.YYYY')}`,
                                );
                            }
                        }

                        const menu = (
                            <Menu>
                                <Menu.Item onClick={() => this.showStatistics(advert.shortid)}>
                                    <Icon type="area-chart" />
                                    Statistiken
                                </Menu.Item>
                                <Menu.Item
                                    disabled={this.state[`pending_dublicate_${advert.uuid}`]}
                                    onClick={() => this.dublicate(advert.uuid, advert.data)}>
                                    <Icon type="copy" />
                                    Duplizieren
                                </Menu.Item>
                                <Menu.Item onClick={() => this.setArchived(advert.uuid, true)}>
                                    <Icon type="folder-add" />
                                    Archivieren
                                </Menu.Item>
                                <Menu.Item>
                                    <Popconfirm
                                        title="Werbeanzeige wirklich löschen?"
                                        onConfirm={() => this.removeAdvert(advert.uuid)}>
                                        <div style={{ width: '100%' }}>
                                            <Icon type="delete" style={{ paddingRight: 5 }} />
                                            Löschen
                                        </div>
                                    </Popconfirm>
                                </Menu.Item>
                                {isADMIN && (
                                    <Menu.ItemGroup title="Admin">
                                        <Menu.Item
                                            onClick={() => {
                                                const getAdvertType = () => {
                                                    const position =
                                                        advert?.targeting?.positions?.[0];
                                                    switch (position) {
                                                        case 'CAREER_OVERVIEW_0':
                                                            return 'recruiting';
                                                        case 'MAIL_OVERVIEW_0':
                                                            return 'mail';
                                                        default:
                                                            return 'feed';
                                                    }
                                                };

                                                Modal.info({
                                                    title: 'Vorschau',
                                                    content: (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'flex-start',
                                                                marginTop: 15,
                                                            }}>
                                                            <PreviewQR
                                                                path={`ad_preview?id=${
                                                                    advert._id
                                                                }&type=${getAdvertType()}`}
                                                            />
                                                            <div style={{ marginLeft: 15 }}>
                                                                Scannen Sie diesen QR-Code mit ihrem
                                                                Mobilgerät, um die Werbeanzeige in
                                                                der UniNow App anzuzeigen
                                                            </div>
                                                        </div>
                                                    ),
                                                    onOk() {},
                                                });
                                            }}>
                                            <Icon type="mobile" />
                                            Vorschau
                                        </Menu.Item>
                                        <Menu.Item
                                            onClick={() => this.showActivityModal(advert._id)}>
                                            <Icon type="bars" />
                                            Aktivitäten
                                        </Menu.Item>
                                    </Menu.ItemGroup>
                                )}
                            </Menu>
                        );

                        const actions = advert.archived
                            ? [
                                  buttonWithTooltip('Werbeanzeige aktiv schalten')(
                                      <Button
                                          loading={this.state[`pending_archive_${advert.uuid}`]}
                                          shape="circle"
                                          icon="rollback"
                                          onClick={() => this.setArchived(advert.uuid, false)}
                                      />,
                                  ),
                                  buttonWithTooltip('Werbeanzeige löschen')(
                                      <Popconfirm
                                          title="Werbeanzeige wirklich löschen?"
                                          onConfirm={() => this.removeAdvert(advert.uuid)}>
                                          <Button shape="circle" icon="delete" />
                                      </Popconfirm>,
                                  ),
                              ]
                            : [
                                  buttonWithTooltip('Werbeanzeige editieren')(
                                      <Button
                                          shape="circle"
                                          icon="edit"
                                          onClick={() => this.editAd(advert.shortid)}
                                      />,
                                  ),
                                  buttonWithTooltip('Weitere Optionen')(
                                      <Dropdown overlay={menu}>
                                          <Button shape="circle" icon="menu" />
                                      </Dropdown>,
                                  ),
                              ];

                        if (!advert.archived && role.toUpperCase() !== 'TRIAL') {
                            actions.unshift(
                                buttonWithTooltip(
                                    <span>
                                        Schalten Sie Ihre Werbeanzeige online (
                                        <Icon type="eye" theme="filled" />) oder offline (
                                        <Icon type="eye" />
                                        ).
                                    </span>,
                                )(
                                    <Switch
                                        loading={this.state[`pending_visibility_${advert.uuid}`]}
                                        checkedChildren={<Icon type="eye" theme="filled" />}
                                        unCheckedChildren={<Icon type="eye" />}
                                        checked={advert.visible}
                                        onChange={(value) =>
                                            this.handleVisibilityChange(advert.uuid, value)
                                        }
                                    />,
                                ),
                            );
                        }

                        const postTitle =
                            advert.data.post && advert.data.post.content
                                ? `${advert.data.post.content.substring(0, 70)}${
                                      advert.data.post.content.length > 70 ? '...' : ''
                                  }`
                                : null;

                        const isSelected = selected === advert._id;

                        return (
                            <Row
                                type="flex"
                                align="middle"
                                justify="space-between"
                                className="advert-list-item"
                                style={{
                                    backgroundColor: isSelected ? '#ffe6e8' : null,
                                    cursor: 'pointer',
                                }}
                                onClick={() => (isSelected ? null : this.onListItemClick(advert))}>
                                <Col span={23}>
                                    <List.Item key={advert.uuid} actions={actions}>
                                        <List.Item.Meta
                                            title={
                                                <a onClick={() => this.editAd(advert.shortid)}>
                                                    {advert.data.title || postTitle}
                                                </a>
                                            }
                                            description={description}
                                        />
                                    </List.Item>
                                </Col>
                                <Col span={1} style={{ textAlign: 'center', color: '#8E8E8E' }}>
                                    {isSelected && <Icon type="right" />}
                                </Col>
                            </Row>
                        );
                    }}
                />
            </div>
        );
    }
}

AdvertList.propTypes = {
    adverts: PropTypes.array.isRequired,
    onToggle: PropTypes.func.isRequired,
    selected: PropTypes.string,
    onClick: PropTypes.func,
    onDublicate: PropTypes.func,
    onRemove: PropTypes.func,
    archived: PropTypes.bool,
    loading: PropTypes.bool,
    error: PropTypes.bool,
    info: PropTypes.object,
};

AdvertList.contextTypes = {
    request: PropTypes.func,
    role: PropTypes.string,
};

const mapStateToProps = ({ filter, currentPageOfLists }) => ({
    sortOptions: filter.advertSortOptions || {},
    currentPage: currentPageOfLists.adListCurrentPage,
});

const mapDispatchToProps = (dispatch) => ({
    setSortOptions: (sortOptions) => dispatch(sortAdverts(sortOptions)),
    setAdCurrentPage: (page) => dispatch(setAdCurrentPage(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvertList);
