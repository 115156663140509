import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { uniqBy } from 'lodash';
import { List, Spin, Icon } from 'antd';

import './style.css';

const IconText = ({ type, text }) => (
    <span>
        <Icon type={type} style={{ marginRight: 8 }} />
        {text}
    </span>
);

const ATTACHMENT_TYPE_MAP = {
    Default: <IconText type="link" text="Link-Vorschau" />,
    Blog: <IconText type="link" text="Link-Vorschau" />,
    Video: <IconText type="video-camera" text="Video" />,
    Gallery: <IconText type="picture" text="Gallerie" />,
    Event: <IconText type="calendar" text="Veranstaltung" />,
    Poll: <IconText type="bar-chart" text="Umfrage" />,
};

const LOAD_MORE_THRESHOLD = 20;

class PostList extends Component {
    constructor(props) {
        super(props);

        const { initialPost } = props;

        this.state = {
            initialLoading: true,
            loading: false,
            error: false,
            posts: [],
            cursor: null,
            hasNext: true,
            selected: initialPost ? initialPost._id : null,
        };
    }

    handleScroll = ({ target }) => {
        const { initialLoading, loading, hasNext } = this.state;
        const { scrollTop, scrollTopMax } = target;

        if (
            !initialLoading &&
            !loading &&
            hasNext &&
            scrollTopMax - scrollTop < LOAD_MORE_THRESHOLD
        ) {
            this.onLoadMore();
        }
    };

    componentDidMount = () => {
        this.loadPosts();

        if (this.listContainer) {
            this.listContainer.addEventListener('scroll', this.handleScroll);
        }
    };

    componentWillUnmount() {
        if (this.listContainer) {
            this.listContainer.removeEventListener('scroll', this.handleScroll);
        }
    }

    loadPosts = async (before = null, limit = 10) => {
        const { posts, selected } = this.state;
        const { request } = this.context;

        const { body, error } = await request('recruiting/post/list', {
            limit,
            before,
        });

        if (error) {
            this.setState({
                initialLoading: false,
                loading: false,
                error: true,
            });
        } else {
            const newPosts = uniqBy(posts.concat(body.posts), '_id');
            const cursor = newPosts.length > 0 ? newPosts[newPosts.length - 1]._id : null;

            if (!selected && newPosts.length) {
                this.props.onSelect(newPosts[0]);
            }

            this.setState({
                selected: selected || newPosts[0]._id,
                cursor,
                hasNext: body.hasNext,
                posts: newPosts,
                initialLoading: false,
                loading: false,
            });
        }
    };

    onLoadMore = () => {
        this.setState({ loading: true });

        const { cursor } = this.state;

        this.loadPosts(cursor);
    };

    onPostSelect = (post) => {
        this.setState({ selected: post._id });

        this.props.onSelect(post);
    };

    renderItem = (post) => {
        const { _id, content, publishDate, likesCount = 0, attachments = [], channels = [] } = post;
        const { selected } = this.state;
        const type =
            attachments.length > 0 && attachments[0] ? attachments[0].__typename : 'Default';

        const isSelected = selected === _id;

        return (
            <List.Item
                className="post-list-item"
                style={{
                    padding: 10,
                    backgroundColor: isSelected ? '#ffe6e8' : null,
                    cursor: isSelected ? 'not-allowed' : 'pointer',
                }}
                onClick={() => (isSelected ? null : this.onPostSelect(post))}
                actions={[
                    <IconText type="heart" text={likesCount} />,
                    <IconText
                        type="clock-circle"
                        text={moment(publishDate).format('DD.MM.YYYY HH:mm')}
                    />,
                    ATTACHMENT_TYPE_MAP[type],
                ]}>
                <List.Item.Meta
                    title={
                        <span
                            style={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                            }}>
                            {content}
                        </span>
                    }
                    description={
                        channels.length > 0
                            ? channels.map((channel) => `#${channel}`).join(' ')
                            : null
                    }
                />
            </List.Item>
        );
    };

    renderLoadMore = () => {
        const { loading } = this.state;

        return loading ? (
            <div style={{ textAlign: 'center', padding: 15 }}>
                <Spin />
            </div>
        ) : null;
    };

    render() {
        const { initialLoading, posts } = this.state;

        return (
            <div
                id="PostList"
                ref={(listContainer) => (this.listContainer = listContainer)}
                style={{ height: 500, overflowY: 'scroll' }}>
                <List
                    rowKey="_id"
                    itemLayout="vertical"
                    loading={initialLoading}
                    dataSource={posts}
                    renderItem={this.renderItem}
                    loadMore={this.renderLoadMore()}
                />
            </div>
        );
    }
}

PostList.contextTypes = {
    request: PropTypes.func,
};

PostList.propTypes = {
    onSelect: PropTypes.func.isRequired,
    initialPost: PropTypes.object,
};

export default PostList;
