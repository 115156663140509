import { Draggable } from 'react-beautiful-dnd';
import { Button } from 'antd';
import React from 'react';
import { UNIcon } from '../../components';
/* eslint-disable react/jsx-props-no-spreading */
const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: isDragging ? 'lightgreen' : 'transparent',
    paddingBottom: 2,
    paddingTop: 2,
    justifyContent: 'space-between',
    display: 'flex',
    ...draggableStyle,
});
const DraggableBenefit = ({ benefit, index, groupIndex, onEditClick }) => (
    <Draggable key={benefit.key} draggableId={benefit.key} index={index}>
        {(draggableProvided, draggableSnapshot) => (
            <div
                ref={draggableProvided.innerRef}
                {...draggableProvided.draggableProps}
                {...draggableProvided.dragHandleProps}
                style={getItemStyle(
                    draggableSnapshot.isDragging,
                    draggableProvided.draggableProps.style,
                )}>
                <div>
                    <UNIcon icon={benefit.icon} />
                    {benefit.text}
                </div>
                <Button type="link" icon="edit" size="small" onClick={onEditClick} />
            </div>
        )}
    </Draggable>
);
export default DraggableBenefit;
