import React, { useEffect, useRef, useState } from 'react';
import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react/dist/cubejs-client-react';
import { useDispatch, useSelector } from 'react-redux';
import Ad from './components/Ad/Ad';
import Company from './components/Company/Company';
import Recruiting from './components/Recruiting/Recruiting';
import { changeCubeTokenValidity } from '../../core/redux/actions';

const Statistics = (props) => {
    const dispatch = useDispatch();
    const isCubeTokenValid = useSelector((state) => state.cubeTokenValidity.isCubeTokenValid);
    const companyUuid = useSelector((state) => state.company.uuid);
    const token = localStorage.getItem('auth.token');
    const parsedToken = JSON.parse(token);
    const jwtToken = parsedToken.raw;
    const [cubeToken, setCubeToken] = useState(localStorage.getItem('cube.token'));
    const [content, setContent] = useState(null);

    async function fetchToken() {
        try {
            const response = await fetch(
                `https://cube-token.uninow.com/token/generate?company_id=${companyUuid}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                        ContentType: 'application/json',
                    },
                },
            );
            const data = await response.json();
            localStorage.setItem('cube.token', data.token);
            setCubeToken(data.token);
            dispatch(changeCubeTokenValidity(true));

            switch (props.statistic) {
                case 'ad':
                    setContent(<Ad />);
                    break;
                case 'company':
                    setContent(<Company />);
                    break;
                default:
                case 'recruiting':
                    setContent(<Recruiting />);
                    break;
            }
            return data.token;
        } catch (error) {
            return null;
        }
    }

    const cubejsApi = cubejs(cubeToken, {
        apiUrl: 'https://cube-api.uninow.com/cubejs-api/v1',
    });

    useEffect(() => {
        if (!cubeToken || !isCubeTokenValid) {
            fetchToken();
        }
    }, [cubeToken, isCubeTokenValid]);

    useEffect(() => {
        if (companyUuid) {
            fetchToken();
        }
    }, [companyUuid, props.statistic]);

    return (
        <CubeProvider cubejsApi={cubejsApi}>
            <div>{content}</div>
        </CubeProvider>
    );
};

export default Statistics;
