import React from 'react';
import parseMarkdown from '../../core/helper/markdown';
import './styles.css';

function Index() {
    const info = [
        {
            text: 'Kursiv',
            code: 'Dies ist ein *kursiver* text',
        },
        {
            text: 'Fett gedruckt',
            code: 'Dies ist ein **wichtiger** text',
        },
        {
            text: 'Kursiv und fett gedruckt',
            code: 'Dies ist ein ***sehr wichtiger*** text',
        },
        {
            text: 'Verlinkung',
            code: `Dies ist ein [Link](${window.location.href})`,
        },
    ];
    return (
        <div>
            <h1 className="title">Anleitung für die Formatierung von Texten</h1>
            {info.map(({ text, code }) => (
                <>
                    <h2 className="subtitle">{text}:</h2>
                    <table>
                        <tr className="row">
                            <td>Eingabe:</td>
                            <td>
                                <code className="markdown">{code}</code>
                            </td>
                        </tr>
                        <tr>
                            <td>Ergebnis:</td>
                            <td>
                                <span className="render">{parseMarkdown(code)}</span>
                            </td>
                        </tr>
                    </table>
                </>
            ))}
        </div>
    );
}

export default Index;
