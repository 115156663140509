import React, { PureComponent } from 'react';

import EditorModal from '../../../EditorModal';

export default class ImageEditorModal extends PureComponent {
    state = {
        quality: 100,
    };

    handleSaveEdit = () => {
        const { onSave } = this.props;
        const imageToSave = this.imageEditorRef.getImage();

        if (onSave) {
            onSave(imageToSave);
        }
    };

    handleCancelEdit = () => {
        const { onCancel } = this.props;
        if (onCancel) {
            onCancel();
        }
    };

    handleDeleteImage = () => {
        const { onDelete } = this.props;
        if (onDelete) {
            onDelete();
        }
    };

    getEditorRef = (imageEditorRef) => {
        this.imageEditorRef = imageEditorRef;
    };

    handleQualityChange = (quality) => {
        this.setState({ quality });
    };

    render() {
        const { imageRatio, imageToEdit, enableZoomOut, optimalWidth, optimalHeight } = this.props;
        const { x, y } = imageRatio;
        const imageModalWidth = Math.round(window.innerWidth * 0.7);
        const imageModalHeight = Math.round(window.innerHeight * 0.7);

        const maxEditorWidth = imageModalWidth * 0.9;
        const maxEditorHeight = imageModalHeight * 0.9;

        let imageEditorHeight = (maxEditorWidth / x) * y;

        imageEditorHeight = Math.round(Math.min(imageEditorHeight, maxEditorHeight));

        let isEdit = false;
        let isVisible = false;

        if (imageToEdit) {
            const { tempID, _id } = imageToEdit;
            isEdit = !!(_id || tempID);
            isVisible = true;
        }

        return (
            <EditorModal
                onRef={this.getEditorRef}
                visible={isVisible}
                editMode={isEdit}
                width={imageModalWidth}
                height={imageEditorHeight}
                optimalWidth={optimalWidth}
                optimalHeight={optimalHeight}
                enableZoomOut={enableZoomOut}
                onCancel={this.handleCancelEdit}
                onSave={this.handleSaveEdit}
                onDelete={this.handleDeleteImage}
                imageToEdit={imageToEdit}
            />
        );
    }
}
