import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { NPSFeedback } from '@uninow/user-feedback-client-react-dom';
import PropTypes from 'prop-types';
import { notification, Col, Row } from 'antd';
import './index.css';
import './App.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { connect } from 'react-redux';
import {
    General,
    Jobs,
    Settings,
    EditJob,
    Applicants,
    Locations,
    Media,
    SocialMedia,
    Awards,
    Benefits,
    Events,
    Ratings,
    Companies,
    Members,
    MemberList,
    ApplicantList,
    Statistics,
    Version,
    Adverts,
    EditAdvert,
    JobReasonsStatistics,
    StudentsProfile,
    StudentsProfileMessages,
    StudentsProfileSearch,
} from './screens';
import { Spinner, SubHeaderLayout, PrivateRoute, Preview } from './components';
import OpenProfile from './components/OpenProfile';
import MarkdownHelp from './screens/MarkdownHelp';
import EditBenefits from './screens/Benefits/Edit';
import Feed from './screens/Feed';
import InstagramAuthScreen from './screens/Instagram/auth';
import ChatwootBubble from './components/ChatwootBubble';

const ArchivedJobs = (props) => <Jobs {...props} archived />;
const ArchivedAdverts = (props) => <Adverts {...props} archived />;

const PREVIEW_WHITELIST = ['^/$', 'profil', 'beitrage'];

const PREVIEW_BLACKLIST = ['statistiken'];

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataIsLoading: false,
        };
    }

    componentWillMount = () => {
        notification.config({ top: 120 });
        const { REACT_APP_SENTRY_DNS, REACT_APP_ENV } = window;

        Sentry.init({
            dsn: REACT_APP_SENTRY_DNS,
            environment: REACT_APP_ENV || 'DEV',
            integrations: [
                new Integrations.BrowserTracing({
                    tracingOrigins: [
                        'localhost',
                        'staging.wapi.uninow.io',
                        'wapi.uninow.io',
                        /^\//,
                    ],
                }),
            ],
            tracesSampleRate: 0.1,
            release: `recruiting-legacy@${process.env.REACT_APP_VERSION}`,
        });
        this.loadCompany();
    };

    loadCompany = async () => {
        const { logout } = this.context;
        this.setState({ dataIsLoading: true });
        const wasSuccessful = await this.context.loadSessionData();
        if (wasSuccessful) {
            this.setState({ dataIsLoading: false });
        } else {
            logout();
        }
    };

    render() {
        const { role, accountId, companyId } = this.context;
        const { pathname } = this.props.location;

        const showPreview =
            PREVIEW_WHITELIST.some((value) => new RegExp(value, 'i').test(pathname)) &&
            !PREVIEW_BLACKLIST.some((value) => new RegExp(value, 'i').test(pathname));

        const RouterSwitch =
            role === 'CUSTOMER_ADVERTS' ? (
                <Switch>
                    <Route exact path="/werbeanzeigen/editieren/:shortid?" component={EditAdvert} />
                    <Route exact path="/werbeanzeigen/archiviert" component={ArchivedAdverts} />
                    <Route path="/werbeanzeigen" component={Adverts} />
                    <Route
                        path="/statistiken/werbeanzeigen"
                        render={(props) => <Statistics {...props} statistic="ad" />}
                    />
                    <Route exact path="/help/markdown" component={MarkdownHelp} />
                    <PrivateRoute exact path="/members" component={Members} />
                    <Route exact path="/passwortaendern" component={Settings} />
                    <Redirect from="/" to="/werbeanzeigen" />
                </Switch>
            ) : (
                <Switch>
                    <Route exact path="/profil/insights" component={Ratings} />
                    <Route exact path="/profil/events" component={Events} />
                    <Route exact path="/profil/benefits" component={Benefits} />
                    <Route
                        exact
                        path="/editBenefits"
                        component={EditBenefits}
                        accessRoles={['SUPERADMIN', 'ADMIN']}
                    />
                    <Route exact path="/profil/auszeichnungen" component={Awards} />
                    <Route exact path="/profil/sozialemedien" component={SocialMedia} />
                    <Route exact path="/profil/galerie" component={Media} />
                    <Route exact path="/profil/standorte" component={Locations} />
                    <Route exact path="/profil/open/:uuid" component={OpenProfile} />
                    <Route exact path="/stellenanzeigen/archiviert" component={ArchivedJobs} />
                    <Route
                        exact
                        path="/stellenanzeigen/duplizieren/:uuid?"
                        render={(props) => <EditJob {...props} duplicate />}
                    />
                    <Route
                        exact
                        path="/stellenanzeigen/bewerbungen/:uuid?"
                        render={(props) => <Applicants {...props} />}
                    />
                    <Route exact path="/stellenanzeigen/editieren/:uuid?" component={EditJob} />
                    <Route path="/stellenanzeigen" component={Jobs} />
                    {!['UNI_ADMIN', 'CUSTOMER_NO_ADVERTS'].includes(role) && [
                        <Route
                            exact
                            path="/werbeanzeigen/editieren/:shortid?"
                            component={EditAdvert}
                        />,
                        this.props.showFeed && <Route path="/beitrage" component={Feed} />,
                        <Route
                            exact
                            path="/werbeanzeigen/archiviert"
                            component={ArchivedAdverts}
                        />,

                        this.props.showTalentPool && (
                            <Route exact path="/students" component={StudentsProfileSearch} />
                        ),
                        this.props.showTalentPool && (
                            <Route path="/students/kontaktiert" component={StudentsProfile} />
                        ),

                        <Route path="/werbeanzeigen" component={Adverts} />,
                        this.props.showFeed && (
                            <PrivateRoute
                                exact
                                path="/instagram/auth"
                                component={InstagramAuthScreen}
                            />
                        ),
                        <Route
                            path="/statistiken/werbeanzeigen"
                            render={(props) => <Statistics {...props} statistic="ad" />}
                        />,
                    ]}
                    {/* <Route exact path="/statistiken/stellenanzeigen" component={JobStatistics} /> */}
                    <Route
                        path="/statistiken/stellenanzeigen"
                        render={(props) => <Statistics {...props} statistic="recruiting" />}
                    />
                    <Route
                        path="/statistiken/unternehmen"
                        render={(props) => <Statistics {...props} statistic="company" />}
                    />
                    <Route
                        path="/statistiken/jobs"
                        accessRoles={['SUPERADMIN', 'ADMIN', 'CUSTOMER_SUPPORT']}
                        component={JobReasonsStatistics}
                    />
                    <Route exact path="/passwortaendern" component={Settings} />
                    <PrivateRoute
                        exact
                        path="/companies"
                        accessRoles={[
                            'SUPERADMIN',
                            'ADMIN',
                            'CUSTOMER_SUPPORT',
                            'UNI_ADMIN',
                            'MANAGER',
                        ]}
                        component={Companies}
                    />
                    <PrivateRoute
                        exact
                        path="/memberlist"
                        accessRoles={['SUPERADMIN', 'ADMIN']}
                        component={MemberList}
                    />
                    <PrivateRoute
                        exact
                        path="/applicantlist"
                        accessRoles={['SUPERADMIN', 'ADMIN']}
                        component={ApplicantList}
                    />
                    <PrivateRoute
                        exact
                        path="/version"
                        accessRoles={['SUPERADMIN', 'ADMIN', 'CUSTOMER_SUPPORT']}
                        component={Version}
                    />
                    <PrivateRoute exact path="/members" component={Members} />
                    <Route exact path="/help/markdown" component={MarkdownHelp} />
                    <Route path="/" component={General} />
                    {/* <PrivateRoute path="/" component={Start} /> */}
                </Switch>
            );

        return (
            <div style={{ height: '100%' }}>
                {this.state.dataIsLoading ? (
                    <Spinner tip="Daten werden geladen..." />
                ) : (
                    <SubHeaderLayout role={role}>
                        <ChatwootBubble context={{ companyId, accountId }}>
                            <Row className="row-content" style={{ height: '100%' }}>
                                <Col
                                    xxl={showPreview ? 16 : 24}
                                    xl={showPreview ? 18 : 24}
                                    span={showPreview ? 16 : 24}
                                    style={
                                        {
                                            // height: '100%',
                                            // overflowY: 'scroll',
                                        }
                                    }>
                                    {RouterSwitch}
                                </Col>
                                {showPreview && (
                                    <Col
                                        style={{
                                            height: '100%',
                                        }}
                                        offset={2}
                                        xxl={6}
                                        xl={4}
                                        span={6}>
                                        <Preview />
                                    </Col>
                                )}
                            </Row>
                            {accountId && companyId && (
                                <NPSFeedback
                                    appID={
                                        window.REACT_APP_ENV !== 'development'
                                            ? '016aa23b-fb83-4d0c-a51c-606e787008ad'
                                            : '90700ee2-0e3c-4345-bb2c-a8590b0f609a'
                                    }
                                    userID={accountId}
                                    locale="de"
                                    position="rightBottom"
                                />
                            )}
                        </ChatwootBubble>
                    </SubHeaderLayout>
                )}
            </div>
        );
    }
}

App.contextTypes = {
    accountId: PropTypes.string,
    companyId: PropTypes.string,
    role: PropTypes.string.isRequired,
    loadSessionData: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    showFeed: state.company.showFeed,
    showInstagramSync: state.company.showInstagramSync,
    showTalentPool: state.company.showTalentPool,
});

export default connect(mapStateToProps)(withRouter(App));
