export const ADVERT_DATA = {
    title: 'UniNow GmbH',
    teaser: "Bewirb Dich noch bis zum 21. Juli 2017 und sichere Dir 1 von 4 Plätzen! Los geht's ab August.",
    headline: 'UniNow IT Summer-School',
    subline: 'Learn how to code',
    action: {
        label: 'Jetzt bewerben',
        link: 'https://www.uninow.de',
    },
    logo: {
        raw: 'https://images.uninow.com/campaigns/59e4ab24c899f2f79c8859d3_logo.png',
        large: 'https://images.uninow.com/campaigns/59e4ab24c899f2f79c8859d3_logo.png',
        medium: 'https://images.uninow.com/campaigns/59e4ab24c899f2f79c8859d3_logo.png',
        small: 'https://images.uninow.com/campaigns/59e4ab24c899f2f79c8859d3_logo.png',
    },
    image: {
        raw: 'https://images.uninow.com/campaigns/59e4ab24c899f2f79c8859d3_header.png',
        large: 'https://images.uninow.com/campaigns/59e4ab24c899f2f79c8859d3_header.png',
        medium: 'https://images.uninow.com/campaigns/59e4ab24c899f2f79c8859d3_header.png',
        small: 'https://images.uninow.com/campaigns/59e4ab24c899f2f79c8859d3_header.png',
    },
};
