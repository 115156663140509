/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Modal, Spin, List, AutoComplete, Input } from 'antd';
import { debounce } from 'lodash';
import { RequestContext } from '../../../components/Session/RequestProvider';

function CompanySearch({ onSelect }) {
    const [query, setQuery] = useState('');
    const { request } = useContext(RequestContext);
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    async function loadCompanies(searchQuery) {
        setLoading(true);
        const { body } = await request('recruiting/administration/companies', {
            limit: 10,
            search: searchQuery,
        });
        if (body?.companies) {
            setCompanies(
                body.companies.map((company) => ({ _id: company._id, name: company.profile.name })),
            );
        } else {
            setCompanies([]);
        }
        setLoading(false);
    }
    const debouncedSearch = debounce(loadCompanies, 500);
    useEffect(() => {
        debouncedSearch(query);
    }, [query]);

    const onValueSelected = (val) => {
        // hacky solution via name because antdesign breaks when loading options on the fly that are not strings
        const selectedCompany = companies.find((company) => company.name === val);
        onSelect(selectedCompany);
    };
    return (
        <>
            <AutoComplete
                dataSource={companies.map((company) => company.name)}
                style={{ width: '100%', marginBottom: 20 }}
                onSelect={onValueSelected}
                onSearch={setQuery}
                placeholder="Unternehmen hinzufügen">
                <Input.Search loading={loading} />
            </AutoComplete>
        </>
    );
}

function AllowedCompanyModal({ accountId, onChange, companies, username }) {
    const [modalVisible, setModalVisible] = useState(false);
    const { request } = useContext(RequestContext);
    const removeCompany = async (companyId) => {
        const { body } = await request('recruiting/member/allowedCompanies/remove', {
            companyId,
            accountId,
        });
        if (body === true) {
            onChange(companies.filter((company) => company._id !== companyId));
        }
    };
    const addCompany = async (company) => {
        const { body } = await request('recruiting/member/allowedCompanies/add', {
            companyId: company._id,
            accountId,
        });
        if (body === true) {
            onChange([...companies, company]);
        }
    };
    return (
        <>
            <Button shape="link" icon="edit" onClick={() => setModalVisible(true)} />
            <Modal
                title={`Von ${username} verwaltete Unternehmen`}
                visible={modalVisible}
                onOk={() => setModalVisible(false)}
                onCancel={() => setModalVisible(false)}
                footer={[
                    <Button key="submit" type="primary" onClick={() => setModalVisible(false)}>
                        OK
                    </Button>,
                ]}>
                <CompanySearch onSelect={addCompany} />
                <List
                    size="small"
                    bordered
                    dataSource={companies}
                    locale={{ emptyText: 'Dieser Nutzer verwaltet derzeit keine Unternehmen' }}
                    renderItem={(company) => (
                        <List.Item
                            actions={[
                                <Button
                                    key="delete"
                                    shape="circle"
                                    icon="delete"
                                    size="small"
                                    onClick={() => removeCompany(company._id)}
                                />,
                            ]}>
                            {company.name}
                        </List.Item>
                    )}
                />
            </Modal>
        </>
    );
}

AllowedCompanyModal.propTypes = {
    accountId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    companies: PropTypes.arrayOf(PropTypes.object).isRequired,
};

function AllowedCompanyList({ accountId, userName, canEdit }) {
    const { request } = useContext(RequestContext);
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        async function loadCompanies() {
            const { body } = await request('recruiting/member/allowedCompanies/list', {
                accountId,
            });
            if (body) {
                setCompanies(body);
            }
            setLoading(false);
        }
        loadCompanies();
    }, [accountId]);
    if (loading) {
        return <Spin size="small" />;
    }
    const companiesText =
        companies.length === 0
            ? 'Keine Unternehmen'
            : `${companies
                  .slice(0, 4)
                  .map((company) => company.name)
                  .join(', ')}${companies.length > 3 ? ',...' : ''}`;

    return (
        <>
            <span>: {companiesText} </span>
            {canEdit && (
                <AllowedCompanyModal
                    accountId={accountId}
                    onChange={(newCompanies) => setCompanies(newCompanies)}
                    companies={companies}
                    username={userName}
                />
            )}
        </>
    );
}

AllowedCompanyList.propTypes = {
    accountId: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    canEdit: PropTypes.bool.isRequired,
};
export default AllowedCompanyList;
