import React from 'react';
import Phone from '../Phone';

import './preview.css';

export default function Preview({ children }) {
    return (
        <div className="preview">
            <Phone backgroundColor="white">{children}</Phone>
        </div>
    );
}
