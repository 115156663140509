import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { Modal, Row, Col, Icon } from 'antd';

import './style.css';

class AdvertSelectionModal extends Component {
    goto = (route, type = '0') => {
        const { match, history } = this.props;

        history.push(`${match.url}/${route}?type=${type}`);
    };

    renderCard = (title, description, onClick) => (
        <div className="post-card" onClick={onClick}>
            <Row
                type="flex"
                align="middle"
                justify="space-between"
                style={{ padding: '10px 15px' }}>
                <Col span={20}>
                    <div className="post-card-header">{title.toUpperCase()}</div>
                    <div style={{ color: '#8E8E8E', padding: '10px 0px' }}>{description}</div>
                </Col>
                <Col span={4} style={{ textAlign: 'center' }}>
                    <Icon style={{ color: '#F21850' }} type="right" />
                </Col>
            </Row>
        </div>
    );

    render() {
        const {
            visible,
            postAdvert = false,
            careerAdvert = false,
            mailAdvert = false,
        } = this.props;

        return (
            <Modal
                title="Wählen Sie Ihre Werbeanzeige"
                maskClosable
                destroyOnClose
                onOk={this.onOk}
                onCancel={this.props.onCancel}
                visible={visible}
                footer={null}>
                <Row id="AdvertSelectionModal" type="flex" justify="center">
                    {this.renderCard(
                        'Werbeanzeigen (Standard)',
                        'Komplett individualisierbar. Bewerbung von Unternehmensprofil und Stellenanzeigen möglich.',
                        () => this.goto('editieren', '0'),
                    )}
                    <div style={{ padding: 5 }} />
                    {postAdvert &&
                        this.renderCard(
                            'Werbeanzeigen für Posts',
                            'Bewerbung von Posts, die im UniNow-Feed von Ihnen verfasst worden sind.',
                            () => this.goto('editieren', '1'),
                        )}
                    <div style={{ padding: 5 }} />
                    {careerAdvert &&
                        this.renderCard(
                            'Werbeanzeigen im "Karriere"-Tab',
                            'Komplett individualisierbar. Schaltung einer Werbeanzeige auf dem Startbildschirm des "Karriere"-Tabs.',
                            () => this.goto('editieren', '2'),
                        )}
                    <div style={{ padding: 5 }} />
                    {mailAdvert &&
                        this.renderCard(
                            'Werbeanzeigen im "Mail"-Modul',
                            'Komplett individualisierbar. Schaltung einer Werbeanzeige in der Inbox des Mail-Moduls.',
                            () => this.goto('editieren', '3'),
                        )}
                </Row>
            </Modal>
        );
    }
}

AdvertSelectionModal.defaultProps = {
    postAdvert: false,
    careerAdvert: false,
    mailAdvert: false,
};

AdvertSelectionModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    postAdvert: PropTypes.bool,
    careerAdvert: PropTypes.bool,
    mailAdvert: PropTypes.bool,
};

export default withRouter(AdvertSelectionModal);
