import update from 'immutability-helper';
import { sortBy } from 'lodash';
import updateState from '../../helper/redux';
import {
    SESSIONDATA_LOAD_SUCCESS,
    SORT_JOBS,
    UPDATE_APPLICANT_STATUS,
    REMOVE_JOB,
} from '../actions/index';

const jobs = (
    state = {
        joblist: [],
    },
    action,
) => {
    if (action.type === UPDATE_APPLICANT_STATUS) {
        const jobIndex = state.joblist.findIndex(
            (job) => job.uuid.toString() === action.jobID.toString(),
        );
        const applicantIndex = state.joblist[jobIndex].applicants.findIndex(
            (applicant) => applicant.uuid === action.applicantID,
        );

        return update(state, {
            joblist: {
                [jobIndex]: {
                    applicants: {
                        [applicantIndex]: {
                            status: { $set: action.status },
                        },
                    },
                },
            },
        });
    }
    if (action.type === REMOVE_JOB) {
        return {
            joblist: state.joblist.filter(({ uuid }) => uuid !== action.jobId),
        };
    }
    if (action.type === SESSIONDATA_LOAD_SUCCESS && action.jobs) {
        return {
            joblist: sortBy(
                [...action.jobs],
                [
                    (job) => (job.visible ? 0 : 1),
                    (job) =>
                        -(job.applicants || []).filter((applicant) => applicant.status === 'OPEN')
                            .length,
                ],
            ),
            jobsLoading: action.jobsLoading,
            includesArchived: action.includesArchived,
        };
    }
    if (action.reducer === 'jobs') {
        return updateState(state, action);
    }
    return state;
};

export default jobs;
