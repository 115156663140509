import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import deDE from 'antd/lib/locale-provider/de_DE';
import smoothscroll from 'smoothscroll-polyfill';

import 'url-search-params-polyfill';
import App from './App';
import { unregister } from './registerServiceWorker';
import { Login, AdStandalone, InAppPreview } from './screens';
import { PrivateRoute, Session, PageContainer } from './components';
import { configureStore } from './store';
import { getUserConfirmation } from './core/helper';

import './core/helper/polyfillsIE';
import './core/helper/canvasToBlob';
import RequestProvider from './components/Session/RequestProvider';
import CompanyProvider from './components/Session/CompanyProvider';
import LegacyContextAdapter from './components/Session/LegacyContextAdapter';
import DefaultErrorBoundary from './components/DefaultErrorBoundary';
import FeedClient from './components/Session/FeedClient';

smoothscroll.polyfill();
const { store, persistor } = configureStore();

ReactDOM.render(
    <ConfigProvider locale={deDE}>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <RequestProvider>
                    <Session store={store}>
                        <CompanyProvider>
                            <FeedClient>
                                <LegacyContextAdapter>
                                    <BrowserRouter getUserConfirmation={getUserConfirmation}>
                                        <PageContainer>
                                            <DefaultErrorBoundary>
                                                <Switch>
                                                    <Route exact path="/login" component={Login} />
                                                    <Route
                                                        exact
                                                        path="/preview/:shortid"
                                                        component={AdStandalone}
                                                    />
                                                    <Route
                                                        path="/app_preview"
                                                        component={InAppPreview}
                                                    />
                                                    <PrivateRoute path="/" component={App} />
                                                </Switch>
                                            </DefaultErrorBoundary>
                                        </PageContainer>
                                    </BrowserRouter>
                                </LegacyContextAdapter>
                            </FeedClient>
                        </CompanyProvider>
                    </Session>
                </RequestProvider>
            </PersistGate>
        </Provider>
    </ConfigProvider>,
    document.getElementById('root'),
);
unregister();
