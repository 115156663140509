import { Popconfirm } from 'antd';
import React from 'react';

export default function buttonWithPopconfirm(props) {
    const { placement, onOk, ...rest } = props;

    return (Button) => (
        <Popconfirm
            placement={placement || 'top'}
            okType="danger"
            cancelText="Abbrechen"
            style={{ pointerEvents: 'none' }}
            {...rest}>
            {Button}
        </Popconfirm>
    );
}
