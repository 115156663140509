import React, { Component } from 'react';

import { Layout, Row, Col, Input } from 'antd';

class Version extends Component {
    render() {
        return (
            <Layout style={{ height: '100vh', backgroundColor: 'white' }}>
                <Row style={{ marginTop: 100 }}>
                    <Col offset={7} span={10}>
                        <Input
                            addonBefore="Build Commit: "
                            disabled
                            value={window.REACT_APP_GIT_COMMIT}
                        />
                    </Col>
                </Row>

                <br />

                <Row style={{}}>
                    <Col offset={7} span={10}>
                        <Input
                            addonBefore="Build Number: "
                            disabled
                            value={window.REACT_APP_BUILD_NUMBER}
                        />
                    </Col>
                </Row>
            </Layout>
        );
    }
}

export default Version;
