import React, { Component } from 'react';
import './styles/unicon.css';

// icon font generated by fontello
export default class UNIcon extends Component {
    render() {
        let { children, icon, style, className = '' } = this.props;

        if (icon === 'youtube-play') {
            icon = 'youtube';
        }

        if (icon === 'x') {
            return (
                <i className={`${icon} ${className}`} style={{ ...style }}>
                    <svg
                        style={{
                            marginLeft: '5px',
                            marginRight: '5px',
                            height: '37.7px',
                        }}
                        width="22.125px"
                        height="22.125px"
                        viewBox="0 0 21.573 19.5"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="m 16.998462,0 h 3.308 l -7.227,8.26 8.502,11.24 h -6.657 l -5.2139994,-6.817 -5.966,6.817 H 0.43446256 L 8.1644626,10.665 0.00846256,0 H 6.8344626 l 4.7129994,6.231 z m -1.161,17.52 h 1.833 L 5.8384626,1.876 h -1.967 z"
                            fill="rgba(0, 0, 0, 0.65)"
                        />
                        {children}
                    </svg>
                </i>
            );
        }

        return (
            <i className={`un-${icon} ${className}`} style={{ ...style }}>
                {' '}
                {children}
            </i>
        );
    }
}
