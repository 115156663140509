import { notification } from 'antd';
import { useDispatch } from 'react-redux';
import { useCubeQuery } from '@cubejs-client/react/dist/cubejs-client-react';
import { changeCubeTokenValidity } from '../../../core/redux/actions';

const useCubeWithQueryErrorHandling = (query) => {
    const dispatch = useDispatch();
    const { resultSet, isLoading, error } = useCubeQuery(query);

    if (error) {
        if (error.message === 'Invalid token') {
            dispatch(changeCubeTokenValidity(false));
        } else {
            notification.error({
                key: 'CubeDevError',
                message: 'Statistiken konnten leider nicht geladen werden',
                description:
                    'Bitte versuchen Sie es später noch einmal oder kontaktieren Sie Ihren Ansprechpartner.',
            });
        }
    }

    return { resultSet, isLoading };
};

export default useCubeWithQueryErrorHandling;
