import React, { Component } from 'react';
import { Button } from 'antd';
import ButtonGroup from 'antd/es/button/button-group';

class SortSettings extends Component {
    render() {
        const { sortOptions, activeOptions, setActiveOptions } = this.props;

        const renderValue = (value) => {
            switch (value) {
                case 'desc':
                    return '↑';
                case 'asc':
                    return '↓';
                default:
                    return '-';
            }
        };

        const renderSortOption = ([key, option]) => (
            <Button
                size="small"
                key={key}
                style={{ backgroundColor: activeOptions[key] ? '#F2185022' : undefined }}
                onClick={(e) => {
                    e.target.blur();
                    const oldValue = activeOptions[key];
                    let newValue = null;
                    if (!oldValue) {
                        newValue = 'asc';
                    }
                    if (oldValue === 'asc') {
                        newValue = 'desc';
                    }
                    setActiveOptions({
                        ...activeOptions,
                        [key]: newValue,
                    });
                }}>
                {option.title} {renderValue(activeOptions[key])}
            </Button>
        );
        return (
            <div
                style={{
                    marginTop: 10,
                    marginBottom: 10,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                <div>
                    Sortierung:
                    <ButtonGroup style={{ marginLeft: 5 }}>
                        {Object.entries(sortOptions).map(renderSortOption)}
                    </ButtonGroup>
                </div>
            </div>
        );
    }
}
export default SortSettings;
