import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Button,
    Modal,
    Icon,
    List,
    Tooltip,
    Divider,
    Switch,
    Badge,
    Menu,
    Dropdown,
    Popconfirm,
    Checkbox,
    Tag,
} from 'antd';
import PropTypes from 'prop-types';
import {
    sortJobs,
    addToArray,
    removeJob,
    changeArrayElement,
    setJobCurrentPage,
} from '../../core/redux/actions';
import buttonWithTooltip from '../../decorators/buttonWithTooltip';
import { UniNowList, ActivityModal } from '..';
import { ReasonModal } from './components';
import { SortSettings } from '../AdvertList/components';
import EditMultipleDropdown from './components/EditMultipleDropdown';
import PlanAlert from '../PlanAlert';

const { confirm } = Modal;

const SORT_OPTIONS = {
    visibility: {
        sortFunc: (a, b) => {
            if (a.visible && !b.visible) {
                return -1;
            }
            if (!a.visible && b.visible) {
                return 1;
            }
            return 0;
        },
        title: 'Sichtbarkeit',
    },
    priority: {
        sortFunc: (a, b) => {
            const { priority: priorityA = 0 } = a;
            const { priority: priorityB = 0 } = b;
            return priorityA - priorityB;
        },
        title: 'Priorität',
    },
    creationDate: {
        sortFunc: (a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix(),
        title: 'Erstellungsdatum',
    },
    changedDate: {
        sortFunc: (a, b) => moment(b.updatedAt).unix() - moment(a.updatedAt).unix(),
        title: 'Änderungsdatum',
    },
    alphabetically: {
        sortFunc: (a, b) => {
            const { title: titleA = '' } = a;
            const { title: titleB = '' } = b;
            return titleA.toUpperCase().localeCompare(titleB.toUpperCase());
        },
        title: 'Alphabetisch',
    },
};

class JobList extends Component {
    state = {
        reasonModalVisible: false,
        selectedJob: {},
        selectedJobs: [],
        activityModalId: null,
    };

    handleUpdateJob =
        (jobToUpdate) =>
        async (attributeValuePairs = []) => {
            const { jobs } = this.props;
            const { updateJob } = this.context;

            const updatedJob = {
                ...jobToUpdate,
            };

            attributeValuePairs.forEach(
                ([attributeName, value]) => (updatedJob[attributeName] = value),
            );

            const indexOfJob = jobs.findIndex((job) => job.uuid === updatedJob.uuid);

            return updateJob(updatedJob, indexOfJob);
        };

    toggleJobVisible = async (job, visible, isValidJob, reason = '') => {
        const { setMessage, request, role } = this.context;
        const { title, uuid } = job;

        this.setState({ [`loading${uuid}`]: true });

        const readonly = role === 'READONLY';
        if (readonly) {
            return;
        }

        if (visible && !isValidJob) {
            confirm({
                iconType: 'warning',
                onOk: () => this.editJob(uuid),
                onCancel: () => this.setState({ [`loading${uuid}`]: false }),
                title: 'Warnung',
                content:
                    'Sie versuchen Ihre Stellenanzeige sichtbar zu schalten. Bitte geben Sie davor sämtliche erforderlichen Daten an. Wollen Sie dies jetzt tun?',
                okText: 'Stellenanzeige bearbeiten',
                cancelText: 'Abbrechen',
            });
            return;
        }

        const { error, body: toggleResponse } = await request('recruiting/job/toggle', {
            job: uuid,
            visible,
            reason,
        });

        this.setState({ [`loading${uuid}`]: false });

        const action = `${visible ? '' : 'un'}sichtbar`;

        if (error) {
            setMessage(
                <span>
                    Beim <b>{action}</b> schalten der Stellenanzeige <b>{title}</b> ist ein Fehler
                    aufgetreten. Bitte versuchen Sie es erneut.
                </span>,
                'error',
            );
        } else {
            const { jobs } = this.props;
            const index = jobs.findIndex((job) => job.uuid === uuid);

            this.props.changeArrayElement(
                'joblist',
                index,
                { ...job, visible: toggleResponse.visible, priority: toggleResponse.priority },
                'jobs',
            );

            setMessage(
                <span>
                    Die Stellenanzeige <b>{title}</b> wurde erfolgreich <b>{action}</b> geschaltet.
                </span>,
                'success',
            );
        }
    };

    toggleJobArchived = (job, reason = undefined) => {
        const { jobs, changeArrayElement } = this.props;
        const { setMessage, request, role } = this.context;
        const { uuid, title, archived } = job;

        const index = jobs.findIndex((job) => job.uuid === uuid);

        const readonly = role === 'READONLY';

        if (!archived) {
            const update = (reason = undefined) =>
                new Promise(async (resolve) => {
                    const { body } = !readonly
                        ? await request('recruiting/job/toggle', {
                              job: uuid,
                              archived: true,

                              reason,
                          })
                        : { body: 200 };

                    if (body) {
                        changeArrayElement('joblist', index, { ...job, archived: true }, 'jobs');

                        setMessage(
                            <span>
                                Die Stellenanzeige <b>{title}</b> wurde erfolgreich{' '}
                                <b>archiviert</b>
                            </span>,
                            'success',
                        );
                    } else {
                        setMessage(
                            <span>
                                Beim <b>archivieren</b> der Stellenanzeige <b>{title}</b> ist ein
                                Fehler aufgetreten. Bitte versuchen Sie es erneut.
                            </span>,
                            'error',
                        );
                    }
                    resolve();
                });
            if (reason) {
                update(reason);
            } else {
                Modal.confirm({
                    title: 'Information',
                    content: (
                        <div>
                            Sind Sie sich sicher, dass Sie diese Stellenanzeige archivieren möchten?
                            Archivierte Stellenanzeigen werden nicht mehr im Karriereportal
                            angezeigt und können nachträglich nicht editiert werden. Beachten Sie,
                            dass dabei aufgrund von DSGVO-Gründen alle Bewerberdaten anonymisiert
                            werden müssen.
                            <div style={{ marginBottom: '1em' }}></div>
                            <p>
                                Sie finden Ihre archivierten Stellenanzeigen unter dem Reiter
                                "Archiviert", wo Sie die Stellenanzeige jederzeit wieder aktiv
                                schalten können.
                            </p>
                        </div>
                    ),
                    okText: 'Archivieren',
                    okType: 'danger',
                    cancelText: 'Abbrechen',
                    onOk: () => update(),
                });
            }
        } else if (this.numOfActiveJobs !== this.props.jobContingent) {
            confirm({
                title: 'Information',
                content:
                    'Sind Sie sich sicher, dass Sie diese Stellenanzeige wieder aktiv schalten möchten? Aktiv geschaltete Stellenanzeigen können wieder editiert werden, sind jedoch zunächst unsichtbar geschaltet.',
                okText: 'Aktiv schalten',
                okType: 'danger',
                cancelText: 'Abbrechen',
                onOk: () =>
                    new Promise(async (resolve) => {
                        const { body } = !readonly
                            ? await request('recruiting/job/toggle', {
                                  job: uuid,
                                  archived: false,
                                  reason,
                              })
                            : { body: true };

                        if (body) {
                            changeArrayElement(
                                'joblist',
                                index,
                                { ...job, archived: false, visible: false },
                                'jobs',
                            );

                            setMessage(
                                <span>
                                    Die Stellenanzeige <b>{title}</b> wurde erfolgreich{' '}
                                    <b>aktiv geschaltet</b>
                                </span>,
                                'success',
                            );
                        } else {
                            setMessage(
                                <span>
                                    Beim <b>aktiv schalten</b> der Stellenanzeige <b>{title}</b> ist
                                    ein Fehler aufgetreten. Bitte versuchen Sie es erneut.
                                </span>,
                                'error',
                            );
                        }
                        resolve();
                    }),
            });
        } else {
            Modal.info({
                title: 'Achtung',
                content: `Ihr Kontingent an aktiven Stellenanzeigen (${this.props.jobContingent}) ist bereits erreicht. Bitte archivieren oder deaktivieren Sie eine Stellenanzeige oder erhöhen Sie Ihr Kontingent, bevor Sie diese Stellenanzeige aktiv schalten.`,
            });
        }
    };

    handleArchive = (job = {}, reason = null) => {
        const { visible } = job;

        if (visible && reason === null) {
            this.setState({
                reasonModalVisible: true,
                selectedJob: job,
            });
        } else {
            this.toggleJobArchived(job, reason);
        }
    };

    deleteJob = async (job) => {
        const { removeJob } = this.props;
        const { request } = this.context;

        removeJob(job.uuid);

        await request('recruiting/job/remove', {
            job: job.uuid,
        });
    };

    duplicateJob = (uuid = '') =>
        this.props.history.push(`${this.props.match.url}/duplizieren/${uuid}`);

    editJob = (uuid = '') => this.props.history.push(`/stellenanzeigen/editieren/${uuid}`);

    showStatistics = (detail) =>
        this.props.history.push(`statistiken/stellenanzeigen${detail ? `?jobId=${detail}` : ''}`);

    showApplicants = (uuid = '') =>
        this.props.history.push(`${this.props.match.url}/bewerbungen/${uuid}`);

    showActivityModal = (jobId) => this.setState({ activityModalId: jobId });

    hideActivityModal = () => this.setState({ activityModalId: null });

    render() {
        const { role } = this.context;
        const { archived, jobs, jobContingent, plan, jobsLoading } = this.props;
        const { activityModalId, selectedJob = {}, reasonModalVisible = false } = this.state;

        const { role: userRole } = this.context;
        const jobsToRender = jobs
            .filter((job) => job.archived === archived)
            .sort((a, b) => {
                for (const [key, sortOption] of Object.entries(SORT_OPTIONS)) {
                    const sortSetting = this.props.sortOptions[key];
                    if (sortSetting) {
                        const res = sortOption.sortFunc(a, b);
                        if (res !== 0) {
                            return sortSetting === 'desc' ? res : res * -1;
                        }
                    }
                }
                return 0;
            });

        this.numOfActiveJobs = 0;

        jobs.forEach((job) => {
            !job.archived && job.visible && this.numOfActiveJobs++;
        });
        let statusBar = [];

        const isADMIN = ['SUPERADMIN', 'ADMIN', 'CUSTOMER_SUPPORT'].includes(
            userRole.toUpperCase(),
        );

        const readonly = role === 'READONLY';

        if (!archived) {
            if (jobContingent) {
                statusBar = [
                    <Button
                        type="primary"
                        size="large"
                        icon="plus"
                        key="add"
                        onClick={() => {
                            this.editJob();
                        }}>
                        Stellenanzeige hinzufügen
                    </Button>,
                ];
                if (jobContingent >= 0 || isADMIN) {
                    statusBar.push(
                        <div
                            key="contingent"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '0px 15px',
                                height: '40px',
                                fontSize: '16px',
                                border: '1px solid #d9d9d9',
                                borderRadius: '4px',
                            }}>
                            <Tooltip
                                title={
                                    jobContingent >= 0
                                        ? `Sie haben ${this.numOfActiveJobs} aktive Stellenanzeigen, bei einem Kontingent von ${jobContingent}.`
                                        : 'Sie haben ein unbegrenztes Kontingent an Stellenanzeigen.'
                                }>
                                {jobContingent >= 0 ? (
                                    <span>
                                        {this.numOfActiveJobs}/{jobContingent}{' '}
                                        <Icon type="solution" />
                                    </span>
                                ) : (
                                    <span>
                                        unbegrenzt <Icon type="solution" />
                                    </span>
                                )}
                            </Tooltip>
                        </div>,
                    );
                }
            }
        }
        const listOptions = (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {isADMIN && !archived && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                            style={{ margin: 9 }}
                            onClick={() => {
                                if (this.state.selectedJobs.length === jobsToRender.length) {
                                    this.setState({ selectedJobs: [] });
                                } else {
                                    this.setState({ selectedJobs: jobsToRender });
                                }
                            }}
                            checked={this.state.selectedJobs.length === jobsToRender.length}
                        />
                        {this.state.selectedJobs.length > 0 && (
                            <EditMultipleDropdown
                                selectedJobs={this.state.selectedJobs}
                                archiveJob={this.toggleJobArchived}
                                toggleJobVisible={this.toggleJobVisible}
                                onFinish={() => this.setState({ selectedJobs: [] })}
                            />
                        )}
                    </div>
                )}
                <SortSettings
                    activeOptions={this.props.sortOptions}
                    sortOptions={SORT_OPTIONS}
                    setActiveOptions={(sortOptions) => this.props.setSortOptions(sortOptions)}
                />
            </div>
        );
        return (
            <div>
                <ActivityModal
                    visible={!!activityModalId}
                    id={activityModalId}
                    type="job"
                    onClose={this.hideActivityModal}
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '10px',
                    }}>
                    {statusBar}
                </div>
                <ReasonModal
                    key={selectedJob.uuid}
                    defaultVisible={reasonModalVisible}
                    title="Information"
                    onSend={(reason) =>
                        this.handleArchive(selectedJob, reason || 'Kein Grund angegeben')
                    }
                    lastReason={selectedJob.lastReason}
                />
                {jobContingent >= 0 && this.numOfActiveJobs >= jobContingent && (
                    <PlanAlert
                        message={
                            <span>
                                Ihr aktuelles Paket erlaubt nur{' '}
                                {jobContingent === 1
                                    ? 'eine Stellenanzeige'
                                    : `${jobContingent} Stellenanzeigen`}
                                .
                            </span>
                        }
                    />
                )}
                <UniNowList
                    noItemsText="Es wurden noch keine Stellenanzeigen hinzugefügt."
                    itemLayout="horizontal"
                    loading={jobsLoading}
                    dataSource={jobsToRender}
                    pagination={{
                        current: this.props.currentPage,
                        pageSize: 10,
                        onChange: (page) => {
                            this.props.setJobCurrentPage(page);
                        },
                    }}
                    optionsElement={listOptions}
                    searchFilter={(job, searchInput) =>
                        (job.title && job.title.toLowerCase().includes(searchInput)) ||
                        job.uuid.includes(searchInput)
                    }
                    renderItem={(job, index) => {
                        const {
                            archived: jobIsArchived,
                            visible,
                            uuid,
                            title,
                            type,
                            location,
                            summary,
                            studies,
                            link,
                            mailing = [],
                            addresses = [],
                            lastReason = undefined,
                            applicants = [],
                            endOfTerm,
                            priority,
                            imported,
                        } = job;

                        const isValidJob =
                            title &&
                            type &&
                            (location || addresses.length > 0) &&
                            summary &&
                            studies &&
                            (link || mailing);

                        const visibleChangeDisabled =
                            jobContingent !== -1 &&
                            !visible &&
                            this.numOfActiveJobs >= jobContingent;

                        let actions = [];
                        if (priority > 0) {
                            actions = [
                                <span>{['Niedrige', 'Mittlere', 'Hohe'][priority]} Priorität</span>,
                            ];
                        }

                        if (!isValidJob) {
                            actions = [
                                ...actions,
                                <Tooltip title="Die Stellenanzeige ist unvollständig ausgefüllt.">
                                    <Icon style={{ color: 'orange' }} type="warning" />
                                </Tooltip>,
                            ];
                        }

                        if (jobIsArchived) {
                            actions = [
                                ...actions,
                                buttonWithTooltip('Stellenanzeige aktiv schalten')(
                                    <Button
                                        onClick={() => this.toggleJobArchived(job)}
                                        shape="circle"
                                        icon="rollback"
                                    />,
                                ),
                                buttonWithTooltip('Stellenanzeige löschen')(
                                    <Popconfirm
                                        title="Stellenanzeige wirklich löschen?"
                                        onConfirm={() => this.deleteJob(job)}>
                                        <Button shape="circle" icon="delete" />
                                    </Popconfirm>,
                                ),
                            ];
                        } else {
                            const menu = (
                                <Menu>
                                    <Menu.Item onClick={() => this.showStatistics(job.uuid)}>
                                        <Icon type="area-chart" />
                                        Statistiken
                                    </Menu.Item>
                                    <Menu.Item
                                        onClick={() => {
                                            if (this.numOfActiveJobs !== jobContingent) {
                                                this.duplicateJob(uuid);
                                            } else {
                                                Modal.info({
                                                    title: 'Achtung',
                                                    content:
                                                        'Sie haben Ihr Kontingent an aktiven Stellenanzeigen erreicht. Um weitere Stellenanzeigen hinzufügen zu können, archivieren Sie eine Stellenanzeige oder erhöhen Sie Ihr Kontingent.',
                                                    onOk() {},
                                                });
                                            }
                                        }}>
                                        <Icon type="copy" />
                                        Duplizieren
                                    </Menu.Item>
                                    <Menu.Item onClick={() => this.handleArchive(job)}>
                                        <Icon type="folder-add" />
                                        Archivieren
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Popconfirm
                                            title="Stellenanzeige wirklich löschen?"
                                            onConfirm={() => this.deleteJob(job)}>
                                            <div style={{ width: '100%' }}>
                                                <Icon type="delete" style={{ paddingRight: 5 }} />
                                                Löschen
                                            </div>
                                        </Popconfirm>
                                    </Menu.Item>
                                    {isADMIN && (
                                        <Menu.ItemGroup title="Admin">
                                            <Menu.Item
                                                onClick={() => this.showActivityModal(job.uuid)}>
                                                <Icon type="bars" />
                                                Aktivitäten
                                            </Menu.Item>
                                        </Menu.ItemGroup>
                                    )}
                                </Menu>
                            );

                            actions = [
                                ...actions,
                                role.toUpperCase() === 'TRIAL' ? null : (
                                    <ReasonModal
                                        onSend={(reason = '') =>
                                            this.toggleJobVisible(job, !visible, isValidJob, reason)
                                        }
                                        lastReason={lastReason}
                                        button={(triggerModal) => (
                                            <Tooltip
                                                title={
                                                    visibleChangeDisabled && !readonly ? (
                                                        <span>
                                                            Sie haben Ihr Kontingent an aktiven
                                                            Stellenanzeigen erreicht. Um weitere
                                                            Stellenanzeigen hinzufügen zu können,
                                                            archivieren oder deaktivieren Sie eine
                                                            Stellenanzeige oder erhöhen Sie Ihr
                                                            Kontingent.
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            Schalten Sie Ihre Stellenanzeige
                                                            sichtbar (
                                                            <Icon type="eye" theme="filled" />) oder
                                                            unsichtbar (
                                                            <Icon type="eye" theme="outlined" />
                                                            ). Unsichtbare Stellenanzeigen werden im
                                                            Karriereportal nicht angezeigt.
                                                        </span>
                                                    )
                                                }
                                                placement="left">
                                                <div>
                                                    <Switch
                                                        disabled={visibleChangeDisabled}
                                                        loading={this.state[`loading${job.uuid}`]}
                                                        checkedChildren={
                                                            <Icon type="eye" theme="filled" />
                                                        }
                                                        unCheckedChildren={
                                                            <Icon type="eye" theme="outlined" />
                                                        }
                                                        checked={visible}
                                                        onChange={() => {
                                                            if (visible) {
                                                                triggerModal();
                                                            } else {
                                                                this.toggleJobVisible(
                                                                    job,
                                                                    !visible,
                                                                    isValidJob,
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        )}
                                    />
                                ),
                                buttonWithTooltip('Editieren')(
                                    <Button
                                        shape="circle"
                                        icon="edit"
                                        onClick={() => this.editJob(uuid)}
                                    />,
                                ),
                                <Badge
                                    count={
                                        applicants.filter(
                                            (applicant) => applicant.status === 'OPEN',
                                        ).length
                                    }>
                                    {buttonWithTooltip('Bewerbungen')(
                                        <Button
                                            shape="circle"
                                            icon="solution"
                                            onClick={() => this.showApplicants(uuid)}
                                        />,
                                    )}
                                </Badge>,
                                buttonWithTooltip('Weitere Optionen')(
                                    <Dropdown overlay={menu}>
                                        <Button shape="circle" icon="menu" />
                                    </Dropdown>,
                                ),
                            ];
                        }

                        const description = [];
                        if (job.type) {
                            description.push(job.type);
                        }

                        if (job.addresses?.length > 0) {
                            description.push(
                                `${job.addresses
                                    .slice(0, 3)
                                    .map((address) => address.name?.trim() || address.city)
                                    .join(', ')}${job.addresses.length > 3 ? '...' : ''}`,
                            );
                        }
                        if (endOfTerm && job.visible) {
                            description.push(`endet am ${moment(endOfTerm).format('DD.MM.YYYY')}`);
                        }

                        if (isADMIN && job.tags && job.tags.length) {
                            description.push(`Tags: ${job.tags.join(', ')}`);
                        }

                        return (
                            <List.Item key={uuid} actions={[...actions]}>
                                {isADMIN && !archived && (
                                    <Checkbox
                                        style={{ marginRight: 15, marginLeft: 8 }}
                                        checked={this.state.selectedJobs.find(
                                            (j) => j.uuid === uuid,
                                        )}
                                        onChange={(e) =>
                                            this.setState({
                                                selectedJobs: e.target.checked
                                                    ? [...this.state.selectedJobs, job]
                                                    : this.state.selectedJobs.filter(
                                                          (j) => j.uuid !== uuid,
                                                      ),
                                            })
                                        }
                                    />
                                )}
                                <List.Item.Meta
                                    title={
                                        <>
                                            <a onClick={() => this.editJob(uuid)}>{job.title}</a>
                                            {imported && (
                                                <Tag
                                                    color="#aaaaaa"
                                                    style={{
                                                        borderRadius: '10px',
                                                        marginLeft: '10px',
                                                    }}>
                                                    importiert
                                                </Tag>
                                            )}
                                        </>
                                    }
                                    description={description.join(' | ')}
                                />

                                <div />
                            </List.Item>
                        );
                    }}
                />
            </div>
        );
    }
}

JobList.contextTypes = {
    updateJob: PropTypes.func.isRequired,
    removeJob: PropTypes.func.isRequired,
    updateJobContingent: PropTypes.func.isRequired,
    setMessage: PropTypes.func.isRequired,
    role: PropTypes.string,
    sortOptions: PropTypes.shape().isRequired,
    setSortOptions: PropTypes.func.isRequired,
    request: PropTypes.func,
    jobsLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ jobs, company, filter, currentPageOfLists }) => ({
    jobs: jobs.joblist.filter((job) => job.uuid),
    jobsLoading: jobs.jobsLoading,
    companyIsVisible: company.profile.visible,
    jobContingent: company.plan.numJobs ?? 0,
    sortOptions: filter.jobSortOptions || {},
    plan: company.plan,
    currentPage: currentPageOfLists.jobListCurrentPage,
});

const mapDispatchToProps = (dispatch) => ({
    addToArray: (attributeName, element, reducer) =>
        dispatch(addToArray(attributeName, element, reducer)),
    removeJob: (jodId) => dispatch(removeJob(jodId)),
    changeArrayElement: (attributeName, index, updatedElement, reducer) =>
        dispatch(changeArrayElement(attributeName, index, updatedElement, reducer)),
    setSortOptions: (sortOptions) => dispatch(sortJobs(sortOptions)),
    setJobCurrentPage: (page) => dispatch(setJobCurrentPage(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobList);
