import React, { Component } from 'react';
import { Button } from 'antd';

export default class GenericList extends Component {
    add = () => {
        this.props.onAddItem();
    };

    remove = (targetKey) => {
        this.props.onRemoveItem(targetKey);
    };

    render() {
        return (
            <div>
                <Button onClick={() => this.add()}>{this.props.addItemText}</Button>
                {this.props.items}
            </div>
        );
    }
}
