import imageConfig from '../constants/imageConfig';

function getMeta(url) {
    return new Promise((resolve) => {
        const img = new Image();
        img.addEventListener('load', function () {
            resolve({ width: this.naturalWidth, height: this.naturalHeight });
        });
        img.src = url;
    });
}

const checkQuality = async (image, optimal, error, enableZoomOut) => {
    if (!image || !image.payload) {
        return null;
    }
    if (!image.payload) {
        return error;
    }

    const {
        raw,
        payload: { scale },
    } = image;
    const { width, height } = await getMeta(raw);
    if (scale < 1 && !enableZoomOut) {
        return error;
    }
    const widthQuality = (width * 100) / (optimal.width * scale);
    const heightQuality = (height * 100) / (optimal.height * scale);

    const quality = Math.min(100, widthQuality, heightQuality);
    return quality < imageConfig.minimumImageQuality ? error : null;
};

export default async (profile) => {
    const { logo, gallery, awards, ratings } = profile;
    const errors = [];
    if (logo) {
        const logoError = await checkQuality(logo, imageConfig.logoOptimalSize, 'Logo', true);
        if (logoError) {
            errors.push(logoError);
        }
    }

    if (gallery) {
        const galleryErrors = await Promise.all(
            gallery.map((image, index) =>
                checkQuality(
                    image,
                    imageConfig.galleryOptimalSize,
                    `Galeriebild (Nummer ${index+1})`,
                    true,
                ),
            ),
        );
        galleryErrors.forEach((error) => {
            if (error) {
                errors.push(error);
            }
        });
    }
    if (awards) {
        const awardsErrors = await Promise.all(
            awards.map(({ image, title }) =>
                checkQuality(image, imageConfig.awardsOptimalSize, `Auszeichnungen (${title})`, true),
            ),
        );
        awardsErrors.forEach((error) => {
            if (error) {
                errors.push(error);
            }
        });
    }
    if (awards) {
        const awardsErrors = await Promise.all(
            ratings.map(({ image, title }) =>
                checkQuality(image, imageConfig.awardsOptimalSize, `Insights (${title})`, true),
            ),
        );
        awardsErrors.forEach((error) => {
            if (error) {
                errors.push(error);
            }
        });
    }

    return errors.length > 0 && `Unzureichende Qualität für folgende Bilder: ${errors.join(', ')}`;
};
