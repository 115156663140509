import { SESSIONDATA_LOAD_SUCCESS, SET_AD_CURRENT_PAGE, SET_JOB_CURRENT_PAGE } from '../actions';

function currentPageOfLists(state = { adListCurrentPage: 1, jobListCurrentPage: 1 }, action) {
    switch (action.type) {
        case SET_AD_CURRENT_PAGE: {
            return { ...state, adListCurrentPage: action.payload };
        }

        case SET_JOB_CURRENT_PAGE: {
            return { ...state, jobListCurrentPage: action.payload };
        }
        case SESSIONDATA_LOAD_SUCCESS: {
            return {
                adListCurrentPage: 1,
                jobListCurrentPage: 1,
            };
        }

        default:
            return state;
    }
}
export default currentPageOfLists;
