import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

import ActivityTimeline from './components/ActivityTimeline';

const ActivityModal = ({ onClose, visible, id, type = 'job' }, { request }) => {
    const [loading, setLoading] = useState(true);
    const [activities, setActivities] = useState([]);

    useEffect(() => {
        if (visible) {
            const loadActivities = async () => {
                const { body } = await request(`recruiting/${type}/activities`, {
                    id,
                });

                setActivities(body);
                setLoading(false);
            };

            setLoading(true);
            loadActivities();
        }
    }, [visible, id]);

    return (
        <Modal
            visible={visible}
            title="Aktivitäten"
            footer={null}
            onCancel={onClose}
            onClose={onClose}>
            <div style={{ paddingTop: 5, paddingLeft: 5, maxHeight: 400, overflowY: 'scroll' }}>
                <ActivityTimeline loading={loading} activities={activities} />
            </div>
        </Modal>
    );
};

ActivityModal.contextTypes = {
    request: PropTypes.func,
};

export default ActivityModal;
