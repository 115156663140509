import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { uniqBy } from 'lodash';

import { Row, Col, Icon, Dropdown, Menu, Input, Modal, Button } from 'antd';

import { LocationManager } from '..';

import AddressCard, { CARD_WIDTH } from '../AddressCard';

import './style.css';

function filterByLabel(label = null, phrases = null) {
    if (!label) {
        return false;
    }

    if (!phrases) {
        return true;
    }

    const searchString = label.toLowerCase();

    return phrases.every((phrase) => searchString.includes(phrase));
}

class AddressManager extends Component {
    state = {
        filter: '',
        dropdownVisible: false,
        modalVisible: false,
        currentSlide: 0,
        newLocation: null,
        selectedLocation: null,
        slideSize: Math.floor(Math.min(Math.max((window.innerWidth * 0.6) / CARD_WIDTH, 2), 4)),
    };

    handleResize = () => {
        const { slideSize } = this.state;

        const nextSize = Math.floor(
            Math.min(Math.max((window.innerWidth * 0.6) / CARD_WIDTH, 2), 4),
        );

        if (nextSize !== slideSize) {
            this.setState({ slideSize: nextSize });
        }
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleInputChange = ({ target }) => {
        this.setState({
            filter: target.value,
            dropdownVisible: true,
            currentSlide: 0,
        });
    };

    openModal = () => {
        this.setState({
            modalVisible: true,
            dropdownVisible: false,
        });
    };

    handleMenuClick = ({ key }) => {
        const {
            selectedAddresses,
            addressOptions,
            onSelect,
            onMultiSelect,
            onDeselect,
            onMultiDeselect,
        } = this.props;

        switch (key) {
            case 'GLOBAL:ADD_ADDRESS':
                this.setState({
                    modalVisible: true,
                    dropdownVisible: false,
                });
                break;

            case 'GLOBAL:ADD_ALL_ADDRESSES':
                const additionalAddresses = addressOptions.filter(
                    (option) =>
                        !selectedAddresses.some(
                            ({ _id = 'NULL', label }) =>
                                option._id === _id || option.label === label,
                        ),
                );

                this.setState({
                    dropdownVisible: false,
                });

                onMultiSelect(additionalAddresses);
                break;

            case 'GLOBAL:REMOVE_ALL_ADDRESSES':
                this.setState({
                    dropdownVisible: false,
                });

                onMultiDeselect(addressOptions.map(({ _id, label }) => _id || label));
                break;

            default:
                if (selectedAddresses.some(({ _id, label }) => _id === key || label === key)) {
                    onDeselect(key);
                } else {
                    const address = addressOptions.find(({ _id }) => _id === key);

                    onSelect(address);
                }
                this.setState({ dropdownVisible: true });
                break;
        }
    };

    hideModal = () => {
        this.setState({
            modalVisible: false,
            newLocation: null,
            selectedLocation: null,
        });
    };

    saveLocation = () => {
        const { onSelect, onEdit, selectedAddresses } = this.props;
        const { newLocation, selectedLocation } = this.state;

        if (newLocation && newLocation.geoPoint) {
            if (selectedLocation) {
                const addressIndex = selectedAddresses.findIndex(
                    ({ _id = 'NULL', label }) =>
                        selectedLocation._id === _id || selectedLocation.label === label,
                );
                onEdit(addressIndex, newLocation);
            } else {
                onSelect(newLocation);
            }

            this.setState({
                filter: '',
                modalVisible: false,
                newLocation: null,
                selectedLocation: null,
            });
        }
    };

    handleLocationSelect = (location) => {
        this.setState({ newLocation: location });
    };

    handleVisibleChange = (flag) => {
        this.setState({ dropdownVisible: flag });
    };

    nextSlide = () => {
        this.setState((state) => ({ currentSlide: state.currentSlide + 1 }));
    };

    prevSlide = () => {
        this.setState((state) => ({
            currentSlide: Math.max(state.currentSlide - 1, 0),
        }));
    };

    onCardClick = (address) => {
        this.setState({
            selectedLocation: address,
            modalVisible: true,
            dropdownVisible: false,
        });
    };

    onCardRemove = ({ _id, label }) => {
        this.props.onRemove(_id || label);
    };

    render() {
        const { selectedAddresses, addressOptions } = this.props;
        const {
            dropdownVisible,
            filter,
            currentSlide,
            modalVisible,
            newLocation,
            selectedLocation,
            slideSize,
        } = this.state;

        const filterPhrases = filter
            ? filter.split(/[\s+]+/gim).map((phrase) => phrase.toLowerCase())
            : null;

        const addresses = selectedAddresses.filter(({ label }) =>
            filterByLabel(label, filterPhrases),
        );

        const options = uniqBy(
            [...addressOptions, ...addresses],
            ({ _id, label }) => _id || label,
        ).filter(({ label }) => filterByLabel(label, filterPhrases));

        const slideStart = currentSlide * slideSize;
        const addressSlide = addresses.slice(slideStart, slideStart + slideSize);

        return (
            <div id="AddressManager">
                <Modal
                    title="Bearbeiten Sie ihren Standort"
                    visible={modalVisible}
                    maskClosable={false}
                    onCancel={this.hideModal}
                    footer={[
                        <Button key="back" size="large" onClick={this.hideModal}>
                            Abbrechen
                        </Button>,
                        <Button
                            disabled={!newLocation?.geoPoint}
                            key="submit"
                            type="primary"
                            size="large"
                            onClick={this.saveLocation}>
                            Standort speichern
                        </Button>,
                    ]}
                    style={{ top: '10%' }}
                    destroyOnClose>
                    <LocationManager
                        onSuggestSelect={this.handleLocationSelect}
                        location={selectedLocation || newLocation || { label: filter }}
                    />
                </Modal>
                <Row>
                    <Col span={12} style={{ height: 40 }} />
                    <Col span={12}>
                        {addresses.length > slideSize && (
                            <div style={{ textAlign: 'right' }}>
                                <Icon
                                    type="left"
                                    onClick={currentSlide > 0 ? this.prevSlide : null}
                                    style={
                                        currentSlide > 0
                                            ? { cursor: 'pointer', color: '#f21850' }
                                            : { cursor: 'default', color: '#8f8f8f' }
                                    }
                                />
                                <span>
                                    {currentSlide + 1} / {Math.ceil(addresses.length / slideSize)}
                                </span>
                                <Icon
                                    type="right"
                                    onClick={
                                        currentSlide < Math.ceil(addresses.length / slideSize - 1)
                                            ? this.nextSlide
                                            : null
                                    }
                                    style={
                                        currentSlide < Math.ceil(addresses.length / slideSize - 1)
                                            ? { cursor: 'pointer', color: '#f21850' }
                                            : { cursor: 'default', color: '#8f8f8f' }
                                    }
                                />
                            </div>
                        )}
                    </Col>
                </Row>

                <Row>
                    {addresses.length > 0 ? (
                        addressSlide.map((address, addressIndex) => (
                            <Col span={24 / slideSize} key={addressIndex}>
                                <AddressCard
                                    address={address}
                                    onClick={this.onCardClick}
                                    onClose={this.onCardRemove}
                                />
                            </Col>
                        ))
                    ) : (
                        <Col span={24 / slideSize}>
                            <AddressCard
                                title="Keine Standorte"
                                address={{
                                    city: 'Suchen Sie in der Suchleiste unten nach Ihren Standorten',
                                }}
                            />
                        </Col>
                    )}
                </Row>

                <Row style={{ padding: '15px 0px' }}>
                    <Dropdown
                        trigger={['click']}
                        onVisibleChange={this.handleVisibleChange}
                        visible={dropdownVisible}
                        getPopupContainer={() => document.getElementById('AddressManager')}
                        overlay={
                            <Menu
                                selectedKeys={addresses.map(({ _id, label }) => _id || label)}
                                onClick={this.handleMenuClick}
                                style={{ maxHeight: 300, overflowY: 'auto' }}>
                                <Menu.Item key="GLOBAL:ADD_ADDRESS">
                                    <Icon type="plus" style={{ fontSize: 12 }} /> neuen Standort
                                    hinzufügen
                                </Menu.Item>
                                <Menu.Item key="GLOBAL:ADD_ALL_ADDRESSES">
                                    <Icon type="plus" style={{ fontSize: 12 }} /> alle
                                    Unternehmensstandorte hinzufügen
                                </Menu.Item>
                                <Menu.Item key="GLOBAL:REMOVE_ALL_ADDRESSES">
                                    <Icon type="minus" style={{ fontSize: 12 }} /> alle
                                    Unternehmensstandorte entfernen
                                </Menu.Item>
                                {options.length > 0 && <Menu.Divider />}
                                {options.map(({ _id, label }) => (
                                    <Menu.Item key={_id || label}>{label}</Menu.Item>
                                ))}
                            </Menu>
                        }>
                        <Input
                            onChange={this.handleInputChange}
                            value={filter}
                            placeholder="Suchen Sie hier nach Standorten"
                        />
                    </Dropdown>
                </Row>
            </div>
        );
    }
}

AddressManager.propTypes = {
    selectedAddresses: PropTypes.array.isRequired,
    addressOptions: PropTypes.array.isRequired,
    onRemove: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onMultiSelect: PropTypes.func.isRequired,
    onDeselect: PropTypes.func.isRequired,
    onMultiDeselect: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
};

export default AddressManager;
