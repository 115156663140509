import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function MarkdownLink(props) {
    return (
        <Link
            to="/help/markdown"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#f21850' }}>
            Hilfe zur Formatierung
        </Link>
    );
}

MarkdownLink.propTypes = {};

export default MarkdownLink;
