import React, { createContext } from 'react';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { notification, Button } from 'antd';
import { request as apiRequest, upload as apiUpload } from '../../core/api/api';
import { convertImageToBlob } from '../../core/helper';

export const RequestContext = createContext();
function RequestProvider(props) {
    const { authToken, profileId } = props;
    const checkServerVersion = (response) => {
        const clientVersion = process.env.REACT_APP_VERSION;
        const serverVersion = response.version;
        const versionMismatch = clientVersion && serverVersion && clientVersion !== serverVersion;
        if (versionMismatch) {
            console.log('versions', clientVersion, serverVersion);
            const btn = (
                <Button type="primary" onClick={() => window.location.reload()}>
                    Aktualisieren
                </Button>
            );
            notification.info({
                message: 'Neue Version verfügbar',
                description:
                    'Eine neue Version des Recruiting Editors ist verfügbar. Bitte aktualisieren Sie die\n' +
                    '            Seite, um Zugriff auf die neuesten Funktionen zu erhalten',
                btn,
                key: 'versionMismatchBanner',
                duration: 0,
            });
        }
    };
    const request = async (url, body = {}, token = null) => {
        let transaction = Sentry.getCurrentHub().getScope().getTransaction();
        const newTransAction = !transaction;
        if (newTransAction) {
            transaction = Sentry.startTransaction({ name: `Request: ${url}` });
            Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(transaction));
        }
        const headers = {
            authorization: token || authToken,
            'x-uninow-recruiting-profile': profileId || '',
        };

        const response = await apiRequest(url, body, headers);
        checkServerVersion(response);
        if (newTransAction) {
            transaction.finish();
        }
        return response;
    };

    const upload = async (url, files, body = {}, progressCallback = null) => {
        let transaction = Sentry.getCurrentHub().getScope().getTransaction();
        const newTransAction = !transaction;
        if (newTransAction) {
            transaction = Sentry.startTransaction({ name: `Upload: ${url}` });
            Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(transaction));
        }
        const headers = {
            authorization: authToken,
            'x-uninow-recruiting-profile': profileId || '',
        };

        const response = await apiUpload(url, body, headers, files, progressCallback);
        checkServerVersion(response);
        if (newTransAction) {
            transaction.finish();
        }
        return response;
    };
    const uploadAsset = async (asset, type) => {
        let image = asset;
        const { raw, edited, editedBlob, payload, ...untaggedAsset } = asset;

        if (edited || editedBlob) {
            const data = {
                raw: await convertImageToBlob(raw),
                edited: await (editedBlob || convertImageToBlob(edited)),
                type,
            };

            const { body } = await upload('recruiting/asset/upload', data);
            image = {
                ...untaggedAsset,
                ...body.asset,
                payload: { ...payload },
            };
        }

        return image;
    };
    const getContext = () => {
        return { request, upload, uploadAsset, authToken };
    };
    return <RequestContext.Provider value={getContext()}>{props.children}</RequestContext.Provider>;
}

RequestProvider.propTypes = {
    children: PropTypes.node.isRequired,
    authToken: PropTypes.string,
    profileId: PropTypes.string,
};

const mapStateToProps = ({ authentication, user }) => ({
    authToken: authentication.token,
    profileId: user.profileId,
});

export default connect(mapStateToProps)(RequestProvider);
