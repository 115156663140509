import styled from 'styled-components';

export const ErrorText = styled.p`
    color: red;
`;

export const FormRow = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
`;

export const FormCol = styled.div`
    width: ${(props) => props.width};
`;
