import React, { PureComponent } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Icon, List, Button, Input, Form, Modal, Select, Divider } from 'antd';
import PropTypes from 'prop-types';
import { UniNowList, ToggleModalButton } from '../../components';
import buttonWithTooltip from '../../decorators/buttonWithTooltip';
import labelWithTooltip from '../../decorators/labelWithTooltip';
import buttonWithPopconfirm from '../../decorators/buttonWithPopconfirm';
import passwordRules from '../../core/constants/passwordRules';

import LoginLinkButton from './components/LoginLinkButton';

import ROLES from '../../core/constants/roles';
import AllowedCompanyList from './components/AllowedCompanyList';

// Legacy code for old roles (e.g. customersupport)
const normalizeRole = (role) => role.toUpperCase().replace('RS', 'R_S');
const UNKNOWN_ROLE = {
    key: 'unknown',
    name: 'Unbekannte Rolle',
    description: 'Dieser Nutzer hat keine Rolle oder die Rolle ist veraltet',
};
const UserEditButton = Form.create()((props) => {
    const { onSave, form, memberRole, roleOptions, resetPassword, userRole, accountId, username } =
        props;
    const { getFieldDecorator, validateFields, resetFields, getFieldValue } = form;

    const normalizedMemberRole = normalizeRole(memberRole);
    const selectedRole = getFieldValue('role') || normalizedMemberRole;

    const ownRoleIndex = ROLES.findIndex(({ key }) => key === userRole);
    const editRoleIndex = ROLES.findIndex(({ key }) => key === selectedRole);
    const canEdit = editRoleIndex < 0 || editRoleIndex >= ownRoleIndex;

    const roleItem = editRoleIndex < 0 ? UNKNOWN_ROLE : ROLES[editRoleIndex];
    return (
        <ToggleModalButton
            modalProps={{
                title: 'Bearbeiten Sie ein Mitglied',
                onOk: async (toggleModal, setLoadingStatus) => {
                    validateFields(async (err, values) => {
                        if (!err) {
                            const { role } = values;
                            setLoadingStatus(true);
                            await onSave(role.toUpperCase());
                            setLoadingStatus(false);
                            toggleModal();
                            resetFields();
                        }
                    });
                },
                okText: 'Speichern',
                cancelText: 'Abbrechen',
                onCancel: resetFields,
                okButtonProps: { disabled: !canEdit },
                children: (
                    <div>
                        <Form.Item extra={roleItem.description} label="Rolle des Nutzers">
                            {getFieldDecorator('role', {
                                initialValue: selectedRole,
                            })(
                                <Select style={{ width: 250 }} disabled={!canEdit}>
                                    {roleOptions.map((ro) => (
                                        <Select.Option key={ro.key} value={ro.key}>
                                            {ro.name}
                                        </Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>
                        <Button
                            onClick={resetPassword}
                            key="submit"
                            type="secondary"
                            disabled={!canEdit}>
                            Passwort zurücksetzen
                        </Button>
                        {!canEdit && (
                            <p style={{ marginTop: 10, color: 'red' }}>
                                Sie haben nicht die nötigen Berechtigungen um diesen Nutzer zu
                                bearbeiten
                            </p>
                        )}
                        <Divider />
                        {selectedRole === 'CUSTOMER_SUPPORT' && (
                            <span>
                                Verwaltete Unternehmen
                                <AllowedCompanyList
                                    accountId={accountId}
                                    userName={username}
                                    canEdit={canEdit && userRole !== 'CUSTOMER_SUPPORT'}
                                />
                            </span>
                        )}
                    </div>
                ),
            }}
            customButton={(toggle) =>
                buttonWithTooltip('Benutzer bearbeiten')(
                    <Button shape="circle" icon="edit" onClick={() => toggle()} />,
                )
            }
        />
    );
});

const UserCreationButton = Form.create()(
    ({ onSave, form, checkUsername, userRole, roleOptions = [] }) => {
        const { getFieldDecorator, validateFields, resetFields, getFieldValue } = form;
        const selectedRole =
            getFieldValue('role') ||
            (roleOptions.find(({ standard }) => standard) || roleOptions[roleOptions.length - 1])
                .key;
        const roleItem = ROLES.find(({ key }) => key === selectedRole);

        const isAdmin = ['SUPERADMIN', 'ADMIN', 'CUSTOMER_SUPPORT'].includes(userRole);

        return (
            <ToggleModalButton
                modalProps={{
                    title: 'Erstellen Sie ein neues Mitglied',
                    onOk: async (toggleModal, setLoadingStatus) => {
                        validateFields(async (err, values) => {
                            if (!err) {
                                const { userName, role = userRole, password } = values;
                                setLoadingStatus(true);
                                await onSave(userName, role.toUpperCase(), password);
                                setLoadingStatus(false);
                                toggleModal();
                                resetFields();
                            }
                        });
                    },
                    okText: 'Erstellen',
                    cancelText: 'Abbrechen',
                    onCancel: resetFields,
                    children: (
                        <div>
                            <Form.Item
                                hasFeedback
                                colon={false}
                                label={labelWithTooltip(
                                    'Geben Sie eine E-Mail-Adresse an',
                                    'Die E-Mail-Adresse dient zugleich als Nutzername des neuen Mitglieds.',
                                )}>
                                {getFieldDecorator('userName', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Bitte geben Sie eine E-Mail-Adresse an.',
                                        },
                                        {
                                            type: 'email',
                                            message:
                                                'Bitte geben Sie eine gültige E-Mail-Adresse an.',
                                        },
                                        {
                                            validator: checkUsername,
                                        },
                                    ],
                                })(<Input placeholder="E-Mail-Adresse des Mitglieds..." />)}
                            </Form.Item>
                            {isAdmin && (
                                <Form.Item label="Initiales Passwort">
                                    {getFieldDecorator('password', {
                                        validateFirst: true,
                                        rules: passwordRules,
                                    })(<Input placeholder="Passwort (optional)" />)}
                                </Form.Item>
                            )}
                            {isAdmin && (
                                <Form.Item extra={roleItem.description} label="Rolle des Nutzers">
                                    {getFieldDecorator('role', {
                                        initialValue: roleItem.key,
                                    })(
                                        <Select style={{ width: 250 }}>
                                            {roleOptions.map((ro) => (
                                                <Select.Option key={ro.key} value={ro.key}>
                                                    {ro.name}
                                                </Select.Option>
                                            ))}
                                        </Select>,
                                    )}
                                </Form.Item>
                            )}
                        </div>
                    ),
                }}
                buttonProps={{
                    size: 'large',
                    icon: 'plus',
                    type: 'primary',
                    children: 'Mitglied hinzufügen',
                }}
            />
        );
    },
);

class Members extends PureComponent {
    constructor(props, context) {
        super(props);

        const { role } = context;

        const normalizedRole = normalizeRole(role);

        const roleIndex = ROLES.findIndex(({ key }) => key === normalizedRole);

        const roleOptions = ROLES.slice(roleIndex);

        this.state = {
            role: normalizedRole,
            roleOptions,
        };
    }

    showNewPasswordModal = (userName, password, isReset) => {
        const createMessage = (before, bold, after) => [before, <b>{bold}</b>, after];
        const message = isReset
            ? createMessage(
                  'Das Passwort des Mitglieds ',
                  userName,
                  ' wurde erfolgreich zurückgesetzt. Nachfolgend finden Sie das neue Passwort dieses Mitglieds: ',
              )
            : createMessage(
                  'Das Mitglied ',
                  userName,
                  ' wurde erfolgreich erstellt. Nachfolgend finden Sie das initiale Passwort dieses Mitglieds: ',
              );
        Modal.success({
            title: 'Erfolg!',
            content: (
                <span>
                    {message}
                    <br />
                    <br />
                    <b>
                        <h3>{password}</h3>
                    </b>
                    <br /> Bitte kopieren Sie es sich in Ihre Zwischenablage.
                </span>
            ),
        });
    };

    handleUserCreation = async (userName, role, password) => {
        const { createUser, setMessage } = this.context;

        password = await createUser(userName, role, password);

        if (password) {
            this.showNewPasswordModal(userName, password, false);
        } else {
            setMessage(
                <span>
                    Beim erstellen des Mitglieds <b>{userName}</b> ist ein Fehler aufgetreten. Bitte
                    versuchen Sie es erneut.
                </span>,
                'error',
            );
        }
    };

    handleUserDelete = async (memberID, username) => {
        const { deleteUser, setMessage } = this.context;
        const wasSuccessful = await deleteUser(memberID);
        if (wasSuccessful) {
            setMessage(
                <span>
                    Das Mitglied <b>{username}</b> wurde erfolgreich gelöscht.
                </span>,
                'success',
            );
        } else {
            setMessage(
                <span>
                    Beim Löschen des Mitglieds <b>{username}</b> ist ein Fehler aufgetreten. Bitte
                    versuchen Sie es erneut.
                </span>,
                'error',
            );
        }
    };

    handleUserPasswordReset = async (memberID, userName) => {
        const { resetPassword, setMessage } = this.context;
        const password = await resetPassword(memberID);
        if (password) {
            this.showNewPasswordModal(userName, password, false);
            setMessage(
                <span>
                    Das Passwort des Mitglieds <b>{userName}</b> wurde erfolgreich zurückgesetzt.
                </span>,
                'success',
            );
        } else {
            setMessage(
                <span>
                    Beim Zurücksetzen des Passworts für das Mitglied <b>{userName}</b> ist ein
                    Fehler aufgetreten. Bitte versuchen Sie es erneut.
                </span>,
                'error',
            );
        }
    };

    handleUserUpdate = async (memberID, role, username) => {
        const { updateUserRole, setMessage } = this.context;
        const password = await updateUserRole(memberID, role);
        if (password) {
            setMessage(
                <span>
                    Das Mitglied <b>{username}</b> wurde erfolgreich bearbeitet.
                </span>,
                'success',
            );
        } else {
            setMessage(
                <span>
                    Beim bearbeiten des Mitglieds <b>{username}</b> ist ein Fehler aufgetreten.
                    Bitte versuchen Sie es erneut.
                </span>,
                'error',
            );
        }
    };

    renderListItem = (member, index) => {
        const { username, lastActive, _id, role } = member;
        const { role: userRole } = this.state;

        const memberRole = normalizeRole(role);

        const { role: currentRole, roleOptions } = this.state;
        const { username: currentUsername } = this.context;
        const { history } = this.props;
        const isCurrentUser = currentUsername === username;
        const selectedStyle = isCurrentUser ? { backgroundColor: '#f2f2f2' } : {};

        const deleteButton = <Button disabled={isCurrentUser} shape="circle" icon="delete" />;
        const resetButton = (
            <Button
                shape="circle"
                icon="key"
                onClick={isCurrentUser && (() => history.push('/passwortaendern'))}
            />
        );

        const actions = [
            isCurrentUser
                ? deleteButton
                : buttonWithPopconfirm({
                      okText: 'Löschen',
                      title: 'Wollen Sie dieses Mitglied wirklich löschen?',
                      style: { pointerEvents: 'none' },
                      onConfirm: () => this.handleUserDelete(_id, username),
                  })(buttonWithTooltip('Mitglied löschen')(deleteButton)),
        ];

        const isAdmin = ['SUPERADMIN', 'ADMIN', 'CUSTOMER_SUPPORT'].includes(currentRole);
        const isManager = isAdmin || ['MANAGER'].includes(currentRole);

        if (isManager) {
            actions.unshift(
                isCurrentUser
                    ? buttonWithTooltip('Passwort ändern')(resetButton)
                    : buttonWithPopconfirm({
                          okText: 'Zurücksetzen',
                          title: 'Wollen Sie das Passwort dieses Mitglieds wirklich zurücksetzen?',
                          style: { pointerEvents: 'none' },
                          onConfirm: () => this.handleUserPasswordReset(_id, username),
                      })(buttonWithTooltip('Passwort zurücksetzen')(resetButton)),
            );
        }
        if (isAdmin) {
            actions.unshift(
                buttonWithTooltip('Letzte Aktivität')(
                    <span>
                        {lastActive ? moment(lastActive).format('DD.MM.YYYY HH:mm') : '-'}{' '}
                        <Icon type="clock-circle" />
                    </span>,
                ),
            );
            actions.push(
                <UserEditButton
                    onSave={(value) => this.handleUserUpdate(_id, value)}
                    accountId={_id}
                    memberRole={memberRole}
                    userRole={userRole}
                    username={username}
                    roleOptions={roleOptions}
                    resetPassword={() => this.handleUserPasswordReset(_id, username)}
                />,
            );

            if (['SUPERADMIN', 'ADMIN'].includes(currentRole)) {
                actions.push(
                    buttonWithTooltip('Loginlink generieren')(<LoginLinkButton member={member} />),
                );
            }
        }

        const roleItem = ROLES.find((r) => r.key === memberRole);
        const isCustomerSupport = memberRole === 'CUSTOMER_SUPPORT';
        const description = `${roleItem ? roleItem.name : memberRole}`;
        return (
            <List.Item key={_id} style={{ ...selectedStyle, paddingLeft: '5px' }} actions={actions}>
                {isAdmin ? (
                    <List.Item.Meta
                        title={username}
                        description={
                            <>
                                {description}
                                {isCustomerSupport && (
                                    <AllowedCompanyList
                                        accountId={_id}
                                        userName={username}
                                        canEdit={currentRole !== 'CUSTOMER_SUPPORT'}
                                    />
                                )}
                            </>
                        }
                    />
                ) : (
                    <List.Item.Meta title={username} />
                )}
            </List.Item>
        );
    };

    activeTimeout = null;

    checkUsername = (rule, value, callback) => {
        const { request } = this.context;

        const timeoutId = setTimeout(async () => {
            if (timeoutId === this.activeTimeout) {
                const { body } = await request('recruiting/member/check', {
                    username: value,
                });
                if (body) {
                    const { available, profile, name } = body;
                    if (available) {
                        callback();
                    } else {
                        callback(`Nutzername bereits vergeben (${name}, ID: ${profile}).`);
                    }
                } else {
                    callback('Fehler beim Überprüfen der Email');
                }
            } else {
                callback('tggg');
            }
        }, 500);

        this.activeTimeout = timeoutId;
    };

    render() {
        const { memberList } = this.props;
        const { role, roleOptions } = this.state;

        return (
            <div>
                <div style={{ marginBottom: '10px' }}>
                    <UserCreationButton
                        onSave={this.handleUserCreation}
                        userRole={role}
                        roleOptions={roleOptions}
                        checkUsername={this.checkUsername}
                    />
                </div>
                <UniNowList
                    noItemsText="Es wurden keine Mitglieder gefunden"
                    itemLayout="horizontal"
                    dataSource={memberList}
                    renderItem={this.renderListItem}
                />
            </div>
        );
    }
}

Members.contextTypes = {
    username: PropTypes.string.isRequired,
    setMessage: PropTypes.func.isRequired,
    createUser: PropTypes.func.isRequired,
    updateUserRole: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
    request: PropTypes.func,
    role: PropTypes.string,
};

const mapStateToProps = (state) => ({
    memberList: state.memberList.list,
});

export default connect(mapStateToProps)(Members);
