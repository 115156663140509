import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InputNumber, Form, Message, Icon } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { RequestContext } from '../../components/Session/RequestProvider';

const FormItem = Form.Item;

function LocationUniFinder({ addresses }) {
    const [perimeter, setPerimeter] = useState(100);
    const { request } = useContext(RequestContext);
    const [universities, setUniversities] = useState([]);
    const find = async () => {
        const locations = addresses.map(({ geoPoint }) => ({
            geoPoint: { ...geoPoint, perimeter },
        }));
        const { body } = await request('recruiting/universities/list', {
            locations,
        });
        setUniversities(body ?? []);
    };
    useEffect(() => {
        find().catch((e) => console.error(e));
    }, [perimeter]);
    return (
        <>
            <FormItem
                label={
                    <>
                        <CopyToClipboard
                            text={JSON.stringify(universities.map(({ id }) => id))}
                            onCopy={() =>
                                Message.info('Hochschul-IDs in die Zwischenablage kopiert')
                            }>
                            <span
                                style={{
                                    cursor: 'pointer',
                                }}>
                                <span style={{ fontWeight: 'bold' }}>
                                    {universities.length} Hochschulen
                                </span>{' '}
                                in der Nähe des Unternehmens{' '}
                                <span
                                    style={{
                                        color: '#8E8E8E',
                                    }}>
                                    <Icon type="copy" />
                                </span>
                            </span>
                        </CopyToClipboard>
                    </>
                }>
                <InputNumber
                    min={0}
                    step={10}
                    value={perimeter}
                    onChange={(value) => setPerimeter(value)}
                    style={{
                        width: 'auto',
                        marginRight: 15,
                        boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.1)',
                    }}
                />
                km Umkreis
            </FormItem>
        </>
    );
}

LocationUniFinder.propTypes = {
    addresses: PropTypes.arrayOf(
        PropTypes.shape({
            geoPoint: PropTypes.shape({
                lat: PropTypes.number.isRequired,
                lng: PropTypes.number.isRequired,
            }),
        }),
    ).isRequired,
};

export default LocationUniFinder;
