import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Collapse } from 'antd';
import { connect } from 'react-redux';

class AdminArea extends Component {
    render() {
        const role = this.context.role || this.props.role || '';

        if (
            this.props.isDemoMode ||
            !['SUPERADMIN', 'ADMIN'].includes(role.toUpperCase())
        ) {
            return null;
        }

        return (
            <Collapse style={{ margin: '10px 0px' }}>
                <Collapse.Panel
                    header="Adminbereich"
                    key="1"
                    style={{ backgroundColor: '#f2f4f5' }}>
                    {this.props.children}
                </Collapse.Panel>
            </Collapse>
        );
    }
}

AdminArea.contextTypes = {
    role: PropTypes.string,
};

const mapStateToProps = (state) => ({
    isDemoMode: state.user.isDemoMode,
});
export default connect(mapStateToProps)(AdminArea);
