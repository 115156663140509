import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { ICONS } from '../../components/UNIcon/icons';
import { UNIcon } from '../../components';

const EditBenefitModal = ({ benefit, onOk, onCancel, onDelete }) => {
    const [text, setText] = useState();
    const [icon, setIcon] = useState();
    useEffect(() => {
        if (benefit) {
            setText(benefit.text);
            setIcon(benefit.icon);
        }
    }, [benefit]);
    return (
        <Modal
            visible={!!benefit}
            title="Benefit bearbeiten"
            maskClosable
            onOk={() => onOk({ ...benefit, text, icon, key: benefit?.key ?? text })}
            onCancel={onCancel}>
            <Form.Item label="Titel">
                <Input value={text} onChange={(e) => setText(e.target.value)} />
            </Form.Item>
            <Form.Item label="Icon">
                <Row>
                    {ICONS.map((ic) => (
                        <Col md={3} value={ic} key={ic} onClick={() => setIcon(ic)}>
                            <UNIcon
                                icon={ic}
                                style={{
                                    fontSize: '1.5em',
                                    padding: 1,
                                    borderRadius: 3,
                                    backgroundColor: ic === icon ? '#F21850' : 'transparent',
                                }}
                            />
                        </Col>
                    ))}
                </Row>
            </Form.Item>
            <Button onClick={onDelete}>Löschen</Button>
        </Modal>
    );
};

export default EditBenefitModal;
