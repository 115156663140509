import { Select } from 'antd';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import universities from '../../../core/constants/universities.json';

const SubTargetsList = ({ targets, type }) => {
    const processedTargets = useMemo(() => {
        if (!targets || targets?.length === 0) {
            return [];
        }

        if (type === 'location') {
            return targets.map((id) => {
                const uniData = universities.find((uni) => uni.id === id);
                return uniData?.name || id;
            });
        }
        return targets;
    }, [targets, type]);

    if (processedTargets.length === 0) {
        return null;
    }

    return (
        <div style={{ fontWeight: 300, paddingBottom: 10 }}>
            <Select
                mode="tags"
                style={{ width: '100%', opacity: 1, pointerEvents: 'none' }}
                defaultValue={processedTargets}
                allowClear={false}
                removeIcon
            />
        </div>
    );
};

export default SubTargetsList;

SubTargetsList.defaultProps = {
    targets: [],
    type: 'none',
};

SubTargetsList.propTypes = {
    targets: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.oneOf(['none', 'location', 'affiliation']),
};
