import React from 'react';

import {
    ResponsiveContainer,
    LineChart,
    XAxis,
    YAxis,
    Label,
    Line,
    Tooltip,
    CartesianGrid,
} from 'recharts';
import { Icon, Spin, Row } from 'antd';
import moment from 'moment';

import './style.css';

export default ({
    data = [],
    xLabel = null,
    yLabel = null,
    dateFormat = 'D. MMM',
    interval = 1,
    loading = false,
}) => {
    let yMin = 0;
    let yMax = 0;

    data.forEach((entry) => {
        const value = Math.round(parseInt(entry.y) * 1.1);

        yMin = Math.min(yMin, value);
        yMax = Math.max(yMax, value);
    });

    return (
        <div id="LineGraph" style={{ width: '100%', height: 300, position: 'relative' }}>
            {loading && (
                <div
                    style={{
                        bottom: 0,
                        top: 0,
                        left: 0,
                        right: 0,
                        zIndex: 10,
                        position: 'absolute',
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    }}>
                    <Row type="flex" align="middle" justify="center" style={{ height: '100%' }}>
                        <Spin />
                    </Row>
                </div>
            )}
            <ResponsiveContainer>
                <LineChart width={300} height={200} data={data}>
                    <YAxis dataKey="y" domain={[yMin, yMax]}>
                        <Label value={yLabel} angle={-90} position="insideLeft" />
                    </YAxis>
                    <XAxis
                        dataKey="x"
                        interval={interval}
                        tickFormatter={(value) => moment(value).format(dateFormat)}>
                        <Label value={xLabel} offset={-5} position="insideBottom" />
                    </XAxis>
                    <Tooltip
                        isAnimationActive={false}
                        content={(args) => {
                            const { label, payload = [] } = args;
                            const { value } = payload[0] || {};

                            if (!value) {
                                return null;
                            }

                            const index = data.findIndex((element) => element.x === label);

                            let comparison = 0;
                            let icon = 'line';

                            if (index > 0) {
                                const previousElement = data[index - 1];

                                comparison = parseInt(value) - parseInt(previousElement.y);

                                if (comparison !== 0) {
                                    icon = comparison < 0 ? 'arrow-down' : 'arrow-up';
                                }
                            }

                            return (
                                <div className="tooltip">
                                    <div className="color-secondary size-small">
                                        {moment(label).format('D. MMMM YYYY')}
                                    </div>
                                    <div style={{ paddingBottom: 10, fontWeight: 600 }}>
                                        {value} {yLabel}
                                    </div>
                                    {index > 0 && (
                                        <div>
                                            <Icon className="color-primary" type={icon} />{' '}
                                            {comparison}
                                        </div>
                                    )}
                                </div>
                            );
                        }}
                    />
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                    <Line type="monotone" dataKey="y" stroke="#f21850" dot={false} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};
