import { Row } from 'antd';
import React from 'react';
import useCubeWithQueryErrorHandling from '../../hooks/useCubeWithQueryErrorHandling';
import StatisticsCard from '../../StatisticsCard';
import { dotFormattedNumber, formatAsPercentage } from '../../../../core/helper/numberFormatter';

const DEFAULT_CUBE_QUERY = {
    measures: ['AdvertStats.clicked', 'AdvertStats.seen', 'AdvertStats.closed'],
};

const getCubeFiltersQuery = (values) => ({
    filters: [
        {
            member: 'AdvertStats.status',
            operator: 'equals',
            values,
        },
    ],
});

function AdCards({ filters }) {
    let data = [];
    const query = (adFilter) =>
        adFilter.length
            ? { ...getCubeFiltersQuery(adFilter), ...DEFAULT_CUBE_QUERY }
            : DEFAULT_CUBE_QUERY;

    const { resultSet, isLoading } = useCubeWithQueryErrorHandling(query(filters));

    if (!resultSet) {
        return null;
    }

    if (resultSet) {
        data = resultSet.tablePivot();
    }

    return (
        <Row id="CompanyTotal">
            <StatisticsCard
                title="Gesehen"
                tooltipTitle="Wie häufig wurden Ihre Werbeanzeigen in der App angezeigt (Auch gelöschte Werbeanzeigen werden berücksichtigt)."
                isLoading={isLoading}
                data={data.map((measure) => (
                    <>{dotFormattedNumber(measure['AdvertStats.seen'].toLocaleString('de-DE'))}</>
                ))}
            />
            <StatisticsCard
                title="Klicks"
                tooltipTitle="Wie häufig wurden Ihre Werbeanzeigen in der App aktiv aufgerufen  (Auch gelöschte Werbeanzeigen werden berücksichtigt)."
                isLoading={isLoading}
                data={data.map((measure) => (
                    <>{dotFormattedNumber(measure['AdvertStats.clicked'])}</>
                ))}
            />
            <StatisticsCard
                title="Klick-Rate"
                tooltipTitle="Wie häufig wurden Ihre Werbeanzeigen in der App aktiv aufgerufen  (Auch gelöschte Werbeanzeigen werden berücksichtigt)."
                isLoading={isLoading}
                measureField="AdvertStats.clicked"
                data={data.map((measure) => (
                    <>
                        {formatAsPercentage(
                            measure['AdvertStats.clicked'],
                            measure['AdvertStats.seen'],
                        )}
                    </>
                ))}
            />
            <StatisticsCard
                title="Geschlossen"
                tooltipTitle="Wie häufig wurden Ihre Werbeanzeigen in der App aktiv geschlossen."
                isLoading={isLoading}
                data={data.map((measure) => (
                    <>{dotFormattedNumber(measure['AdvertStats.closed'])}</>
                ))}
            />
        </Row>
    );
}

export default AdCards;
