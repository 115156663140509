import React, { useContext, useState } from 'react';
import { Button, Message, Modal } from 'antd';
import { connect } from 'react-redux';
import contactSucessImg from './contactRequestSuccess.svg';
import { setAttributeValue } from '../../../../../core/redux/actions';
import { RequestContext } from '../../../../../components/Session/RequestProvider';

const SecondModal = ({ visible, handleClose }) => {
    return (
        <Modal
            visible={visible}
            centered
            closable={false}
            style={{ minWidth: '700px' }}
            footer={null}>
            <div
                style={{
                    padding: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}>
                <div
                    style={{
                        textAlign: 'center',
                        fontSize: '24px',
                        fontWeight: 600,
                    }}>
                    Kontaktanfrage erfolgreich!
                </div>
                <img
                    src={contactSucessImg}
                    style={{
                        objectFit: 'cover',
                        height: '264px',
                    }}
                    alt="contact confiramtion image"
                />
                <div key="button-container" style={{ textAlign: 'center' }}>
                    <Button
                        style={{ fontSize: '16px' }}
                        key="close"
                        type="primary"
                        onClick={handleClose}>
                        Schließen
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

const ContactModal = ({ visible, handleOk, handleCancel, hit, refresh }) => {
    const [secondModalVisible, setSecondModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const { request } = useContext(RequestContext);
    const handleSecondModalClose = async () => {
        setSecondModalVisible(false);
        refresh();
    };

    const contactRequest = async () => {
        setLoading(true);
        const response = await request('recruiting/talentPool/createContactRequest', {
            hit,
        });
        if (response.error || response.body === null) {
            handleOk();
            Message.error({
                content:
                    'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
            });
            return;
        }
        setLoading(false);
        setSecondModalVisible(true);
        handleOk();
    };

    return (
        <>
            <Modal
                visible={visible}
                centered
                closable={false}
                style={{ padding: '32px', minWidth: '700px' }}
                footer={null}>
                <div
                    style={{
                        textAlign: 'center',
                        fontSize: '24px',
                        fontWeight: 500,
                        lineHeight: '32px',
                        marginBottom: '16px',
                    }}>
                    Bestätigung der Kontaktanfrage!
                </div>
                <div
                    style={{
                        textAlign: 'center',
                        fontSize: '16px',
                        color: '#37373B',
                        marginBottom: '32px',
                    }}>
                    Sie sind dabei, der Person eine Benachrichtigung über Ihr Interesse an ihrem
                    Karriereprofil zu senden. Durch die Bestätigung unten wird die Person informiert
                    und kann selbst entscheiden, ob sie Ihnen die Erlaubnis erteilen möchte, sie zu
                    kontaktieren.
                </div>
                <div key="button-container" style={{ textAlign: 'center', borderTop: 'none' }}>
                    <Button
                        key="cancel"
                        style={{
                            margin: '0 8px',
                            background: '#ffffff',
                            borderColor: '#F42847',
                            color: '#F42847',
                        }}
                        disabled={loading}
                        onClick={handleCancel}>
                        Abbrechen
                    </Button>
                    <Button
                        key="ok"
                        type="primary"
                        style={{
                            margin: '0 8px',
                            background: '#F42847',
                            borderColor: '#F42847',
                        }}
                        disabled={loading}
                        onClick={contactRequest}>
                        {loading ? 'Laden...' : 'Bestätigen'}
                    </Button>
                </div>
            </Modal>
            <SecondModal visible={secondModalVisible} handleClose={handleSecondModalClose} />
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    setAttributeValue: (attributeName, value) => dispatch(setAttributeValue(attributeName, value)),
});
export default connect(mapDispatchToProps)(ContactModal);
