import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Modal, Form, Select, Divider, InputNumber, DatePicker } from 'antd';

import moment from 'moment';
import { AdvertUniversitiesForm, AffiliationSelection } from '../index';
import { SessionContext } from '../Session';

const { Option } = Select;

const targetingTypes = [
    { label: 'Alle Standorte und Fachrichtungen', value: 'none' },
    { label: 'Standort', value: 'location' },
    { label: 'Fachrichtung', value: 'affiliation' },
];
function AdvertImpressionsModal({
    onCancel,
    onOk: onPropOk,
    visible,
    info,
    lastStartDate: inputLastStartDate,
}) {
    const { adCoins } = info;
    const [targetingType, setTargetingType] = useState(targetingTypes[0].value);
    const [impressions, setImpressions] = useState(0);
    const [dailyLimit, setDailyLimit] = useState(0);
    const [startDate, setStartDate] = useState(moment());
    const [lastStartDate, setLastStartDate] = useState(inputLastStartDate);
    const [endOfTerm, setEndOfTerm] = useState();
    const [locationTargeting, setLocationTargeting] = useState({});
    const [affiliations, setAffiliations] = useState([]);
    useEffect(() => {
        if (visible) {
            setLastStartDate(inputLastStartDate);
        }
    }, [visible]);
    const onOk = () => {
        let targeting = {
            totalImpressions: impressions,
            targetGroup: targetingType === 'none' ? 0 : 1,
            dailyLimit: dailyLimit || null,
        };
        if (targetingType === 'location') {
            targeting = { ...targeting, ...locationTargeting };
        } else if (targetingType === 'affiliation') {
            targeting = { ...targeting, affiliations };
        }
        onPropOk(targeting, startDate, endOfTerm);
    };
    const coinMultiplier = targetingType === 'none' ? 1 : 2;

    const { role } = useContext(SessionContext);
    const isADMIN = ['SUPERADMIN', 'ADMIN', 'CUSTOMER_SUPPORT'].includes(role.toUpperCase());
    return (
        <Modal
            title="Impressions"
            maskClosable={false}
            destroyOnClose
            width={600}
            onOk={onOk}
            onCancel={onCancel}
            visible={visible}>
            <div />
            <Form.Item label="Targetierung">
                <Select
                    value={targetingType}
                    onChange={(value) => setTargetingType(value)}
                    style={{ width: '100%' }}>
                    {targetingTypes.map((option) => (
                        <Option key={option.label} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            {targetingType === 'location' && (
                <AdvertUniversitiesForm
                    onChange={(universities, locations, disabledUniversities) =>
                        setLocationTargeting({ universities, locations, disabledUniversities })
                    }
                    universities={locationTargeting.universities || []}
                />
            )}
            {targetingType === 'affiliation' && (
                <AffiliationSelection
                    description="Wählen Sie die Fachrichtungen aus, an denen Ihre Werbeanzeige ausgeliefert werden soll."
                    onChange={setAffiliations}
                    initialAffiliations={affiliations}
                />
            )}
            <Divider />
            <Form.Item label="Legen Sie fest wie oft Ihre Werbeanzeige maximal ausgeliefert werden soll.">
                <InputNumber
                    min={0}
                    max={adCoins / coinMultiplier}
                    value={impressions}
                    formatter={(value) => value?.toLocaleString('de')}
                    parser={(value) => Math.round(value.replace('.', ''))}
                    step={100}
                    onChange={setImpressions}
                />{' '}
                Impressionen
                <div style={{ float: 'right' }}>
                    {dailyLimit ? (
                        <span>
                            max{' '}
                            <InputNumber
                                min={0}
                                max={impressions}
                                value={dailyLimit}
                                parser={(value) => Math.round(value)}
                                step={100}
                                onChange={setDailyLimit}
                            />{' '}
                            pro Tag
                        </span>
                    ) : (
                        <span
                            className="primary-clickable"
                            onClick={() => setDailyLimit(impressions)}>
                            tägliches Limit hinzufügen
                        </span>
                    )}
                </div>
            </Form.Item>
            Ihre aktuelle Konfiguration verbraucht {coinMultiplier * impressions} von insgesamt{' '}
            {adCoins} AdCoins
            <Divider />
            <Form.Item label="Geben Sie an, ab wann die Werbeanzeige ausgeliefert werden soll.">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    {startDate ? (
                        <DatePicker
                            onChange={setStartDate}
                            showTime={{ format: 'HH:mm' }}
                            format="DD.MM.YYYY HH:mm"
                            value={startDate}
                        />
                    ) : (
                        <span>sofort</span>
                    )}
                    {isADMIN && lastStartDate && (
                        <span
                            className="primary-clickable"
                            onClick={() => {
                                setStartDate(moment(lastStartDate));
                            }}>
                            Letztes Startdatum übernehmen
                        </span>
                    )}
                    {startDate ? (
                        <span className="primary-clickable" onClick={() => setStartDate(null)}>
                            sofort
                        </span>
                    ) : (
                        <span
                            className="primary-clickable"
                            onClick={() => setStartDate(moment().add(1, 'hour').startOf('hour'))}>
                            benutzerdefiniertes Datum
                        </span>
                    )}
                </div>
            </Form.Item>
            <Form.Item label="Geben Sie an, wie lange die Werbeanzeige ausgeliefert werden soll.">
                <DatePicker
                    onChange={setEndOfTerm}
                    showTime={{ format: 'HH:mm' }}
                    format="DD.MM.YYYY HH:mm"
                    value={endOfTerm}
                />
            </Form.Item>
        </Modal>
    );
}

AdvertImpressionsModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    info: PropTypes.object.isRequired,
    lastStartDate: PropTypes.string.isRequired,
};

export default AdvertImpressionsModal;
