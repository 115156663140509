import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';

const EditGroupModal = ({ group, onOk, onCancel, onDelete }) => {
    const [text, setText] = useState();
    useEffect(() => {
        if (group) {
            setText(group.group);
        }
    }, [group]);
    return (
        <Modal
            visible={!!group}
            title="Gruppe bearbeiten"
            maskClosable
            onOk={() => onOk({ ...group, group: text })}
            onCancel={onCancel}>
            <Form.Item label="Titel">
                <Input value={text} onChange={(e) => setText(e.target.value)} />
            </Form.Item>
            <Button onClick={onDelete}>Löschen</Button>
        </Modal>
    );
};

export default EditGroupModal;
