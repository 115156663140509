import React from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import 'antd/lib/timeline';
import { Spinner } from '../index';

const WrappedMap = withGoogleMap((props) => {
    const { showMarker, lat, lng, zoom } = props;
    return (
        <GoogleMap
            zoom={zoom}
            center={lat && lng ? { lat, lng } : null}
            defaultOptions={{
                scrollwheel: false,
                zoomControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                draggable: false,
                clickable: false,
                disableDefaultUI: true,
            }}>
            {showMarker && lat && lng && <Marker position={{ lat, lng }} />}
        </GoogleMap>
    );
});

export const withGoogleMaps = (Component) => {
    const WrappedComponent = withScriptjs(Component);
    return function MapsComponent(props) {
        return (
            <WrappedComponent
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDXL322wKf2pcXOwiq76_cDGGC72jkc2fI&libraries=places&language=de&region=GER"
                loadingElement={<Spinner />}
                {...props}
            />
        );
    };
};
export default withGoogleMaps(WrappedMap);
