export default [
    {
        min: 8,
        message: 'Bitte geben Sie mehr als 8 Zeichen ein.',
    },
    {
        max: 100,
        message: 'Bitte geben Sie weniger als 100 Zeichen ein.',
    },
    {
        pattern: /[A-Z]+/gm,
        message: 'Das Passwort muss einen Großbuchstaben enhalten.',
    },
    {
        pattern: /[a-z]+/gm,
        message: 'Das Passwort muss einen Kleinbuchstaben enhalten.',
    },
    {
        pattern: /[0-9]+/gm,
        message: 'Das Passwort muss eine Ziffer enhalten.',
    },
    {
        validator: (rule, value, callback) => {
            callback(
                /passw[0o]r[dt]\d+|dragonfly/gim.test(value) ? 'Ungültiges Passwort.' : undefined,
            );
        },
    },
];
