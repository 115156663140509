import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Popover, List, Input, Icon } from 'antd';

class UniversityPopover extends Component {
    state = {
        search: '',
    };

    onSearch = ({ target }) => {
        this.setState({ search: target.value.toLowerCase() });
    };

    renderUniversity = (uni) => (
        <List.Item style={{ flexDirection: 'row' }}>
            <div>{uni.name || uni.id}</div>
            <Icon
                type="close"
                onClick={() => this.props.removeUni(uni)}
                style={{ cursor: 'pointer', color: '#f21850', padding: '5px', marginRight: '10px' }}
            />
        </List.Item>
    );

    onVisibleChange = (visible) => {
        if (visible) {
            this.setState({ search: '' });
        }
    };

    render() {
        const { search } = this.state;
        const { universities = [] } = this.props;

        let unis = universities;
        if (search) {
            unis = universities.filter((uni) => uni.name.toLowerCase().includes(search));
        }

        return (
            <Popover
                trigger="click"
                onVisibleChange={this.onVisibleChange}
                content={
                    <div style={{ padding: '5px 10px' }}>
                        <Input
                            placeholder="Hochschule suchen"
                            value={search}
                            onChange={this.onSearch}
                            style={{ padding: 10, boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.1)' }}
                        />
                        <br />
                        <br />
                        <List
                            locale={{ emptyText: 'Keine Hochschule gefunden' }}
                            style={{ height: 200, width: 400, overflow: 'scroll' }}
                            dataSource={unis}
                            renderItem={this.renderUniversity}
                        />
                    </div>
                }>
                {this.props.children}
            </Popover>
        );
    }
}

UniversityPopover.propTypes = {
    universities: PropTypes.array.isRequired,
    removeUni: PropTypes.func.isRequired,
};

export default UniversityPopover;
