import React from 'react';
import { Tooltip, Icon } from 'antd';

export default function labelWithTooltip(label, tooltip) {
    return (
        <span>
            {label}&nbsp;
            <Tooltip title={tooltip}>
                <Icon type="question-circle-o" />
            </Tooltip>
            :
        </span>
    );
}
