export function formatAsPercentage(clicked, seen) {
    if (!clicked || !seen || Number(seen) === 0) {
        return '0,00 %';
    }

    const rate = (Number(clicked) * 100) / Number(seen);
    const formattedRate = rate.toFixed(2).replace('.', ',');
    return `${formattedRate} %`;
}

export function dotFormattedNumber(number) {
    const parsedNumber = Number(number);
    return isNaN(parsedNumber) ? number : parsedNumber.toLocaleString('de-DE');
}
