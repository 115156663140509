// import { loadCompany, updateCompany } from '../../api/api.js';

export const SORT_JOBS = 'SORT_JOBS';
export const REMOVE_JOB = 'REMOVE_JOB';
export const ADD_TO_ARRAY = 'ADD_TO_ARRAY';
export const REMOVE_FROM_ARRAY = 'REMOVE_FROM_ARRAY';
export const CHANGE_ARRAY_ELEMENT = 'CHANGE_ARRAY_ELEMENT';
export const CHANGE_ARRAY_ELEMENT_POS = 'CHANGE_ARRAY_ELEMENT_POS';
export const SET_ATTRIBUTEVALUE = 'SET_ATTRIBUTEVALUE';
export const SET_DEMOMODE = 'SET_DEMOMODE';
export const SET_STATE = 'SET_STATE';
export const SAVE_STATE = 'SAVE_STATE';
export const SESSIONDATA_LOAD_REQUEST = 'SESSIONDATA_LOAD_REQUEST';
export const SESSIONDATA_LOAD_SUCCESS = 'SESSIONDATA_LOAD_SUCCESS';
export const SAVE_COMPANY_REQUEST = 'SAVE_COMPANY_REQUEST';
export const SAVE_COMPANY_SUCCESS = 'SAVE_COMPANY_SUCCESS';
export const UPDATE_APPLICANT_STATUS = 'UPDATE_APPLICANT_STATUS';
export const SORT_ADVERTS = 'SORT_ADVERTS';
export const CHANGE_CUBE_TOKEN_VALIDITY = 'CHANGE_CUBE_TOKEN_VALIDITY';
export const SET_AD_CURRENT_PAGE = 'SET_AD_CURRENT_PAGE';
export const SET_JOB_CURRENT_PAGE = 'SET_JOB_CURRENT_PAGE';

export function changeCubeTokenValidity(isValid) {
    return { type: CHANGE_CUBE_TOKEN_VALIDITY, isValid };
}

export function sortJobs(sortOptions) {
    return {
        type: SORT_JOBS,
        sortOptions,
    };
}
export function sortAdverts(sortOptions) {
    return {
        type: SORT_ADVERTS,
        sortOptions,
    };
}

export function removeJob(jobId) {
    return {
        type: REMOVE_JOB,
        jobId,
    };
}

export function requestSaveOfCompany() {
    return { type: SAVE_COMPANY_REQUEST };
}

export function saveCompanyData(company) {
    return {
        type: SAVE_COMPANY_SUCCESS,
        company,
    };
}

export function saveSessionData(data) {
    return {
        type: SESSIONDATA_LOAD_SUCCESS,
        ...data,
    };
}

export function setReducerState(state, reducer = 'company') {
    return {
        reducer,
        type: SET_STATE,
        state,
    };
}

export function setAttributeValue(attributeName = '', value, reducer = 'company') {
    return {
        reducer,
        type: SET_ATTRIBUTEVALUE,
        attributeName,
        value,
    };
}
export function setDemoMode(value) {
    return {
        type: SET_DEMOMODE,
        value,
    };
}
export function addToArray(attributeName = '', element = {}, reducer = 'company', prepend = false) {
    return {
        reducer,
        type: ADD_TO_ARRAY,
        attributeName,
        element,
        prepend,
    };
}

export function removeFromArray(attributeName = '', index = 0, reducer = 'company') {
    return {
        reducer,
        type: REMOVE_FROM_ARRAY,
        attributeName,
        index,
    };
}

export function changeArrayElement(
    attributeName = '',
    index = 0,
    updatedElement = {},
    reducer = 'company',
) {
    return {
        reducer,
        type: CHANGE_ARRAY_ELEMENT,
        attributeName,
        index,
        updatedElement,
    };
}

export function changeArrayElementPos(
    attributeName = '',
    oldIndex = 0,
    positionChange = 0,
    reducer = 'company',
) {
    return {
        reducer,
        type: CHANGE_ARRAY_ELEMENT_POS,
        attributeName,
        oldIndex,
        positionChange,
    };
}

export function updateApplicantStatus(jobID, applicantID, status) {
    return {
        type: UPDATE_APPLICANT_STATUS,
        jobID,
        applicantID,
        status,
    };
}

export const setAdCurrentPage = (page) => ({
    type: SET_AD_CURRENT_PAGE,
    payload: page,
});

export const setJobCurrentPage = (page) => ({
    type: SET_JOB_CURRENT_PAGE,
    payload: page,
});
