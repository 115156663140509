import update from 'immutability-helper';

import { LOAD_POST, RESET_POST } from '../actions/post.js';

function post(state = { post: null }, action) {
    switch (action.type) {
        case LOAD_POST: {
            return update(state, {
                post: {
                    $set: action.post,
                },
            });
        }

        case RESET_POST: {
            return update(state, {
                post: {
                    $set: null,
                },
            });
        }

        default:
            return state;
    }
}

export default post;
