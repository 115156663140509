import axios from 'axios';
import { merge } from 'lodash';

const { REACT_APP_ENV, REACT_APP_API_HOST, REACT_APP_API_HOST_DEV, REACT_APP_WITH_CREDENTIALS } =
    window;

const HOST = REACT_APP_ENV === 'development' ? REACT_APP_API_HOST_DEV : REACT_APP_API_HOST;

async function axiosCall(url, options = {}) {
    try {
        const response = await axios(url, options);
        return {
            status: response.status,
            body: response.data,
            error: null,
            version: response.headers['x-uninow-version'],
        };
    } catch (error) {
        const { response } = error;

        return {
            status: response ? response.status : 500,
            body: null,
            error: response ? response.data : error.message,
            version: response?.headers['x-uninow-version'],
        };
    }
}

export const request = async (url = '', body = {}, headers = {}) => {
    const reqOptions = {
        headers: merge(
            {
                'content-type': 'application/json',
            },
            headers,
        ),
        method: 'post',
        data: body,
        withCredentials: REACT_APP_WITH_CREDENTIALS ? true : undefined,
    };

    return axiosCall(`${HOST}/${url}`, reqOptions);
};

export const upload = async (url, body = {}, headers = {}, files = {}, progressCallback = null) => {
    const formData = new FormData();

    Object.entries(body).forEach(([key, value]) => {
        formData.append(key, typeof value === 'string' ? value : JSON.stringify(value));
    });

    Object.entries(files).forEach(([key, value]) => {
        formData.append(key, value);
    });

    const reqOptions = {
        headers,
        method: 'post',
        data: formData,
        onUploadProgress: progressCallback,
        withCredentials: REACT_APP_WITH_CREDENTIALS ? true : undefined,
    };

    return axiosCall(`${HOST}/${url}`, reqOptions);
};
