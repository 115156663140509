import React from 'react';

import { Modal } from 'antd';
import FollowerTrend from '../FollowerTrend';

export default ({ visible, onCancel }) => {
    return (
        <Modal title="Follower" visible={visible} width={600} footer={null} onCancel={onCancel}>
            <FollowerTrend />
        </Modal>
    );
};
